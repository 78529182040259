import axios from 'axios';
import { AppConfig } from 'mocks/restHandlers';
import config from '../../config.json';

export const getConfig = async () => {
  const { data, headers } = await axios.get(`/config.json`);

  const CSPHeader =
    headers['content-security-policy-report-only'] || headers['content-security-policy'];
  const nonceMatch = CSPHeader?.match(/nonce-([\w\d]+)/);

  if (nonceMatch) {
    const nonce = nonceMatch[1];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).__NONCE__ = nonce;

    const styleTag = document.createElement('style');

    styleTag.id = '_goober';
    styleTag.setAttribute('nonce', nonce);
    styleTag.textContent = '/* do not remove */';

    document.head.appendChild(styleTag);
  }

  return data;
};

export const getConfigValue = (endpoint?: keyof AppConfig) => {
  const mode = import.meta.env.MODE;
  if (!window.config) {
    // cypress
    window.config = config;
  }
  return endpoint ? window.config[mode][endpoint] : window.config[mode];
};
