import { ledgerAccountingWorkspace } from '@wyden/layout-configurations/ledgerAccounting';
import { historyWorkspace } from './layout-configurations/history';
import { getTradingWorkspace } from './layout-configurations/trading';
import { Capability } from './services/graphql/generated/graphql';

export const PREDEFINED_WORKSPACES_IDS = {
  EMPTY: 'empty',
  FORMS: 'forms',
  CONFIG: 'config',
  TRADING: 'trading',
  HISTORY: 'history',
  LEDGER_ACCOUNTING: 'ledger-accounting',
  INSTRUMENTS: 'instruments',
  PORTFOLIO_DATA: 'portfolio-data',
  PERMISSIONS: 'permissions',
  VENUE_ACCOUNTS: 'accounts',
};

// TODO we will need to somehow get those types from BE in the future
export const PREDEFINED_GROUPS = {
  ADMIN: 'admin',
  TRADER: 'trader',
  RETAIL: 'retail',
  MANAGER: 'manager',
} as const;

export const PORTFOLIO_GROUP_TAG_NAME = 'portfolioGroup';

export const ERRORS = {
  NETWORK_ERROR: 'NETWORK_ERROR',
  CLIENT_ERROR: 'CLIENT_ERROR',
} as const;

export const PREDEFINED_WORKSPACES = {
  TRADING: {
    id: PREDEFINED_WORKSPACES_IDS.TRADING,
    name: 'Trading',
    json: getTradingWorkspace(),
    isTrading: true,
  },
  HISTORY: {
    id: PREDEFINED_WORKSPACES_IDS.HISTORY,
    name: 'History',
    json: historyWorkspace,
  },
  LEDGER_ACCOUNTING: {
    id: PREDEFINED_WORKSPACES_IDS.LEDGER_ACCOUNTING,
    name: 'Ledger Accounting',
    json: ledgerAccountingWorkspace,
  },
};

export const BREAKPOINTS = {
  xs: 0,
  min: 300,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export const BORDER_RADIUS = '8px';

export const ORDERBOOK_TICK_SIZE_OPTIONS = [0.001, 0.05, 0.1, 0.5];

export const INFINITE_SCROLL_PAGE_SIZE = 50;

export const CAPABILITIES_STATUSES_FOR_DISPLAY = [
  Capability.Trading,
  Capability.ReferenceData,
  Capability.MarketData,
];

export enum ENTITLEMENTS {
  'Algo Engine' = 'PLATFORM_ALGO_ENGINE',
  'Brokerage Engine' = 'PLATFORM_BROKERAGE_ENGINE',
  'Connectors 10' = 'CONNECTORS_10',
  'Connectors 3' = 'CONNECTORS_3',
  'Connectors 30' = 'CONNECTORS_30',
  'Core' = 'PLATFORM_CORE',
  'Custom BPCE' = 'CUSTOM_BPCE',
  'Custom Garanti' = 'CUSTOM_GARANTI',
  'Market Making Engine' = 'MM_ENGINE',
  'Principal Trading' = 'PLATFORM_PRINCIPAL_TRADING',
  'Quoting Engine' = 'PLATFORM_QUOTING_ENGINE',
  'Settlement & Treasury Engine' = 'PLATFORM_SETTLEMENT_TREASURY_ENGINE',
  'Wyden Exchange' = 'PLATFORM_WYDEN_EXCHANGE',
}
