import { z } from 'zod';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type AccountCapabilities = {
  __typename?: 'AccountCapabilities';
  accountPositionsRetrievalSupported?: Maybe<Scalars['Boolean']['output']>;
  allocationSupported?: Maybe<Scalars['Boolean']['output']>;
  balanceRetrievalBySubAccount?: Maybe<Scalars['Boolean']['output']>;
  balanceRetrievalSupported?: Maybe<Scalars['Boolean']['output']>;
  balanceStreamingSupported?: Maybe<Scalars['Boolean']['output']>;
  depositAddressRetrievalSupported?: Maybe<Scalars['Boolean']['output']>;
  extAccountsRetrievalSupported?: Maybe<Scalars['Boolean']['output']>;
  orderReconciliationSupported?: Maybe<Scalars['Boolean']['output']>;
  subAccountRetrievalSupported?: Maybe<Scalars['Boolean']['output']>;
  walletHistorySupported?: Maybe<Scalars['Boolean']['output']>;
  withdrawalSupported?: Maybe<Scalars['Boolean']['output']>;
};

export type AccountCashTransfer = {
  __typename?: 'AccountCashTransfer';
  currency: Scalars['String']['output'];
  dateTime: Scalars['String']['output'];
  description: Scalars['String']['output'];
  executionId: Scalars['String']['output'];
  feeAccountId?: Maybe<Scalars['String']['output']>;
  feeAccountName?: Maybe<Scalars['String']['output']>;
  feePortfolioId?: Maybe<Scalars['String']['output']>;
  feePortfolioName?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  settled: Scalars['String']['output'];
  settledDateTime?: Maybe<Scalars['Float']['output']>;
  sourceAccountId: Scalars['String']['output'];
  sourceAccountName: Scalars['String']['output'];
  targetAccountId: Scalars['String']['output'];
  targetAccountName: Scalars['String']['output'];
  updatedAt: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
  venueExecutionId?: Maybe<Scalars['String']['output']>;
};

export type ActivateVenueAccountInput = {
  correlationObject?: InputMaybe<Scalars['String']['input']>;
  venueAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type AddOrRemoveGroupPermissionsInput = {
  groupName: Scalars['String']['input'];
  permissions: Array<PermissionInput>;
};

export type AddOrRemoveUserPermissionsInput = {
  permissions: Array<PermissionInput>;
  username: Scalars['String']['input'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  status: ApiKeyStatus;
};

export type ApiKeyResponse = {
  __typename?: 'ApiKeyResponse';
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  secret: Scalars['String']['output'];
  status: ApiKeyStatus;
};

export enum ApiKeyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum AssetClass {
  Forex = 'FOREX',
}

export type AuditLogEvent = {
  __typename?: 'AuditLogEvent';
  createdAt?: Maybe<Scalars['String']['output']>;
  payload: Scalars['String']['output'];
  payloadType: PayloadType;
  source: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type BaseInstrumentResponse = {
  __typename?: 'BaseInstrumentResponse';
  assetClass: AssetClass;
  description?: Maybe<Scalars['String']['output']>;
  inverseContract?: Maybe<Scalars['Boolean']['output']>;
  quoteCurrency: Scalars['String']['output'];
  symbol?: Maybe<Scalars['String']['output']>;
  /** Unique name of the (internal or external) venue when this Instrument can be traded */
  venueName: Scalars['String']['output'];
  venueType: VenueType;
};

/** Notifies the user if the Cancel request has been rejected */
export type CancelRejectResponse = {
  __typename?: 'CancelRejectResponse';
  cancelRejectReason?: Maybe<Scalars['String']['output']>;
  cancelRejectResponseTo?: Maybe<CancelRejectResponseTo>;
  /** Id of the cancel request that was rejected */
  clOrderId?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['String']['output']>;
  /** Current Order status */
  orderStatus?: Maybe<OrderStatus>;
  /** Id of the Order requested to be cancelled */
  origClOrderId?: Maybe<Scalars['String']['output']>;
  /** Id of the Order requested to be cancelled */
  origOrderId?: Maybe<Scalars['String']['output']>;
  /**
   * Contains an error code
   * TODO: To be converted to enum in scope of https://algotrader.atlassian.net/browse/AC-1335
   */
  result?: Maybe<Scalars['String']['output']>;
};

export enum CancelRejectResponseTo {
  OrderCancelReplaceRequest = 'ORDER_CANCEL_REPLACE_REQUEST',
  OrderCancelRequest = 'ORDER_CANCEL_REQUEST',
  Unspecified = 'UNSPECIFIED',
}

export enum Capability {
  Account = 'ACCOUNT',
  CapabilityUnspecified = 'CAPABILITY_UNSPECIFIED',
  DropCopy = 'DROP_COPY',
  GenericEvent = 'GENERIC_EVENT',
  HistoricalData = 'HISTORICAL_DATA',
  MarketData = 'MARKET_DATA',
  OffExchange = 'OFF_EXCHANGE',
  Reconciliation = 'RECONCILIATION',
  ReferenceData = 'REFERENCE_DATA',
  RequestForQuote = 'REQUEST_FOR_QUOTE',
  RequestRelay = 'REQUEST_RELAY',
  Trading = 'TRADING',
  Transfer = 'TRANSFER',
}

export type ClientAssetTrade = Trade & {
  __typename?: 'ClientAssetTrade';
  counterPortfolioId: Scalars['String']['output'];
  counterPortfolioName: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  dateTime: Scalars['String']['output'];
  description: Scalars['String']['output'];
  executionId: Scalars['String']['output'];
  extOrderId: Scalars['String']['output'];
  fee: Scalars['Float']['output'];
  feeCurrency: Scalars['String']['output'];
  instrument?: Maybe<InstrumentResponse>;
  intOrderId: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  portfolioId: Scalars['String']['output'];
  portfolioName: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  rootExecution?: Maybe<RootExecution>;
  settled: Scalars['String']['output'];
  settledDateTime?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
  venueExecutionId?: Maybe<Scalars['String']['output']>;
};

export type ClientCashTrade = Trade & {
  __typename?: 'ClientCashTrade';
  baseCurrency: Scalars['String']['output'];
  counterPortfolioId: Scalars['String']['output'];
  counterPortfolioName: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  dateTime: Scalars['String']['output'];
  description: Scalars['String']['output'];
  executionId: Scalars['String']['output'];
  extOrderId: Scalars['String']['output'];
  fee: Scalars['Float']['output'];
  feeCurrency: Scalars['String']['output'];
  intOrderId: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  parentOrderId?: Maybe<Scalars['String']['output']>;
  portfolioId: Scalars['String']['output'];
  portfolioName: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  rootExecution?: Maybe<RootExecution>;
  rootOrderId?: Maybe<Scalars['String']['output']>;
  settled: Scalars['String']['output'];
  settledDateTime?: Maybe<Scalars['Float']['output']>;
  underlyingExecutionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
  venueExecutionId?: Maybe<Scalars['String']['output']>;
};

export type CollectionPredicateInput = {
  field?: InputMaybe<OrderStateCollectionField>;
  method?: InputMaybe<CollectionPredicateType>;
  value: Array<InputMaybe<Scalars['String']['input']>>;
};

export enum CollectionPredicateType {
  In = 'IN',
}

export type ConfigField = {
  __typename?: 'ConfigField';
  defaultValue?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enumValues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  propertyKey?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  secret?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ConfigInstrumentValidationResult = {
  __typename?: 'ConfigInstrumentValidationResult';
  errors: Array<ConfigValidationError>;
  instrumentId: Scalars['String']['output'];
  isValid: Scalars['Boolean']['output'];
  preventsMarketData: Scalars['Boolean']['output'];
  preventsTrading: Scalars['Boolean']['output'];
};

export type ConfigValidationError = {
  __typename?: 'ConfigValidationError';
  errorMessage?: Maybe<Scalars['String']['output']>;
  errorType: ErrorType;
  fieldName: Scalars['String']['output'];
  preventsMarketData: Scalars['Boolean']['output'];
  preventsTrading: Scalars['Boolean']['output'];
};

export type ConfigValidationResult = {
  __typename?: 'ConfigValidationResult';
  resultsPerInstrument: Array<ConfigInstrumentValidationResult>;
};

export enum ConfigurationLevel {
  Portfolio = 'PORTFOLIO',
  PortfolioGroup = 'PORTFOLIO_GROUP',
  PortfolioGroupInstrument = 'PORTFOLIO_GROUP_INSTRUMENT',
  PortfolioInstrument = 'PORTFOLIO_INSTRUMENT',
}

export enum ConfigurationType {
  Execution = 'EXECUTION',
  Hedging = 'HEDGING',
  Pricing = 'PRICING',
  Quoting = 'QUOTING',
}

export type ConversionRate = {
  baseCurrency: Scalars['String']['input'];
  quoteCurrency: Scalars['String']['input'];
  rate: Scalars['String']['input'];
};

export type ConversionSourceResponse = {
  __typename?: 'ConversionSourceResponse';
  priority: Scalars['Int']['output'];
  venueAccount: Scalars['String']['output'];
  venueAccountName: Scalars['String']['output'];
};

export type ConversionSourceVenueAccount = {
  __typename?: 'ConversionSourceVenueAccount';
  venueAccount: Scalars['String']['output'];
  venueAccountName: Scalars['String']['output'];
};

export type ConversionSourceVenueAccountResponse = {
  __typename?: 'ConversionSourceVenueAccountResponse';
  venueAccounts: Array<ConversionSourceVenueAccount>;
  venueName: Scalars['String']['output'];
};

export type Counterparty = {
  __typename?: 'Counterparty';
  orderId?: Maybe<Scalars['String']['output']>;
  portfolioId?: Maybe<Scalars['String']['output']>;
  portfolioName?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  referenceType?: Maybe<ParticipantReferenceType>;
  venueAccount?: Maybe<Scalars['String']['output']>;
  venueAccountName?: Maybe<Scalars['String']['output']>;
  volume: Scalars['Float']['output'];
};

export type CreateBaseInstrumentInput = {
  assetClass: AssetClass;
  description?: InputMaybe<Scalars['String']['input']>;
  inverseContract?: InputMaybe<Scalars['Boolean']['input']>;
  quoteCurrency: Scalars['String']['input'];
  symbol?: InputMaybe<Scalars['String']['input']>;
  venueName: Scalars['String']['input'];
  venueType?: InputMaybe<VenueType>;
};

export type CreateConversionSourceInput = {
  priority?: InputMaybe<Scalars['Int']['input']>;
  venueAccount: Scalars['String']['input'];
};

export type CreateForexSpotPropertiesInput = {
  baseCurrency: Scalars['String']['input'];
};

export type CreateInstrumentIdentifiersInput = {
  /** Instrument identifier that will be used when communicating with the external venue directly */
  adapterTicker?: InputMaybe<Scalars['String']['input']>;
  /** Instrument identifier that will be used when communicating with TradingView */
  tradingViewId?: InputMaybe<Scalars['String']['input']>;
  /** Venue identifier for this instrument that will be used when communicating with TradingView */
  venueTradingViewId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInstrumentInput = {
  baseInstrument: CreateBaseInstrumentInput;
  correlationObject?: InputMaybe<Scalars['String']['input']>;
  forexSpotProperties: CreateForexSpotPropertiesInput;
  instrumentIdentifiers: CreateInstrumentIdentifiersInput;
  tradingConstraints: CreateTradingConstraintsInput;
};

export type CreateInstrumentResult = {
  __typename?: 'CreateInstrumentResult';
  status?: Maybe<Scalars['String']['output']>;
};

export type CreatePortfolioInput = {
  correlationObject?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  portfolioCurrency: Scalars['String']['input'];
  portfolioType?: InputMaybe<PortfolioType>;
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
};

export type CreateRateSubscriptionInput = {
  baseCurrency: Scalars['String']['input'];
  quoteCurrency: Scalars['String']['input'];
};

export type CreateSettlementRunInput = {
  accountIds: Array<Scalars['String']['input']>;
  /** Date format: ISO8601 without time part */
  date: Scalars['String']['input'];
};

export type CreateTradingConstraintsInput = {
  /** Contract size */
  contractSize?: InputMaybe<Scalars['String']['input']>;
  /**
   * Maximum trading price - constraints Limit and StopLimit Orders
   * Optional, no constraint if not set
   */
  maxPrice?: InputMaybe<Scalars['String']['input']>;
  /** Maximum quantity allowed for trading */
  maxQty: Scalars['String']['input'];
  /**
   * Maximum quote quantity allowed for trading
   * Optional, no constraint if not set
   */
  maxQuoteQty?: InputMaybe<Scalars['String']['input']>;
  /**
   * Minimum notional value that should be used for trading
   * Typically it can be calculated by multiplying ContractSize * NotionalPrice (Note: For FOREX Spot, the contract size is always 1 = baseCurrency quantity)
   * For more details, see: https://www.investopedia.com/terms/n/notionalvalue.asp
   */
  minNotional?: InputMaybe<Scalars['String']['input']>;
  /**
   * Minimum trading price - constraints Limit and StopLimit Orders
   * Optional, no constraint if not set
   */
  minPrice?: InputMaybe<Scalars['String']['input']>;
  /** Minimum quantity allowed for trading */
  minQty: Scalars['String']['input'];
  /**
   * Minimum quote quantity allowed for trading
   * Optional, no constraint if not set
   */
  minQuoteQty?: InputMaybe<Scalars['String']['input']>;
  /**
   * Minimum price increment
   * - constraints Limit and StopLimit Orders
   * - defines tickSize in OrderBook
   */
  priceIncr: Scalars['String']['input'];
  /** Minimum quantity increment */
  qtyIncr: Scalars['String']['input'];
  /** Minimum quote quantity increment */
  quoteQtyIncr?: InputMaybe<Scalars['String']['input']>;
  /** Defines whether this Instrument can be traded or not */
  tradeable: Scalars['Boolean']['input'];
};

export type CreateVenueAccountInput = {
  apiKey?: InputMaybe<Scalars['String']['input']>;
  apiSecret?: InputMaybe<Scalars['String']['input']>;
  correlationObject?: InputMaybe<Scalars['String']['input']>;
  keyValues?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  venueAccountId?: InputMaybe<Scalars['String']['input']>;
  venueAccountName: Scalars['String']['input'];
  venueName: Scalars['String']['input'];
};

export type CreateWalletAccountInput = {
  correlationObject?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export enum CurrencyCategory {
  Crypto = 'CRYPTO',
  Fiat = 'FIAT',
}

export type CurrencyInput = {
  displayPrecision: Scalars['Int']['input'];
  precision: Scalars['Int']['input'];
  symbol: Scalars['String']['input'];
  type: CurrencyCategory;
};

export type CurrencyResponse = {
  __typename?: 'CurrencyResponse';
  displayPrecision: Scalars['Int']['output'];
  precision: Scalars['Int']['output'];
  symbol: Scalars['String']['output'];
  type: CurrencyCategory;
};

export type CurrencySearchInput = {
  /**
   * Result set will be restricted to given list of symbols (using "equals" match mode)
   * Optional - if field is null or empty then all permitted symbols will be returned
   */
  symbols?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<CurrencyCategory>;
};

export enum CurrencyType {
  BaseCurrency = 'BASE_CURRENCY',
  QuoteCurrency = 'QUOTE_CURRENCY',
  SpecificCurrency = 'SPECIFIC_CURRENCY',
}

export type DatePredicate = {
  field?: InputMaybe<OrderStateDateField>;
  method?: InputMaybe<DatePredicateType>;
  value: Scalars['String']['input'];
};

export enum DatePredicateType {
  From = 'FROM',
  To = 'TO',
}

export enum DayOfTheWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DeactivateVenueAccountInput = {
  correlationObject?: InputMaybe<Scalars['String']['input']>;
  venueAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type DefaultTif = {
  __typename?: 'DefaultTif';
  key?: Maybe<OrderType>;
  value?: Maybe<Tif>;
};

export type DeleteRateSubscriptionInput = {
  baseCurrency: Scalars['String']['input'];
  quoteCurrency: Scalars['String']['input'];
};

export type Deposit = {
  __typename?: 'Deposit';
  account: Scalars['String']['output'];
  accountName: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  dateTime: Scalars['String']['output'];
  description: Scalars['String']['output'];
  executionId: Scalars['String']['output'];
  feeAccountId?: Maybe<Scalars['String']['output']>;
  feeAccountName?: Maybe<Scalars['String']['output']>;
  feePortfolioId?: Maybe<Scalars['String']['output']>;
  feePortfolioName?: Maybe<Scalars['String']['output']>;
  portfolioId: Scalars['String']['output'];
  portfolioName: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  settled: Scalars['String']['output'];
  settledDateTime?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
  venueExecutionId?: Maybe<Scalars['String']['output']>;
};

export type DetailedCapabilities = {
  __typename?: 'DetailedCapabilities';
  accountCapabilities?: Maybe<AccountCapabilities>;
  connectorCapabilities?: Maybe<Array<Maybe<Capability>>>;
  dropCopyCapabilities?: Maybe<Scalars['Boolean']['output']>;
  genericEventCapabilities?: Maybe<Scalars['Boolean']['output']>;
  historicalDataCapabilities?: Maybe<HistoricalDataCapabilities>;
  marketDataCapabilities?: Maybe<MarketDataCapabilities>;
  rateLimited?: Maybe<Scalars['Boolean']['output']>;
  referenceDataCapabilities?: Maybe<ReferenceDataCapabilities>;
  tradingCapabilities?: Maybe<TradingCapabilities>;
  transferCapabilities?: Maybe<Scalars['Boolean']['output']>;
};

export type EffectiveVenueAccountsResponse = {
  __typename?: 'EffectiveVenueAccountsResponse';
  isSor?: Maybe<Scalars['Boolean']['output']>;
  venueAccounts: Array<Scalars['String']['output']>;
};

export enum ErrorType {
  Invalid = 'INVALID',
  Missing = 'MISSING',
}

export type EventLogResponse = {
  __typename?: 'EventLogResponse';
  clientId: Scalars['String']['output'];
  correlationObject?: Maybe<Scalars['String']['output']>;
  eventType: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: Status;
  timestamp: Scalars['String']['output'];
};

export enum ExecType {
  Calculated = 'CALCULATED',
  Canceled = 'CANCELED',
  DoneForDay = 'DONE_FOR_DAY',
  Expired = 'EXPIRED',
  Fill = 'FILL',
  New = 'NEW',
  OrderStatus = 'ORDER_STATUS',
  PartialFill = 'PARTIAL_FILL',
  PendingCancel = 'PENDING_CANCEL',
  PendingNew = 'PENDING_NEW',
  PendingReplace = 'PENDING_REPLACE',
  Rejected = 'REJECTED',
  Replaced = 'REPLACED',
  Restated = 'RESTATED',
  Stopped = 'STOPPED',
  Suspended = 'SUSPENDED',
  Trade = 'TRADE',
  TradeCancel = 'TRADE_CANCEL',
  TradeCorrect = 'TRADE_CORRECT',
  TradeHasBeenReleasedToClearing = 'TRADE_HAS_BEEN_RELEASED_TO_CLEARING',
  TradeInAClearingHold = 'TRADE_IN_A_CLEARING_HOLD',
  TriggeredOrActivatedBySystem = 'TRIGGERED_OR_ACTIVATED_BY_SYSTEM',
  Unspecified = 'UNSPECIFIED',
}

export type ExecutionConfiguration = {
  __typename?: 'ExecutionConfiguration';
  agencyTargetInstrument?: Maybe<InstrumentResponse>;
  agencyTradingAccount?: Maybe<Scalars['String']['output']>;
  agencyTradingAccountName?: Maybe<Scalars['String']['output']>;
  chargeExchangeFee?: Maybe<Scalars['Boolean']['output']>;
  counterPortfolioId?: Maybe<Scalars['String']['output']>;
  counterPortfolioName?: Maybe<Scalars['String']['output']>;
  discloseTradingVenue?: Maybe<Scalars['Boolean']['output']>;
  executionMode?: Maybe<ExecutionMode>;
  fixedFee?: Maybe<Scalars['Float']['output']>;
  fixedFeeCurrency?: Maybe<Scalars['String']['output']>;
  minFee?: Maybe<Scalars['Float']['output']>;
  minFeeCurrency?: Maybe<Scalars['String']['output']>;
  percentageFee?: Maybe<Scalars['Float']['output']>;
  percentageFeeCurrency?: Maybe<Scalars['String']['output']>;
  percentageFeeCurrencyType?: Maybe<CurrencyType>;
  sorTarget?: Maybe<SorTarget>;
  sorTradingAccountDescs?: Maybe<Array<VenueAccountDesc>>;
  tradingMode?: Maybe<TradingMode>;
};

export type ExecutionConfigurationInput = {
  agencyTargetInstrumentId?: InputMaybe<Scalars['String']['input']>;
  agencyTradingAccount?: InputMaybe<Scalars['String']['input']>;
  chargeExchangeFee?: InputMaybe<Scalars['Boolean']['input']>;
  counterPortfolioId?: InputMaybe<Scalars['String']['input']>;
  discloseTradingVenue?: InputMaybe<Scalars['Boolean']['input']>;
  executionMode?: InputMaybe<ExecutionMode>;
  fixedFee?: InputMaybe<Scalars['Float']['input']>;
  fixedFeeCurrency?: InputMaybe<Scalars['String']['input']>;
  minFee?: InputMaybe<Scalars['Float']['input']>;
  minFeeCurrency?: InputMaybe<Scalars['String']['input']>;
  percentageFee?: InputMaybe<Scalars['Float']['input']>;
  percentageFeeCurrency?: InputMaybe<Scalars['String']['input']>;
  percentageFeeCurrencyType?: InputMaybe<CurrencyType>;
  sorTarget?: InputMaybe<SorTargetInput>;
  sorTradingAccounts?: InputMaybe<Array<Scalars['String']['input']>>;
  tradingMode?: InputMaybe<TradingMode>;
};

export enum ExecutionMode {
  Simple = 'SIMPLE',
  Sor = 'SOR',
}

export type ExecutionReportResponse = {
  __typename?: 'ExecutionReportResponse';
  avgPrice?: Maybe<Scalars['String']['output']>;
  clOrderId?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  cumQty?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  execType?: Maybe<ExecType>;
  executionId?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  feeCurrency?: Maybe<Scalars['String']['output']>;
  instrumentId?: Maybe<Scalars['String']['output']>;
  instrumentType?: Maybe<InstrumentType>;
  lastPrice?: Maybe<Scalars['String']['output']>;
  lastQty?: Maybe<Scalars['Float']['output']>;
  leavesQty?: Maybe<Scalars['Float']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  orderQty?: Maybe<Scalars['Float']['output']>;
  orderStatus?: Maybe<OrderStatus>;
  orderStatusRequestId?: Maybe<Scalars['String']['output']>;
  origClOrderId?: Maybe<Scalars['String']['output']>;
  parentOrderId?: Maybe<Scalars['String']['output']>;
  portfolioId?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  sequenceNumber?: Maybe<Scalars['Int']['output']>;
  side?: Maybe<Side>;
  symbol?: Maybe<Scalars['String']['output']>;
  targetVenueAccount?: Maybe<Scalars['String']['output']>;
  targetVenueTicker?: Maybe<Scalars['String']['output']>;
  targetVenueTimestamp?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  underlyingVenueAccount?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  venueAccount?: Maybe<Scalars['String']['output']>;
  venueExecutionId?: Maybe<Scalars['String']['output']>;
};

export type FlatPermission = {
  __typename?: 'FlatPermission';
  groups?: Maybe<Array<GroupPermission>>;
  resource: Resource;
  resourceId?: Maybe<Scalars['String']['output']>;
  userScopes?: Maybe<Array<Scope>>;
  username: Scalars['String']['output'];
};

export type ForexSpotPropertiesResponse = {
  __typename?: 'ForexSpotPropertiesResponse';
  baseCurrency: Scalars['String']['output'];
};

export type GroupPermission = {
  __typename?: 'GroupPermission';
  groupName: Scalars['String']['output'];
  groupScopes?: Maybe<Array<Scope>>;
};

export type GroupResponse = {
  __typename?: 'GroupResponse';
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
};

export type GroupsPermissionsForResourceResponse = {
  __typename?: 'GroupsPermissionsForResourceResponse';
  groupName: Scalars['String']['output'];
  scopes: Array<Scope>;
};

export enum HealthStatus {
  Alive = 'ALIVE',
  Dead = 'DEAD',
  HealthStatusUnspecified = 'HEALTH_STATUS_UNSPECIFIED',
  Unhealthy = 'UNHEALTHY',
}

export type HedgeResult = {
  __typename?: 'HedgeResult';
  breakEvenFxRate?: Maybe<Scalars['Float']['output']>;
  clobOrderId: Scalars['String']['output'];
  estimatedPrice?: Maybe<Scalars['Float']['output']>;
  executionPrice?: Maybe<Scalars['Float']['output']>;
  hedgeOrderAmount?: Maybe<Scalars['Float']['output']>;
  hedgeOrderAmountInClobQuoteCurrency?: Maybe<Scalars['Float']['output']>;
  hedgeOrderId: Scalars['String']['output'];
  hedgeOrderLimitPrice?: Maybe<Scalars['Float']['output']>;
  hedgeOrderQuantity: Scalars['Float']['output'];
  hedgeOrderSide: Side;
  hedgingVenue: Scalars['String']['output'];
  matchId: Scalars['String']['output'];
  primarySymbolQuote?: Maybe<MarketQuote>;
  secondarySymbolQuote?: Maybe<MarketQuote>;
  success: Scalars['Boolean']['output'];
  timestamp: Scalars['String']['output'];
};

export type HedgingConfiguration = {
  __typename?: 'HedgingConfiguration';
  autoHedging?: Maybe<Scalars['Boolean']['output']>;
  targetAccountId?: Maybe<Scalars['String']['output']>;
  targetAccountName?: Maybe<Scalars['String']['output']>;
  thresholdConfiguration?: Maybe<Array<ThresholdConfiguration>>;
};

export type HedgingConfigurationInput = {
  autoHedging?: InputMaybe<Scalars['Boolean']['input']>;
  targetAccountId?: InputMaybe<Scalars['String']['input']>;
  thresholdConfiguration?: InputMaybe<Array<ThresholdConfigurationInput>>;
};

export type HistoricalDataCapabilities = {
  __typename?: 'HistoricalDataCapabilities';
  supportingCollectingAsks?: Maybe<Scalars['Boolean']['output']>;
  supportingCollectingBars?: Maybe<Scalars['Boolean']['output']>;
  supportingCollectingBidAsks?: Maybe<Scalars['Boolean']['output']>;
  supportingCollectingBids?: Maybe<Scalars['Boolean']['output']>;
  supportingCollectingTrades?: Maybe<Scalars['Boolean']['output']>;
};

export type HistoryOrderStatusesInput = {
  includeRelated?: InputMaybe<Scalars['Boolean']['input']>;
  orderId: Scalars['String']['input'];
};

export type InstrumentConfiguration = {
  __typename?: 'InstrumentConfiguration';
  executionConfiguration?: Maybe<ExecutionConfiguration>;
  instrumentGroupConfiguration?: Maybe<InstrumentGroupConfiguration>;
  instrumentId: Scalars['ID']['output'];
  pricingConfiguration?: Maybe<InstrumentPricingConfiguration>;
  tradeable?: Maybe<Scalars['Boolean']['output']>;
};

export type InstrumentConfigurationInput = {
  executionConfiguration?: InputMaybe<ExecutionConfigurationInput>;
  instrumentId: Scalars['ID']['input'];
  pricingConfiguration?: InputMaybe<InstrumentPricingConfigurationInput>;
  tradeable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InstrumentEdge = {
  __typename?: 'InstrumentEdge';
  cursor: Scalars['String']['output'];
  node: InstrumentResponse;
};

export type InstrumentGroupConfiguration = {
  __typename?: 'InstrumentGroupConfiguration';
  executionConfiguration?: Maybe<ExecutionConfiguration>;
  instrumentId: Scalars['ID']['output'];
  pricingConfiguration?: Maybe<InstrumentPricingConfiguration>;
  tradeable?: Maybe<Scalars['Boolean']['output']>;
};

export type InstrumentGroupConfigurationInput = {
  executionConfiguration?: InputMaybe<ExecutionConfigurationInput>;
  instrumentId: Scalars['ID']['input'];
  pricingConfiguration?: InputMaybe<InstrumentPricingConfigurationInput>;
  tradeable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InstrumentIdentifiersResponse = {
  __typename?: 'InstrumentIdentifiersResponse';
  /** Instrument identifier that will be used when communicating with the external venue directly */
  adapterTicker?: Maybe<Scalars['String']['output']>;
  /**
   * Wyden-system instrument identifier, unique in scope of the Wyden system across all instruments.
   * Immutable, cannot be changed once set.
   */
  instrumentId: Scalars['String']['output'];
  /** Instrument identifier that will be used when communicating with TradingView */
  tradingViewId?: Maybe<Scalars['String']['output']>;
  /** Venue identifier for this instrument that will be used when communicating with TradingView */
  venueTradingViewId?: Maybe<Scalars['String']['output']>;
};

export type InstrumentIdsPredicateInput = {
  instrumentIds: Array<Scalars['String']['input']>;
  method: InstrumentIdsPredicateType;
};

export enum InstrumentIdsPredicateType {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
}

export type InstrumentKey = {
  __typename?: 'InstrumentKey';
  instrument: InstrumentResponse;
  venueAccount: Scalars['String']['output'];
  venueAccountName: Scalars['String']['output'];
};

export type InstrumentKeyInput = {
  instrumentId: Scalars['String']['input'];
  venueAccount: Scalars['String']['input'];
};

export type InstrumentPricingConfiguration = {
  __typename?: 'InstrumentPricingConfiguration';
  markup?: Maybe<Scalars['Float']['output']>;
  pricingSource?: Maybe<Array<InstrumentKey>>;
};

export type InstrumentPricingConfigurationInput = {
  markup?: InputMaybe<Scalars['Float']['input']>;
  pricingSource?: InputMaybe<Array<InstrumentKeyInput>>;
};

export type InstrumentQuotingConfiguration = {
  __typename?: 'InstrumentQuotingConfiguration';
  askMarkup?: Maybe<Scalars['Float']['output']>;
  bidMarkup?: Maybe<Scalars['Float']['output']>;
  deactivated: Scalars['Boolean']['output'];
  hedgingSafetyMargin?: Maybe<Scalars['Float']['output']>;
  instrumentId: Scalars['String']['output'];
  maxQuantityFactor?: Maybe<Scalars['Float']['output']>;
  maximumDepth?: Maybe<Scalars['Float']['output']>;
  minQuantityFactor?: Maybe<Scalars['Float']['output']>;
  priceLevelIncrement?: Maybe<Scalars['Float']['output']>;
  quantityIncrement?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Not used anymore */
  quoteTTL?: Maybe<Scalars['Int']['output']>;
  sourceConfigurations: Array<SourceConfiguration>;
};

export type InstrumentQuotingConfigurationInput = {
  askMarkup?: InputMaybe<Scalars['Float']['input']>;
  bidMarkup?: InputMaybe<Scalars['Float']['input']>;
  deactivated: Scalars['Boolean']['input'];
  hedgingSafetyMargin?: InputMaybe<Scalars['Float']['input']>;
  instrumentId: Scalars['String']['input'];
  maxQuantityFactor?: InputMaybe<Scalars['Float']['input']>;
  maximumDepth?: InputMaybe<Scalars['Float']['input']>;
  minQuantityFactor?: InputMaybe<Scalars['Float']['input']>;
  priceLevelIncrement?: InputMaybe<Scalars['Float']['input']>;
  quantityIncrement?: InputMaybe<Scalars['Float']['input']>;
  /** @deprecated Not used anymore */
  quoteTTL?: InputMaybe<Scalars['Int']['input']>;
  sourceConfigurations: Array<SourceConfigurationInput>;
};

/** Describes an Instrument in Wyden system */
export type InstrumentResponse = {
  __typename?: 'InstrumentResponse';
  /**
   * Not-null if Instrument has been archived.
   * Archived Instruments cannot be traded, but it is still possible to view trade history on them and hold an open position on it.
   */
  archivedAt?: Maybe<Scalars['String']['output']>;
  baseInstrument: BaseInstrumentResponse;
  /** Date the instrument was created in our system */
  createdAt?: Maybe<Scalars['String']['output']>;
  /** Not-null for baseInstrument.assetClass=FOREX */
  forexSpotProperties?: Maybe<ForexSpotPropertiesResponse>;
  instrumentIdentifiers: InstrumentIdentifiersResponse;
  tradingConstraints: TradingConstraintsResponse;
  /** Date of a last update of the instrument in our system */
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum InstrumentSortBy {
  CreatedAt = 'CREATED_AT',
  InstrumentId = 'INSTRUMENT_ID',
  Symbol = 'SYMBOL',
}

export enum InstrumentType {
  Bond = 'BOND',
  Comodity = 'COMODITY',
  Forex = 'FOREX',
  Fund = 'FUND',
  Future = 'FUTURE',
  Index = 'INDEX',
  InstrumentTypeUndetermined = 'INSTRUMENT_TYPE_UNDETERMINED',
  Option = 'OPTION',
  PerpetualSwap = 'PERPETUAL_SWAP',
  Spread = 'SPREAD',
  Stock = 'STOCK',
}

export type InstrumentsConnection = {
  __typename?: 'InstrumentsConnection';
  edges: Array<InstrumentEdge>;
  pageInfo: PageInfo;
};

/**
 * Requests reference data reload for given Venue, via given venueAccount
 * Note: Only street side reference data reload is possible to request, requests with client-side venue or venue accounts will be rejected.
 */
export type InstrumentsRefreshRequestInput = {
  /**
   * Optional.
   * If provided and request has failed, an event log will be emitted that contains failure reason and this request's correlationObject
   */
  correlationObject?: InputMaybe<Scalars['String']['input']>;
  /**
   * VenueAccount name which will be used for downloading the instruments.
   * Requester has to hold 'read' scope permission on venueAccount resource, otherwise request will be rejected.
   */
  venueAccount?: InputMaybe<Scalars['String']['input']>;
};

export type InstrumentsSearchInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  instrumentIdsPredicate?: InputMaybe<InstrumentIdsPredicateInput>;
  /** Null sortBy defaults to InstrumentSortBy.INSTRUMENT_ID */
  sortBy?: InputMaybe<InstrumentSortBy>;
  /**
   * Result list will be sorted by instrumentId field
   * Default sorting order is ASC if this field is not set
   */
  sortingOrder?: InputMaybe<SortingOrder>;
  tradeable?: InputMaybe<Scalars['Boolean']['input']>;
  venueNames?: InputMaybe<Array<Scalars['String']['input']>>;
  venueType?: InputMaybe<VenueType>;
};

export type InternalExchangeConfigurationInput = {
  account: Scalars['String']['input'];
  availableOrderTypes: Array<OrderType>;
  availableTifs: Array<Tif>;
};

export type InternalExchangeConfigurationResponse = {
  __typename?: 'InternalExchangeConfigurationResponse';
  account: Scalars['String']['output'];
  accountName: Scalars['String']['output'];
  availableOrderTypes: Array<OrderType>;
  availableTifs: Array<Tif>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type KeyValueInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type KeyValues = {
  __typename?: 'KeyValues';
  key?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type LedgerEntryConnection = {
  __typename?: 'LedgerEntryConnection';
  edges: Array<LedgerEntryEdge>;
  pageInfo: PageInfo;
};

export type LedgerEntryEdge = {
  __typename?: 'LedgerEntryEdge';
  cursor: Scalars['String']['output'];
  node: LedgerEntryResponse;
};

export type LedgerEntryResponse = {
  __typename?: 'LedgerEntryResponse';
  accountId?: Maybe<Scalars['String']['output']>;
  accountName?: Maybe<Scalars['String']['output']>;
  balanceAfter?: Maybe<Scalars['Float']['output']>;
  balanceBefore?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  fee: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  orderId?: Maybe<Scalars['String']['output']>;
  portfolioId?: Maybe<Scalars['String']['output']>;
  portfolioName?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  symbol?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  type: LedgerEntryType;
  updatedAt: Scalars['Float']['output'];
};

export type LedgerEntrySearchInput = {
  accountName?: InputMaybe<Array<Scalars['String']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  ledgerEntryType?: InputMaybe<Array<LedgerEntryType>>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  portfolioId?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Result list will be sorted by updatedAt field
   * Default sorting order will be DESC if this field is not set
   */
  sortingOrder?: InputMaybe<SortingOrder>;
  symbol?: InputMaybe<Array<Scalars['String']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export enum LedgerEntryType {
  AssetTradeBuy = 'ASSET_TRADE_BUY',
  AssetTradeProceeds = 'ASSET_TRADE_PROCEEDS',
  AssetTradeSell = 'ASSET_TRADE_SELL',
  CashTradeCredit = 'CASH_TRADE_CREDIT',
  CashTradeDebit = 'CASH_TRADE_DEBIT',
  Deposit = 'DEPOSIT',
  DepositFee = 'DEPOSIT_FEE',
  Fee = 'FEE',
  Reservation = 'RESERVATION',
  ReservationRelease = 'RESERVATION_RELEASE',
  ReservationReleaseRemaining = 'RESERVATION_RELEASE_REMAINING',
  TradingFee = 'TRADING_FEE',
  Transfer = 'TRANSFER',
  TransferFee = 'TRANSFER_FEE',
  Withdrawal = 'WITHDRAWAL',
  WithdrawalFee = 'WITHDRAWAL_FEE',
  WithdrawalReservation = 'WITHDRAWAL_RESERVATION',
}

export type MarketDataCapabilities = {
  __typename?: 'MarketDataCapabilities';
  fetchingMarketDataSupported?: Maybe<Scalars['Boolean']['output']>;
  marketScanSupported?: Maybe<Scalars['Boolean']['output']>;
  otcBroker?: Maybe<Scalars['Boolean']['output']>;
  streamingLiveMarketDataSupported?: Maybe<Scalars['Boolean']['output']>;
  supportedOrderBookLevels?: Maybe<Array<Maybe<OrderBookLevel>>>;
  supportedSecurityTypes?: Maybe<Array<Maybe<InstrumentType>>>;
};

export type MarketDataResponse = {
  __typename?: 'MarketDataResponse';
  askPrice?: Maybe<Scalars['Float']['output']>;
  askSize?: Maybe<Scalars['Float']['output']>;
  bidPrice?: Maybe<Scalars['Float']['output']>;
  bidSize?: Maybe<Scalars['Float']['output']>;
  dateTime?: Maybe<Scalars['Float']['output']>;
  instrument?: Maybe<InstrumentResponse>;
  lastPrice?: Maybe<Scalars['Float']['output']>;
  lastSize?: Maybe<Scalars['Float']['output']>;
  marketDataType?: Maybe<MarketDataType>;
  side?: Maybe<Side>;
  venue?: Maybe<Scalars['String']['output']>;
  venueAccount?: Maybe<Scalars['String']['output']>;
  venueAccountName?: Maybe<Scalars['String']['output']>;
  vol?: Maybe<Scalars['Float']['output']>;
};

export enum MarketDataType {
  Ask = 'ASK',
  Bid = 'BID',
  Bidask = 'BIDASK',
  Orderbook = 'ORDERBOOK',
  Tick = 'TICK',
  Trade = 'TRADE',
}

export type MarketQuote = {
  __typename?: 'MarketQuote';
  instrument: InstrumentResponse;
  marketAskPrice: Scalars['Float']['output'];
  marketAskSize: Scalars['Float']['output'];
  marketBidPrice: Scalars['Float']['output'];
  marketBidSize: Scalars['Float']['output'];
  markupAskPrice?: Maybe<Scalars['Float']['output']>;
  markupBidPrice?: Maybe<Scalars['Float']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
};

export type MatchResponse = {
  __typename?: 'MatchResponse';
  id: Scalars['String']['output'];
  makers: Array<Counterparty>;
  primarySymbolQuotes: Array<MarketQuote>;
  secondarySymbolQuotes?: Maybe<Array<MarketQuote>>;
  taker: Counterparty;
  /** Epoch Unix Timestamp */
  timestamp: Scalars['String']['output'];
};

export type MatchesResult = {
  __typename?: 'MatchesResult';
  avgPrice?: Maybe<Scalars['Float']['output']>;
  matches: Array<MatchResponse>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  totalQuantity?: Maybe<Scalars['Float']['output']>;
};

export type MatchesSearchInput = {
  orderId?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateVenueAccount?: Maybe<MutationSubmittedResponse>;
  addGroupPermissions?: Maybe<MutationReturnValue>;
  addTransaction: MutationSubmittedResponse;
  addUserPermissions?: Maybe<MutationReturnValue>;
  cancelOrder?: Maybe<MutationReturnValue>;
  cancelReplaceOrder?: Maybe<MutationReturnValue>;
  createApiKey: ApiKeyResponse;
  createConversionSource?: Maybe<MutationSubmittedResponse>;
  createInstrument?: Maybe<CreateInstrumentResult>;
  createInternalExchangeConfiguration?: Maybe<MutationSubmittedResponse>;
  createPortfolio?: Maybe<MutationSubmittedResponse>;
  createQuotingConfiguration?: Maybe<QuotingConfigValidationResult>;
  createRateSubscription?: Maybe<MutationSubmittedResponse>;
  createVenueAccount?: Maybe<MutationSubmittedResponse>;
  createWalletAccount?: Maybe<MutationSubmittedResponse>;
  deactivateApiKey?: Maybe<MutationReturnValue>;
  deactivateVenueAccount?: Maybe<MutationSubmittedResponse>;
  deleteConversionSource?: Maybe<MutationSubmittedResponse>;
  deletePortfolioConfiguration?: Maybe<Scalars['String']['output']>;
  deletePortfolioGroupConfiguration?: Maybe<Scalars['String']['output']>;
  deletePreTradeCheck?: Maybe<MutationReturnValue>;
  deleteQuotingConfiguration?: Maybe<Scalars['String']['output']>;
  deleteRateSubscription?: Maybe<MutationSubmittedResponse>;
  instrumentsRefresh?: Maybe<MutationReturnValue>;
  removeGroupPermissions?: Maybe<MutationReturnValue>;
  removeUserPermissions?: Maybe<MutationReturnValue>;
  resetConfiguration?: Maybe<ConfigValidationResult>;
  saveCurrency?: Maybe<MutationSubmittedResponse>;
  savePreTradeCheck?: Maybe<MutationReturnValue>;
  sendOrder?: Maybe<MutationReturnValue>;
  settlementConfigurationUpdate?: Maybe<MutationSubmittedResponse>;
  settlementLegComplete?: Maybe<MutationSubmittedResponse>;
  settlementLegInitiate?: Maybe<MutationSubmittedResponse>;
  settlementRunCreate?: Maybe<MutationSubmittedResponse>;
  settlementRunDelete?: Maybe<MutationSubmittedResponse>;
  settlementRunStart?: Maybe<MutationSubmittedResponse>;
  settlementTransactionsAllSelect?: Maybe<MutationSubmittedResponse>;
  settlementTransactionsSelect?: Maybe<MutationSubmittedResponse>;
  updateApiKeyName?: Maybe<MutationReturnValue>;
  updateConversionSource?: Maybe<MutationSubmittedResponse>;
  updateInstrument?: Maybe<UpdateInstrumentResult>;
  updateInstrumentQuotingConfiguration?: Maybe<QuotingConfigValidationResult>;
  updateInternalExchangeConfiguration?: Maybe<MutationSubmittedResponse>;
  updatePortfolio?: Maybe<MutationSubmittedResponse>;
  updatePortfolioConfiguration?: Maybe<ConfigValidationResult>;
  updatePortfolioExecutionConfiguration?: Maybe<ConfigValidationResult>;
  updatePortfolioGroupConfiguration?: Maybe<Scalars['String']['output']>;
  updatePortfolioGroupExecutionConfiguration?: Maybe<Scalars['String']['output']>;
  updatePortfolioGroupHedgingConfiguration?: Maybe<Scalars['String']['output']>;
  updatePortfolioGroupInstrumentConfiguration?: Maybe<Scalars['String']['output']>;
  updatePortfolioGroupPricingConfiguration?: Maybe<Scalars['String']['output']>;
  updatePortfolioHedgingConfiguration?: Maybe<Scalars['String']['output']>;
  updatePortfolioInstrumentConfiguration?: Maybe<ConfigValidationResult>;
  updatePortfolioPricingConfiguration?: Maybe<ConfigValidationResult>;
  updateQuotingConfiguration?: Maybe<QuotingConfigValidationResult>;
  updateUserData: UserDataResponse;
  updateVenueAccount?: Maybe<MutationSubmittedResponse>;
};

export type MutationActivateVenueAccountArgs = {
  request: ActivateVenueAccountInput;
};

export type MutationAddGroupPermissionsArgs = {
  request: AddOrRemoveGroupPermissionsInput;
};

export type MutationAddTransactionArgs = {
  input: TransactionInput;
};

export type MutationAddUserPermissionsArgs = {
  request: AddOrRemoveUserPermissionsInput;
};

export type MutationCancelOrderArgs = {
  request?: InputMaybe<OrderCancelRequestInput>;
};

export type MutationCancelReplaceOrderArgs = {
  request?: InputMaybe<OrderCancelReplaceRequestInput>;
};

export type MutationCreateApiKeyArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateConversionSourceArgs = {
  request: CreateConversionSourceInput;
};

export type MutationCreateInstrumentArgs = {
  request?: InputMaybe<CreateInstrumentInput>;
};

export type MutationCreateInternalExchangeConfigurationArgs = {
  request: InternalExchangeConfigurationInput;
};

export type MutationCreatePortfolioArgs = {
  request: CreatePortfolioInput;
};

export type MutationCreateQuotingConfigurationArgs = {
  request: QuotingConfigurationInput;
};

export type MutationCreateRateSubscriptionArgs = {
  request: CreateRateSubscriptionInput;
};

export type MutationCreateVenueAccountArgs = {
  request: CreateVenueAccountInput;
};

export type MutationCreateWalletAccountArgs = {
  request: CreateWalletAccountInput;
};

export type MutationDeactivateApiKeyArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeactivateVenueAccountArgs = {
  request: DeactivateVenueAccountInput;
};

export type MutationDeleteConversionSourceArgs = {
  venueAccount: Scalars['String']['input'];
};

export type MutationDeletePortfolioConfigurationArgs = {
  portfolioId: Scalars['ID']['input'];
};

export type MutationDeletePortfolioGroupConfigurationArgs = {
  portfolioGroupId: Scalars['ID']['input'];
};

export type MutationDeletePreTradeCheckArgs = {
  preTradeCheckId: Scalars['String']['input'];
};

export type MutationDeleteQuotingConfigurationArgs = {
  clobUid: Scalars['String']['input'];
};

export type MutationDeleteRateSubscriptionArgs = {
  request: DeleteRateSubscriptionInput;
};

export type MutationInstrumentsRefreshArgs = {
  request: InstrumentsRefreshRequestInput;
};

export type MutationRemoveGroupPermissionsArgs = {
  request: AddOrRemoveGroupPermissionsInput;
};

export type MutationRemoveUserPermissionsArgs = {
  request: AddOrRemoveUserPermissionsInput;
};

export type MutationResetConfigurationArgs = {
  resetConfigurationInput?: InputMaybe<ResetConfigurationInput>;
};

export type MutationSaveCurrencyArgs = {
  request: CurrencyInput;
};

export type MutationSavePreTradeCheckArgs = {
  request: PreTradeCheckInput;
};

export type MutationSendOrderArgs = {
  request?: InputMaybe<NewOrderSingleRequestInput>;
};

export type MutationSettlementConfigurationUpdateArgs = {
  request: Array<SettlementConfigurationInput>;
};

export type MutationSettlementLegCompleteArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSettlementLegInitiateArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSettlementRunCreateArgs = {
  request?: InputMaybe<CreateSettlementRunInput>;
};

export type MutationSettlementRunDeleteArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSettlementRunStartArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSettlementTransactionsAllSelectArgs = {
  request?: InputMaybe<SelectAllTransactionsInput>;
};

export type MutationSettlementTransactionsSelectArgs = {
  request?: InputMaybe<SelectTransactionsInput>;
};

export type MutationUpdateApiKeyNameArgs = {
  request?: InputMaybe<UpdateApiKeyNameInput>;
};

export type MutationUpdateConversionSourceArgs = {
  request: UpdateConversionSourceInput;
};

export type MutationUpdateInstrumentArgs = {
  request?: InputMaybe<UpdateInstrumentInput>;
};

export type MutationUpdateInstrumentQuotingConfigurationArgs = {
  clobUid: Scalars['String']['input'];
  request: InstrumentQuotingConfigurationInput;
};

export type MutationUpdateInternalExchangeConfigurationArgs = {
  request: InternalExchangeConfigurationInput;
};

export type MutationUpdatePortfolioArgs = {
  request: UpdatePortfolioInput;
};

export type MutationUpdatePortfolioConfigurationArgs = {
  portfolioId: Scalars['ID']['input'];
  request?: InputMaybe<PortfolioConfigurationInput>;
};

export type MutationUpdatePortfolioExecutionConfigurationArgs = {
  portfolioId: Scalars['ID']['input'];
  request: ExecutionConfigurationInput;
};

export type MutationUpdatePortfolioGroupConfigurationArgs = {
  portfolioGroupId: Scalars['ID']['input'];
  request?: InputMaybe<PortfolioGroupConfigurationInput>;
};

export type MutationUpdatePortfolioGroupExecutionConfigurationArgs = {
  portfolioGroupId: Scalars['ID']['input'];
  request: ExecutionConfigurationInput;
};

export type MutationUpdatePortfolioGroupHedgingConfigurationArgs = {
  portfolioGroupId: Scalars['ID']['input'];
  request: HedgingConfigurationInput;
};

export type MutationUpdatePortfolioGroupInstrumentConfigurationArgs = {
  portfolioGroupId: Scalars['ID']['input'];
  request: InstrumentConfigurationInput;
};

export type MutationUpdatePortfolioGroupPricingConfigurationArgs = {
  portfolioGroupId: Scalars['ID']['input'];
  request: PricingConfigurationInput;
};

export type MutationUpdatePortfolioHedgingConfigurationArgs = {
  portfolioId: Scalars['ID']['input'];
  request: HedgingConfigurationInput;
};

export type MutationUpdatePortfolioInstrumentConfigurationArgs = {
  portfolioId: Scalars['ID']['input'];
  request: InstrumentConfigurationInput;
};

export type MutationUpdatePortfolioPricingConfigurationArgs = {
  portfolioId: Scalars['ID']['input'];
  request: PricingConfigurationInput;
};

export type MutationUpdateQuotingConfigurationArgs = {
  clobUid: Scalars['String']['input'];
  request: QuotingConfigurationInput;
};

export type MutationUpdateUserDataArgs = {
  request: UpdateUserDataInput;
};

export type MutationUpdateVenueAccountArgs = {
  request: UpdateVenueAccountInput;
};

export type MutationReturnValue = {
  __typename?: 'MutationReturnValue';
  clientId?: Maybe<Scalars['String']['output']>;
};

export type MutationSubmittedResponse = {
  __typename?: 'MutationSubmittedResponse';
  status: Scalars['String']['output'];
};

/** New Order request */
export type NewOrderSingleRequestInput = {
  /**
   * Mandatory for SOR Orders.
   * Ignored for simple Orders. Use instrumentId instead
   */
  assetClass?: InputMaybe<AssetClass>;
  /**
   * Client Order Id.
   * Generated on client-side.
   * Can be used to refer to the Order later (in combination with clientId).
   * Should be unique in scope of the clientId, otherwise will be rejected
   */
  clOrderId: Scalars['String']['input'];
  /** Quantity currency */
  currency?: InputMaybe<Scalars['String']['input']>;
  /**
   * Valid For
   * In case of GTD orders, this field specifies the date and time when the order will be cancelled.
   * Epoch Unix Timestamp
   */
  expirationDateTime?: InputMaybe<Scalars['String']['input']>;
  /**
   * Mandatory for simple Orders.
   * Ignored for SOR. Use symbol and assetClass instead
   * Example: BTCUSD@FOREX@Binance
   */
  instrumentId?: InputMaybe<Scalars['String']['input']>;
  /** Mandatory for Limit and StopLimit Orders */
  limitPrice?: InputMaybe<Scalars['Float']['input']>;
  orderType: OrderType;
  /**
   * Portfolio where this Order should be booked.
   * Mandatory.
   * Requires portfolio#trade permission on client side.
   */
  portfolioId: Scalars['String']['input'];
  /** Order quantity */
  quantity: Scalars['Float']['input'];
  side: Side;
  /** Mandatory for Stop and StopLimit Orders */
  stopPrice?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Mandatory for SOR Orders.
   * Ignored for simple Orders. Use instrumentId instead
   * Example: BTCUSD
   */
  symbol?: InputMaybe<Scalars['String']['input']>;
  /** Time in force */
  tif: Tif;
  /**
   * VenueAccounts to be used for Order execution.
   * Exactly 1-element is required for simple Orders, otherwise Order will be rejected.
   * 1+ elements allowed for SOR orders.
   */
  venueAccounts?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrderBook = {
  __typename?: 'OrderBook';
  amount?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
};

export enum OrderBookLevel {
  L1 = 'L1',
  L1L2 = 'L1L2',
  L2 = 'L2',
}

export type OrderBookResponse = {
  __typename?: 'OrderBookResponse';
  asks?: Maybe<Array<Maybe<OrderBook>>>;
  bids?: Maybe<Array<Maybe<OrderBook>>>;
  dateTime?: Maybe<Scalars['String']['output']>;
  instrumentId?: Maybe<Scalars['String']['output']>;
  venue?: Maybe<Scalars['String']['output']>;
  venueAccount?: Maybe<Scalars['String']['output']>;
  venueAccountName?: Maybe<Scalars['String']['output']>;
};

export type OrderBookSnapshot = {
  __typename?: 'OrderBookSnapshot';
  asks?: Maybe<Array<Maybe<OrderBook>>>;
  bids?: Maybe<Array<Maybe<OrderBook>>>;
};

export type OrderBookSnapshotSearchInput = {
  matchId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type OrderCancelReplaceRequestInput = {
  /**
   * Identifies Order to be cancelled with client-assigned clOrderId in NewOrderSingleRequestInput message.
   * Mandatory if orderId is not present.
   */
  origClOrderId?: InputMaybe<Scalars['String']['input']>;
  /** New Order that will be placed after cancelling the previous Order */
  replacingOrder?: InputMaybe<NewOrderSingleRequestInput>;
};

/** Order cancel request */
export type OrderCancelRequestInput = {
  /**
   * Identifies the cancel request itself, NOT the Order to be cancelled.
   * If the cancel operation fails, the report will contain this clOrderId as an id of the request.
   */
  clOrderId?: InputMaybe<Scalars['String']['input']>;
  forceCancel?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Describes Order to be cancelled by client-assigned clOrderId in NewOrderSingleRequestInput message.
   * Mandatory if orderId is not present.
   */
  origClOrderId?: InputMaybe<Scalars['String']['input']>;
};

export enum OrderCategory {
  AgencyClobOrder = 'AGENCY_CLOB_ORDER',
  AgencyOrder = 'AGENCY_ORDER',
  AgencySorOrder = 'AGENCY_SOR_ORDER',
  AgencyStreetOrder = 'AGENCY_STREET_ORDER',
  AutoHedgerExternalHedgeOrder = 'AUTO_HEDGER_EXTERNAL_HEDGE_ORDER',
  ClobExternalHedgeOrder = 'CLOB_EXTERNAL_HEDGE_ORDER',
  ClobQuotingOrder = 'CLOB_QUOTING_ORDER',
  DirectMarketAccessOrder = 'DIRECT_MARKET_ACCESS_ORDER',
  OrderCategoryUnspecified = 'ORDER_CATEGORY_UNSPECIFIED',
  SorChildOrder = 'SOR_CHILD_ORDER',
  SorOrder = 'SOR_ORDER',
}

export enum OrderRequest {
  Cancel = 'CANCEL',
  CancelReplace = 'CANCEL_REPLACE',
  Modify = 'MODIFY',
  Submit = 'SUBMIT',
}

export type OrderRequestsPerOrderType = {
  __typename?: 'OrderRequestsPerOrderType';
  key?: Maybe<OrderType>;
  values: Array<OrderRequest>;
};

export type OrderSnapshot = {
  __typename?: 'OrderSnapshot';
  baseCurrency: Scalars['String']['output'];
  instrumentId: Scalars['String']['output'];
  instrumentType: InstrumentType;
  orderId: Scalars['String']['output'];
  orderType: OrderType;
  parentOrderId?: Maybe<Scalars['String']['output']>;
  portfolioId: Scalars['String']['output'];
  portfolioName: Scalars['String']['output'];
  postOnly?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  quoteCurrency: Scalars['String']['output'];
  side: Side;
  stopPrice?: Maybe<Scalars['Float']['output']>;
  tif?: Maybe<Tif>;
  venueAccount: Scalars['String']['output'];
  venueAccountDescs?: Maybe<Array<VenueAccountDesc>>;
  venueAccountName: Scalars['String']['output'];
};

export enum OrderStateCollectionField {
  InstrumentId = 'INSTRUMENT_ID',
  OrderCategory = 'ORDER_CATEGORY',
  OrderStatus = 'ORDER_STATUS',
  PortfolioId = 'PORTFOLIO_ID',
  VenueAccountId = 'VENUE_ACCOUNT_ID',
}

export type OrderStateConnection = {
  __typename?: 'OrderStateConnection';
  edges: Array<OrderStateEdge>;
  pageInfo: PageInfo;
};

export enum OrderStateDateField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type OrderStateEdge = {
  __typename?: 'OrderStateEdge';
  cursor: Scalars['String']['output'];
  node: OrderStateResponse;
};

/** Describes current state of the Order */
export type OrderStateResponse = {
  __typename?: 'OrderStateResponse';
  /**
   * Instrument asset class.
   * For SOR Orders, can be used to determine the common asset class of all candidate instruments
   */
  assetClass?: Maybe<AssetClass>;
  /** Average fill price */
  avgPrice?: Maybe<Scalars['String']['output']>;
  /** Client Order id - assigned by the client */
  clOrderId?: Maybe<Scalars['String']['output']>;
  /** Id of the client who has initiated the Order */
  clientId?: Maybe<Scalars['String']['output']>;
  /**
   * Optional
   * Counter portfolio id
   */
  counterPortfolioId?: Maybe<Scalars['String']['output']>;
  /**
   * Optional
   * Name of the related counter portfolio
   */
  counterPortfolioName?: Maybe<Scalars['String']['output']>;
  /** Order creation time */
  createdAt?: Maybe<Scalars['String']['output']>;
  /** Quantity currency */
  currency?: Maybe<Scalars['String']['output']>;
  /**
   * Valid For
   * In case of GTD orders, this field specifies the date and time when the order will be cancelled.
   * Epoch Unix Timestamp
   */
  expirationDateTime?: Maybe<Scalars['String']['output']>;
  /** Filled quantity */
  filledQty: Scalars['Float']['output'];
  /**
   * Wyden Instrument bound to that Order
   * Mandatory for simple Orders, empty for SOR orders
   */
  instrument?: Maybe<InstrumentResponse>;
  /** Price of the last fill */
  lastPrice?: Maybe<Scalars['String']['output']>;
  /** Quantity of the last fill */
  lastQty?: Maybe<Scalars['Float']['output']>;
  /**
   * Mandatory
   * Describes last result of action requested by user (NEW, CANCEL, CANCEL-REPLACE)
   */
  lastRequestResult: Scalars['String']['output'];
  /** Mandatory for Limit and StopLimit Orders */
  limitPrice?: Maybe<Scalars['Float']['output']>;
  /** Order category (Simple, Parent or Child) */
  orderCategory: OrderCategory;
  /** Order id, as assigned by the Wyden system */
  orderId: Scalars['String']['output'];
  /** Requested Order quantity to be filled */
  orderQty: Scalars['Float']['output'];
  /** Id of a specific order state. Empty for a snapshot. */
  orderStateId?: Maybe<Scalars['String']['output']>;
  /** Current Order status */
  orderStatus: OrderStatus;
  /** Order type */
  orderType: OrderType;
  /**
   * Optional
   * If this Order is replacing another other, the previous Order id will be filled here
   */
  origClOrderId?: Maybe<Scalars['String']['output']>;
  /** Id of the parent order if current is "children" */
  parentOrderId?: Maybe<Scalars['String']['output']>;
  /** Portfolio id where transactions created by this Order will be booked */
  portfolioId: Scalars['String']['output'];
  /** Name of the related portfolio */
  portfolioName: Scalars['String']['output'];
  /**
   * Optional
   * If the request result is not successful, describes a reason
   */
  reason?: Maybe<Scalars['String']['output']>;
  /** Remaining quantity to be filled */
  remainingQty: Scalars['Float']['output'];
  /** Id of the root order - in a chain of Orders, this is the first Order in the chain */
  rootOrderId?: Maybe<Scalars['String']['output']>;
  /** Used to determine the order of order status updates */
  sequenceNumber?: Maybe<Scalars['Int']['output']>;
  /** Side */
  side: Side;
  /** Mandatory for Stop and StopLimit Orders */
  stopPrice?: Maybe<Scalars['Float']['output']>;
  /**
   * Instrument symbol.
   * For SOR Orders, can be used to determine the common symbol of all candidate instruments
   * Example: BTCUSD
   */
  symbol?: Maybe<Scalars['String']['output']>;
  /** Time in Force */
  tif?: Maybe<Tif>;
  /** Time of the last Order update */
  updatedAt?: Maybe<Scalars['String']['output']>;
  /**
   * Instrument venue.
   * For SOR Orders, can be used to determine the venue of last filed child order
   */
  venue?: Maybe<Scalars['String']['output']>;
  /** VenueAccounts used for Order execution. */
  venueAccountDescs?: Maybe<Array<VenueAccountDesc>>;
  /** Time of the last update on the venue side */
  venueTimestamp?: Maybe<Scalars['String']['output']>;
};

export type OrderStateSearchInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  collectionPredicates?: InputMaybe<Array<InputMaybe<CollectionPredicateInput>>>;
  datePredicateInputs?: InputMaybe<Array<InputMaybe<DatePredicate>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  simplePredicates?: InputMaybe<Array<InputMaybe<SimplePredicateInput>>>;
  /**
   * Result list will be sorted by order.updatedAt field
   * Default sorting order will be DESC if this field is not set
   */
  sortingOrder?: InputMaybe<SortingOrder>;
};

export enum OrderStateStringField {
  ClOrderId = 'CL_ORDER_ID',
  InstrumentId = 'INSTRUMENT_ID',
  OrderId = 'ORDER_ID',
  OrderStatus = 'ORDER_STATUS',
  ParentOrderId = 'PARENT_ORDER_ID',
  PortfolioId = 'PORTFOLIO_ID',
  RootOrderId = 'ROOT_ORDER_ID',
  VenueAccountId = 'VENUE_ACCOUNT_ID',
}

export enum OrderStatus {
  Calculated = 'CALCULATED',
  /**
   * Order has been cancelled by the user.
   * Terminal status.
   */
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  /**
   * Order has been fuly filled.
   * Terminal status.
   */
  Filled = 'FILLED',
  /** Order has been submitted successfully and is awaiting to be filled. */
  New = 'NEW',
  /** Unknown */
  OrderStatusUnspecified = 'ORDER_STATUS_UNSPECIFIED',
  /** Order has been submitted successfully and partially filled. It is awaiting to be fully filled. */
  PartiallyFilled = 'PARTIALLY_FILLED',
  /** Order has been requested to be cancelled by the user and is awaiting cancel confirmation. */
  PendingCancel = 'PENDING_CANCEL',
  /** Order has been requested by the user to be submitted and is awaiting submit confirmation. */
  PendingNew = 'PENDING_NEW',
  /** Order has been requested by the user to be replaced with a different Order. It is currently awaiting cancellation confirmation. */
  PendingReplace = 'PENDING_REPLACE',
  /** Order placement has been rejected - either internally or by the external venue. */
  Rejected = 'REJECTED',
  /**
   * Order has been replaced by another Order is no longer active.
   * Terminal status.
   */
  Replaced = 'REPLACED',
}

export enum OrderType {
  Limit = 'LIMIT',
  LimitCash = 'LIMIT_CASH',
  Market = 'MARKET',
  MarketCash = 'MARKET_CASH',
  OrderTypeUnspecified = 'ORDER_TYPE_UNSPECIFIED',
  PreviouslyIndicated = 'PREVIOUSLY_INDICATED',
  Stop = 'STOP',
  StopCash = 'STOP_CASH',
  StopLimit = 'STOP_LIMIT',
  StopLimitCash = 'STOP_LIMIT_CASH',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  /** Can be null if page is empty, otherwise non-null */
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export enum ParticipantReferenceType {
  Portfolio = 'PORTFOLIO',
  VenueAccount = 'VENUE_ACCOUNT',
}

export enum PayloadType {
  OrderBook = 'ORDER_BOOK',
  PreTradeCheck = 'PRE_TRADE_CHECK',
  Recommendation = 'RECOMMENDATION',
}

export type Permission = {
  __typename?: 'Permission';
  resource: Resource;
  resourceId?: Maybe<Scalars['String']['output']>;
  scope: Scope;
};

export type PermissionConnection = {
  __typename?: 'PermissionConnection';
  edges: Array<PermissionEdge>;
  pageInfo: PageInfo;
};

export type PermissionEdge = {
  __typename?: 'PermissionEdge';
  cursor: Scalars['String']['output'];
  node: FlatPermission;
};

export type PermissionInput = {
  resource: Resource;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  scope: Scope;
};

export type PermissionSearchInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type PortfolioCashTransfer = {
  __typename?: 'PortfolioCashTransfer';
  currency: Scalars['String']['output'];
  dateTime: Scalars['String']['output'];
  description: Scalars['String']['output'];
  executionId: Scalars['String']['output'];
  feePortfolioId?: Maybe<Scalars['String']['output']>;
  feePortfolioName?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  settled: Scalars['String']['output'];
  settledDateTime?: Maybe<Scalars['Float']['output']>;
  sourcePortfolioId: Scalars['String']['output'];
  sourcePortfolioName: Scalars['String']['output'];
  targetPortfolioId: Scalars['String']['output'];
  targetPortfolioName: Scalars['String']['output'];
  updatedAt: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
  venueExecutionId?: Maybe<Scalars['String']['output']>;
};

export type PortfolioConfiguration = {
  __typename?: 'PortfolioConfiguration';
  executionConfiguration?: Maybe<ExecutionConfiguration>;
  hedgingConfiguration?: Maybe<HedgingConfiguration>;
  id: Scalars['ID']['output'];
  instrumentConfiguration?: Maybe<Array<InstrumentConfiguration>>;
  name?: Maybe<Scalars['String']['output']>;
  portfolioGroupConfiguration?: Maybe<PortfolioGroupConfiguration>;
  pricingConfiguration?: Maybe<PricingConfiguration>;
};

export type PortfolioConfigurationFlat = {
  __typename?: 'PortfolioConfigurationFlat';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type PortfolioConfigurationInput = {
  executionConfiguration?: InputMaybe<ExecutionConfigurationInput>;
  hedgingConfiguration?: InputMaybe<HedgingConfigurationInput>;
  id: Scalars['ID']['input'];
  instrumentConfiguration?: InputMaybe<Array<InstrumentConfigurationInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  portfolioGroupConfigurationId?: InputMaybe<Scalars['ID']['input']>;
  pricingConfiguration?: InputMaybe<PricingConfigurationInput>;
};

export type PortfolioConnection = {
  __typename?: 'PortfolioConnection';
  edges: Array<PortfolioEdge>;
  pageInfo: PageInfo;
};

export type PortfolioEdge = {
  __typename?: 'PortfolioEdge';
  cursor: Scalars['String']['output'];
  node: PortfolioResponse;
};

export type PortfolioGroupConfiguration = {
  __typename?: 'PortfolioGroupConfiguration';
  executionConfiguration?: Maybe<ExecutionConfiguration>;
  hedgingConfiguration?: Maybe<HedgingConfiguration>;
  id: Scalars['ID']['output'];
  instrumentConfiguration?: Maybe<Array<InstrumentGroupConfiguration>>;
  name?: Maybe<Scalars['String']['output']>;
  portfolioType?: Maybe<PortfolioType>;
  pricingConfiguration?: Maybe<PricingConfiguration>;
};

export type PortfolioGroupConfigurationFlat = {
  __typename?: 'PortfolioGroupConfigurationFlat';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  portfolioType?: Maybe<PortfolioType>;
};

export type PortfolioGroupConfigurationInput = {
  executionConfiguration?: InputMaybe<ExecutionConfigurationInput>;
  hedgingConfiguration?: InputMaybe<HedgingConfigurationInput>;
  id: Scalars['ID']['input'];
  instrumentConfiguration?: InputMaybe<Array<InstrumentGroupConfigurationInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  portfolioType?: InputMaybe<PortfolioType>;
  pricingConfiguration?: InputMaybe<PricingConfigurationInput>;
};

export type PortfolioPredicateInput = {
  method: PortfolioPredicateType;
  searchType: PortfolioSearchType;
  value: Scalars['String']['input'];
};

export enum PortfolioPredicateType {
  Contains = 'CONTAINS',
}

export type PortfolioResponse = {
  __typename?: 'PortfolioResponse';
  /** Nullable - only present if the Portfolio is archived. Null value means not archived. */
  archivedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  portfolioCurrency: Scalars['String']['output'];
  portfolioType?: Maybe<PortfolioType>;
  /** Permission scopes, granted to the originator of the request. */
  scopes: Array<Scope>;
  tags: Array<Tag>;
};

export type PortfolioSearchInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Result set will be restricted to given list of portfolioIds.
   * Optional - if field is null or empty then all permitted portfolios will be returned.
   */
  portfolioIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Result set will contain all portfolios that meet the following predicate. Overwrites "name" when both used */
  portfolioPredicate?: InputMaybe<PortfolioPredicateInput>;
  portfolioType?: InputMaybe<PortfolioType>;
  /**
   * Result set will only contain Portfolios where requesting user is authorized to use Portfolio in one of given scopes.
   * If collection is null or empty then Portfolios authorized to the user in any Scope will be returned.
   */
  scopes?: InputMaybe<Array<Scope>>;
  /** Null sortBy defaults to PortfolioSortBy.PORTFOLIO_NAME */
  sortBy?: InputMaybe<PortfolioSortBy>;
  /** Default sorting order is ASC if this field is not set */
  sortingOrder?: InputMaybe<SortingOrder>;
  tagValues?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum PortfolioSearchType {
  Id = 'ID',
  Name = 'NAME',
  NameOrId = 'NAME_OR_ID',
}

export enum PortfolioSortBy {
  CreatedAt = 'CREATED_AT',
  PortfolioId = 'PORTFOLIO_ID',
  PortfolioName = 'PORTFOLIO_NAME',
}

export type PortfolioTag = {
  __typename?: 'PortfolioTag';
  key: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export enum PortfolioType {
  Nostro = 'NOSTRO',
  Vostro = 'VOSTRO',
}

export type PositionConnection = {
  __typename?: 'PositionConnection';
  edges: Array<PositionEdge>;
  pageInfo: PageInfo;
};

export type PositionEdge = {
  __typename?: 'PositionEdge';
  cursor: Scalars['String']['output'];
  node: PositionResponse;
};

export type PositionResponse = {
  __typename?: 'PositionResponse';
  account?: Maybe<Scalars['String']['output']>;
  accountName?: Maybe<Scalars['String']['output']>;
  availableForTradingQuantity?: Maybe<Scalars['Float']['output']>;
  availableForWithdrawalQuantity?: Maybe<Scalars['Float']['output']>;
  bookingCurrency: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  grossAveragePrice?: Maybe<Scalars['Float']['output']>;
  grossCost: Scalars['Float']['output'];
  grossCostSc?: Maybe<Scalars['Float']['output']>;
  grossRealizedPnl: Scalars['Float']['output'];
  grossRealizedPnlSc?: Maybe<Scalars['Float']['output']>;
  grossUnrealizedPnl?: Maybe<Scalars['Float']['output']>;
  grossUnrealizedPnlSc?: Maybe<Scalars['Float']['output']>;
  marketValue?: Maybe<Scalars['Float']['output']>;
  marketValueSc?: Maybe<Scalars['Float']['output']>;
  netAveragePrice?: Maybe<Scalars['Float']['output']>;
  netCost: Scalars['Float']['output'];
  netCostSc?: Maybe<Scalars['Float']['output']>;
  netRealizedPnl: Scalars['Float']['output'];
  netRealizedPnlSc?: Maybe<Scalars['Float']['output']>;
  netUnrealizedPnl?: Maybe<Scalars['Float']['output']>;
  netUnrealizedPnlSc?: Maybe<Scalars['Float']['output']>;
  notionalQuantity?: Maybe<Scalars['Float']['output']>;
  pendingQuantity?: Maybe<Scalars['Float']['output']>;
  portfolio?: Maybe<PortfolioResponse>;
  quantity: Scalars['Float']['output'];
  settledQuantity?: Maybe<Scalars['Float']['output']>;
  symbol: Scalars['String']['output'];
  unsettledQuantity?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Float']['output'];
};

export type PositionSearchInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Array<Scalars['String']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  portfolio?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Result list will be sorted by updatedAt field
   * Default sorting order will be DESC if this field is not set
   */
  sortingOrder?: InputMaybe<SortingOrder>;
  symbol?: InputMaybe<Array<Scalars['String']['input']>>;
  venueAccount?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PreTradeCheck = {
  __typename?: 'PreTradeCheck';
  channels: Array<PreTradeCheckChannels>;
  configuration: Array<PreTradeCheckProperty>;
  id: Scalars['String']['output'];
  level: PreTradeCheckLevel;
  portfolioTags: Array<Tag>;
  portfolios: Array<PortfolioResponse>;
  type: Scalars['String']['output'];
};

export type PreTradeCheckAuditLog = {
  __typename?: 'PreTradeCheckAuditLog';
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  order?: Maybe<OrderSnapshot>;
  portfolioId?: Maybe<Scalars['String']['output']>;
  portfolioName?: Maybe<Scalars['String']['output']>;
  preTradeCheckResults?: Maybe<Array<Maybe<PreTradeCheckResult>>>;
};

export type PreTradeCheckAuditLogConnection = {
  __typename?: 'PreTradeCheckAuditLogConnection';
  edges: Array<PreTradeCheckAuditLogEdge>;
  pageInfo: PageInfo;
};

export type PreTradeCheckAuditLogEdge = {
  __typename?: 'PreTradeCheckAuditLogEdge';
  cursor: Scalars['String']['output'];
  node: PreTradeCheckAuditLog;
};

export type PreTradeCheckAuditLogSearchInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  portfolioIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export enum PreTradeCheckChannels {
  Api = 'API',
  Ui = 'UI',
}

export type PreTradeCheckInput = {
  channels: Array<PreTradeCheckChannels>;
  configuration: Array<PreTradeCheckPropertyInput>;
  id: Scalars['String']['input'];
  level: PreTradeCheckLevel;
  portfolioTags: Array<TagInput>;
  portfolios: Array<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export enum PreTradeCheckLevel {
  Block = 'BLOCK',
  Warn = 'WARN',
}

export type PreTradeCheckProperty = {
  __typename?: 'PreTradeCheckProperty';
  name: Scalars['String']['output'];
  type: PreTradeCheckPropertyType;
  values: Array<Scalars['String']['output']>;
};

export type PreTradeCheckPropertyInput = {
  name: Scalars['String']['input'];
  type: PreTradeCheckPropertyType;
  values: Array<Scalars['String']['input']>;
};

export type PreTradeCheckPropertySchema = {
  __typename?: 'PreTradeCheckPropertySchema';
  isEnum: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Array<Scalars['String']['output']>>;
  required: Scalars['Boolean']['output'];
  type: PreTradeCheckPropertyType;
};

export enum PreTradeCheckPropertyType {
  DateTime = 'DATE_TIME',
  Number = 'NUMBER',
  String = 'STRING',
  StringList = 'STRING_LIST',
}

export type PreTradeCheckResult = {
  __typename?: 'PreTradeCheckResult';
  preTradeCheck: PreTradeCheck;
  reason: Scalars['String']['output'];
  status: PreTradeCheckStatus;
};

export type PreTradeCheckSchema = {
  __typename?: 'PreTradeCheckSchema';
  configuration: Array<PreTradeCheckPropertySchema>;
  type: Scalars['String']['output'];
};

export enum PreTradeCheckStatus {
  Approved = 'APPROVED',
  ApprovedWithWarning = 'APPROVED_WITH_WARNING',
  Rejected = 'REJECTED',
  StatusUnspecified = 'STATUS_UNSPECIFIED',
}

export type PricingConfiguration = {
  __typename?: 'PricingConfiguration';
  markup?: Maybe<Scalars['Float']['output']>;
  venueAccounts?: Maybe<Array<VenueAccountDesc>>;
};

export type PricingConfigurationInput = {
  markup?: InputMaybe<Scalars['Float']['input']>;
  venueAccount?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Query = {
  __typename?: 'Query';
  apiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  auditEventLogsByType?: Maybe<Array<Maybe<AuditLogEvent>>>;
  checkAccountName?: Maybe<ValidationResponse>;
  checkPortfolioName?: Maybe<ValidationResponse>;
  connectorTemplate?: Maybe<Array<ConfigField>>;
  conversionSourceVenueAccounts: Array<ConversionSourceVenueAccountResponse>;
  conversionSources: Array<ConversionSourceResponse>;
  currencySearch: Array<CurrencyResponse>;
  effectiveVenueAccounts?: Maybe<EffectiveVenueAccountsResponse>;
  entitlements: Array<Maybe<Scalars['String']['output']>>;
  groupNames: Array<Scalars['String']['output']>;
  groupStaticPermissions?: Maybe<Array<Permission>>;
  groupsPermissionsForResource: Array<GroupsPermissionsForResourceResponse>;
  instrumentSearch?: Maybe<InstrumentsConnection>;
  /**
   * Result list will be sorted by instrumentId field
   * Default sorting order is ASC if this field is not set
   */
  instruments: Array<InstrumentResponse>;
  internalExchangeConfiguration?: Maybe<InternalExchangeConfigurationResponse>;
  ledgerEntries?: Maybe<LedgerEntryConnection>;
  matches?: Maybe<MatchesResult>;
  orderBookSnapshot?: Maybe<OrderBookSnapshot>;
  orderStates?: Maybe<Array<OrderStateResponse>>;
  orderStatesWithPredicates?: Maybe<OrderStateConnection>;
  permissionSearch: PermissionConnection;
  portfolioConfigValidation?: Maybe<ConfigValidationResult>;
  portfolioConfiguration?: Maybe<PortfolioConfiguration>;
  portfolioConfigurationList: Array<PortfolioConfigurationFlat>;
  portfolioGroupConfiguration?: Maybe<PortfolioGroupConfiguration>;
  portfolioGroupConfigurationList: Array<PortfolioGroupConfigurationFlat>;
  portfolioSearch?: Maybe<PortfolioConnection>;
  portfolioTags: Array<PortfolioTag>;
  positionTypes: Array<Scalars['String']['output']>;
  positions?: Maybe<PositionConnection>;
  preTradeCheckAuditLogs?: Maybe<PreTradeCheckAuditLogConnection>;
  preTradeCheckFormSchema: Array<PreTradeCheckSchema>;
  preTradeChecks: Array<PreTradeCheck>;
  quotingConfigValidations: Array<QuotingConfigValidationResult>;
  quotingConfiguration: Array<QuotingConfiguration>;
  rateSubscriptions: Array<RateSubscriptionResponse>;
  settlementConfiguration: Array<SettlementConfigurationResponse>;
  /** date parameter - ISO8601 format without time part */
  settlementRuns: Array<SettlementRunResponse>;
  settlementTransactions?: Maybe<TransactionConnection>;
  symbolSearch?: Maybe<SymbolSearchConnection>;
  systemCurrency: Scalars['String']['output'];
  timeline?: Maybe<TimelineConnection>;
  transactionTypes: Array<Scalars['String']['output']>;
  transactions?: Maybe<TransactionConnection>;
  userData: UserDataResponse;
  userNames: Array<Scalars['String']['output']>;
  userStaticPermissions?: Maybe<Array<Permission>>;
  userWithPermissions: UserResponse;
  usersPermissionsForResource: Array<UsersPermissionsForResourceResponse>;
  venueAccountDetails?: Maybe<VenueAccountDetailsResponse>;
  venueAccounts?: Maybe<Array<Maybe<VenueAccountsPerVenue>>>;
  venueCapabilities?: Maybe<Array<Maybe<VenueCapabilitiesResponse>>>;
  venues: Array<Venue>;
  walletAccountSearch: WalletAccountConnection;
};

export type QueryAuditEventLogsByTypeArgs = {
  type: PayloadType;
};

export type QueryCheckAccountNameArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCheckPortfolioNameArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryConnectorTemplateArgs = {
  venue?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCurrencySearchArgs = {
  search?: InputMaybe<CurrencySearchInput>;
};

export type QueryEffectiveVenueAccountsArgs = {
  instrumentId: Scalars['String']['input'];
  portfolioId: Scalars['String']['input'];
};

export type QueryGroupsPermissionsForResourceArgs = {
  resource: Resource;
  resourceId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryInstrumentSearchArgs = {
  search?: InputMaybe<InstrumentsSearchInput>;
};

export type QueryInstrumentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortingOrder?: InputMaybe<SortingOrder>;
  venueNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryInternalExchangeConfigurationArgs = {
  account: Scalars['String']['input'];
};

export type QueryLedgerEntriesArgs = {
  search: LedgerEntrySearchInput;
};

export type QueryMatchesArgs = {
  search?: InputMaybe<MatchesSearchInput>;
};

export type QueryOrderBookSnapshotArgs = {
  search?: InputMaybe<OrderBookSnapshotSearchInput>;
};

export type QueryOrderStatesArgs = {
  clOrderId?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  instrumentId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  portfolioId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  venueAccount?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOrderStatesWithPredicatesArgs = {
  search: OrderStateSearchInput;
};

export type QueryPermissionSearchArgs = {
  search?: InputMaybe<PermissionSearchInput>;
};

export type QueryPortfolioConfigValidationArgs = {
  portfolioId: Scalars['String']['input'];
};

export type QueryPortfolioConfigurationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPortfolioGroupConfigurationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPortfolioSearchArgs = {
  search?: InputMaybe<PortfolioSearchInput>;
};

export type QueryPositionsArgs = {
  search: PositionSearchInput;
};

export type QueryPreTradeCheckAuditLogsArgs = {
  search?: InputMaybe<PreTradeCheckAuditLogSearchInput>;
};

export type QuerySettlementRunsArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySettlementTransactionsArgs = {
  search?: InputMaybe<SettlementTransactionSearchInput>;
};

export type QuerySymbolSearchArgs = {
  search?: InputMaybe<SymbolSearchInput>;
};

export type QueryTimelineArgs = {
  search?: InputMaybe<TimelineSearchInput>;
};

export type QueryTransactionsArgs = {
  search: TransactionSearchInput;
};

export type QueryUsersPermissionsForResourceArgs = {
  resource: Resource;
  resourceId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVenueAccountDetailsArgs = {
  request?: InputMaybe<VenueAccountDetailsInput>;
};

export type QueryVenuesArgs = {
  venueType?: InputMaybe<VenueType>;
};

export type QueryWalletAccountSearchArgs = {
  search?: InputMaybe<WalletAccountSearchInput>;
};

export enum QuoteTtlUnit {
  Days = 'DAYS',
  Hours = 'HOURS',
  Milliseconds = 'MILLISECONDS',
  Minutes = 'MINUTES',
  Seconds = 'SECONDS',
}

export type QuotingCalendar = {
  __typename?: 'QuotingCalendar';
  additionalMarkup: Scalars['Float']['output'];
  endTime: Scalars['String']['output'];
  fromDayOfTheWeek: DayOfTheWeek;
  quoteTTL: Scalars['Int']['output'];
  quoteTTLUnit: QuoteTtlUnit;
  startTime: Scalars['String']['output'];
  toDayOfTheWeek: DayOfTheWeek;
};

export type QuotingCalendarInput = {
  additionalMarkup: Scalars['Float']['input'];
  endTime: Scalars['String']['input'];
  fromDayOfTheWeek: DayOfTheWeek;
  quoteTTL: Scalars['Int']['input'];
  quoteTTLUnit: QuoteTtlUnit;
  startTime: Scalars['String']['input'];
  toDayOfTheWeek: DayOfTheWeek;
};

export type QuotingConfigValidationResult = {
  __typename?: 'QuotingConfigValidationResult';
  clobUid: Scalars['String']['output'];
  resultsPerInstrument: Array<ConfigInstrumentValidationResult>;
};

export type QuotingConfiguration = {
  __typename?: 'QuotingConfiguration';
  askMarkup?: Maybe<Scalars['Float']['output']>;
  bidMarkup?: Maybe<Scalars['Float']['output']>;
  clobUid: Scalars['String']['output'];
  deactivated: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  hedgingAccountDescs?: Maybe<Array<VenueAccountDesc>>;
  hedgingAccounts: Array<Scalars['String']['output']>;
  hedgingSafetyMargin: Scalars['Float']['output'];
  instrumentConfigurations: Array<InstrumentQuotingConfiguration>;
  maxQuantityFactor: Scalars['Float']['output'];
  maximumDepth?: Maybe<Scalars['Float']['output']>;
  minQuantityFactor: Scalars['Float']['output'];
  nostroPortfolioId: Scalars['String']['output'];
  nostroPortfolioName: Scalars['String']['output'];
  /** @deprecated Not used anymore */
  quoteTTL?: Maybe<Scalars['Int']['output']>;
  sourceAccountDescs?: Maybe<Array<VenueAccountDesc>>;
  sourceAccounts?: Maybe<Array<Scalars['String']['output']>>;
  sources?: Maybe<Array<QuotingSourceAccountConfig>>;
  throttlingPeriod?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type QuotingConfigurationInput = {
  askMarkup?: InputMaybe<Scalars['Float']['input']>;
  bidMarkup?: InputMaybe<Scalars['Float']['input']>;
  deactivated: Scalars['Boolean']['input'];
  displayName: Scalars['String']['input'];
  hedgingAccounts: Array<Scalars['String']['input']>;
  hedgingSafetyMargin: Scalars['Float']['input'];
  maxQuantityFactor: Scalars['Float']['input'];
  maximumDepth?: InputMaybe<Scalars['Float']['input']>;
  minQuantityFactor: Scalars['Float']['input'];
  nostroPortfolioId: Scalars['String']['input'];
  /** @deprecated Not used anymore */
  quoteTTL?: InputMaybe<Scalars['Int']['input']>;
  sourceAccounts?: InputMaybe<Array<Scalars['String']['input']>>;
  sources?: InputMaybe<Array<QuotingSourceAccountConfigInput>>;
  throttlingPeriod?: InputMaybe<Scalars['Int']['input']>;
};

export type QuotingSourceAccountConfig = {
  __typename?: 'QuotingSourceAccountConfig';
  accountId: Scalars['String']['output'];
  accountName: Scalars['String']['output'];
  calendarEntries?: Maybe<Array<QuotingCalendar>>;
  defaultQuoteTTL?: Maybe<Scalars['Int']['output']>;
  isDroppingQuotesOnDisconnection?: Maybe<Scalars['Boolean']['output']>;
  quoteTTLUnit: QuoteTtlUnit;
};

export type QuotingSourceAccountConfigInput = {
  accountId: Scalars['String']['input'];
  calendarEntries?: InputMaybe<Array<QuotingCalendarInput>>;
  defaultQuoteTTL?: InputMaybe<Scalars['Int']['input']>;
  isDroppingQuotesOnDisconnection?: InputMaybe<Scalars['Boolean']['input']>;
  quoteTTLUnit: QuoteTtlUnit;
};

export enum RateSubscriptionHealth {
  Down = 'DOWN',
  Pending = 'PENDING',
  Up = 'UP',
}

export type RateSubscriptionResponse = {
  __typename?: 'RateSubscriptionResponse';
  baseCurrency: Scalars['String']['output'];
  health: RateSubscriptionHealth;
  lastEventTimestamp?: Maybe<Scalars['String']['output']>;
  lastPrice?: Maybe<Scalars['String']['output']>;
  quoteCurrency: Scalars['String']['output'];
  venueAccountId?: Maybe<Scalars['String']['output']>;
  venueAccountName?: Maybe<Scalars['String']['output']>;
};

export type ReferenceDataCapabilities = {
  __typename?: 'ReferenceDataCapabilities';
  canRetrieveAll?: Maybe<Scalars['Boolean']['output']>;
  canRetrieveSampleData?: Maybe<Scalars['Boolean']['output']>;
  canRetrieveSampleDataRequests?: Maybe<Scalars['Boolean']['output']>;
  retrievableByRequest?: Maybe<Array<Maybe<KeyValues>>>;
};

export type ResetConfigurationInput = {
  configurationLevel: ConfigurationLevel;
  configurationType: ConfigurationType;
  instrumentId?: InputMaybe<Scalars['ID']['input']>;
  resourceId: Scalars['ID']['input'];
};

export enum Resource {
  ApiKey = 'API_KEY',
  BrokerConfig = 'BROKER_CONFIG',
  ClientInstrument = 'CLIENT_INSTRUMENT',
  ClobInstrument = 'CLOB_INSTRUMENT',
  Currency = 'CURRENCY',
  Portfolio = 'PORTFOLIO',
  PortfolioNostro = 'PORTFOLIO_NOSTRO',
  PortfolioVostro = 'PORTFOLIO_VOSTRO',
  Risk = 'RISK',
  Venue = 'VENUE',
  VenueAccount = 'VENUE_ACCOUNT',
  Wallet = 'WALLET',
}

export type RootExecution = {
  __typename?: 'RootExecution';
  executionId: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
};

export enum Scope {
  Create = 'CREATE',
  Manage = 'MANAGE',
  Read = 'READ',
  Trade = 'TRADE',
}

export type SelectAllTransactionsInput = {
  accountId?: InputMaybe<Array<Scalars['String']['input']>>;
  currency?: InputMaybe<Array<Scalars['String']['input']>>;
  executionId?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  parentOrderId?: InputMaybe<Scalars['String']['input']>;
  portfolioId?: InputMaybe<Array<Scalars['String']['input']>>;
  rootExecutionId?: InputMaybe<Scalars['String']['input']>;
  rootOrderId?: InputMaybe<Scalars['String']['input']>;
  selected?: InputMaybe<Scalars['Boolean']['input']>;
  settlementId: Scalars['String']['input'];
  symbol?: InputMaybe<Array<Scalars['String']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
  transactionType?: InputMaybe<Array<Scalars['String']['input']>>;
  underlyingExecutionId?: InputMaybe<Scalars['String']['input']>;
  venueExecutionId?: InputMaybe<Scalars['String']['input']>;
};

export type SelectTransactionsInput = {
  changes: Array<TransactionSelect>;
  settlementId: Scalars['String']['input'];
};

export type Settlement = {
  __typename?: 'Settlement';
  dateTime: Scalars['String']['output'];
  description: Scalars['String']['output'];
  settledTransactionIds: Array<Maybe<Scalars['String']['output']>>;
  updatedAt: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
};

export type SettlementAccountConfiguration = {
  __typename?: 'SettlementAccountConfiguration';
  assetToWalletMap?: Maybe<Array<WalletByAsset>>;
  automationEnabled?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Calendar day to exclude
   * MM-DD format
   */
  daysExcluded?: Maybe<Array<Scalars['String']['output']>>;
  directionPriority?: Maybe<SettlementDirectionPriority>;
  legPriority?: Maybe<SettlementLegPriority>;
  schedule?: Maybe<Array<SettlementSchedulePoint>>;
  treasuryManagementAutomationEnabled?: Maybe<Scalars['Boolean']['output']>;
  treasuryThresholds?: Maybe<Array<TreasuryThreshold>>;
};

export type SettlementAccountConfigurationInput = {
  assetToWalletMap?: InputMaybe<Array<WalletByAssetInput>>;
  automationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Calendar day to exclude
   * MM-DD format
   */
  daysExcluded?: InputMaybe<Array<Scalars['String']['input']>>;
  directionPriority?: InputMaybe<SettlementDirectionPriority>;
  legPriority?: InputMaybe<SettlementLegPriority>;
  schedule?: InputMaybe<Array<SettlementSchedulePointInput>>;
  treasuryManagementAutomationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  treasuryThresholds?: InputMaybe<Array<TreasuryThresholdInput>>;
};

export type SettlementConfigurationInput = {
  accountId: Scalars['String']['input'];
  config?: InputMaybe<SettlementAccountConfigurationInput>;
};

export type SettlementConfigurationResponse = {
  __typename?: 'SettlementConfigurationResponse';
  accountId: Scalars['String']['output'];
  config?: Maybe<SettlementAccountConfiguration>;
};

export enum SettlementDirection {
  Receive = 'RECEIVE',
  Send = 'SEND',
}

export enum SettlementDirectionPriority {
  None = 'NONE',
  ReceiveFirst = 'RECEIVE_FIRST',
  SendFirst = 'SEND_FIRST',
}

export type SettlementLeg = {
  __typename?: 'SettlementLeg';
  /** The asset of the settlement leg */
  asset: Scalars['String']['output'];
  /** True or false. It indicates whether the initiation is manual or automatic. */
  auto: Scalars['Boolean']['output'];
  /**
   * Direction of the settlement from the point of view of Wyden
   * i. Send: To the venue
   * ii. Receive: From the venue
   */
  direction: SettlementDirection;
  /** The ID od the settlement */
  id: Scalars['String']['output'];
  /** The amount of the asset to be transferred */
  quantity: Scalars['Float']['output'];
  /** The status of the transfer. 4 options: New | In-progress | Completed | Canceled */
  status: SettlementStatus;
  /** The ID of the linked transfer */
  transferId?: Maybe<Scalars['String']['output']>;
  /** The trading venue of the settlement leg */
  venue: Scalars['String']['output'];
  /** The account of the settlement leg */
  venueAccountId: Scalars['String']['output'];
  venueAccountName: Scalars['String']['output'];
};

export enum SettlementLegPriority {
  CryptoFirst = 'CRYPTO_FIRST',
  FiatFirst = 'FIAT_FIRST',
  None = 'NONE',
}

export type SettlementRunResponse = {
  __typename?: 'SettlementRunResponse';
  dateTime: Scalars['String']['output'];
  /** The ID od the settlement */
  id: Scalars['String']['output'];
  legs: Array<SettlementLeg>;
  /** The status of the settlement run. 4 options: New | In-progress | Completed | Canceled */
  status: SettlementStatus;
};

export type SettlementSchedulePoint = {
  __typename?: 'SettlementSchedulePoint';
  day: DayOfTheWeek;
  /**
   * Schedule time
   * HH:mm format
   */
  time: Scalars['String']['output'];
};

export type SettlementSchedulePointInput = {
  day: DayOfTheWeek;
  /**
   * Schedule time
   * HH:mm format
   */
  time: Scalars['String']['input'];
};

export enum SettlementStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type SettlementTransactionSearchInput = {
  accountId?: InputMaybe<Array<Scalars['String']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Array<Scalars['String']['input']>>;
  executionId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  parentOrderId?: InputMaybe<Scalars['String']['input']>;
  portfolioId?: InputMaybe<Array<Scalars['String']['input']>>;
  rootExecutionId?: InputMaybe<Scalars['String']['input']>;
  rootOrderId?: InputMaybe<Scalars['String']['input']>;
  selected?: InputMaybe<Scalars['Boolean']['input']>;
  settlementId: Scalars['String']['input'];
  /**
   * Result list will be sorted by updatedAt field
   * Default sorting order will be DESC if this field is not set
   */
  sortingOrder?: InputMaybe<SortingOrder>;
  symbol?: InputMaybe<Array<Scalars['String']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
  transactionType?: InputMaybe<Array<Scalars['String']['input']>>;
  underlyingExecutionId?: InputMaybe<Scalars['String']['input']>;
  venueExecutionId?: InputMaybe<Scalars['String']['input']>;
};

export enum Side {
  Buy = 'BUY',
  ReduceShort = 'REDUCE_SHORT',
  Sell = 'SELL',
  SellShort = 'SELL_SHORT',
  SideUndetermined = 'SIDE_UNDETERMINED',
}

export type SimplePredicateInput = {
  field?: InputMaybe<OrderStateStringField>;
  method?: InputMaybe<SimplePredicateType>;
  value: Scalars['String']['input'];
};

export enum SimplePredicateType {
  Contains = 'CONTAINS',
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
}

export type SorTarget = {
  __typename?: 'SorTarget';
  assetClass: AssetClass;
  symbol: Scalars['String']['output'];
};

export type SorTargetInput = {
  assetClass: AssetClass;
  symbol: Scalars['String']['input'];
};

export enum SortingOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SourceConfiguration = {
  __typename?: 'SourceConfiguration';
  conversionSourceInstrument?: Maybe<InstrumentResponse>;
  inverse: Scalars['Boolean']['output'];
  sourceInstrument: InstrumentResponse;
};

export type SourceConfigurationInput = {
  conversionSourceInstrumentId?: InputMaybe<Scalars['String']['input']>;
  inverse: Scalars['Boolean']['input'];
  sourceInstrumentId: Scalars['String']['input'];
};

export enum Status {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
}

export type StreetAssetTrade = Trade & {
  __typename?: 'StreetAssetTrade';
  currency: Scalars['String']['output'];
  dateTime: Scalars['String']['output'];
  description: Scalars['String']['output'];
  executionId: Scalars['String']['output'];
  extOrderId: Scalars['String']['output'];
  fee: Scalars['Float']['output'];
  feeCurrency: Scalars['String']['output'];
  instrument?: Maybe<InstrumentResponse>;
  intOrderId: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  portfolioId: Scalars['String']['output'];
  portfolioName: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  rootOrderId?: Maybe<Scalars['String']['output']>;
  settled: Scalars['String']['output'];
  settledDateTime?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
  venueAccount: Scalars['String']['output'];
  venueAccountName: Scalars['String']['output'];
  venueExecutionId?: Maybe<Scalars['String']['output']>;
};

export type StreetCashTrade = Trade & {
  __typename?: 'StreetCashTrade';
  baseCurrency: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  dateTime: Scalars['String']['output'];
  description: Scalars['String']['output'];
  executionId: Scalars['String']['output'];
  extOrderId: Scalars['String']['output'];
  fee: Scalars['Float']['output'];
  feeCurrency: Scalars['String']['output'];
  intOrderId: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  parentOrderId?: Maybe<Scalars['String']['output']>;
  portfolioId: Scalars['String']['output'];
  portfolioName: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  rootExecution?: Maybe<RootExecution>;
  rootOrderId?: Maybe<Scalars['String']['output']>;
  settled: Scalars['String']['output'];
  settledDateTime?: Maybe<Scalars['Float']['output']>;
  underlyingExecutionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
  venueAccount: Scalars['String']['output'];
  venueAccountName: Scalars['String']['output'];
  venueExecutionId?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  cancelReject?: Maybe<CancelRejectResponse>;
  eventsLogs: EventLogResponse;
  /** @deprecated executionReport is deprecated. Use orderStates instead. */
  executionReport?: Maybe<ExecutionReportResponse>;
  /**
   * Stream of order book snapshot (L2, e.g. full-book) events.
   * vanueAccount parameter is conditionally required - Street-side market data streaming (e.g. data produced by external venue)
   * portfolioId parameter is conditionally required - Client-side market data streaming (e.g. data produced by broker desk)
   */
  orderBook?: Maybe<OrderBookResponse>;
  orderStatesWithSnapshot?: Maybe<OrderStateResponse>;
  positionChanges: PositionResponse;
  /** date parameter - ISO8601 format without time part */
  settlementRuns: Array<SettlementRunResponse>;
  targetStates: TargetStatesResponse;
  /**
   * Stream of tick (L1, e.g. top-of-book) events.
   * vanueAccount parameter is conditionally required - Street-side market data streaming (e.g. data produced by external venue)
   * portfolioId parameter is conditionally required - Client-side market data streaming (e.g. data produced by broker desk)
   */
  tick?: Maybe<MarketDataResponse>;
  transactionsWithSnapshot?: Maybe<TransactionResponse>;
};

export type SubscriptionCancelRejectArgs = {
  portfolioId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionExecutionReportArgs = {
  portfolioId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOrderBookArgs = {
  instrumentId: Scalars['String']['input'];
  portfolioId?: InputMaybe<Scalars['String']['input']>;
  venueAccount?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionOrderStatesWithSnapshotArgs = {
  historyCap?: InputMaybe<Scalars['Int']['input']>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  portfolioId?: InputMaybe<Scalars['String']['input']>;
  venueAccount?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionPositionChangesArgs = {
  search: PositionSearchInput;
};

export type SubscriptionSettlementRunsArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionTickArgs = {
  instrumentId: Scalars['String']['input'];
  portfolioId?: InputMaybe<Scalars['String']['input']>;
  venueAccount?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionTransactionsWithSnapshotArgs = {
  search: TransactionSearchInput;
};

export type SymbolPredicateInput = {
  method: SymbolPredicateType;
  symbols: Array<Scalars['String']['input']>;
};

export enum SymbolPredicateType {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
}

export type SymbolSearchConnection = {
  __typename?: 'SymbolSearchConnection';
  edges: Array<SymbolSearchEdge>;
  pageInfo: PageInfo;
};

export type SymbolSearchEdge = {
  __typename?: 'SymbolSearchEdge';
  cursor: Scalars['String']['output'];
  node: SymbolSearchResponse;
};

export type SymbolSearchInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Result list will be sorted by symbol field
   * Default sorting order is ASC if this field is not set
   */
  sortingOrder?: InputMaybe<SortingOrder>;
  symbolPredicate?: InputMaybe<SymbolPredicateInput>;
  venueNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SymbolSearchResponse = {
  __typename?: 'SymbolSearchResponse';
  assetClass?: Maybe<AssetClass>;
  symbol?: Maybe<Scalars['String']['output']>;
  /** Only venues where instrument is active (not archived) */
  venues?: Maybe<Array<Maybe<VenueAccountNamesPerVenue>>>;
};

/**
 * Time in Force
 * Describes how long the Order should be valid
 */
export enum Tif {
  /** Valid till end of day */
  Day = 'DAY',
  /** Fill or Kill */
  Fok = 'FOK',
  /** Good till cancelled */
  Gtc = 'GTC',
  /** Good till Date */
  Gtd = 'GTD',
  /** Immediate or Cancel */
  Ioc = 'IOC',
  /**
   * Unknown
   * Should never be set by client
   */
  TifUnspecified = 'TIF_UNSPECIFIED',
}

export type Tag = {
  __typename?: 'Tag';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TagInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TargetCapabilityStatus = {
  __typename?: 'TargetCapabilityStatus';
  capability: Capability;
  healthStatus: HealthStatus;
  message?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
};

export type TargetStatesResponse = {
  __typename?: 'TargetStatesResponse';
  capabilities: Array<Maybe<TargetCapabilityStatus>>;
  target: Scalars['String']['output'];
  targetName?: Maybe<Scalars['String']['output']>;
};

export type ThresholdConfiguration = {
  __typename?: 'ThresholdConfiguration';
  asset?: Maybe<Scalars['String']['output']>;
  hedgeInstrument?: Maybe<InstrumentResponse>;
  highThreshold?: Maybe<Scalars['String']['output']>;
  lowThreshold?: Maybe<Scalars['String']['output']>;
  targetExposure?: Maybe<Scalars['String']['output']>;
};

export type ThresholdConfigurationInput = {
  asset?: InputMaybe<Scalars['String']['input']>;
  hedgeInstrumentId?: InputMaybe<Scalars['String']['input']>;
  highThreshold?: InputMaybe<Scalars['String']['input']>;
  lowThreshold?: InputMaybe<Scalars['String']['input']>;
  targetExposure?: InputMaybe<Scalars['String']['input']>;
};

export type TifsPerOrderType = {
  __typename?: 'TifsPerOrderType';
  key?: Maybe<OrderType>;
  values: Array<Tif>;
};

export type TimelineConnection = {
  __typename?: 'TimelineConnection';
  edges: Array<TimelineEdge>;
  pageInfo: PageInfo;
};

export type TimelineEdge = {
  __typename?: 'TimelineEdge';
  cursor: Scalars['String']['output'];
  node: TimelineResult;
};

export enum TimelineEventType {
  HedgeResult = 'HEDGE_RESULT',
  MatchResponse = 'MATCH_RESPONSE',
  OrderState = 'ORDER_STATE',
}

export type TimelineResponse = HedgeResult | MatchResponse | OrderStateResponse;

export type TimelineResult = {
  __typename?: 'TimelineResult';
  data?: Maybe<TimelineResponse>;
  type?: Maybe<TimelineEventType>;
};

export type TimelineSearchInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Array<InputMaybe<TimelineEventType>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelated?: InputMaybe<Scalars['Boolean']['input']>;
  orderId: Scalars['String']['input'];
  sortingOrder?: InputMaybe<SortingOrder>;
};

export type Trade = {
  currency: Scalars['String']['output'];
  dateTime: Scalars['String']['output'];
  description: Scalars['String']['output'];
  executionId: Scalars['String']['output'];
  extOrderId: Scalars['String']['output'];
  fee: Scalars['Float']['output'];
  feeCurrency: Scalars['String']['output'];
  intOrderId: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  settled: Scalars['String']['output'];
  settledDateTime?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
  venueExecutionId?: Maybe<Scalars['String']['output']>;
};

export type TradingCapabilities = {
  __typename?: 'TradingCapabilities';
  accountingLongAndShortPositionsSeparately?: Maybe<Scalars['Boolean']['output']>;
  allowingMultipleRFQsForTheSameSecurity?: Maybe<Scalars['Boolean']['output']>;
  allowingMultipleRFQsOnAdapterLevel?: Maybe<Scalars['Boolean']['output']>;
  allowingOrdersWhenRFQIsOngoingForTheSameSecurity?: Maybe<Scalars['Boolean']['output']>;
  defaultTIFs?: Maybe<Array<Maybe<DefaultTif>>>;
  exchangeTradingSupported?: Maybe<Scalars['Boolean']['output']>;
  marginTradingSupported?: Maybe<Scalars['Boolean']['output']>;
  postOnlyOrderSupportedTypesAndTIFs?: Maybe<Array<Maybe<TifsPerOrderType>>>;
  rfqcancelSupported?: Maybe<Scalars['Boolean']['output']>;
  rfqstreamingSupported?: Maybe<Scalars['Boolean']['output']>;
  rfqsupported?: Maybe<Scalars['Boolean']['output']>;
  supportedOrderRequests?: Maybe<Array<Maybe<OrderRequestsPerOrderType>>>;
  supportedOrderTypes?: Maybe<Array<Maybe<OrderType>>>;
  supportedSecurityTypes?: Maybe<Array<Maybe<InstrumentType>>>;
  supportedTIFs?: Maybe<Array<Maybe<TifsPerOrderType>>>;
  supportingCustomizedOrders?: Maybe<Scalars['Boolean']['output']>;
  supportingFixLiteralProperties?: Maybe<Scalars['Boolean']['output']>;
};

export type TradingConstraintsResponse = {
  __typename?: 'TradingConstraintsResponse';
  /** Contract size */
  contractSize?: Maybe<Scalars['String']['output']>;
  /**
   * Maximum trading price - constraints Limit and StopLimit Orders
   * Optional, no constraint if not set
   */
  maxPrice?: Maybe<Scalars['String']['output']>;
  /**
   * Maximum quantity allowed for trading
   * Optional, no constraint if not set
   */
  maxQty?: Maybe<Scalars['String']['output']>;
  /**
   * Maximum quote quantity allowed for trading
   * Optional, no constraint if not set
   */
  maxQuoteQty?: Maybe<Scalars['String']['output']>;
  /**
   * Minimum notional value that should be used for trading
   * Typically it can be calculated by multiplying ContractSize * NotionalPrice (Note: For FOREX Spot, the contract size is always 1 = baseCurrency quantity)
   * For more details, see: https://www.investopedia.com/terms/n/notionalvalue.asp
   */
  minNotional?: Maybe<Scalars['String']['output']>;
  /**
   * Minimum trading price - constraints Limit and StopLimit Orders
   * Optional, no constraint if not set
   */
  minPrice?: Maybe<Scalars['String']['output']>;
  /**
   * Minimum quantity allowed for trading
   * Optional, no constraint if not set
   */
  minQty?: Maybe<Scalars['String']['output']>;
  /**
   * Minimum quote quantity allowed for trading
   * Optional, no constraint if not set
   */
  minQuoteQty?: Maybe<Scalars['String']['output']>;
  /**
   * Minimum price increment
   * - constraints Limit and StopLimit Orders
   * - defines tickSize in OrderBook
   */
  priceIncr?: Maybe<Scalars['String']['output']>;
  /** Minimum quantity increment */
  qtyIncr?: Maybe<Scalars['String']['output']>;
  /** Minimum quote quantity increment */
  quoteQtyIncr?: Maybe<Scalars['String']['output']>;
  /** Defines whether this Instrument can be traded or not */
  tradeable?: Maybe<Scalars['Boolean']['output']>;
};

export enum TradingMode {
  Agency = 'AGENCY',
  Principal = 'PRINCIPAL',
}

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  edges: Array<TransactionEdge>;
  pageInfo: PageInfo;
};

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  cursor: Scalars['String']['output'];
  node: TransactionResponse;
};

export type TransactionInput = {
  conversionRates?: InputMaybe<Array<ConversionRate>>;
  counterPortfolioId?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  dateTime: Scalars['String']['input'];
  executionId?: InputMaybe<Scalars['String']['input']>;
  externalOrderId?: InputMaybe<Scalars['String']['input']>;
  fee?: InputMaybe<Scalars['String']['input']>;
  feeAccountId?: InputMaybe<Scalars['String']['input']>;
  feePortfolioId?: InputMaybe<Scalars['String']['input']>;
  instrumentId?: InputMaybe<Scalars['String']['input']>;
  isSettled: Scalars['Boolean']['input'];
  portfolioId?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['String']['input'];
  settlementDate?: InputMaybe<Scalars['String']['input']>;
  sourceAccountId?: InputMaybe<Scalars['String']['input']>;
  sourcePortfolioId?: InputMaybe<Scalars['String']['input']>;
  type: TransactionType;
  venueAccountId?: InputMaybe<Scalars['String']['input']>;
  venueExecutionId?: InputMaybe<Scalars['String']['input']>;
};

export type TransactionResponse =
  | AccountCashTransfer
  | ClientAssetTrade
  | ClientCashTrade
  | Deposit
  | PortfolioCashTransfer
  | Settlement
  | StreetAssetTrade
  | StreetCashTrade
  | Withdrawal;

export type TransactionSearchInput = {
  accountId?: InputMaybe<Array<Scalars['String']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Array<Scalars['String']['input']>>;
  executionId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  parentOrderId?: InputMaybe<Scalars['String']['input']>;
  portfolioId?: InputMaybe<Array<Scalars['String']['input']>>;
  rootExecutionId?: InputMaybe<Scalars['String']['input']>;
  rootOrderId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Result list will be sorted by updatedAt field
   * Default sorting order will be DESC if this field is not set
   */
  sortingOrder?: InputMaybe<SortingOrder>;
  symbol?: InputMaybe<Array<Scalars['String']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
  transactionType?: InputMaybe<Array<Scalars['String']['input']>>;
  underlyingExecutionId?: InputMaybe<Scalars['String']['input']>;
  venueExecutionId?: InputMaybe<Scalars['String']['input']>;
};

export type TransactionSelect = {
  selected: Scalars['Boolean']['input'];
  transactionId: Scalars['String']['input'];
};

export enum TransactionType {
  Deposit = 'DEPOSIT',
  Trade = 'TRADE',
  Transfer = 'TRANSFER',
  Withdrawal = 'WITHDRAWAL',
}

export type TreasuryThreshold = {
  __typename?: 'TreasuryThreshold';
  currency: Scalars['String']['output'];
  /** Balance amount above which an automatic transfer will be initiated to reach target amount */
  maximum?: Maybe<Scalars['String']['output']>;
  /** Balance amount below which an automatic transfer will be initiated to reach target amount */
  minimum?: Maybe<Scalars['String']['output']>;
  /** Balance amount to be reached after the minimum or maximum thresholds are breached */
  target?: Maybe<Scalars['String']['output']>;
};

export type TreasuryThresholdInput = {
  currency: Scalars['String']['input'];
  /** Balance amount above which an automatic transfer will be initiated to reach target amount */
  maximum?: InputMaybe<Scalars['String']['input']>;
  /** Balance amount below which an automatic transfer will be initiated to reach target amount */
  minimum?: InputMaybe<Scalars['String']['input']>;
  /** Balance amount to be reached after the minimum or maximum thresholds are breached */
  target?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateApiKeyNameInput = {
  apiKeyId?: InputMaybe<Scalars['String']['input']>;
  apiKeyName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBaseInstrumentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  inverseContract?: InputMaybe<Scalars['Boolean']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConversionSourceInput = {
  priority: Scalars['Int']['input'];
  venueAccount: Scalars['String']['input'];
};

export type UpdateInstrumentIdentifiersInput = {
  adapterTicker?: InputMaybe<Scalars['String']['input']>;
  tradingViewId?: InputMaybe<Scalars['String']['input']>;
  /** Venue identifier for this instrument that will be used when communicating with TradingView */
  venueTradingViewId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInstrumentInput = {
  /**
   * Optional
   * If set to true, this Instrument will become archived and it will not be possible to trade it any more.
   * Since there's no possibility to hard-delete Instrument from the system (for audit purposes), archiving option should be used instead
   */
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  baseInstrument?: InputMaybe<UpdateBaseInstrumentInput>;
  correlationObject?: InputMaybe<Scalars['String']['input']>;
  instrumentId: Scalars['String']['input'];
  instrumentIdentifiers?: InputMaybe<UpdateInstrumentIdentifiersInput>;
  tradingConstraints?: InputMaybe<UpdateTradingConstraintsInput>;
};

export type UpdateInstrumentResult = {
  __typename?: 'UpdateInstrumentResult';
  status?: Maybe<Scalars['String']['output']>;
};

export type UpdatePortfolioInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  correlationObject?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  portfolioType?: InputMaybe<PortfolioType>;
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
};

export type UpdateTradingConstraintsInput = {
  /** Contract size */
  contractSize?: InputMaybe<Scalars['String']['input']>;
  /**
   * Maximum trading price - constraints Limit and StopLimit Orders
   * Optional, no constraint if not set
   */
  maxPrice?: InputMaybe<Scalars['String']['input']>;
  /** Maximum quantity allowed for trading */
  maxQty: Scalars['String']['input'];
  /**
   * Maximum quote quantity allowed for trading
   * Optional, no constraint if not set
   */
  maxQuoteQty?: InputMaybe<Scalars['String']['input']>;
  /**
   * Minimum notional value that should be used for trading
   * Typically it can be calculated by multiplying ContractSize * NotionalPrice (Note: For FOREX Spot, the contract size is always 1 = baseCurrency quantity)
   * For more details, see: https://www.investopedia.com/terms/n/notionalvalue.asp
   */
  minNotional?: InputMaybe<Scalars['String']['input']>;
  /**
   * Minimum trading price - constraints Limit and StopLimit Orders
   * Optional, no constraint if not set
   */
  minPrice?: InputMaybe<Scalars['String']['input']>;
  /** Minimum quantity allowed for trading */
  minQty: Scalars['String']['input'];
  /**
   * Minimum quote quantity allowed for trading
   * Optional, no constraint if not set
   */
  minQuoteQty?: InputMaybe<Scalars['String']['input']>;
  /**
   * Minimum price increment
   * - constraints Limit and StopLimit Orders
   * - defines tickSize in OrderBook
   */
  priceIncr: Scalars['String']['input'];
  /** Minimum quantity increment */
  qtyIncr: Scalars['String']['input'];
  /** Minimum quote quantity increment */
  quoteQtyIncr?: InputMaybe<Scalars['String']['input']>;
  /** Defines whether this Instrument can be traded or not */
  tradeable: Scalars['Boolean']['input'];
};

export type UpdateUserDataInput = {
  data: Scalars['String']['input'];
};

export type UpdateVenueAccountInput = {
  keyValues?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  venueAccountId?: InputMaybe<Scalars['String']['input']>;
  venueAccountName: Scalars['String']['input'];
};

export type UserDataResponse = {
  __typename?: 'UserDataResponse';
  data?: Maybe<Scalars['String']['output']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  groups: Array<GroupResponse>;
  permissions: Array<Permission>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsForResourceResponse = {
  __typename?: 'UsersPermissionsForResourceResponse';
  scopes: Array<Scope>;
  username: Scalars['String']['output'];
};

export type ValidationError = {
  __typename?: 'ValidationError';
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ValidationResponse = {
  __typename?: 'ValidationResponse';
  errors?: Maybe<Array<ValidationError>>;
  isValid: Scalars['Boolean']['output'];
};

export type Venue = {
  __typename?: 'Venue';
  name: Scalars['String']['output'];
  venueType: VenueType;
};

export type VenueAccount = {
  __typename?: 'VenueAccount';
  createdAt?: Maybe<Scalars['String']['output']>;
  deactivatedAt?: Maybe<Scalars['String']['output']>;
  /** Permission scopes, granted to the originator of the request. */
  scopes: Array<Scope>;
  venueAccountId: Scalars['String']['output'];
  venueAccountName: Scalars['String']['output'];
};

export type VenueAccountDesc = {
  __typename?: 'VenueAccountDesc';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type VenueAccountDetailsInput = {
  venueAccount: Scalars['String']['input'];
};

export type VenueAccountDetailsResponse = {
  __typename?: 'VenueAccountDetailsResponse';
  keyValues?: Maybe<Array<Maybe<KeyValue>>>;
  /** Permission scopes, granted to the originator of the request. */
  scopes: Array<Scope>;
  venueAccountId: Scalars['String']['output'];
  venueAccountName: Scalars['String']['output'];
  venueName: Scalars['String']['output'];
};

export type VenueAccountNamesPerVenue = {
  __typename?: 'VenueAccountNamesPerVenue';
  /** Only active (not archived) instruments are included */
  instrument?: Maybe<InstrumentResponse>;
  venue?: Maybe<Scalars['String']['output']>;
  venueAccountDescs?: Maybe<Array<Maybe<VenueAccountDesc>>>;
};

export type VenueAccountsPerVenue = {
  __typename?: 'VenueAccountsPerVenue';
  venue?: Maybe<Scalars['String']['output']>;
  venueAccounts?: Maybe<Array<Maybe<VenueAccount>>>;
};

export type VenueCapabilitiesResponse = {
  __typename?: 'VenueCapabilitiesResponse';
  capabilities?: Maybe<DetailedCapabilities>;
  venue: Scalars['String']['output'];
};

export enum VenueType {
  /** Internal broker or market maker */
  Client = 'CLIENT',
  /** Central Limit Order Book */
  Clob = 'CLOB',
  /**
   * External Venue
   * Can be one of: Exchange, Broker, Market-maker, Liquidity provider, market data provider
   */
  Street = 'STREET',
}

export type WalletAccountConnection = {
  __typename?: 'WalletAccountConnection';
  edges: Array<WalletAccountEdge>;
  pageInfo: PageInfo;
};

export type WalletAccountEdge = {
  __typename?: 'WalletAccountEdge';
  cursor: Scalars['String']['output'];
  node: WalletAccountResponse;
};

export type WalletAccountResponse = {
  __typename?: 'WalletAccountResponse';
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  scopes: Array<Scope>;
};

export type WalletAccountSearchInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  scopes?: InputMaybe<Array<Scope>>;
};

export type WalletByAsset = {
  __typename?: 'WalletByAsset';
  asset: Scalars['String']['output'];
  wallet: Scalars['String']['output'];
};

export type WalletByAssetInput = {
  asset: Scalars['String']['input'];
  wallet: Scalars['String']['input'];
};

export type Withdrawal = {
  __typename?: 'Withdrawal';
  account: Scalars['String']['output'];
  accountName: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  dateTime: Scalars['String']['output'];
  description: Scalars['String']['output'];
  executionId: Scalars['String']['output'];
  feeAccountId?: Maybe<Scalars['String']['output']>;
  feeAccountName?: Maybe<Scalars['String']['output']>;
  feePortfolioId?: Maybe<Scalars['String']['output']>;
  feePortfolioName?: Maybe<Scalars['String']['output']>;
  portfolioId: Scalars['String']['output'];
  portfolioName: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  settled: Scalars['String']['output'];
  settledDateTime?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
  venueExecutionId?: Maybe<Scalars['String']['output']>;
};

export type ActivateVenueAccountMutationVariables = Exact<{
  request: ActivateVenueAccountInput;
}>;

export type ActivateVenueAccountMutation = {
  __typename?: 'Mutation';
  activateVenueAccount?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type AddGroupPermissionsMutationVariables = Exact<{
  request: AddOrRemoveGroupPermissionsInput;
}>;

export type AddGroupPermissionsMutation = {
  __typename?: 'Mutation';
  addGroupPermissions?: { __typename?: 'MutationReturnValue'; clientId?: string | null } | null;
};

export type AddTransactionMutationVariables = Exact<{
  request: TransactionInput;
}>;

export type AddTransactionMutation = {
  __typename?: 'Mutation';
  addTransaction: { __typename?: 'MutationSubmittedResponse'; status: string };
};

export type AddUserPermissionsMutationVariables = Exact<{
  request: AddOrRemoveUserPermissionsInput;
}>;

export type AddUserPermissionsMutation = {
  __typename?: 'Mutation';
  addUserPermissions?: { __typename?: 'MutationReturnValue'; clientId?: string | null } | null;
};

export type ApiKeysQueryVariables = Exact<{ [key: string]: never }>;

export type ApiKeysQuery = {
  __typename?: 'Query';
  apiKeys?: Array<{
    __typename?: 'ApiKey';
    name?: string | null;
    id: string;
    createdAt: string;
    status: ApiKeyStatus;
  } | null> | null;
};

export type CancelOrderMutationVariables = Exact<{
  request: OrderCancelRequestInput;
}>;

export type CancelOrderMutation = {
  __typename?: 'Mutation';
  cancelOrder?: { __typename?: 'MutationReturnValue'; clientId?: string | null } | null;
};

export type CancelRejectSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CancelRejectSubscription = {
  __typename?: 'Subscription';
  cancelReject?: {
    __typename?: 'CancelRejectResponse';
    result?: string | null;
    cancelRejectReason?: string | null;
    cancelRejectResponseTo?: CancelRejectResponseTo | null;
    clOrderId?: string | null;
    clientId?: string | null;
    orderStatus?: OrderStatus | null;
    origClOrderId?: string | null;
    origOrderId?: string | null;
  } | null;
};

export type CancelReplaceOrderMutationVariables = Exact<{
  request: OrderCancelReplaceRequestInput;
}>;

export type CancelReplaceOrderMutation = {
  __typename?: 'Mutation';
  cancelReplaceOrder?: { __typename?: 'MutationReturnValue'; clientId?: string | null } | null;
};

export type ConnectorTemplateQueryVariables = Exact<{
  venue?: InputMaybe<Scalars['String']['input']>;
}>;

export type ConnectorTemplateQuery = {
  __typename?: 'Query';
  connectorTemplate?: Array<{
    __typename?: 'ConfigField';
    type?: string | null;
    secret?: boolean | null;
    enumValues?: Array<string | null> | null;
    propertyKey?: string | null;
    defaultValue?: string | null;
    required?: boolean | null;
    description?: string | null;
  }> | null;
};

export type ConversionSourceVenueAccountQueryVariables = Exact<{ [key: string]: never }>;

export type ConversionSourceVenueAccountQuery = {
  __typename?: 'Query';
  conversionSourceVenueAccounts: Array<{
    __typename?: 'ConversionSourceVenueAccountResponse';
    venueName: string;
    venueAccounts: Array<{
      __typename?: 'ConversionSourceVenueAccount';
      venueAccount: string;
      venueAccountName: string;
    }>;
  }>;
};

export type ConversionSourcesQueryVariables = Exact<{ [key: string]: never }>;

export type ConversionSourcesQuery = {
  __typename?: 'Query';
  conversionSources: Array<{
    __typename?: 'ConversionSourceResponse';
    venueAccount: string;
    venueAccountName: string;
    priority: number;
  }>;
};

export type CreateApiKeyMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateApiKeyMutation = {
  __typename?: 'Mutation';
  createApiKey: {
    __typename?: 'ApiKeyResponse';
    id: string;
    name?: string | null;
    createdAt: string;
    secret: string;
    status: ApiKeyStatus;
  };
};

export type CreateConversionSourceMutationVariables = Exact<{
  request: CreateConversionSourceInput;
}>;

export type CreateConversionSourceMutation = {
  __typename?: 'Mutation';
  createConversionSource?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type CreateInstrumentMutationVariables = Exact<{
  request: CreateInstrumentInput;
}>;

export type CreateInstrumentMutation = {
  __typename?: 'Mutation';
  createInstrument?: { __typename?: 'CreateInstrumentResult'; status?: string | null } | null;
};

export type CreateInternalExchangeConfigurationMutationVariables = Exact<{
  request: InternalExchangeConfigurationInput;
}>;

export type CreateInternalExchangeConfigurationMutation = {
  __typename?: 'Mutation';
  createInternalExchangeConfiguration?: {
    __typename?: 'MutationSubmittedResponse';
    status: string;
  } | null;
};

export type CreatePortfolioMutationVariables = Exact<{
  request: CreatePortfolioInput;
}>;

export type CreatePortfolioMutation = {
  __typename?: 'Mutation';
  createPortfolio?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type CreateQuotingConfigurationMutationVariables = Exact<{
  request: QuotingConfigurationInput;
}>;

export type CreateQuotingConfigurationMutation = {
  __typename?: 'Mutation';
  createQuotingConfiguration?: {
    __typename?: 'QuotingConfigValidationResult';
    resultsPerInstrument: Array<{
      __typename?: 'ConfigInstrumentValidationResult';
      instrumentId: string;
      isValid: boolean;
      preventsTrading: boolean;
      preventsMarketData: boolean;
      errors: Array<{
        __typename?: 'ConfigValidationError';
        errorMessage?: string | null;
        preventsTrading: boolean;
        preventsMarketData: boolean;
      }>;
    }>;
  } | null;
};

export type CreateRateSubscriptionMutationVariables = Exact<{
  request: CreateRateSubscriptionInput;
}>;

export type CreateRateSubscriptionMutation = {
  __typename?: 'Mutation';
  createRateSubscription?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type CreateVenueAccountMutationVariables = Exact<{
  request: CreateVenueAccountInput;
}>;

export type CreateVenueAccountMutation = {
  __typename?: 'Mutation';
  createVenueAccount?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type CreateWalletAccountMutationVariables = Exact<{
  request: CreateWalletAccountInput;
}>;

export type CreateWalletAccountMutation = {
  __typename?: 'Mutation';
  createWalletAccount?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type CurrencySearchQueryVariables = Exact<{
  search?: InputMaybe<CurrencySearchInput>;
}>;

export type CurrencySearchQuery = {
  __typename?: 'Query';
  currencySearch: Array<{
    __typename?: 'CurrencyResponse';
    symbol: string;
    type: CurrencyCategory;
    precision: number;
    displayPrecision: number;
  }>;
};

export type DeactivateApiKeyMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeactivateApiKeyMutation = {
  __typename?: 'Mutation';
  deactivateApiKey?: { __typename?: 'MutationReturnValue'; clientId?: string | null } | null;
};

export type DeactivateVenueAccountMutationVariables = Exact<{
  request: DeactivateVenueAccountInput;
}>;

export type DeactivateVenueAccountMutation = {
  __typename?: 'Mutation';
  deactivateVenueAccount?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type DeleteConversionSourceMutationVariables = Exact<{
  venueAccount: Scalars['String']['input'];
}>;

export type DeleteConversionSourceMutation = {
  __typename?: 'Mutation';
  deleteConversionSource?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type DeletePreTradeCheckMutationVariables = Exact<{
  request: Scalars['String']['input'];
}>;

export type DeletePreTradeCheckMutation = {
  __typename?: 'Mutation';
  deletePreTradeCheck?: { __typename?: 'MutationReturnValue'; clientId?: string | null } | null;
};

export type DeleteQuotingConfigurationMutationVariables = Exact<{
  clobUid: Scalars['String']['input'];
}>;

export type DeleteQuotingConfigurationMutation = {
  __typename?: 'Mutation';
  deleteQuotingConfiguration?: string | null;
};

export type DeleteRateSubscriptionMutationVariables = Exact<{
  request: DeleteRateSubscriptionInput;
}>;

export type DeleteRateSubscriptionMutation = {
  __typename?: 'Mutation';
  deleteRateSubscription?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type EffectiveVenueAccountsQueryVariables = Exact<{
  portfolioId: Scalars['String']['input'];
  instrumentId: Scalars['String']['input'];
}>;

export type EffectiveVenueAccountsQuery = {
  __typename?: 'Query';
  effectiveVenueAccounts?: {
    __typename?: 'EffectiveVenueAccountsResponse';
    venueAccounts: Array<string>;
    isSor?: boolean | null;
  } | null;
};

export type EntitlementsQueryVariables = Exact<{ [key: string]: never }>;

export type EntitlementsQuery = { __typename?: 'Query'; entitlements: Array<string | null> };

export type EventsLogsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type EventsLogsSubscription = {
  __typename?: 'Subscription';
  eventsLogs: {
    __typename?: 'EventLogResponse';
    status: Status;
    clientId: string;
    message: string;
    timestamp: string;
    eventType: string;
    correlationObject?: string | null;
  };
};

export type GroupNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GroupNamesQuery = { __typename?: 'Query'; groupNames: Array<string> };

export type InstrumentSearchQueryVariables = Exact<{
  search: InstrumentsSearchInput;
}>;

export type InstrumentSearchQuery = {
  __typename?: 'Query';
  instrumentSearch?: {
    __typename?: 'InstrumentsConnection';
    edges: Array<{
      __typename?: 'InstrumentEdge';
      cursor: string;
      node: {
        __typename?: 'InstrumentResponse';
        archivedAt?: string | null;
        createdAt?: string | null;
        baseInstrument: {
          __typename?: 'BaseInstrumentResponse';
          assetClass: AssetClass;
          symbol?: string | null;
          description?: string | null;
          quoteCurrency: string;
          inverseContract?: boolean | null;
          venueType: VenueType;
          venueName: string;
        };
        forexSpotProperties?: {
          __typename?: 'ForexSpotPropertiesResponse';
          baseCurrency: string;
        } | null;
        tradingConstraints: {
          __typename?: 'TradingConstraintsResponse';
          minQty?: string | null;
          maxQty?: string | null;
          qtyIncr?: string | null;
          minQuoteQty?: string | null;
          maxQuoteQty?: string | null;
          quoteQtyIncr?: string | null;
          minPrice?: string | null;
          maxPrice?: string | null;
          priceIncr?: string | null;
          minNotional?: string | null;
          contractSize?: string | null;
          tradeable?: boolean | null;
        };
        instrumentIdentifiers: {
          __typename?: 'InstrumentIdentifiersResponse';
          adapterTicker?: string | null;
          instrumentId: string;
          tradingViewId?: string | null;
          venueTradingViewId?: string | null;
        };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
  } | null;
};

export type InstrumentContentFragment = {
  __typename?: 'InstrumentResponse';
  archivedAt?: string | null;
  createdAt?: string | null;
  baseInstrument: {
    __typename?: 'BaseInstrumentResponse';
    assetClass: AssetClass;
    symbol?: string | null;
    description?: string | null;
    quoteCurrency: string;
    inverseContract?: boolean | null;
    venueType: VenueType;
    venueName: string;
  };
  forexSpotProperties?: { __typename?: 'ForexSpotPropertiesResponse'; baseCurrency: string } | null;
  tradingConstraints: {
    __typename?: 'TradingConstraintsResponse';
    minQty?: string | null;
    minQuoteQty?: string | null;
    maxQty?: string | null;
    maxQuoteQty?: string | null;
    qtyIncr?: string | null;
    quoteQtyIncr?: string | null;
    minPrice?: string | null;
    maxPrice?: string | null;
    priceIncr?: string | null;
    minNotional?: string | null;
    contractSize?: string | null;
    tradeable?: boolean | null;
  };
  instrumentIdentifiers: {
    __typename?: 'InstrumentIdentifiersResponse';
    adapterTicker?: string | null;
    instrumentId: string;
    tradingViewId?: string | null;
    venueTradingViewId?: string | null;
  };
};

export type InstrumentsRefreshMutationVariables = Exact<{
  request: InstrumentsRefreshRequestInput;
}>;

export type InstrumentsRefreshMutation = {
  __typename?: 'Mutation';
  instrumentsRefresh?: { __typename?: 'MutationReturnValue'; clientId?: string | null } | null;
};

export type InternalExchangeConfigurationQueryVariables = Exact<{
  account: Scalars['String']['input'];
}>;

export type InternalExchangeConfigurationQuery = {
  __typename?: 'Query';
  internalExchangeConfiguration?: {
    __typename?: 'InternalExchangeConfigurationResponse';
    account: string;
    accountName: string;
    availableOrderTypes: Array<OrderType>;
    availableTifs: Array<Tif>;
  } | null;
};

export type LedgerEntriesQueryVariables = Exact<{
  search: LedgerEntrySearchInput;
}>;

export type LedgerEntriesQuery = {
  __typename?: 'Query';
  ledgerEntries?: {
    __typename?: 'LedgerEntryConnection';
    edges: Array<{
      __typename?: 'LedgerEntryEdge';
      node: {
        __typename?: 'LedgerEntryResponse';
        updatedAt: number;
        quantity: number;
        price: number;
        type: LedgerEntryType;
        symbol?: string | null;
        currency?: string | null;
        portfolioId?: string | null;
        portfolioName?: string | null;
        transactionId?: string | null;
        id: string;
        accountId?: string | null;
        accountName?: string | null;
        balanceBefore?: number | null;
        balanceAfter?: number | null;
        orderId?: string | null;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean };
  } | null;
};

export type MatchesSearchQueryVariables = Exact<{
  search?: InputMaybe<MatchesSearchInput>;
}>;

export type MatchesSearchQuery = {
  __typename?: 'Query';
  matches?: {
    __typename?: 'MatchesResult';
    totalQuantity?: number | null;
    totalAmount?: number | null;
    avgPrice?: number | null;
    matches: Array<{
      __typename?: 'MatchResponse';
      id: string;
      timestamp: string;
      makers: Array<{
        __typename?: 'Counterparty';
        portfolioId?: string | null;
        portfolioName?: string | null;
        venueAccount?: string | null;
        venueAccountName?: string | null;
        referenceType?: ParticipantReferenceType | null;
        orderId?: string | null;
        price: number;
        volume: number;
      }>;
      taker: {
        __typename?: 'Counterparty';
        portfolioId?: string | null;
        portfolioName?: string | null;
        venueAccount?: string | null;
        venueAccountName?: string | null;
        referenceType?: ParticipantReferenceType | null;
        orderId?: string | null;
        price: number;
        volume: number;
      };
      primarySymbolQuotes: Array<{
        __typename?: 'MarketQuote';
        marketAskPrice: number;
        marketBidPrice: number;
        marketAskSize: number;
        marketBidSize: number;
        markupAskPrice?: number | null;
        markupBidPrice?: number | null;
        timestamp?: string | null;
        instrument: {
          __typename?: 'InstrumentResponse';
          baseInstrument: {
            __typename?: 'BaseInstrumentResponse';
            symbol?: string | null;
            venueName: string;
            quoteCurrency: string;
          };
          instrumentIdentifiers: {
            __typename?: 'InstrumentIdentifiersResponse';
            instrumentId: string;
          };
          forexSpotProperties?: {
            __typename?: 'ForexSpotPropertiesResponse';
            baseCurrency: string;
          } | null;
        };
      }>;
      secondarySymbolQuotes?: Array<{
        __typename?: 'MarketQuote';
        marketAskPrice: number;
        marketBidPrice: number;
        marketAskSize: number;
        marketBidSize: number;
        markupAskPrice?: number | null;
        markupBidPrice?: number | null;
        timestamp?: string | null;
        instrument: {
          __typename?: 'InstrumentResponse';
          baseInstrument: {
            __typename?: 'BaseInstrumentResponse';
            symbol?: string | null;
            venueName: string;
            quoteCurrency: string;
          };
          instrumentIdentifiers: {
            __typename?: 'InstrumentIdentifiersResponse';
            instrumentId: string;
          };
          forexSpotProperties?: {
            __typename?: 'ForexSpotPropertiesResponse';
            baseCurrency: string;
          } | null;
        };
      }> | null;
    }>;
  } | null;
};

export type OrderBookSubscriptionVariables = Exact<{
  venueAccount?: InputMaybe<Scalars['String']['input']>;
  instrumentId: Scalars['String']['input'];
  portfolioId?: InputMaybe<Scalars['String']['input']>;
}>;

export type OrderBookSubscription = {
  __typename?: 'Subscription';
  orderBook?: {
    __typename?: 'OrderBookResponse';
    instrumentId?: string | null;
    venue?: string | null;
    venueAccount?: string | null;
    venueAccountName?: string | null;
    dateTime?: string | null;
    bids?: Array<{
      __typename?: 'OrderBook';
      price?: string | null;
      amount?: string | null;
      count?: string | null;
    } | null> | null;
    asks?: Array<{
      __typename?: 'OrderBook';
      price?: string | null;
      amount?: string | null;
      count?: string | null;
    } | null> | null;
  } | null;
};

export type OrderBookContentFragment = {
  __typename?: 'OrderBook';
  price?: string | null;
  amount?: string | null;
  count?: string | null;
};

export type OrderBookSnapshotQueryVariables = Exact<{
  search?: InputMaybe<OrderBookSnapshotSearchInput>;
}>;

export type OrderBookSnapshotQuery = {
  __typename?: 'Query';
  orderBookSnapshot?: {
    __typename?: 'OrderBookSnapshot';
    bids?: Array<{
      __typename?: 'OrderBook';
      price?: string | null;
      amount?: string | null;
      count?: string | null;
    } | null> | null;
    asks?: Array<{
      __typename?: 'OrderBook';
      price?: string | null;
      amount?: string | null;
      count?: string | null;
    } | null> | null;
  } | null;
};

export type OrderStatesQueryVariables = Exact<{
  portfolioId?: InputMaybe<Scalars['String']['input']>;
  venueAccount?: InputMaybe<Scalars['String']['input']>;
  instrumentId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  clOrderId?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
}>;

export type OrderStatesQuery = {
  __typename?: 'Query';
  orderStates?: Array<{
    __typename?: 'OrderStateResponse';
    orderId: string;
    clientId?: string | null;
    clOrderId?: string | null;
    expirationDateTime?: string | null;
    origClOrderId?: string | null;
    portfolioId: string;
    portfolioName: string;
    counterPortfolioId?: string | null;
    counterPortfolioName?: string | null;
    orderStatus: OrderStatus;
    orderQty: number;
    limitPrice?: number | null;
    stopPrice?: number | null;
    tif?: Tif | null;
    filledQty: number;
    remainingQty: number;
    lastQty?: number | null;
    avgPrice?: string | null;
    lastPrice?: string | null;
    reason?: string | null;
    lastRequestResult: string;
    side: Side;
    symbol?: string | null;
    assetClass?: AssetClass | null;
    venue?: string | null;
    venueTimestamp?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    orderCategory: OrderCategory;
    parentOrderId?: string | null;
    instrument?: {
      __typename?: 'InstrumentResponse';
      archivedAt?: string | null;
      createdAt?: string | null;
      baseInstrument: {
        __typename?: 'BaseInstrumentResponse';
        assetClass: AssetClass;
        symbol?: string | null;
        description?: string | null;
        quoteCurrency: string;
        inverseContract?: boolean | null;
        venueType: VenueType;
        venueName: string;
      };
      forexSpotProperties?: {
        __typename?: 'ForexSpotPropertiesResponse';
        baseCurrency: string;
      } | null;
      tradingConstraints: {
        __typename?: 'TradingConstraintsResponse';
        minQty?: string | null;
        minQuoteQty?: string | null;
        maxQty?: string | null;
        maxQuoteQty?: string | null;
        qtyIncr?: string | null;
        quoteQtyIncr?: string | null;
        minPrice?: string | null;
        maxPrice?: string | null;
        priceIncr?: string | null;
        minNotional?: string | null;
        contractSize?: string | null;
        tradeable?: boolean | null;
      };
      instrumentIdentifiers: {
        __typename?: 'InstrumentIdentifiersResponse';
        adapterTicker?: string | null;
        instrumentId: string;
        tradingViewId?: string | null;
        venueTradingViewId?: string | null;
      };
    } | null;
    venueAccountDescs?: Array<{ __typename?: 'VenueAccountDesc'; id: string; name: string }> | null;
  }> | null;
};

export type OrderStatesWithPredicatesQueryVariables = Exact<{
  search: OrderStateSearchInput;
}>;

export type OrderStatesWithPredicatesQuery = {
  __typename?: 'Query';
  orderStatesWithPredicates?: {
    __typename?: 'OrderStateConnection';
    edges: Array<{
      __typename?: 'OrderStateEdge';
      node: {
        __typename?: 'OrderStateResponse';
        orderStateId?: string | null;
        orderId: string;
        clientId?: string | null;
        clOrderId?: string | null;
        expirationDateTime?: string | null;
        origClOrderId?: string | null;
        portfolioId: string;
        portfolioName: string;
        counterPortfolioId?: string | null;
        counterPortfolioName?: string | null;
        orderStatus: OrderStatus;
        orderQty: number;
        currency?: string | null;
        limitPrice?: number | null;
        stopPrice?: number | null;
        tif?: Tif | null;
        filledQty: number;
        remainingQty: number;
        lastQty?: number | null;
        avgPrice?: string | null;
        lastPrice?: string | null;
        reason?: string | null;
        rootOrderId?: string | null;
        lastRequestResult: string;
        side: Side;
        symbol?: string | null;
        assetClass?: AssetClass | null;
        venue?: string | null;
        venueTimestamp?: string | null;
        createdAt?: string | null;
        updatedAt?: string | null;
        orderCategory: OrderCategory;
        parentOrderId?: string | null;
        instrument?: {
          __typename?: 'InstrumentResponse';
          archivedAt?: string | null;
          createdAt?: string | null;
          baseInstrument: {
            __typename?: 'BaseInstrumentResponse';
            assetClass: AssetClass;
            symbol?: string | null;
            description?: string | null;
            quoteCurrency: string;
            inverseContract?: boolean | null;
            venueType: VenueType;
            venueName: string;
          };
          forexSpotProperties?: {
            __typename?: 'ForexSpotPropertiesResponse';
            baseCurrency: string;
          } | null;
          tradingConstraints: {
            __typename?: 'TradingConstraintsResponse';
            minQty?: string | null;
            minQuoteQty?: string | null;
            maxQty?: string | null;
            maxQuoteQty?: string | null;
            qtyIncr?: string | null;
            quoteQtyIncr?: string | null;
            minPrice?: string | null;
            maxPrice?: string | null;
            priceIncr?: string | null;
            minNotional?: string | null;
            contractSize?: string | null;
            tradeable?: boolean | null;
          };
          instrumentIdentifiers: {
            __typename?: 'InstrumentIdentifiersResponse';
            adapterTicker?: string | null;
            instrumentId: string;
            tradingViewId?: string | null;
            venueTradingViewId?: string | null;
          };
        } | null;
        venueAccountDescs?: Array<{
          __typename?: 'VenueAccountDesc';
          id: string;
          name: string;
        }> | null;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
  } | null;
};

export type OrderStateContentFragment = {
  __typename?: 'OrderStateResponse';
  orderStateId?: string | null;
  orderId: string;
  clientId?: string | null;
  clOrderId?: string | null;
  expirationDateTime?: string | null;
  origClOrderId?: string | null;
  portfolioId: string;
  portfolioName: string;
  counterPortfolioId?: string | null;
  counterPortfolioName?: string | null;
  orderStatus: OrderStatus;
  orderQty: number;
  currency?: string | null;
  limitPrice?: number | null;
  stopPrice?: number | null;
  tif?: Tif | null;
  filledQty: number;
  remainingQty: number;
  lastQty?: number | null;
  avgPrice?: string | null;
  lastPrice?: string | null;
  reason?: string | null;
  rootOrderId?: string | null;
  lastRequestResult: string;
  side: Side;
  symbol?: string | null;
  assetClass?: AssetClass | null;
  venue?: string | null;
  venueTimestamp?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  orderCategory: OrderCategory;
  parentOrderId?: string | null;
  instrument?: {
    __typename?: 'InstrumentResponse';
    archivedAt?: string | null;
    createdAt?: string | null;
    baseInstrument: {
      __typename?: 'BaseInstrumentResponse';
      assetClass: AssetClass;
      symbol?: string | null;
      description?: string | null;
      quoteCurrency: string;
      inverseContract?: boolean | null;
      venueType: VenueType;
      venueName: string;
    };
    forexSpotProperties?: {
      __typename?: 'ForexSpotPropertiesResponse';
      baseCurrency: string;
    } | null;
    tradingConstraints: {
      __typename?: 'TradingConstraintsResponse';
      minQty?: string | null;
      minQuoteQty?: string | null;
      maxQty?: string | null;
      maxQuoteQty?: string | null;
      qtyIncr?: string | null;
      quoteQtyIncr?: string | null;
      minPrice?: string | null;
      maxPrice?: string | null;
      priceIncr?: string | null;
      minNotional?: string | null;
      contractSize?: string | null;
      tradeable?: boolean | null;
    };
    instrumentIdentifiers: {
      __typename?: 'InstrumentIdentifiersResponse';
      adapterTicker?: string | null;
      instrumentId: string;
      tradingViewId?: string | null;
      venueTradingViewId?: string | null;
    };
  } | null;
  venueAccountDescs?: Array<{ __typename?: 'VenueAccountDesc'; id: string; name: string }> | null;
};

export type OrderStatesWithSnapshotSubscriptionVariables = Exact<{
  portfolioId?: InputMaybe<Scalars['String']['input']>;
  venueAccount?: InputMaybe<Scalars['String']['input']>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  historyCap?: InputMaybe<Scalars['Int']['input']>;
}>;

export type OrderStatesWithSnapshotSubscription = {
  __typename?: 'Subscription';
  orderStatesWithSnapshot?: {
    __typename?: 'OrderStateResponse';
    orderType: OrderType;
    avgPrice?: string | null;
    clOrderId?: string | null;
    clientId?: string | null;
    createdAt?: string | null;
    expirationDateTime?: string | null;
    filledQty: number;
    lastPrice?: string | null;
    lastQty?: number | null;
    lastRequestResult: string;
    limitPrice?: number | null;
    orderId: string;
    orderQty: number;
    currency?: string | null;
    rootOrderId?: string | null;
    orderStatus: OrderStatus;
    origClOrderId?: string | null;
    portfolioId: string;
    portfolioName: string;
    counterPortfolioId?: string | null;
    counterPortfolioName?: string | null;
    reason?: string | null;
    remainingQty: number;
    side: Side;
    stopPrice?: number | null;
    symbol?: string | null;
    assetClass?: AssetClass | null;
    venue?: string | null;
    tif?: Tif | null;
    updatedAt?: string | null;
    venueTimestamp?: string | null;
    sequenceNumber?: number | null;
    orderCategory: OrderCategory;
    parentOrderId?: string | null;
    instrument?: {
      __typename?: 'InstrumentResponse';
      archivedAt?: string | null;
      createdAt?: string | null;
      baseInstrument: {
        __typename?: 'BaseInstrumentResponse';
        assetClass: AssetClass;
        symbol?: string | null;
        description?: string | null;
        quoteCurrency: string;
        inverseContract?: boolean | null;
        venueType: VenueType;
        venueName: string;
      };
      forexSpotProperties?: {
        __typename?: 'ForexSpotPropertiesResponse';
        baseCurrency: string;
      } | null;
      tradingConstraints: {
        __typename?: 'TradingConstraintsResponse';
        minQty?: string | null;
        minQuoteQty?: string | null;
        maxQty?: string | null;
        maxQuoteQty?: string | null;
        qtyIncr?: string | null;
        quoteQtyIncr?: string | null;
        minPrice?: string | null;
        maxPrice?: string | null;
        priceIncr?: string | null;
        minNotional?: string | null;
        contractSize?: string | null;
        tradeable?: boolean | null;
      };
      instrumentIdentifiers: {
        __typename?: 'InstrumentIdentifiersResponse';
        adapterTicker?: string | null;
        instrumentId: string;
        tradingViewId?: string | null;
        venueTradingViewId?: string | null;
      };
    } | null;
    venueAccountDescs?: Array<{ __typename?: 'VenueAccountDesc'; id: string; name: string }> | null;
  } | null;
};

export type PermissionsForResourceQueryVariables = Exact<{
  resource: Resource;
  resourceId: Scalars['String']['input'];
}>;

export type PermissionsForResourceQuery = {
  __typename?: 'Query';
  usersPermissionsForResource: Array<{
    __typename?: 'UsersPermissionsForResourceResponse';
    username: string;
    scopes: Array<Scope>;
  }>;
  groupsPermissionsForResource: Array<{
    __typename?: 'GroupsPermissionsForResourceResponse';
    groupName: string;
    scopes: Array<Scope>;
  }>;
};

export type PortfolioTagsQueryVariables = Exact<{ [key: string]: never }>;

export type PortfolioTagsQuery = {
  __typename?: 'Query';
  portfolioTags: Array<{ __typename?: 'PortfolioTag'; key: string; values: Array<string> }>;
};

export type PortfolioConfigValidationQueryVariables = Exact<{
  portfolioId: Scalars['String']['input'];
}>;

export type PortfolioConfigValidationQuery = {
  __typename?: 'Query';
  portfolioConfigValidation?: {
    __typename?: 'ConfigValidationResult';
    resultsPerInstrument: Array<{
      __typename?: 'ConfigInstrumentValidationResult';
      instrumentId: string;
      isValid: boolean;
      preventsTrading: boolean;
      preventsMarketData: boolean;
      errors: Array<{
        __typename?: 'ConfigValidationError';
        errorMessage?: string | null;
        errorType: ErrorType;
        fieldName: string;
        preventsTrading: boolean;
        preventsMarketData: boolean;
      }>;
    }>;
  } | null;
};

export type PortfolioConfigurationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PortfolioConfigurationQuery = {
  __typename?: 'Query';
  portfolioConfiguration?: {
    __typename?: 'PortfolioConfiguration';
    id: string;
    name?: string | null;
    executionConfiguration?: {
      __typename?: 'ExecutionConfiguration';
      tradingMode?: TradingMode | null;
      counterPortfolioId?: string | null;
      counterPortfolioName?: string | null;
      fixedFee?: number | null;
      percentageFee?: number | null;
      minFee?: number | null;
      minFeeCurrency?: string | null;
      fixedFeeCurrency?: string | null;
      percentageFeeCurrency?: string | null;
      percentageFeeCurrencyType?: CurrencyType | null;
      agencyTradingAccount?: string | null;
      agencyTradingAccountName?: string | null;
      executionMode?: ExecutionMode | null;
      chargeExchangeFee?: boolean | null;
      discloseTradingVenue?: boolean | null;
      sorTradingAccountDescs?: Array<{
        __typename?: 'VenueAccountDesc';
        id: string;
        name: string;
      }> | null;
      sorTarget?: { __typename?: 'SorTarget'; assetClass: AssetClass; symbol: string } | null;
      agencyTargetInstrument?: {
        __typename?: 'InstrumentResponse';
        archivedAt?: string | null;
        createdAt?: string | null;
        baseInstrument: {
          __typename?: 'BaseInstrumentResponse';
          assetClass: AssetClass;
          symbol?: string | null;
          description?: string | null;
          quoteCurrency: string;
          inverseContract?: boolean | null;
          venueType: VenueType;
          venueName: string;
        };
        forexSpotProperties?: {
          __typename?: 'ForexSpotPropertiesResponse';
          baseCurrency: string;
        } | null;
        tradingConstraints: {
          __typename?: 'TradingConstraintsResponse';
          minQty?: string | null;
          minQuoteQty?: string | null;
          maxQty?: string | null;
          maxQuoteQty?: string | null;
          qtyIncr?: string | null;
          quoteQtyIncr?: string | null;
          minPrice?: string | null;
          maxPrice?: string | null;
          priceIncr?: string | null;
          minNotional?: string | null;
          contractSize?: string | null;
          tradeable?: boolean | null;
        };
        instrumentIdentifiers: {
          __typename?: 'InstrumentIdentifiersResponse';
          adapterTicker?: string | null;
          instrumentId: string;
          tradingViewId?: string | null;
          venueTradingViewId?: string | null;
        };
      } | null;
    } | null;
    pricingConfiguration?: {
      __typename?: 'PricingConfiguration';
      markup?: number | null;
      venueAccounts?: Array<{ __typename?: 'VenueAccountDesc'; id: string; name: string }> | null;
    } | null;
    hedgingConfiguration?: {
      __typename?: 'HedgingConfiguration';
      autoHedging?: boolean | null;
      targetAccountId?: string | null;
      targetAccountName?: string | null;
      thresholdConfiguration?: Array<{
        __typename?: 'ThresholdConfiguration';
        asset?: string | null;
        highThreshold?: string | null;
        lowThreshold?: string | null;
        targetExposure?: string | null;
        hedgeInstrument?: {
          __typename?: 'InstrumentResponse';
          archivedAt?: string | null;
          createdAt?: string | null;
          baseInstrument: {
            __typename?: 'BaseInstrumentResponse';
            assetClass: AssetClass;
            symbol?: string | null;
            description?: string | null;
            quoteCurrency: string;
            inverseContract?: boolean | null;
            venueType: VenueType;
            venueName: string;
          };
          forexSpotProperties?: {
            __typename?: 'ForexSpotPropertiesResponse';
            baseCurrency: string;
          } | null;
          tradingConstraints: {
            __typename?: 'TradingConstraintsResponse';
            minQty?: string | null;
            minQuoteQty?: string | null;
            maxQty?: string | null;
            maxQuoteQty?: string | null;
            qtyIncr?: string | null;
            quoteQtyIncr?: string | null;
            minPrice?: string | null;
            maxPrice?: string | null;
            priceIncr?: string | null;
            minNotional?: string | null;
            contractSize?: string | null;
            tradeable?: boolean | null;
          };
          instrumentIdentifiers: {
            __typename?: 'InstrumentIdentifiersResponse';
            adapterTicker?: string | null;
            instrumentId: string;
            tradingViewId?: string | null;
            venueTradingViewId?: string | null;
          };
        } | null;
      }> | null;
    } | null;
    portfolioGroupConfiguration?: {
      __typename?: 'PortfolioGroupConfiguration';
      id: string;
      name?: string | null;
      executionConfiguration?: {
        __typename?: 'ExecutionConfiguration';
        tradingMode?: TradingMode | null;
        counterPortfolioId?: string | null;
        counterPortfolioName?: string | null;
        fixedFee?: number | null;
        percentageFee?: number | null;
        minFee?: number | null;
        minFeeCurrency?: string | null;
        fixedFeeCurrency?: string | null;
        percentageFeeCurrency?: string | null;
        percentageFeeCurrencyType?: CurrencyType | null;
        agencyTradingAccount?: string | null;
        agencyTradingAccountName?: string | null;
        executionMode?: ExecutionMode | null;
        chargeExchangeFee?: boolean | null;
        discloseTradingVenue?: boolean | null;
        sorTradingAccountDescs?: Array<{
          __typename?: 'VenueAccountDesc';
          id: string;
          name: string;
        }> | null;
        sorTarget?: { __typename?: 'SorTarget'; assetClass: AssetClass; symbol: string } | null;
        agencyTargetInstrument?: {
          __typename?: 'InstrumentResponse';
          archivedAt?: string | null;
          createdAt?: string | null;
          baseInstrument: {
            __typename?: 'BaseInstrumentResponse';
            assetClass: AssetClass;
            symbol?: string | null;
            description?: string | null;
            quoteCurrency: string;
            inverseContract?: boolean | null;
            venueType: VenueType;
            venueName: string;
          };
          forexSpotProperties?: {
            __typename?: 'ForexSpotPropertiesResponse';
            baseCurrency: string;
          } | null;
          tradingConstraints: {
            __typename?: 'TradingConstraintsResponse';
            minQty?: string | null;
            minQuoteQty?: string | null;
            maxQty?: string | null;
            maxQuoteQty?: string | null;
            qtyIncr?: string | null;
            quoteQtyIncr?: string | null;
            minPrice?: string | null;
            maxPrice?: string | null;
            priceIncr?: string | null;
            minNotional?: string | null;
            contractSize?: string | null;
            tradeable?: boolean | null;
          };
          instrumentIdentifiers: {
            __typename?: 'InstrumentIdentifiersResponse';
            adapterTicker?: string | null;
            instrumentId: string;
            tradingViewId?: string | null;
            venueTradingViewId?: string | null;
          };
        } | null;
      } | null;
      pricingConfiguration?: {
        __typename?: 'PricingConfiguration';
        markup?: number | null;
        venueAccounts?: Array<{ __typename?: 'VenueAccountDesc'; id: string; name: string }> | null;
      } | null;
      instrumentConfiguration?: Array<{
        __typename?: 'InstrumentGroupConfiguration';
        instrumentId: string;
        tradeable?: boolean | null;
        executionConfiguration?: {
          __typename?: 'ExecutionConfiguration';
          tradingMode?: TradingMode | null;
          counterPortfolioId?: string | null;
          counterPortfolioName?: string | null;
          fixedFee?: number | null;
          percentageFee?: number | null;
          minFee?: number | null;
          minFeeCurrency?: string | null;
          fixedFeeCurrency?: string | null;
          percentageFeeCurrency?: string | null;
          percentageFeeCurrencyType?: CurrencyType | null;
          agencyTradingAccount?: string | null;
          agencyTradingAccountName?: string | null;
          executionMode?: ExecutionMode | null;
          chargeExchangeFee?: boolean | null;
          discloseTradingVenue?: boolean | null;
          sorTradingAccountDescs?: Array<{
            __typename?: 'VenueAccountDesc';
            id: string;
            name: string;
          }> | null;
          sorTarget?: { __typename?: 'SorTarget'; assetClass: AssetClass; symbol: string } | null;
          agencyTargetInstrument?: {
            __typename?: 'InstrumentResponse';
            archivedAt?: string | null;
            createdAt?: string | null;
            baseInstrument: {
              __typename?: 'BaseInstrumentResponse';
              assetClass: AssetClass;
              symbol?: string | null;
              description?: string | null;
              quoteCurrency: string;
              inverseContract?: boolean | null;
              venueType: VenueType;
              venueName: string;
            };
            forexSpotProperties?: {
              __typename?: 'ForexSpotPropertiesResponse';
              baseCurrency: string;
            } | null;
            tradingConstraints: {
              __typename?: 'TradingConstraintsResponse';
              minQty?: string | null;
              minQuoteQty?: string | null;
              maxQty?: string | null;
              maxQuoteQty?: string | null;
              qtyIncr?: string | null;
              quoteQtyIncr?: string | null;
              minPrice?: string | null;
              maxPrice?: string | null;
              priceIncr?: string | null;
              minNotional?: string | null;
              contractSize?: string | null;
              tradeable?: boolean | null;
            };
            instrumentIdentifiers: {
              __typename?: 'InstrumentIdentifiersResponse';
              adapterTicker?: string | null;
              instrumentId: string;
              tradingViewId?: string | null;
              venueTradingViewId?: string | null;
            };
          } | null;
        } | null;
        pricingConfiguration?: {
          __typename?: 'InstrumentPricingConfiguration';
          markup?: number | null;
          pricingSource?: Array<{
            __typename?: 'InstrumentKey';
            venueAccount: string;
            venueAccountName: string;
            instrument: {
              __typename?: 'InstrumentResponse';
              archivedAt?: string | null;
              createdAt?: string | null;
              baseInstrument: {
                __typename?: 'BaseInstrumentResponse';
                assetClass: AssetClass;
                symbol?: string | null;
                description?: string | null;
                quoteCurrency: string;
                inverseContract?: boolean | null;
                venueType: VenueType;
                venueName: string;
              };
              forexSpotProperties?: {
                __typename?: 'ForexSpotPropertiesResponse';
                baseCurrency: string;
              } | null;
              tradingConstraints: {
                __typename?: 'TradingConstraintsResponse';
                minQty?: string | null;
                minQuoteQty?: string | null;
                maxQty?: string | null;
                maxQuoteQty?: string | null;
                qtyIncr?: string | null;
                quoteQtyIncr?: string | null;
                minPrice?: string | null;
                maxPrice?: string | null;
                priceIncr?: string | null;
                minNotional?: string | null;
                contractSize?: string | null;
                tradeable?: boolean | null;
              };
              instrumentIdentifiers: {
                __typename?: 'InstrumentIdentifiersResponse';
                adapterTicker?: string | null;
                instrumentId: string;
                tradingViewId?: string | null;
                venueTradingViewId?: string | null;
              };
            };
          }> | null;
        } | null;
      }> | null;
    } | null;
    instrumentConfiguration?: Array<{
      __typename?: 'InstrumentConfiguration';
      instrumentId: string;
      tradeable?: boolean | null;
      instrumentGroupConfiguration?: {
        __typename?: 'InstrumentGroupConfiguration';
        tradeable?: boolean | null;
        instrumentId: string;
        executionConfiguration?: {
          __typename?: 'ExecutionConfiguration';
          tradingMode?: TradingMode | null;
          counterPortfolioId?: string | null;
          counterPortfolioName?: string | null;
          fixedFee?: number | null;
          percentageFee?: number | null;
          minFee?: number | null;
          minFeeCurrency?: string | null;
          fixedFeeCurrency?: string | null;
          percentageFeeCurrency?: string | null;
          percentageFeeCurrencyType?: CurrencyType | null;
          agencyTradingAccount?: string | null;
          agencyTradingAccountName?: string | null;
          executionMode?: ExecutionMode | null;
          chargeExchangeFee?: boolean | null;
          discloseTradingVenue?: boolean | null;
          sorTradingAccountDescs?: Array<{
            __typename?: 'VenueAccountDesc';
            id: string;
            name: string;
          }> | null;
          sorTarget?: { __typename?: 'SorTarget'; assetClass: AssetClass; symbol: string } | null;
          agencyTargetInstrument?: {
            __typename?: 'InstrumentResponse';
            archivedAt?: string | null;
            createdAt?: string | null;
            baseInstrument: {
              __typename?: 'BaseInstrumentResponse';
              assetClass: AssetClass;
              symbol?: string | null;
              description?: string | null;
              quoteCurrency: string;
              inverseContract?: boolean | null;
              venueType: VenueType;
              venueName: string;
            };
            forexSpotProperties?: {
              __typename?: 'ForexSpotPropertiesResponse';
              baseCurrency: string;
            } | null;
            tradingConstraints: {
              __typename?: 'TradingConstraintsResponse';
              minQty?: string | null;
              minQuoteQty?: string | null;
              maxQty?: string | null;
              maxQuoteQty?: string | null;
              qtyIncr?: string | null;
              quoteQtyIncr?: string | null;
              minPrice?: string | null;
              maxPrice?: string | null;
              priceIncr?: string | null;
              minNotional?: string | null;
              contractSize?: string | null;
              tradeable?: boolean | null;
            };
            instrumentIdentifiers: {
              __typename?: 'InstrumentIdentifiersResponse';
              adapterTicker?: string | null;
              instrumentId: string;
              tradingViewId?: string | null;
              venueTradingViewId?: string | null;
            };
          } | null;
        } | null;
        pricingConfiguration?: {
          __typename?: 'InstrumentPricingConfiguration';
          markup?: number | null;
          pricingSource?: Array<{
            __typename?: 'InstrumentKey';
            venueAccount: string;
            venueAccountName: string;
            instrument: {
              __typename?: 'InstrumentResponse';
              archivedAt?: string | null;
              createdAt?: string | null;
              baseInstrument: {
                __typename?: 'BaseInstrumentResponse';
                assetClass: AssetClass;
                symbol?: string | null;
                description?: string | null;
                quoteCurrency: string;
                inverseContract?: boolean | null;
                venueType: VenueType;
                venueName: string;
              };
              forexSpotProperties?: {
                __typename?: 'ForexSpotPropertiesResponse';
                baseCurrency: string;
              } | null;
              tradingConstraints: {
                __typename?: 'TradingConstraintsResponse';
                minQty?: string | null;
                minQuoteQty?: string | null;
                maxQty?: string | null;
                maxQuoteQty?: string | null;
                qtyIncr?: string | null;
                quoteQtyIncr?: string | null;
                minPrice?: string | null;
                maxPrice?: string | null;
                priceIncr?: string | null;
                minNotional?: string | null;
                contractSize?: string | null;
                tradeable?: boolean | null;
              };
              instrumentIdentifiers: {
                __typename?: 'InstrumentIdentifiersResponse';
                adapterTicker?: string | null;
                instrumentId: string;
                tradingViewId?: string | null;
                venueTradingViewId?: string | null;
              };
            };
          }> | null;
        } | null;
      } | null;
      executionConfiguration?: {
        __typename?: 'ExecutionConfiguration';
        tradingMode?: TradingMode | null;
        counterPortfolioId?: string | null;
        counterPortfolioName?: string | null;
        fixedFee?: number | null;
        percentageFee?: number | null;
        minFee?: number | null;
        minFeeCurrency?: string | null;
        fixedFeeCurrency?: string | null;
        percentageFeeCurrency?: string | null;
        percentageFeeCurrencyType?: CurrencyType | null;
        agencyTradingAccount?: string | null;
        agencyTradingAccountName?: string | null;
        executionMode?: ExecutionMode | null;
        chargeExchangeFee?: boolean | null;
        discloseTradingVenue?: boolean | null;
        sorTradingAccountDescs?: Array<{
          __typename?: 'VenueAccountDesc';
          id: string;
          name: string;
        }> | null;
        sorTarget?: { __typename?: 'SorTarget'; assetClass: AssetClass; symbol: string } | null;
        agencyTargetInstrument?: {
          __typename?: 'InstrumentResponse';
          archivedAt?: string | null;
          createdAt?: string | null;
          baseInstrument: {
            __typename?: 'BaseInstrumentResponse';
            assetClass: AssetClass;
            symbol?: string | null;
            description?: string | null;
            quoteCurrency: string;
            inverseContract?: boolean | null;
            venueType: VenueType;
            venueName: string;
          };
          forexSpotProperties?: {
            __typename?: 'ForexSpotPropertiesResponse';
            baseCurrency: string;
          } | null;
          tradingConstraints: {
            __typename?: 'TradingConstraintsResponse';
            minQty?: string | null;
            minQuoteQty?: string | null;
            maxQty?: string | null;
            maxQuoteQty?: string | null;
            qtyIncr?: string | null;
            quoteQtyIncr?: string | null;
            minPrice?: string | null;
            maxPrice?: string | null;
            priceIncr?: string | null;
            minNotional?: string | null;
            contractSize?: string | null;
            tradeable?: boolean | null;
          };
          instrumentIdentifiers: {
            __typename?: 'InstrumentIdentifiersResponse';
            adapterTicker?: string | null;
            instrumentId: string;
            tradingViewId?: string | null;
            venueTradingViewId?: string | null;
          };
        } | null;
      } | null;
      pricingConfiguration?: {
        __typename?: 'InstrumentPricingConfiguration';
        markup?: number | null;
        pricingSource?: Array<{
          __typename?: 'InstrumentKey';
          venueAccount: string;
          venueAccountName: string;
          instrument: {
            __typename?: 'InstrumentResponse';
            archivedAt?: string | null;
            createdAt?: string | null;
            baseInstrument: {
              __typename?: 'BaseInstrumentResponse';
              assetClass: AssetClass;
              symbol?: string | null;
              description?: string | null;
              quoteCurrency: string;
              inverseContract?: boolean | null;
              venueType: VenueType;
              venueName: string;
            };
            forexSpotProperties?: {
              __typename?: 'ForexSpotPropertiesResponse';
              baseCurrency: string;
            } | null;
            tradingConstraints: {
              __typename?: 'TradingConstraintsResponse';
              minQty?: string | null;
              minQuoteQty?: string | null;
              maxQty?: string | null;
              maxQuoteQty?: string | null;
              qtyIncr?: string | null;
              quoteQtyIncr?: string | null;
              minPrice?: string | null;
              maxPrice?: string | null;
              priceIncr?: string | null;
              minNotional?: string | null;
              contractSize?: string | null;
              tradeable?: boolean | null;
            };
            instrumentIdentifiers: {
              __typename?: 'InstrumentIdentifiersResponse';
              adapterTicker?: string | null;
              instrumentId: string;
              tradingViewId?: string | null;
              venueTradingViewId?: string | null;
            };
          };
        }> | null;
      } | null;
    }> | null;
  } | null;
};

export type PricingConfigurationContentFragment = {
  __typename?: 'PricingConfiguration';
  markup?: number | null;
  venueAccounts?: Array<{ __typename?: 'VenueAccountDesc'; id: string; name: string }> | null;
};

export type PortfolioGroupConfigurationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PortfolioGroupConfigurationQuery = {
  __typename?: 'Query';
  portfolioGroupConfiguration?: {
    __typename?: 'PortfolioGroupConfiguration';
    id: string;
    name?: string | null;
    executionConfiguration?: {
      __typename?: 'ExecutionConfiguration';
      tradingMode?: TradingMode | null;
      counterPortfolioId?: string | null;
      counterPortfolioName?: string | null;
      fixedFee?: number | null;
      percentageFee?: number | null;
      minFee?: number | null;
      minFeeCurrency?: string | null;
      fixedFeeCurrency?: string | null;
      percentageFeeCurrency?: string | null;
      percentageFeeCurrencyType?: CurrencyType | null;
      agencyTradingAccount?: string | null;
      agencyTradingAccountName?: string | null;
      executionMode?: ExecutionMode | null;
      chargeExchangeFee?: boolean | null;
      discloseTradingVenue?: boolean | null;
      sorTradingAccountDescs?: Array<{
        __typename?: 'VenueAccountDesc';
        id: string;
        name: string;
      }> | null;
      sorTarget?: { __typename?: 'SorTarget'; assetClass: AssetClass; symbol: string } | null;
      agencyTargetInstrument?: {
        __typename?: 'InstrumentResponse';
        archivedAt?: string | null;
        createdAt?: string | null;
        baseInstrument: {
          __typename?: 'BaseInstrumentResponse';
          assetClass: AssetClass;
          symbol?: string | null;
          description?: string | null;
          quoteCurrency: string;
          inverseContract?: boolean | null;
          venueType: VenueType;
          venueName: string;
        };
        forexSpotProperties?: {
          __typename?: 'ForexSpotPropertiesResponse';
          baseCurrency: string;
        } | null;
        tradingConstraints: {
          __typename?: 'TradingConstraintsResponse';
          minQty?: string | null;
          minQuoteQty?: string | null;
          maxQty?: string | null;
          maxQuoteQty?: string | null;
          qtyIncr?: string | null;
          quoteQtyIncr?: string | null;
          minPrice?: string | null;
          maxPrice?: string | null;
          priceIncr?: string | null;
          minNotional?: string | null;
          contractSize?: string | null;
          tradeable?: boolean | null;
        };
        instrumentIdentifiers: {
          __typename?: 'InstrumentIdentifiersResponse';
          adapterTicker?: string | null;
          instrumentId: string;
          tradingViewId?: string | null;
          venueTradingViewId?: string | null;
        };
      } | null;
    } | null;
    pricingConfiguration?: {
      __typename?: 'PricingConfiguration';
      markup?: number | null;
      venueAccounts?: Array<{ __typename?: 'VenueAccountDesc'; id: string; name: string }> | null;
    } | null;
    instrumentConfiguration?: Array<{
      __typename?: 'InstrumentGroupConfiguration';
      instrumentId: string;
      tradeable?: boolean | null;
      executionConfiguration?: {
        __typename?: 'ExecutionConfiguration';
        tradingMode?: TradingMode | null;
        counterPortfolioId?: string | null;
        counterPortfolioName?: string | null;
        fixedFee?: number | null;
        percentageFee?: number | null;
        minFee?: number | null;
        minFeeCurrency?: string | null;
        fixedFeeCurrency?: string | null;
        percentageFeeCurrency?: string | null;
        percentageFeeCurrencyType?: CurrencyType | null;
        agencyTradingAccount?: string | null;
        agencyTradingAccountName?: string | null;
        executionMode?: ExecutionMode | null;
        chargeExchangeFee?: boolean | null;
        discloseTradingVenue?: boolean | null;
        sorTradingAccountDescs?: Array<{
          __typename?: 'VenueAccountDesc';
          id: string;
          name: string;
        }> | null;
        sorTarget?: { __typename?: 'SorTarget'; assetClass: AssetClass; symbol: string } | null;
        agencyTargetInstrument?: {
          __typename?: 'InstrumentResponse';
          archivedAt?: string | null;
          createdAt?: string | null;
          baseInstrument: {
            __typename?: 'BaseInstrumentResponse';
            assetClass: AssetClass;
            symbol?: string | null;
            description?: string | null;
            quoteCurrency: string;
            inverseContract?: boolean | null;
            venueType: VenueType;
            venueName: string;
          };
          forexSpotProperties?: {
            __typename?: 'ForexSpotPropertiesResponse';
            baseCurrency: string;
          } | null;
          tradingConstraints: {
            __typename?: 'TradingConstraintsResponse';
            minQty?: string | null;
            minQuoteQty?: string | null;
            maxQty?: string | null;
            maxQuoteQty?: string | null;
            qtyIncr?: string | null;
            quoteQtyIncr?: string | null;
            minPrice?: string | null;
            maxPrice?: string | null;
            priceIncr?: string | null;
            minNotional?: string | null;
            contractSize?: string | null;
            tradeable?: boolean | null;
          };
          instrumentIdentifiers: {
            __typename?: 'InstrumentIdentifiersResponse';
            adapterTicker?: string | null;
            instrumentId: string;
            tradingViewId?: string | null;
            venueTradingViewId?: string | null;
          };
        } | null;
      } | null;
      pricingConfiguration?: {
        __typename?: 'InstrumentPricingConfiguration';
        markup?: number | null;
        pricingSource?: Array<{
          __typename?: 'InstrumentKey';
          venueAccount: string;
          venueAccountName: string;
          instrument: {
            __typename?: 'InstrumentResponse';
            archivedAt?: string | null;
            createdAt?: string | null;
            baseInstrument: {
              __typename?: 'BaseInstrumentResponse';
              assetClass: AssetClass;
              symbol?: string | null;
              description?: string | null;
              quoteCurrency: string;
              inverseContract?: boolean | null;
              venueType: VenueType;
              venueName: string;
            };
            forexSpotProperties?: {
              __typename?: 'ForexSpotPropertiesResponse';
              baseCurrency: string;
            } | null;
            tradingConstraints: {
              __typename?: 'TradingConstraintsResponse';
              minQty?: string | null;
              minQuoteQty?: string | null;
              maxQty?: string | null;
              maxQuoteQty?: string | null;
              qtyIncr?: string | null;
              quoteQtyIncr?: string | null;
              minPrice?: string | null;
              maxPrice?: string | null;
              priceIncr?: string | null;
              minNotional?: string | null;
              contractSize?: string | null;
              tradeable?: boolean | null;
            };
            instrumentIdentifiers: {
              __typename?: 'InstrumentIdentifiersResponse';
              adapterTicker?: string | null;
              instrumentId: string;
              tradingViewId?: string | null;
              venueTradingViewId?: string | null;
            };
          };
        }> | null;
      } | null;
    }> | null;
  } | null;
};

export type ExecutionConfigurationContentFragment = {
  __typename?: 'ExecutionConfiguration';
  tradingMode?: TradingMode | null;
  counterPortfolioId?: string | null;
  counterPortfolioName?: string | null;
  fixedFee?: number | null;
  percentageFee?: number | null;
  minFee?: number | null;
  minFeeCurrency?: string | null;
  fixedFeeCurrency?: string | null;
  percentageFeeCurrency?: string | null;
  percentageFeeCurrencyType?: CurrencyType | null;
  agencyTradingAccount?: string | null;
  agencyTradingAccountName?: string | null;
  executionMode?: ExecutionMode | null;
  chargeExchangeFee?: boolean | null;
  discloseTradingVenue?: boolean | null;
  sorTradingAccountDescs?: Array<{
    __typename?: 'VenueAccountDesc';
    id: string;
    name: string;
  }> | null;
  sorTarget?: { __typename?: 'SorTarget'; assetClass: AssetClass; symbol: string } | null;
  agencyTargetInstrument?: {
    __typename?: 'InstrumentResponse';
    archivedAt?: string | null;
    createdAt?: string | null;
    baseInstrument: {
      __typename?: 'BaseInstrumentResponse';
      assetClass: AssetClass;
      symbol?: string | null;
      description?: string | null;
      quoteCurrency: string;
      inverseContract?: boolean | null;
      venueType: VenueType;
      venueName: string;
    };
    forexSpotProperties?: {
      __typename?: 'ForexSpotPropertiesResponse';
      baseCurrency: string;
    } | null;
    tradingConstraints: {
      __typename?: 'TradingConstraintsResponse';
      minQty?: string | null;
      minQuoteQty?: string | null;
      maxQty?: string | null;
      maxQuoteQty?: string | null;
      qtyIncr?: string | null;
      quoteQtyIncr?: string | null;
      minPrice?: string | null;
      maxPrice?: string | null;
      priceIncr?: string | null;
      minNotional?: string | null;
      contractSize?: string | null;
      tradeable?: boolean | null;
    };
    instrumentIdentifiers: {
      __typename?: 'InstrumentIdentifiersResponse';
      adapterTicker?: string | null;
      instrumentId: string;
      tradingViewId?: string | null;
      venueTradingViewId?: string | null;
    };
  } | null;
};

export type InstrumentPricingConfigurationContentFragment = {
  __typename?: 'InstrumentPricingConfiguration';
  markup?: number | null;
  pricingSource?: Array<{
    __typename?: 'InstrumentKey';
    venueAccount: string;
    venueAccountName: string;
    instrument: {
      __typename?: 'InstrumentResponse';
      archivedAt?: string | null;
      createdAt?: string | null;
      baseInstrument: {
        __typename?: 'BaseInstrumentResponse';
        assetClass: AssetClass;
        symbol?: string | null;
        description?: string | null;
        quoteCurrency: string;
        inverseContract?: boolean | null;
        venueType: VenueType;
        venueName: string;
      };
      forexSpotProperties?: {
        __typename?: 'ForexSpotPropertiesResponse';
        baseCurrency: string;
      } | null;
      tradingConstraints: {
        __typename?: 'TradingConstraintsResponse';
        minQty?: string | null;
        minQuoteQty?: string | null;
        maxQty?: string | null;
        maxQuoteQty?: string | null;
        qtyIncr?: string | null;
        quoteQtyIncr?: string | null;
        minPrice?: string | null;
        maxPrice?: string | null;
        priceIncr?: string | null;
        minNotional?: string | null;
        contractSize?: string | null;
        tradeable?: boolean | null;
      };
      instrumentIdentifiers: {
        __typename?: 'InstrumentIdentifiersResponse';
        adapterTicker?: string | null;
        instrumentId: string;
        tradingViewId?: string | null;
        venueTradingViewId?: string | null;
      };
    };
  }> | null;
};

export type HedgingConfigurationContentFragment = {
  __typename?: 'HedgingConfiguration';
  autoHedging?: boolean | null;
  targetAccountId?: string | null;
  targetAccountName?: string | null;
  thresholdConfiguration?: Array<{
    __typename?: 'ThresholdConfiguration';
    asset?: string | null;
    highThreshold?: string | null;
    lowThreshold?: string | null;
    targetExposure?: string | null;
    hedgeInstrument?: {
      __typename?: 'InstrumentResponse';
      archivedAt?: string | null;
      createdAt?: string | null;
      baseInstrument: {
        __typename?: 'BaseInstrumentResponse';
        assetClass: AssetClass;
        symbol?: string | null;
        description?: string | null;
        quoteCurrency: string;
        inverseContract?: boolean | null;
        venueType: VenueType;
        venueName: string;
      };
      forexSpotProperties?: {
        __typename?: 'ForexSpotPropertiesResponse';
        baseCurrency: string;
      } | null;
      tradingConstraints: {
        __typename?: 'TradingConstraintsResponse';
        minQty?: string | null;
        minQuoteQty?: string | null;
        maxQty?: string | null;
        maxQuoteQty?: string | null;
        qtyIncr?: string | null;
        quoteQtyIncr?: string | null;
        minPrice?: string | null;
        maxPrice?: string | null;
        priceIncr?: string | null;
        minNotional?: string | null;
        contractSize?: string | null;
        tradeable?: boolean | null;
      };
      instrumentIdentifiers: {
        __typename?: 'InstrumentIdentifiersResponse';
        adapterTicker?: string | null;
        instrumentId: string;
        tradingViewId?: string | null;
        venueTradingViewId?: string | null;
      };
    } | null;
  }> | null;
};

export type PortfolioGroupConfigurationListQueryVariables = Exact<{ [key: string]: never }>;

export type PortfolioGroupConfigurationListQuery = {
  __typename?: 'Query';
  portfolioGroupConfigurationList: Array<{
    __typename?: 'PortfolioGroupConfigurationFlat';
    id: string;
    name?: string | null;
    portfolioType?: PortfolioType | null;
  }>;
};

export type PortfolioSearchQueryVariables = Exact<{
  search?: InputMaybe<PortfolioSearchInput>;
}>;

export type PortfolioSearchQuery = {
  __typename?: 'Query';
  portfolioSearch?: {
    __typename?: 'PortfolioConnection';
    edges: Array<{
      __typename?: 'PortfolioEdge';
      cursor: string;
      node: {
        __typename?: 'PortfolioResponse';
        id: string;
        name: string;
        createdAt: string;
        scopes: Array<Scope>;
        portfolioCurrency: string;
        portfolioType?: PortfolioType | null;
        archivedAt?: string | null;
        tags: Array<{ __typename?: 'Tag'; value: string; key: string }>;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean };
  } | null;
};

export type PortfolioContentFragment = {
  __typename?: 'PortfolioResponse';
  id: string;
  name: string;
  createdAt: string;
  portfolioType?: PortfolioType | null;
  portfolioCurrency: string;
  scopes: Array<Scope>;
  archivedAt?: string | null;
  tags: Array<{ __typename?: 'Tag'; key: string; value: string }>;
};

export type PositionChangesSubscriptionVariables = Exact<{
  search: PositionSearchInput;
}>;

export type PositionChangesSubscription = {
  __typename?: 'Subscription';
  positionChanges: {
    __typename?: 'PositionResponse';
    account?: string | null;
    accountName?: string | null;
    availableForTradingQuantity?: number | null;
    availableForWithdrawalQuantity?: number | null;
    bookingCurrency: string;
    currency?: string | null;
    grossAveragePrice?: number | null;
    grossCost: number;
    grossCostSc?: number | null;
    grossRealizedPnl: number;
    grossRealizedPnlSc?: number | null;
    grossUnrealizedPnl?: number | null;
    grossUnrealizedPnlSc?: number | null;
    marketValue?: number | null;
    marketValueSc?: number | null;
    netAveragePrice?: number | null;
    netCost: number;
    netCostSc?: number | null;
    netRealizedPnl: number;
    netRealizedPnlSc?: number | null;
    netUnrealizedPnl?: number | null;
    netUnrealizedPnlSc?: number | null;
    notionalQuantity?: number | null;
    pendingQuantity?: number | null;
    quantity: number;
    settledQuantity?: number | null;
    symbol: string;
    unsettledQuantity?: number | null;
    updatedAt: number;
    portfolio?: {
      __typename?: 'PortfolioResponse';
      id: string;
      name: string;
      createdAt: string;
      portfolioType?: PortfolioType | null;
      portfolioCurrency: string;
      scopes: Array<Scope>;
      archivedAt?: string | null;
      tags: Array<{ __typename?: 'Tag'; key: string; value: string }>;
    } | null;
  };
};

export type PositionsQueryVariables = Exact<{
  search: PositionSearchInput;
}>;

export type PositionsQuery = {
  __typename?: 'Query';
  positions?: {
    __typename?: 'PositionConnection';
    edges: Array<{
      __typename?: 'PositionEdge';
      node: {
        __typename?: 'PositionResponse';
        account?: string | null;
        accountName?: string | null;
        availableForTradingQuantity?: number | null;
        availableForWithdrawalQuantity?: number | null;
        bookingCurrency: string;
        currency?: string | null;
        grossAveragePrice?: number | null;
        grossCost: number;
        grossCostSc?: number | null;
        grossRealizedPnl: number;
        grossRealizedPnlSc?: number | null;
        grossUnrealizedPnl?: number | null;
        grossUnrealizedPnlSc?: number | null;
        marketValue?: number | null;
        marketValueSc?: number | null;
        netAveragePrice?: number | null;
        netCost: number;
        netCostSc?: number | null;
        netRealizedPnl: number;
        netRealizedPnlSc?: number | null;
        netUnrealizedPnl?: number | null;
        netUnrealizedPnlSc?: number | null;
        notionalQuantity?: number | null;
        pendingQuantity?: number | null;
        quantity: number;
        settledQuantity?: number | null;
        symbol: string;
        unsettledQuantity?: number | null;
        updatedAt: number;
        portfolio?: {
          __typename?: 'PortfolioResponse';
          id: string;
          name: string;
          createdAt: string;
          portfolioType?: PortfolioType | null;
          portfolioCurrency: string;
          scopes: Array<Scope>;
          archivedAt?: string | null;
          tags: Array<{ __typename?: 'Tag'; key: string; value: string }>;
        } | null;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
  } | null;
};

export type PreTradeCheckAuditLogsQueryVariables = Exact<{
  request: PreTradeCheckAuditLogSearchInput;
}>;

export type PreTradeCheckAuditLogsQuery = {
  __typename?: 'Query';
  preTradeCheckAuditLogs?: {
    __typename?: 'PreTradeCheckAuditLogConnection';
    edges: Array<{
      __typename?: 'PreTradeCheckAuditLogEdge';
      node: {
        __typename?: 'PreTradeCheckAuditLog';
        id: string;
        createdAt?: string | null;
        portfolioId?: string | null;
        portfolioName?: string | null;
        order?: {
          __typename?: 'OrderSnapshot';
          orderId: string;
          orderType: OrderType;
          quantity?: number | null;
          side: Side;
          instrumentId: string;
        } | null;
        preTradeCheckResults?: Array<{
          __typename?: 'PreTradeCheckResult';
          status: PreTradeCheckStatus;
          reason: string;
          preTradeCheck: {
            __typename?: 'PreTradeCheck';
            id: string;
            type: string;
            channels: Array<PreTradeCheckChannels>;
            level: PreTradeCheckLevel;
            portfolios: Array<{
              __typename?: 'PortfolioResponse';
              id: string;
              name: string;
              createdAt: string;
              portfolioType?: PortfolioType | null;
              portfolioCurrency: string;
              scopes: Array<Scope>;
              archivedAt?: string | null;
              tags: Array<{ __typename?: 'Tag'; key: string; value: string }>;
            }>;
            portfolioTags: Array<{ __typename?: 'Tag'; key: string; value: string }>;
            configuration: Array<{
              __typename?: 'PreTradeCheckProperty';
              name: string;
              type: PreTradeCheckPropertyType;
              values: Array<string>;
            }>;
          };
        } | null> | null;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean };
  } | null;
};

export type PreTradeCheckFormSchemaQueryVariables = Exact<{ [key: string]: never }>;

export type PreTradeCheckFormSchemaQuery = {
  __typename?: 'Query';
  preTradeCheckFormSchema: Array<{
    __typename?: 'PreTradeCheckSchema';
    type: string;
    configuration: Array<{
      __typename?: 'PreTradeCheckPropertySchema';
      type: PreTradeCheckPropertyType;
      name: string;
      required: boolean;
      isEnum: boolean;
      options?: Array<string> | null;
    }>;
  }>;
};

export type PreTradeChecksQueryVariables = Exact<{ [key: string]: never }>;

export type PreTradeChecksQuery = {
  __typename?: 'Query';
  preTradeChecks: Array<{
    __typename?: 'PreTradeCheck';
    id: string;
    type: string;
    channels: Array<PreTradeCheckChannels>;
    level: PreTradeCheckLevel;
    portfolios: Array<{
      __typename?: 'PortfolioResponse';
      id: string;
      name: string;
      createdAt: string;
      portfolioType?: PortfolioType | null;
      portfolioCurrency: string;
      scopes: Array<Scope>;
      archivedAt?: string | null;
      tags: Array<{ __typename?: 'Tag'; key: string; value: string }>;
    }>;
    portfolioTags: Array<{ __typename?: 'Tag'; key: string; value: string }>;
    configuration: Array<{
      __typename?: 'PreTradeCheckProperty';
      name: string;
      type: PreTradeCheckPropertyType;
      values: Array<string>;
    }>;
  }>;
};

export type QuotingConfigValidationsQueryVariables = Exact<{ [key: string]: never }>;

export type QuotingConfigValidationsQuery = {
  __typename?: 'Query';
  quotingConfigValidations: Array<{
    __typename?: 'QuotingConfigValidationResult';
    clobUid: string;
    resultsPerInstrument: Array<{
      __typename?: 'ConfigInstrumentValidationResult';
      instrumentId: string;
      isValid: boolean;
      preventsTrading: boolean;
      preventsMarketData: boolean;
      errors: Array<{
        __typename?: 'ConfigValidationError';
        errorMessage?: string | null;
        errorType: ErrorType;
        fieldName: string;
        preventsTrading: boolean;
        preventsMarketData: boolean;
      }>;
    }>;
  }>;
};

export type QuotingConfigurationQueryVariables = Exact<{ [key: string]: never }>;

export type QuotingConfigurationQuery = {
  __typename?: 'Query';
  quotingConfiguration: Array<{
    __typename?: 'QuotingConfiguration';
    displayName: string;
    clobUid: string;
    nostroPortfolioId: string;
    nostroPortfolioName: string;
    minQuantityFactor: number;
    maxQuantityFactor: number;
    maximumDepth?: number | null;
    askMarkup?: number | null;
    bidMarkup?: number | null;
    throttlingPeriod?: number | null;
    deactivated: boolean;
    updatedAt: string;
    quoteTTL?: number | null;
    hedgingSafetyMargin: number;
    hedgingAccounts: Array<string>;
    sources?: Array<{
      __typename?: 'QuotingSourceAccountConfig';
      accountId: string;
      accountName: string;
      isDroppingQuotesOnDisconnection?: boolean | null;
      defaultQuoteTTL?: number | null;
      quoteTTLUnit: QuoteTtlUnit;
      calendarEntries?: Array<{
        __typename?: 'QuotingCalendar';
        fromDayOfTheWeek: DayOfTheWeek;
        toDayOfTheWeek: DayOfTheWeek;
        startTime: string;
        endTime: string;
        quoteTTL: number;
        quoteTTLUnit: QuoteTtlUnit;
        additionalMarkup: number;
      }> | null;
    }> | null;
    hedgingAccountDescs?: Array<{
      __typename?: 'VenueAccountDesc';
      id: string;
      name: string;
    }> | null;
    instrumentConfigurations: Array<{
      __typename?: 'InstrumentQuotingConfiguration';
      priceLevelIncrement?: number | null;
      hedgingSafetyMargin?: number | null;
      instrumentId: string;
      quoteTTL?: number | null;
      quantityIncrement?: number | null;
      maximumDepth?: number | null;
      deactivated: boolean;
      askMarkup?: number | null;
      bidMarkup?: number | null;
      minQuantityFactor?: number | null;
      maxQuantityFactor?: number | null;
      sourceConfigurations: Array<{
        __typename?: 'SourceConfiguration';
        inverse: boolean;
        sourceInstrument: {
          __typename?: 'InstrumentResponse';
          archivedAt?: string | null;
          createdAt?: string | null;
          baseInstrument: {
            __typename?: 'BaseInstrumentResponse';
            assetClass: AssetClass;
            symbol?: string | null;
            description?: string | null;
            quoteCurrency: string;
            inverseContract?: boolean | null;
            venueType: VenueType;
            venueName: string;
          };
          forexSpotProperties?: {
            __typename?: 'ForexSpotPropertiesResponse';
            baseCurrency: string;
          } | null;
          tradingConstraints: {
            __typename?: 'TradingConstraintsResponse';
            minQty?: string | null;
            maxQty?: string | null;
            qtyIncr?: string | null;
            minPrice?: string | null;
            maxPrice?: string | null;
            priceIncr?: string | null;
            minNotional?: string | null;
            contractSize?: string | null;
            tradeable?: boolean | null;
          };
          instrumentIdentifiers: {
            __typename?: 'InstrumentIdentifiersResponse';
            adapterTicker?: string | null;
            instrumentId: string;
            tradingViewId?: string | null;
            venueTradingViewId?: string | null;
          };
        };
        conversionSourceInstrument?: {
          __typename?: 'InstrumentResponse';
          archivedAt?: string | null;
          createdAt?: string | null;
          baseInstrument: {
            __typename?: 'BaseInstrumentResponse';
            assetClass: AssetClass;
            symbol?: string | null;
            description?: string | null;
            quoteCurrency: string;
            inverseContract?: boolean | null;
            venueType: VenueType;
            venueName: string;
          };
          forexSpotProperties?: {
            __typename?: 'ForexSpotPropertiesResponse';
            baseCurrency: string;
          } | null;
          tradingConstraints: {
            __typename?: 'TradingConstraintsResponse';
            minQty?: string | null;
            maxQty?: string | null;
            qtyIncr?: string | null;
            minPrice?: string | null;
            maxPrice?: string | null;
            priceIncr?: string | null;
            minNotional?: string | null;
            contractSize?: string | null;
            tradeable?: boolean | null;
          };
          instrumentIdentifiers: {
            __typename?: 'InstrumentIdentifiersResponse';
            adapterTicker?: string | null;
            instrumentId: string;
            tradingViewId?: string | null;
            venueTradingViewId?: string | null;
          };
        } | null;
      }>;
    }>;
  }>;
};

export type RateSubscriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type RateSubscriptionsQuery = {
  __typename?: 'Query';
  rateSubscriptions: Array<{
    __typename?: 'RateSubscriptionResponse';
    baseCurrency: string;
    quoteCurrency: string;
    venueAccountId?: string | null;
    venueAccountName?: string | null;
    lastPrice?: string | null;
    health: RateSubscriptionHealth;
    lastEventTimestamp?: string | null;
  }>;
};

export type RemoveGroupPermissionsMutationVariables = Exact<{
  request: AddOrRemoveGroupPermissionsInput;
}>;

export type RemoveGroupPermissionsMutation = {
  __typename?: 'Mutation';
  removeGroupPermissions?: { __typename?: 'MutationReturnValue'; clientId?: string | null } | null;
};

export type RemoveUserPermissionsMutationVariables = Exact<{
  request: AddOrRemoveUserPermissionsInput;
}>;

export type RemoveUserPermissionsMutation = {
  __typename?: 'Mutation';
  removeUserPermissions?: { __typename?: 'MutationReturnValue'; clientId?: string | null } | null;
};

export type ResetConfigurationMutationVariables = Exact<{
  resetConfigurationInput: ResetConfigurationInput;
}>;

export type ResetConfigurationMutation = {
  __typename?: 'Mutation';
  resetConfiguration?: {
    __typename?: 'ConfigValidationResult';
    resultsPerInstrument: Array<{
      __typename?: 'ConfigInstrumentValidationResult';
      instrumentId: string;
      isValid: boolean;
      preventsTrading: boolean;
      preventsMarketData: boolean;
      errors: Array<{
        __typename?: 'ConfigValidationError';
        errorMessage?: string | null;
        preventsTrading: boolean;
        preventsMarketData: boolean;
      }>;
    }>;
  } | null;
};

export type SaveCurrencyMutationVariables = Exact<{
  request: CurrencyInput;
}>;

export type SaveCurrencyMutation = {
  __typename?: 'Mutation';
  saveCurrency?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type SavePreTradeCheckMutationVariables = Exact<{
  request: PreTradeCheckInput;
}>;

export type SavePreTradeCheckMutation = {
  __typename?: 'Mutation';
  savePreTradeCheck?: { __typename?: 'MutationReturnValue'; clientId?: string | null } | null;
};

export type SendOrderMutationVariables = Exact<{
  request: NewOrderSingleRequestInput;
}>;

export type SendOrderMutation = {
  __typename?: 'Mutation';
  sendOrder?: { __typename?: 'MutationReturnValue'; clientId?: string | null } | null;
};

export type StaticPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type StaticPermissionsQuery = {
  __typename?: 'Query';
  userStaticPermissions?: Array<{
    __typename?: 'Permission';
    resource: Resource;
    scope: Scope;
    resourceId?: string | null;
  }> | null;
  groupStaticPermissions?: Array<{
    __typename?: 'Permission';
    resource: Resource;
    scope: Scope;
    resourceId?: string | null;
  }> | null;
};

export type SymbolSearchQueryVariables = Exact<{
  search: SymbolSearchInput;
}>;

export type SymbolSearchQuery = {
  __typename?: 'Query';
  symbolSearch?: {
    __typename?: 'SymbolSearchConnection';
    edges: Array<{
      __typename?: 'SymbolSearchEdge';
      cursor: string;
      node: {
        __typename?: 'SymbolSearchResponse';
        assetClass?: AssetClass | null;
        symbol?: string | null;
        venues?: Array<{
          __typename?: 'VenueAccountNamesPerVenue';
          venue?: string | null;
          venueAccountDescs?: Array<{
            __typename?: 'VenueAccountDesc';
            id: string;
            name: string;
          } | null> | null;
          instrument?: {
            __typename?: 'InstrumentResponse';
            archivedAt?: string | null;
            baseInstrument: {
              __typename?: 'BaseInstrumentResponse';
              assetClass: AssetClass;
              symbol?: string | null;
              description?: string | null;
              quoteCurrency: string;
              inverseContract?: boolean | null;
              venueType: VenueType;
              venueName: string;
            };
            forexSpotProperties?: {
              __typename?: 'ForexSpotPropertiesResponse';
              baseCurrency: string;
            } | null;
            tradingConstraints: {
              __typename?: 'TradingConstraintsResponse';
              minQty?: string | null;
              maxQty?: string | null;
              minQuoteQty?: string | null;
              maxQuoteQty?: string | null;
              qtyIncr?: string | null;
              minPrice?: string | null;
              maxPrice?: string | null;
              priceIncr?: string | null;
              minNotional?: string | null;
              contractSize?: string | null;
              tradeable?: boolean | null;
            };
            instrumentIdentifiers: {
              __typename?: 'InstrumentIdentifiersResponse';
              adapterTicker?: string | null;
              instrumentId: string;
              tradingViewId?: string | null;
              venueTradingViewId?: string | null;
            };
          } | null;
        } | null> | null;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
  } | null;
};

export type VenueAccountDescContentFragment = {
  __typename?: 'VenueAccountDesc';
  id: string;
  name: string;
};

export type SystemCurrencyQueryVariables = Exact<{ [key: string]: never }>;

export type SystemCurrencyQuery = { __typename?: 'Query'; systemCurrency: string };

export type TargetStatesSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TargetStatesSubscription = {
  __typename?: 'Subscription';
  targetStates: {
    __typename?: 'TargetStatesResponse';
    target: string;
    targetName?: string | null;
    capabilities: Array<{
      __typename?: 'TargetCapabilityStatus';
      capability: Capability;
      healthStatus: HealthStatus;
      timestamp?: string | null;
      message?: string | null;
    } | null>;
  };
};

export type TickSubscriptionVariables = Exact<{
  venueAccount?: InputMaybe<Scalars['String']['input']>;
  instrumentId: Scalars['String']['input'];
  portfolioId?: InputMaybe<Scalars['String']['input']>;
}>;

export type TickSubscription = {
  __typename?: 'Subscription';
  tick?: {
    __typename?: 'MarketDataResponse';
    venue?: string | null;
    venueAccount?: string | null;
    dateTime?: number | null;
    bidPrice?: number | null;
    bidSize?: number | null;
    askPrice?: number | null;
    askSize?: number | null;
    vol?: number | null;
    lastPrice?: number | null;
    lastSize?: number | null;
    side?: Side | null;
    marketDataType?: MarketDataType | null;
    instrument?: {
      __typename?: 'InstrumentResponse';
      archivedAt?: string | null;
      createdAt?: string | null;
      baseInstrument: {
        __typename?: 'BaseInstrumentResponse';
        assetClass: AssetClass;
        symbol?: string | null;
        description?: string | null;
        quoteCurrency: string;
        inverseContract?: boolean | null;
        venueType: VenueType;
        venueName: string;
      };
      forexSpotProperties?: {
        __typename?: 'ForexSpotPropertiesResponse';
        baseCurrency: string;
      } | null;
      tradingConstraints: {
        __typename?: 'TradingConstraintsResponse';
        minQty?: string | null;
        minQuoteQty?: string | null;
        maxQty?: string | null;
        maxQuoteQty?: string | null;
        qtyIncr?: string | null;
        quoteQtyIncr?: string | null;
        minPrice?: string | null;
        maxPrice?: string | null;
        priceIncr?: string | null;
        minNotional?: string | null;
        contractSize?: string | null;
        tradeable?: boolean | null;
      };
      instrumentIdentifiers: {
        __typename?: 'InstrumentIdentifiersResponse';
        adapterTicker?: string | null;
        instrumentId: string;
        tradingViewId?: string | null;
        venueTradingViewId?: string | null;
      };
    } | null;
  } | null;
};

export type TimelineQueryVariables = Exact<{
  search?: InputMaybe<TimelineSearchInput>;
}>;

export type TimelineQuery = {
  __typename?: 'Query';
  timeline?: {
    __typename?: 'TimelineConnection';
    edges: Array<{
      __typename?: 'TimelineEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineResult';
        type?: TimelineEventType | null;
        data?:
          | {
              __typename?: 'HedgeResult';
              timestamp: string;
              clobOrderId: string;
              matchId: string;
              estimatedPrice?: number | null;
              hedgeOrderId: string;
              hedgingVenue: string;
              hedgeOrderQuantity: number;
              hedgeOrderLimitPrice?: number | null;
              hedgeOrderSide: Side;
              success: boolean;
              executionPrice?: number | null;
              hedgeOrderAmount?: number | null;
              hedgeOrderAmountInClobQuoteCurrency?: number | null;
              breakEvenFxRate?: number | null;
              primarySymbolQuote?: {
                __typename?: 'MarketQuote';
                marketAskPrice: number;
                marketBidPrice: number;
                marketAskSize: number;
                marketBidSize: number;
                markupAskPrice?: number | null;
                markupBidPrice?: number | null;
                timestamp?: string | null;
                instrument: {
                  __typename?: 'InstrumentResponse';
                  archivedAt?: string | null;
                  createdAt?: string | null;
                  baseInstrument: {
                    __typename?: 'BaseInstrumentResponse';
                    assetClass: AssetClass;
                    symbol?: string | null;
                    description?: string | null;
                    quoteCurrency: string;
                    inverseContract?: boolean | null;
                    venueType: VenueType;
                    venueName: string;
                  };
                  forexSpotProperties?: {
                    __typename?: 'ForexSpotPropertiesResponse';
                    baseCurrency: string;
                  } | null;
                  tradingConstraints: {
                    __typename?: 'TradingConstraintsResponse';
                    minQty?: string | null;
                    minQuoteQty?: string | null;
                    maxQty?: string | null;
                    maxQuoteQty?: string | null;
                    qtyIncr?: string | null;
                    quoteQtyIncr?: string | null;
                    minPrice?: string | null;
                    maxPrice?: string | null;
                    priceIncr?: string | null;
                    minNotional?: string | null;
                    contractSize?: string | null;
                    tradeable?: boolean | null;
                  };
                  instrumentIdentifiers: {
                    __typename?: 'InstrumentIdentifiersResponse';
                    adapterTicker?: string | null;
                    instrumentId: string;
                    tradingViewId?: string | null;
                    venueTradingViewId?: string | null;
                  };
                };
              } | null;
              secondarySymbolQuote?: {
                __typename?: 'MarketQuote';
                marketAskPrice: number;
                marketBidPrice: number;
                marketAskSize: number;
                marketBidSize: number;
                markupAskPrice?: number | null;
                markupBidPrice?: number | null;
                timestamp?: string | null;
                instrument: {
                  __typename?: 'InstrumentResponse';
                  archivedAt?: string | null;
                  createdAt?: string | null;
                  baseInstrument: {
                    __typename?: 'BaseInstrumentResponse';
                    assetClass: AssetClass;
                    symbol?: string | null;
                    description?: string | null;
                    quoteCurrency: string;
                    inverseContract?: boolean | null;
                    venueType: VenueType;
                    venueName: string;
                  };
                  forexSpotProperties?: {
                    __typename?: 'ForexSpotPropertiesResponse';
                    baseCurrency: string;
                  } | null;
                  tradingConstraints: {
                    __typename?: 'TradingConstraintsResponse';
                    minQty?: string | null;
                    minQuoteQty?: string | null;
                    maxQty?: string | null;
                    maxQuoteQty?: string | null;
                    qtyIncr?: string | null;
                    quoteQtyIncr?: string | null;
                    minPrice?: string | null;
                    maxPrice?: string | null;
                    priceIncr?: string | null;
                    minNotional?: string | null;
                    contractSize?: string | null;
                    tradeable?: boolean | null;
                  };
                  instrumentIdentifiers: {
                    __typename?: 'InstrumentIdentifiersResponse';
                    adapterTicker?: string | null;
                    instrumentId: string;
                    tradingViewId?: string | null;
                    venueTradingViewId?: string | null;
                  };
                };
              } | null;
            }
          | {
              __typename?: 'MatchResponse';
              id: string;
              timestamp: string;
              makers: Array<{
                __typename?: 'Counterparty';
                portfolioId?: string | null;
                portfolioName?: string | null;
                venueAccount?: string | null;
                venueAccountName?: string | null;
                referenceType?: ParticipantReferenceType | null;
                orderId?: string | null;
                price: number;
                volume: number;
              }>;
              primarySymbolQuotes: Array<{
                __typename?: 'MarketQuote';
                marketAskPrice: number;
                marketBidPrice: number;
                marketAskSize: number;
                marketBidSize: number;
                markupAskPrice?: number | null;
                markupBidPrice?: number | null;
                timestamp?: string | null;
                instrument: {
                  __typename?: 'InstrumentResponse';
                  archivedAt?: string | null;
                  createdAt?: string | null;
                  baseInstrument: {
                    __typename?: 'BaseInstrumentResponse';
                    assetClass: AssetClass;
                    symbol?: string | null;
                    description?: string | null;
                    quoteCurrency: string;
                    inverseContract?: boolean | null;
                    venueType: VenueType;
                    venueName: string;
                  };
                  forexSpotProperties?: {
                    __typename?: 'ForexSpotPropertiesResponse';
                    baseCurrency: string;
                  } | null;
                  tradingConstraints: {
                    __typename?: 'TradingConstraintsResponse';
                    minQty?: string | null;
                    minQuoteQty?: string | null;
                    maxQty?: string | null;
                    maxQuoteQty?: string | null;
                    qtyIncr?: string | null;
                    quoteQtyIncr?: string | null;
                    minPrice?: string | null;
                    maxPrice?: string | null;
                    priceIncr?: string | null;
                    minNotional?: string | null;
                    contractSize?: string | null;
                    tradeable?: boolean | null;
                  };
                  instrumentIdentifiers: {
                    __typename?: 'InstrumentIdentifiersResponse';
                    adapterTicker?: string | null;
                    instrumentId: string;
                    tradingViewId?: string | null;
                    venueTradingViewId?: string | null;
                  };
                };
              }>;
              secondarySymbolQuotes?: Array<{
                __typename?: 'MarketQuote';
                marketAskPrice: number;
                marketBidPrice: number;
                marketAskSize: number;
                marketBidSize: number;
                markupAskPrice?: number | null;
                markupBidPrice?: number | null;
                timestamp?: string | null;
                instrument: {
                  __typename?: 'InstrumentResponse';
                  archivedAt?: string | null;
                  createdAt?: string | null;
                  baseInstrument: {
                    __typename?: 'BaseInstrumentResponse';
                    assetClass: AssetClass;
                    symbol?: string | null;
                    description?: string | null;
                    quoteCurrency: string;
                    inverseContract?: boolean | null;
                    venueType: VenueType;
                    venueName: string;
                  };
                  forexSpotProperties?: {
                    __typename?: 'ForexSpotPropertiesResponse';
                    baseCurrency: string;
                  } | null;
                  tradingConstraints: {
                    __typename?: 'TradingConstraintsResponse';
                    minQty?: string | null;
                    minQuoteQty?: string | null;
                    maxQty?: string | null;
                    maxQuoteQty?: string | null;
                    qtyIncr?: string | null;
                    quoteQtyIncr?: string | null;
                    minPrice?: string | null;
                    maxPrice?: string | null;
                    priceIncr?: string | null;
                    minNotional?: string | null;
                    contractSize?: string | null;
                    tradeable?: boolean | null;
                  };
                  instrumentIdentifiers: {
                    __typename?: 'InstrumentIdentifiersResponse';
                    adapterTicker?: string | null;
                    instrumentId: string;
                    tradingViewId?: string | null;
                    venueTradingViewId?: string | null;
                  };
                };
              }> | null;
              taker: {
                __typename?: 'Counterparty';
                portfolioId?: string | null;
                portfolioName?: string | null;
                venueAccount?: string | null;
                venueAccountName?: string | null;
                referenceType?: ParticipantReferenceType | null;
                orderId?: string | null;
                price: number;
                volume: number;
              };
            }
          | {
              __typename?: 'OrderStateResponse';
              orderStateId?: string | null;
              orderId: string;
              clientId?: string | null;
              clOrderId?: string | null;
              expirationDateTime?: string | null;
              origClOrderId?: string | null;
              portfolioId: string;
              portfolioName: string;
              counterPortfolioId?: string | null;
              counterPortfolioName?: string | null;
              orderStatus: OrderStatus;
              orderQty: number;
              currency?: string | null;
              limitPrice?: number | null;
              stopPrice?: number | null;
              tif?: Tif | null;
              filledQty: number;
              remainingQty: number;
              lastQty?: number | null;
              avgPrice?: string | null;
              lastPrice?: string | null;
              reason?: string | null;
              rootOrderId?: string | null;
              lastRequestResult: string;
              side: Side;
              symbol?: string | null;
              assetClass?: AssetClass | null;
              venue?: string | null;
              venueTimestamp?: string | null;
              createdAt?: string | null;
              updatedAt?: string | null;
              orderCategory: OrderCategory;
              parentOrderId?: string | null;
              instrument?: {
                __typename?: 'InstrumentResponse';
                archivedAt?: string | null;
                createdAt?: string | null;
                baseInstrument: {
                  __typename?: 'BaseInstrumentResponse';
                  assetClass: AssetClass;
                  symbol?: string | null;
                  description?: string | null;
                  quoteCurrency: string;
                  inverseContract?: boolean | null;
                  venueType: VenueType;
                  venueName: string;
                };
                forexSpotProperties?: {
                  __typename?: 'ForexSpotPropertiesResponse';
                  baseCurrency: string;
                } | null;
                tradingConstraints: {
                  __typename?: 'TradingConstraintsResponse';
                  minQty?: string | null;
                  minQuoteQty?: string | null;
                  maxQty?: string | null;
                  maxQuoteQty?: string | null;
                  qtyIncr?: string | null;
                  quoteQtyIncr?: string | null;
                  minPrice?: string | null;
                  maxPrice?: string | null;
                  priceIncr?: string | null;
                  minNotional?: string | null;
                  contractSize?: string | null;
                  tradeable?: boolean | null;
                };
                instrumentIdentifiers: {
                  __typename?: 'InstrumentIdentifiersResponse';
                  adapterTicker?: string | null;
                  instrumentId: string;
                  tradingViewId?: string | null;
                  venueTradingViewId?: string | null;
                };
              } | null;
              venueAccountDescs?: Array<{
                __typename?: 'VenueAccountDesc';
                id: string;
                name: string;
              }> | null;
            }
          | null;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
  } | null;
};

export type CounterpartyContentFragment = {
  __typename?: 'Counterparty';
  portfolioId?: string | null;
  portfolioName?: string | null;
  venueAccount?: string | null;
  venueAccountName?: string | null;
  referenceType?: ParticipantReferenceType | null;
  orderId?: string | null;
  price: number;
  volume: number;
};

export type MarketQuoteContentFragment = {
  __typename?: 'MarketQuote';
  marketAskPrice: number;
  marketBidPrice: number;
  marketAskSize: number;
  marketBidSize: number;
  markupAskPrice?: number | null;
  markupBidPrice?: number | null;
  timestamp?: string | null;
  instrument: {
    __typename?: 'InstrumentResponse';
    archivedAt?: string | null;
    createdAt?: string | null;
    baseInstrument: {
      __typename?: 'BaseInstrumentResponse';
      assetClass: AssetClass;
      symbol?: string | null;
      description?: string | null;
      quoteCurrency: string;
      inverseContract?: boolean | null;
      venueType: VenueType;
      venueName: string;
    };
    forexSpotProperties?: {
      __typename?: 'ForexSpotPropertiesResponse';
      baseCurrency: string;
    } | null;
    tradingConstraints: {
      __typename?: 'TradingConstraintsResponse';
      minQty?: string | null;
      minQuoteQty?: string | null;
      maxQty?: string | null;
      maxQuoteQty?: string | null;
      qtyIncr?: string | null;
      quoteQtyIncr?: string | null;
      minPrice?: string | null;
      maxPrice?: string | null;
      priceIncr?: string | null;
      minNotional?: string | null;
      contractSize?: string | null;
      tradeable?: boolean | null;
    };
    instrumentIdentifiers: {
      __typename?: 'InstrumentIdentifiersResponse';
      adapterTicker?: string | null;
      instrumentId: string;
      tradingViewId?: string | null;
      venueTradingViewId?: string | null;
    };
  };
};

export type TransactionTypesQueryVariables = Exact<{ [key: string]: never }>;

export type TransactionTypesQuery = { __typename?: 'Query'; transactionTypes: Array<string> };

export type TransactionsQueryVariables = Exact<{
  search: TransactionSearchInput;
}>;

export type TransactionsQuery = {
  __typename?: 'Query';
  transactionTypes: Array<string>;
  transactions?: {
    __typename?: 'TransactionConnection';
    edges: Array<{
      __typename?: 'TransactionEdge';
      cursor: string;
      node:
        | {
            __typename?: 'AccountCashTransfer';
            uuid: string;
            updatedAt: number;
            dateTime: string;
            executionId: string;
            venueExecutionId?: string | null;
            description: string;
            quantity: number;
            currency: string;
            sourceAccountId: string;
            sourceAccountName: string;
            targetAccountId: string;
            targetAccountName: string;
            settled: string;
            settledDateTime?: number | null;
            feeAccountId?: string | null;
            feeAccountName?: string | null;
            feePortfolioId?: string | null;
            feePortfolioName?: string | null;
          }
        | {
            __typename?: 'ClientAssetTrade';
            orderId: string;
            uuid: string;
            updatedAt: number;
            dateTime: string;
            fee: number;
            feeCurrency: string;
            description: string;
            quantity: number;
            price: number;
            currency: string;
            intOrderId: string;
            extOrderId: string;
            portfolioId: string;
            portfolioName: string;
            counterPortfolioId: string;
            counterPortfolioName: string;
            settled: string;
            settledDateTime?: number | null;
            instrument?: {
              __typename?: 'InstrumentResponse';
              archivedAt?: string | null;
              createdAt?: string | null;
              baseInstrument: {
                __typename?: 'BaseInstrumentResponse';
                assetClass: AssetClass;
                symbol?: string | null;
                description?: string | null;
                quoteCurrency: string;
                inverseContract?: boolean | null;
                venueType: VenueType;
                venueName: string;
              };
              forexSpotProperties?: {
                __typename?: 'ForexSpotPropertiesResponse';
                baseCurrency: string;
              } | null;
              tradingConstraints: {
                __typename?: 'TradingConstraintsResponse';
                minQty?: string | null;
                minQuoteQty?: string | null;
                maxQty?: string | null;
                maxQuoteQty?: string | null;
                qtyIncr?: string | null;
                quoteQtyIncr?: string | null;
                minPrice?: string | null;
                maxPrice?: string | null;
                priceIncr?: string | null;
                minNotional?: string | null;
                contractSize?: string | null;
                tradeable?: boolean | null;
              };
              instrumentIdentifiers: {
                __typename?: 'InstrumentIdentifiersResponse';
                adapterTicker?: string | null;
                instrumentId: string;
                tradingViewId?: string | null;
                venueTradingViewId?: string | null;
              };
            } | null;
            rootExecution?: {
              __typename?: 'RootExecution';
              orderId: string;
              executionId: string;
            } | null;
          }
        | {
            __typename?: 'ClientCashTrade';
            orderId: string;
            uuid: string;
            updatedAt: number;
            dateTime: string;
            fee: number;
            feeCurrency: string;
            description: string;
            quantity: number;
            price: number;
            currency: string;
            intOrderId: string;
            extOrderId: string;
            baseCurrency: string;
            portfolioId: string;
            portfolioName: string;
            counterPortfolioId: string;
            counterPortfolioName: string;
            settled: string;
            settledDateTime?: number | null;
            rootExecution?: {
              __typename?: 'RootExecution';
              orderId: string;
              executionId: string;
            } | null;
          }
        | {
            __typename?: 'Deposit';
            uuid: string;
            updatedAt: number;
            dateTime: string;
            executionId: string;
            venueExecutionId?: string | null;
            description: string;
            quantity: number;
            currency: string;
            portfolioId: string;
            portfolioName: string;
            account: string;
            accountName: string;
            settled: string;
            settledDateTime?: number | null;
            feeAccountId?: string | null;
            feeAccountName?: string | null;
            feePortfolioId?: string | null;
            feePortfolioName?: string | null;
          }
        | {
            __typename?: 'PortfolioCashTransfer';
            uuid: string;
            updatedAt: number;
            dateTime: string;
            executionId: string;
            venueExecutionId?: string | null;
            description: string;
            quantity: number;
            currency: string;
            sourcePortfolioId: string;
            sourcePortfolioName: string;
            targetPortfolioId: string;
            targetPortfolioName: string;
            settled: string;
            settledDateTime?: number | null;
            feePortfolioId?: string | null;
            feePortfolioName?: string | null;
          }
        | {
            __typename?: 'Settlement';
            uuid: string;
            updatedAt: number;
            dateTime: string;
            description: string;
            settledTransactionIds: Array<string | null>;
          }
        | {
            __typename?: 'StreetAssetTrade';
            orderId: string;
            uuid: string;
            updatedAt: number;
            dateTime: string;
            fee: number;
            feeCurrency: string;
            description: string;
            quantity: number;
            price: number;
            currency: string;
            intOrderId: string;
            extOrderId: string;
            portfolioId: string;
            portfolioName: string;
            venueAccount: string;
            venueAccountName: string;
            rootOrderId?: string | null;
            settled: string;
            settledDateTime?: number | null;
            instrument?: {
              __typename?: 'InstrumentResponse';
              archivedAt?: string | null;
              createdAt?: string | null;
              baseInstrument: {
                __typename?: 'BaseInstrumentResponse';
                assetClass: AssetClass;
                symbol?: string | null;
                description?: string | null;
                quoteCurrency: string;
                inverseContract?: boolean | null;
                venueType: VenueType;
                venueName: string;
              };
              forexSpotProperties?: {
                __typename?: 'ForexSpotPropertiesResponse';
                baseCurrency: string;
              } | null;
              tradingConstraints: {
                __typename?: 'TradingConstraintsResponse';
                minQty?: string | null;
                minQuoteQty?: string | null;
                maxQty?: string | null;
                maxQuoteQty?: string | null;
                qtyIncr?: string | null;
                quoteQtyIncr?: string | null;
                minPrice?: string | null;
                maxPrice?: string | null;
                priceIncr?: string | null;
                minNotional?: string | null;
                contractSize?: string | null;
                tradeable?: boolean | null;
              };
              instrumentIdentifiers: {
                __typename?: 'InstrumentIdentifiersResponse';
                adapterTicker?: string | null;
                instrumentId: string;
                tradingViewId?: string | null;
                venueTradingViewId?: string | null;
              };
            } | null;
          }
        | {
            __typename?: 'StreetCashTrade';
            orderId: string;
            uuid: string;
            updatedAt: number;
            dateTime: string;
            fee: number;
            feeCurrency: string;
            description: string;
            quantity: number;
            price: number;
            currency: string;
            intOrderId: string;
            extOrderId: string;
            baseCurrency: string;
            portfolioId: string;
            portfolioName: string;
            venueAccount: string;
            venueAccountName: string;
            rootOrderId?: string | null;
            settled: string;
            settledDateTime?: number | null;
          }
        | {
            __typename?: 'Withdrawal';
            uuid: string;
            updatedAt: number;
            dateTime: string;
            executionId: string;
            venueExecutionId?: string | null;
            description: string;
            quantity: number;
            currency: string;
            portfolioId: string;
            portfolioName: string;
            account: string;
            accountName: string;
            settled: string;
            settledDateTime?: number | null;
            feeAccountId?: string | null;
            feeAccountName?: string | null;
            feePortfolioId?: string | null;
            feePortfolioName?: string | null;
          };
    }>;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null };
  } | null;
};

export type TransactionsWithSnapshotSubscriptionVariables = Exact<{
  search: TransactionSearchInput;
}>;

export type TransactionsWithSnapshotSubscription = {
  __typename?: 'Subscription';
  transactionsWithSnapshot?:
    | {
        __typename?: 'AccountCashTransfer';
        uuid: string;
        updatedAt: number;
        dateTime: string;
        executionId: string;
        venueExecutionId?: string | null;
        description: string;
        quantity: number;
        currency: string;
        sourceAccountId: string;
        sourceAccountName: string;
        targetAccountId: string;
        targetAccountName: string;
        settled: string;
        settledDateTime?: number | null;
        feeAccountId?: string | null;
        feeAccountName?: string | null;
        feePortfolioId?: string | null;
        feePortfolioName?: string | null;
      }
    | {
        __typename?: 'ClientAssetTrade';
        orderId: string;
        uuid: string;
        updatedAt: number;
        dateTime: string;
        fee: number;
        feeCurrency: string;
        description: string;
        quantity: number;
        price: number;
        currency: string;
        intOrderId: string;
        extOrderId: string;
        portfolioId: string;
        portfolioName: string;
        counterPortfolioId: string;
        counterPortfolioName: string;
        settled: string;
        settledDateTime?: number | null;
        instrument?: {
          __typename?: 'InstrumentResponse';
          archivedAt?: string | null;
          createdAt?: string | null;
          baseInstrument: {
            __typename?: 'BaseInstrumentResponse';
            assetClass: AssetClass;
            symbol?: string | null;
            description?: string | null;
            quoteCurrency: string;
            inverseContract?: boolean | null;
            venueType: VenueType;
            venueName: string;
          };
          forexSpotProperties?: {
            __typename?: 'ForexSpotPropertiesResponse';
            baseCurrency: string;
          } | null;
          tradingConstraints: {
            __typename?: 'TradingConstraintsResponse';
            minQty?: string | null;
            minQuoteQty?: string | null;
            maxQty?: string | null;
            maxQuoteQty?: string | null;
            qtyIncr?: string | null;
            quoteQtyIncr?: string | null;
            minPrice?: string | null;
            maxPrice?: string | null;
            priceIncr?: string | null;
            minNotional?: string | null;
            contractSize?: string | null;
            tradeable?: boolean | null;
          };
          instrumentIdentifiers: {
            __typename?: 'InstrumentIdentifiersResponse';
            adapterTicker?: string | null;
            instrumentId: string;
            tradingViewId?: string | null;
            venueTradingViewId?: string | null;
          };
        } | null;
        rootExecution?: {
          __typename?: 'RootExecution';
          orderId: string;
          executionId: string;
        } | null;
      }
    | {
        __typename?: 'ClientCashTrade';
        orderId: string;
        uuid: string;
        updatedAt: number;
        dateTime: string;
        fee: number;
        feeCurrency: string;
        description: string;
        quantity: number;
        price: number;
        currency: string;
        intOrderId: string;
        extOrderId: string;
        baseCurrency: string;
        portfolioId: string;
        portfolioName: string;
        counterPortfolioId: string;
        counterPortfolioName: string;
        settled: string;
        settledDateTime?: number | null;
        rootExecution?: {
          __typename?: 'RootExecution';
          orderId: string;
          executionId: string;
        } | null;
      }
    | {
        __typename?: 'Deposit';
        uuid: string;
        updatedAt: number;
        dateTime: string;
        executionId: string;
        venueExecutionId?: string | null;
        description: string;
        quantity: number;
        currency: string;
        portfolioId: string;
        portfolioName: string;
        account: string;
        accountName: string;
        settled: string;
        settledDateTime?: number | null;
        feeAccountId?: string | null;
        feeAccountName?: string | null;
        feePortfolioId?: string | null;
        feePortfolioName?: string | null;
      }
    | {
        __typename?: 'PortfolioCashTransfer';
        uuid: string;
        updatedAt: number;
        dateTime: string;
        executionId: string;
        venueExecutionId?: string | null;
        description: string;
        quantity: number;
        currency: string;
        sourcePortfolioId: string;
        sourcePortfolioName: string;
        targetPortfolioId: string;
        targetPortfolioName: string;
        settled: string;
        settledDateTime?: number | null;
        feePortfolioId?: string | null;
        feePortfolioName?: string | null;
      }
    | {
        __typename?: 'Settlement';
        uuid: string;
        updatedAt: number;
        dateTime: string;
        description: string;
        settledTransactionIds: Array<string | null>;
      }
    | {
        __typename?: 'StreetAssetTrade';
        orderId: string;
        uuid: string;
        updatedAt: number;
        dateTime: string;
        fee: number;
        feeCurrency: string;
        description: string;
        quantity: number;
        price: number;
        currency: string;
        intOrderId: string;
        extOrderId: string;
        portfolioId: string;
        portfolioName: string;
        venueAccount: string;
        venueAccountName: string;
        rootOrderId?: string | null;
        settled: string;
        settledDateTime?: number | null;
        instrument?: {
          __typename?: 'InstrumentResponse';
          archivedAt?: string | null;
          createdAt?: string | null;
          baseInstrument: {
            __typename?: 'BaseInstrumentResponse';
            assetClass: AssetClass;
            symbol?: string | null;
            description?: string | null;
            quoteCurrency: string;
            inverseContract?: boolean | null;
            venueType: VenueType;
            venueName: string;
          };
          forexSpotProperties?: {
            __typename?: 'ForexSpotPropertiesResponse';
            baseCurrency: string;
          } | null;
          tradingConstraints: {
            __typename?: 'TradingConstraintsResponse';
            minQty?: string | null;
            minQuoteQty?: string | null;
            maxQty?: string | null;
            maxQuoteQty?: string | null;
            qtyIncr?: string | null;
            quoteQtyIncr?: string | null;
            minPrice?: string | null;
            maxPrice?: string | null;
            priceIncr?: string | null;
            minNotional?: string | null;
            contractSize?: string | null;
            tradeable?: boolean | null;
          };
          instrumentIdentifiers: {
            __typename?: 'InstrumentIdentifiersResponse';
            adapterTicker?: string | null;
            instrumentId: string;
            tradingViewId?: string | null;
            venueTradingViewId?: string | null;
          };
        } | null;
      }
    | {
        __typename?: 'StreetCashTrade';
        orderId: string;
        uuid: string;
        updatedAt: number;
        dateTime: string;
        fee: number;
        feeCurrency: string;
        description: string;
        quantity: number;
        price: number;
        currency: string;
        intOrderId: string;
        extOrderId: string;
        baseCurrency: string;
        portfolioId: string;
        portfolioName: string;
        venueAccount: string;
        venueAccountName: string;
        rootOrderId?: string | null;
        settled: string;
        settledDateTime?: number | null;
      }
    | {
        __typename?: 'Withdrawal';
        uuid: string;
        updatedAt: number;
        dateTime: string;
        executionId: string;
        venueExecutionId?: string | null;
        description: string;
        quantity: number;
        currency: string;
        portfolioId: string;
        portfolioName: string;
        account: string;
        accountName: string;
        settled: string;
        settledDateTime?: number | null;
        feeAccountId?: string | null;
        feeAccountName?: string | null;
        feePortfolioId?: string | null;
        feePortfolioName?: string | null;
      }
    | null;
};

export type UpdateConversionSourceMutationVariables = Exact<{
  request: UpdateConversionSourceInput;
}>;

export type UpdateConversionSourceMutation = {
  __typename?: 'Mutation';
  updateConversionSource?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type UpdateInstrumentMutationVariables = Exact<{
  request: UpdateInstrumentInput;
}>;

export type UpdateInstrumentMutation = {
  __typename?: 'Mutation';
  updateInstrument?: { __typename?: 'UpdateInstrumentResult'; status?: string | null } | null;
};

export type UpdateInstrumentQuotingConfigurationMutationVariables = Exact<{
  clobUid: Scalars['String']['input'];
  request: InstrumentQuotingConfigurationInput;
}>;

export type UpdateInstrumentQuotingConfigurationMutation = {
  __typename?: 'Mutation';
  updateInstrumentQuotingConfiguration?: {
    __typename?: 'QuotingConfigValidationResult';
    resultsPerInstrument: Array<{
      __typename?: 'ConfigInstrumentValidationResult';
      instrumentId: string;
      isValid: boolean;
      preventsTrading: boolean;
      preventsMarketData: boolean;
      errors: Array<{
        __typename?: 'ConfigValidationError';
        errorMessage?: string | null;
        preventsTrading: boolean;
        preventsMarketData: boolean;
      }>;
    }>;
  } | null;
};

export type UpdateInternalExchangeConfigurationMutationVariables = Exact<{
  request: InternalExchangeConfigurationInput;
}>;

export type UpdateInternalExchangeConfigurationMutation = {
  __typename?: 'Mutation';
  updateInternalExchangeConfiguration?: {
    __typename?: 'MutationSubmittedResponse';
    status: string;
  } | null;
};

export type UpdatePortfolioMutationVariables = Exact<{
  request: UpdatePortfolioInput;
}>;

export type UpdatePortfolioMutation = {
  __typename?: 'Mutation';
  updatePortfolio?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type UpdatePortfolioConfigurationMutationVariables = Exact<{
  portfolioId: Scalars['ID']['input'];
  request: PortfolioConfigurationInput;
}>;

export type UpdatePortfolioConfigurationMutation = {
  __typename?: 'Mutation';
  updatePortfolioConfiguration?: {
    __typename?: 'ConfigValidationResult';
    resultsPerInstrument: Array<{
      __typename?: 'ConfigInstrumentValidationResult';
      instrumentId: string;
      isValid: boolean;
      preventsTrading: boolean;
      preventsMarketData: boolean;
      errors: Array<{
        __typename?: 'ConfigValidationError';
        errorMessage?: string | null;
        preventsTrading: boolean;
        preventsMarketData: boolean;
      }>;
    }>;
  } | null;
};

export type InstrumentValidationResultFragment = {
  __typename?: 'ConfigInstrumentValidationResult';
  instrumentId: string;
  isValid: boolean;
  preventsTrading: boolean;
  preventsMarketData: boolean;
  errors: Array<{
    __typename?: 'ConfigValidationError';
    errorMessage?: string | null;
    preventsTrading: boolean;
    preventsMarketData: boolean;
  }>;
};

export type UpdatePortfolioExecutionConfigurationMutationVariables = Exact<{
  portfolioId: Scalars['ID']['input'];
  request: ExecutionConfigurationInput;
}>;

export type UpdatePortfolioExecutionConfigurationMutation = {
  __typename?: 'Mutation';
  updatePortfolioExecutionConfiguration?: {
    __typename?: 'ConfigValidationResult';
    resultsPerInstrument: Array<{
      __typename?: 'ConfigInstrumentValidationResult';
      instrumentId: string;
      isValid: boolean;
      preventsTrading: boolean;
      preventsMarketData: boolean;
      errors: Array<{
        __typename?: 'ConfigValidationError';
        errorMessage?: string | null;
        preventsTrading: boolean;
        preventsMarketData: boolean;
      }>;
    }>;
  } | null;
};

export type UpdatePortfolioGroupConfigurationMutationVariables = Exact<{
  portfolioGroupId: Scalars['ID']['input'];
  request: PortfolioGroupConfigurationInput;
}>;

export type UpdatePortfolioGroupConfigurationMutation = {
  __typename?: 'Mutation';
  updatePortfolioGroupConfiguration?: string | null;
};

export type UpdatePortfolioGroupExecutionConfigurationMutationVariables = Exact<{
  portfolioGroupId: Scalars['ID']['input'];
  request: ExecutionConfigurationInput;
}>;

export type UpdatePortfolioGroupExecutionConfigurationMutation = {
  __typename?: 'Mutation';
  updatePortfolioGroupExecutionConfiguration?: string | null;
};

export type UpdatePortfolioGroupInstrumentConfigurationMutationVariables = Exact<{
  portfolioGroupId: Scalars['ID']['input'];
  request: InstrumentConfigurationInput;
}>;

export type UpdatePortfolioGroupInstrumentConfigurationMutation = {
  __typename?: 'Mutation';
  updatePortfolioGroupInstrumentConfiguration?: string | null;
};

export type UpdatePortfolioGroupPricingConfigurationMutationVariables = Exact<{
  portfolioGroupId: Scalars['ID']['input'];
  request: PricingConfigurationInput;
}>;

export type UpdatePortfolioGroupPricingConfigurationMutation = {
  __typename?: 'Mutation';
  updatePortfolioGroupPricingConfiguration?: string | null;
};

export type UpdatePortfolioHedgingConfigurationMutationVariables = Exact<{
  portfolioId: Scalars['ID']['input'];
  request: HedgingConfigurationInput;
}>;

export type UpdatePortfolioHedgingConfigurationMutation = {
  __typename?: 'Mutation';
  updatePortfolioHedgingConfiguration?: string | null;
};

export type UpdatePortfolioInstrumentConfigurationMutationVariables = Exact<{
  portfolioId: Scalars['ID']['input'];
  request: InstrumentConfigurationInput;
}>;

export type UpdatePortfolioInstrumentConfigurationMutation = {
  __typename?: 'Mutation';
  updatePortfolioInstrumentConfiguration?: {
    __typename?: 'ConfigValidationResult';
    resultsPerInstrument: Array<{
      __typename?: 'ConfigInstrumentValidationResult';
      instrumentId: string;
      isValid: boolean;
      preventsTrading: boolean;
      preventsMarketData: boolean;
      errors: Array<{
        __typename?: 'ConfigValidationError';
        errorMessage?: string | null;
        preventsTrading: boolean;
        preventsMarketData: boolean;
      }>;
    }>;
  } | null;
};

export type UpdatePortfolioPricingConfigurationMutationVariables = Exact<{
  portfolioId: Scalars['ID']['input'];
  request: PricingConfigurationInput;
}>;

export type UpdatePortfolioPricingConfigurationMutation = {
  __typename?: 'Mutation';
  updatePortfolioPricingConfiguration?: {
    __typename?: 'ConfigValidationResult';
    resultsPerInstrument: Array<{
      __typename?: 'ConfigInstrumentValidationResult';
      instrumentId: string;
      isValid: boolean;
      preventsTrading: boolean;
      preventsMarketData: boolean;
      errors: Array<{
        __typename?: 'ConfigValidationError';
        errorMessage?: string | null;
        preventsTrading: boolean;
        preventsMarketData: boolean;
      }>;
    }>;
  } | null;
};

export type UpdateQuotingConfigurationMutationVariables = Exact<{
  clobUid: Scalars['String']['input'];
  request: QuotingConfigurationInput;
}>;

export type UpdateQuotingConfigurationMutation = {
  __typename?: 'Mutation';
  updateQuotingConfiguration?: {
    __typename?: 'QuotingConfigValidationResult';
    resultsPerInstrument: Array<{
      __typename?: 'ConfigInstrumentValidationResult';
      instrumentId: string;
      isValid: boolean;
      preventsTrading: boolean;
      preventsMarketData: boolean;
      errors: Array<{
        __typename?: 'ConfigValidationError';
        errorMessage?: string | null;
        preventsTrading: boolean;
        preventsMarketData: boolean;
      }>;
    }>;
  } | null;
};

export type UpdateUserDataMutationVariables = Exact<{
  request: UpdateUserDataInput;
}>;

export type UpdateUserDataMutation = {
  __typename?: 'Mutation';
  updateUserData: { __typename?: 'UserDataResponse'; data?: string | null };
};

export type UpdateVenueAccountMutationVariables = Exact<{
  request: UpdateVenueAccountInput;
}>;

export type UpdateVenueAccountMutation = {
  __typename?: 'Mutation';
  updateVenueAccount?: { __typename?: 'MutationSubmittedResponse'; status: string } | null;
};

export type UserDataQueryVariables = Exact<{ [key: string]: never }>;

export type UserDataQuery = {
  __typename?: 'Query';
  userData: { __typename?: 'UserDataResponse'; data?: string | null };
};

export type UserNamesQueryVariables = Exact<{ [key: string]: never }>;

export type UserNamesQuery = { __typename?: 'Query'; userNames: Array<string> };

export type VenueAccountDetailsQueryVariables = Exact<{
  request: VenueAccountDetailsInput;
}>;

export type VenueAccountDetailsQuery = {
  __typename?: 'Query';
  venueAccountDetails?: {
    __typename?: 'VenueAccountDetailsResponse';
    venueName: string;
    venueAccountId: string;
    venueAccountName: string;
    scopes: Array<Scope>;
    keyValues?: Array<{ __typename?: 'KeyValue'; key: string; value: string } | null> | null;
  } | null;
};

export type VenueAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type VenueAccountsQuery = {
  __typename?: 'Query';
  venueAccounts?: Array<{
    __typename?: 'VenueAccountsPerVenue';
    venue?: string | null;
    venueAccounts?: Array<{
      __typename?: 'VenueAccount';
      venueAccountId: string;
      venueAccountName: string;
      deactivatedAt?: string | null;
      scopes: Array<Scope>;
    } | null> | null;
  } | null> | null;
};

export type VenueCapabilitiesQueryVariables = Exact<{ [key: string]: never }>;

export type VenueCapabilitiesQuery = {
  __typename?: 'Query';
  venueCapabilities?: Array<{
    __typename?: 'VenueCapabilitiesResponse';
    venue: string;
    capabilities?: {
      __typename?: 'DetailedCapabilities';
      tradingCapabilities?: {
        __typename?: 'TradingCapabilities';
        supportedOrderTypes?: Array<OrderType | null> | null;
        supportedTIFs?: Array<{
          __typename?: 'TifsPerOrderType';
          key?: OrderType | null;
          values: Array<Tif>;
        } | null> | null;
        supportedOrderRequests?: Array<{
          __typename?: 'OrderRequestsPerOrderType';
          key?: OrderType | null;
          values: Array<OrderRequest>;
        } | null> | null;
      } | null;
    } | null;
  } | null> | null;
};

export type VenuesQueryVariables = Exact<{
  venueType?: InputMaybe<VenueType>;
}>;

export type VenuesQuery = {
  __typename?: 'Query';
  venues: Array<{ __typename?: 'Venue'; name: string; venueType: VenueType }>;
};

export type WalletAccountSearchQueryVariables = Exact<{
  search?: InputMaybe<WalletAccountSearchInput>;
}>;

export type WalletAccountSearchQuery = {
  __typename?: 'Query';
  walletAccountSearch: {
    __typename?: 'WalletAccountConnection';
    edges: Array<{
      __typename?: 'WalletAccountEdge';
      cursor: string;
      node: {
        __typename?: 'WalletAccountResponse';
        id: string;
        name: string;
        createdAt?: string | null;
        scopes: Array<Scope>;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean };
  };
};

export type WalletAccountContentFragment = {
  __typename?: 'WalletAccountResponse';
  id: string;
  name: string;
  createdAt?: string | null;
  scopes: Array<Scope>;
};

export const OrderBookContentFragmentDoc = gql`
  fragment OrderBookContent on OrderBook {
    price
    amount
    count
  }
`;
export const InstrumentContentFragmentDoc = gql`
  fragment InstrumentContent on InstrumentResponse {
    baseInstrument {
      assetClass
      symbol
      description
      quoteCurrency
      inverseContract
      venueType
      venueName
    }
    forexSpotProperties {
      baseCurrency
    }
    tradingConstraints {
      minQty
      minQuoteQty
      maxQty
      maxQuoteQty
      qtyIncr
      minQuoteQty
      maxQuoteQty
      quoteQtyIncr
      minPrice
      maxPrice
      priceIncr
      minNotional
      contractSize
      tradeable
    }
    instrumentIdentifiers {
      adapterTicker
      instrumentId
      tradingViewId
      venueTradingViewId
    }
    archivedAt
    createdAt
  }
`;
export const VenueAccountDescContentFragmentDoc = gql`
  fragment VenueAccountDescContent on VenueAccountDesc {
    id
    name
  }
`;
export const OrderStateContentFragmentDoc = gql`
  fragment OrderStateContent on OrderStateResponse {
    orderStateId
    orderId
    clientId
    clOrderId
    expirationDateTime
    origClOrderId
    portfolioId
    portfolioName
    counterPortfolioId
    counterPortfolioName
    orderStatus
    orderQty
    currency
    limitPrice
    stopPrice
    tif
    filledQty
    remainingQty
    lastQty
    avgPrice
    lastPrice
    reason
    rootOrderId
    lastRequestResult
    side
    instrument {
      ...InstrumentContent
    }
    symbol
    assetClass
    venue
    venueTimestamp
    createdAt
    updatedAt
    orderCategory
    parentOrderId
    venueAccountDescs {
      ...VenueAccountDescContent
    }
  }
  ${InstrumentContentFragmentDoc}
  ${VenueAccountDescContentFragmentDoc}
`;
export const PricingConfigurationContentFragmentDoc = gql`
  fragment PricingConfigurationContent on PricingConfiguration {
    venueAccounts {
      id
      name
    }
    markup
  }
`;
export const ExecutionConfigurationContentFragmentDoc = gql`
  fragment ExecutionConfigurationContent on ExecutionConfiguration {
    tradingMode
    counterPortfolioId
    counterPortfolioName
    fixedFee
    percentageFee
    minFee
    minFeeCurrency
    fixedFeeCurrency
    percentageFeeCurrency
    minFeeCurrency
    percentageFeeCurrencyType
    agencyTradingAccount
    agencyTradingAccountName
    executionMode
    sorTradingAccountDescs {
      id
      name
    }
    sorTarget {
      assetClass
      symbol
    }
    agencyTargetInstrument {
      ...InstrumentContent
    }
    chargeExchangeFee
    discloseTradingVenue
  }
  ${InstrumentContentFragmentDoc}
`;
export const InstrumentPricingConfigurationContentFragmentDoc = gql`
  fragment InstrumentPricingConfigurationContent on InstrumentPricingConfiguration {
    pricingSource {
      venueAccount
      venueAccountName
      instrument {
        ...InstrumentContent
      }
    }
    markup
  }
  ${InstrumentContentFragmentDoc}
`;
export const HedgingConfigurationContentFragmentDoc = gql`
  fragment HedgingConfigurationContent on HedgingConfiguration {
    autoHedging
    targetAccountId
    targetAccountName
    thresholdConfiguration {
      asset
      highThreshold
      lowThreshold
      targetExposure
      hedgeInstrument {
        ...InstrumentContent
      }
    }
  }
  ${InstrumentContentFragmentDoc}
`;
export const PortfolioContentFragmentDoc = gql`
  fragment PortfolioContent on PortfolioResponse {
    id
    name
    createdAt
    portfolioType
    portfolioCurrency
    scopes
    tags {
      key
      value
    }
    archivedAt
  }
`;
export const CounterpartyContentFragmentDoc = gql`
  fragment CounterpartyContent on Counterparty {
    portfolioId
    portfolioName
    venueAccount
    venueAccountName
    referenceType
    orderId
    price
    volume
  }
`;
export const MarketQuoteContentFragmentDoc = gql`
  fragment MarketQuoteContent on MarketQuote {
    instrument {
      ...InstrumentContent
    }
    marketAskPrice
    marketBidPrice
    marketAskSize
    marketBidSize
    markupAskPrice
    markupBidPrice
    timestamp
  }
  ${InstrumentContentFragmentDoc}
`;
export const InstrumentValidationResultFragmentDoc = gql`
  fragment InstrumentValidationResult on ConfigInstrumentValidationResult {
    instrumentId
    isValid
    preventsTrading
    preventsMarketData
    errors {
      errorMessage
      preventsTrading
      preventsMarketData
    }
  }
`;
export const WalletAccountContentFragmentDoc = gql`
  fragment WalletAccountContent on WalletAccountResponse {
    id
    name
    createdAt
    scopes
  }
`;
export const ActivateVenueAccountDocument = gql`
  mutation ActivateVenueAccount($request: ActivateVenueAccountInput!) {
    activateVenueAccount(request: $request) {
      status
    }
  }
`;
export type ActivateVenueAccountMutationFn = Apollo.MutationFunction<
  ActivateVenueAccountMutation,
  ActivateVenueAccountMutationVariables
>;

/**
 * __useActivateVenueAccountMutation__
 *
 * To run a mutation, you first call `useActivateVenueAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateVenueAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateVenueAccountMutation, { data, loading, error }] = useActivateVenueAccountMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useActivateVenueAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateVenueAccountMutation,
    ActivateVenueAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateVenueAccountMutation, ActivateVenueAccountMutationVariables>(
    ActivateVenueAccountDocument,
    options,
  );
}
export type ActivateVenueAccountMutationHookResult = ReturnType<
  typeof useActivateVenueAccountMutation
>;
export type ActivateVenueAccountMutationResult =
  Apollo.MutationResult<ActivateVenueAccountMutation>;
export type ActivateVenueAccountMutationOptions = Apollo.BaseMutationOptions<
  ActivateVenueAccountMutation,
  ActivateVenueAccountMutationVariables
>;
export const AddGroupPermissionsDocument = gql`
  mutation AddGroupPermissions($request: AddOrRemoveGroupPermissionsInput!) {
    addGroupPermissions(request: $request) {
      clientId
    }
  }
`;
export type AddGroupPermissionsMutationFn = Apollo.MutationFunction<
  AddGroupPermissionsMutation,
  AddGroupPermissionsMutationVariables
>;

/**
 * __useAddGroupPermissionsMutation__
 *
 * To run a mutation, you first call `useAddGroupPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupPermissionsMutation, { data, loading, error }] = useAddGroupPermissionsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddGroupPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupPermissionsMutation,
    AddGroupPermissionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddGroupPermissionsMutation, AddGroupPermissionsMutationVariables>(
    AddGroupPermissionsDocument,
    options,
  );
}
export type AddGroupPermissionsMutationHookResult = ReturnType<
  typeof useAddGroupPermissionsMutation
>;
export type AddGroupPermissionsMutationResult = Apollo.MutationResult<AddGroupPermissionsMutation>;
export type AddGroupPermissionsMutationOptions = Apollo.BaseMutationOptions<
  AddGroupPermissionsMutation,
  AddGroupPermissionsMutationVariables
>;
export const AddTransactionDocument = gql`
  mutation AddTransaction($request: TransactionInput!) {
    addTransaction(input: $request) {
      status
    }
  }
`;
export type AddTransactionMutationFn = Apollo.MutationFunction<
  AddTransactionMutation,
  AddTransactionMutationVariables
>;

/**
 * __useAddTransactionMutation__
 *
 * To run a mutation, you first call `useAddTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTransactionMutation, { data, loading, error }] = useAddTransactionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTransactionMutation, AddTransactionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTransactionMutation, AddTransactionMutationVariables>(
    AddTransactionDocument,
    options,
  );
}
export type AddTransactionMutationHookResult = ReturnType<typeof useAddTransactionMutation>;
export type AddTransactionMutationResult = Apollo.MutationResult<AddTransactionMutation>;
export type AddTransactionMutationOptions = Apollo.BaseMutationOptions<
  AddTransactionMutation,
  AddTransactionMutationVariables
>;
export const AddUserPermissionsDocument = gql`
  mutation AddUserPermissions($request: AddOrRemoveUserPermissionsInput!) {
    addUserPermissions(request: $request) {
      clientId
    }
  }
`;
export type AddUserPermissionsMutationFn = Apollo.MutationFunction<
  AddUserPermissionsMutation,
  AddUserPermissionsMutationVariables
>;

/**
 * __useAddUserPermissionsMutation__
 *
 * To run a mutation, you first call `useAddUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserPermissionsMutation, { data, loading, error }] = useAddUserPermissionsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddUserPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserPermissionsMutation,
    AddUserPermissionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUserPermissionsMutation, AddUserPermissionsMutationVariables>(
    AddUserPermissionsDocument,
    options,
  );
}
export type AddUserPermissionsMutationHookResult = ReturnType<typeof useAddUserPermissionsMutation>;
export type AddUserPermissionsMutationResult = Apollo.MutationResult<AddUserPermissionsMutation>;
export type AddUserPermissionsMutationOptions = Apollo.BaseMutationOptions<
  AddUserPermissionsMutation,
  AddUserPermissionsMutationVariables
>;
export const ApiKeysDocument = gql`
  query ApiKeys {
    apiKeys {
      name
      id
      createdAt
      status
    }
  }
`;

/**
 * __useApiKeysQuery__
 *
 * To run a query within a React component, call `useApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiKeysQuery(
  baseOptions?: Apollo.QueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options);
}
export function useApiKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options);
}
export type ApiKeysQueryHookResult = ReturnType<typeof useApiKeysQuery>;
export type ApiKeysLazyQueryHookResult = ReturnType<typeof useApiKeysLazyQuery>;
export type ApiKeysQueryResult = Apollo.QueryResult<ApiKeysQuery, ApiKeysQueryVariables>;
export const CancelOrderDocument = gql`
  mutation CancelOrder($request: OrderCancelRequestInput!) {
    cancelOrder(request: $request) {
      clientId
    }
  }
`;
export type CancelOrderMutationFn = Apollo.MutationFunction<
  CancelOrderMutation,
  CancelOrderMutationVariables
>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(
    CancelOrderDocument,
    options,
  );
}
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelOrderMutation,
  CancelOrderMutationVariables
>;
export const CancelRejectDocument = gql`
  subscription CancelReject {
    cancelReject {
      result
      cancelRejectReason
      cancelRejectResponseTo
      clOrderId
      clientId
      orderStatus
      origClOrderId
      origOrderId
    }
  }
`;

/**
 * __useCancelRejectSubscription__
 *
 * To run a query within a React component, call `useCancelRejectSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCancelRejectSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelRejectSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCancelRejectSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CancelRejectSubscription,
    CancelRejectSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<CancelRejectSubscription, CancelRejectSubscriptionVariables>(
    CancelRejectDocument,
    options,
  );
}
export type CancelRejectSubscriptionHookResult = ReturnType<typeof useCancelRejectSubscription>;
export type CancelRejectSubscriptionResult = Apollo.SubscriptionResult<CancelRejectSubscription>;
export const CancelReplaceOrderDocument = gql`
  mutation CancelReplaceOrder($request: OrderCancelReplaceRequestInput!) {
    cancelReplaceOrder(request: $request) {
      clientId
    }
  }
`;
export type CancelReplaceOrderMutationFn = Apollo.MutationFunction<
  CancelReplaceOrderMutation,
  CancelReplaceOrderMutationVariables
>;

/**
 * __useCancelReplaceOrderMutation__
 *
 * To run a mutation, you first call `useCancelReplaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReplaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReplaceOrderMutation, { data, loading, error }] = useCancelReplaceOrderMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCancelReplaceOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelReplaceOrderMutation,
    CancelReplaceOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelReplaceOrderMutation, CancelReplaceOrderMutationVariables>(
    CancelReplaceOrderDocument,
    options,
  );
}
export type CancelReplaceOrderMutationHookResult = ReturnType<typeof useCancelReplaceOrderMutation>;
export type CancelReplaceOrderMutationResult = Apollo.MutationResult<CancelReplaceOrderMutation>;
export type CancelReplaceOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelReplaceOrderMutation,
  CancelReplaceOrderMutationVariables
>;
export const ConnectorTemplateDocument = gql`
  query ConnectorTemplate($venue: String) {
    connectorTemplate(venue: $venue) {
      type
      secret
      enumValues
      propertyKey
      defaultValue
      required
      description
    }
  }
`;

/**
 * __useConnectorTemplateQuery__
 *
 * To run a query within a React component, call `useConnectorTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectorTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectorTemplateQuery({
 *   variables: {
 *      venue: // value for 'venue'
 *   },
 * });
 */
export function useConnectorTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<ConnectorTemplateQuery, ConnectorTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConnectorTemplateQuery, ConnectorTemplateQueryVariables>(
    ConnectorTemplateDocument,
    options,
  );
}
export function useConnectorTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectorTemplateQuery,
    ConnectorTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConnectorTemplateQuery, ConnectorTemplateQueryVariables>(
    ConnectorTemplateDocument,
    options,
  );
}
export type ConnectorTemplateQueryHookResult = ReturnType<typeof useConnectorTemplateQuery>;
export type ConnectorTemplateLazyQueryHookResult = ReturnType<typeof useConnectorTemplateLazyQuery>;
export type ConnectorTemplateQueryResult = Apollo.QueryResult<
  ConnectorTemplateQuery,
  ConnectorTemplateQueryVariables
>;
export const ConversionSourceVenueAccountDocument = gql`
  query ConversionSourceVenueAccount {
    conversionSourceVenueAccounts {
      venueName
      venueAccounts {
        venueAccount
        venueAccountName
      }
    }
  }
`;

/**
 * __useConversionSourceVenueAccountQuery__
 *
 * To run a query within a React component, call `useConversionSourceVenueAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversionSourceVenueAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversionSourceVenueAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useConversionSourceVenueAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ConversionSourceVenueAccountQuery,
    ConversionSourceVenueAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConversionSourceVenueAccountQuery,
    ConversionSourceVenueAccountQueryVariables
  >(ConversionSourceVenueAccountDocument, options);
}
export function useConversionSourceVenueAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConversionSourceVenueAccountQuery,
    ConversionSourceVenueAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConversionSourceVenueAccountQuery,
    ConversionSourceVenueAccountQueryVariables
  >(ConversionSourceVenueAccountDocument, options);
}
export type ConversionSourceVenueAccountQueryHookResult = ReturnType<
  typeof useConversionSourceVenueAccountQuery
>;
export type ConversionSourceVenueAccountLazyQueryHookResult = ReturnType<
  typeof useConversionSourceVenueAccountLazyQuery
>;
export type ConversionSourceVenueAccountQueryResult = Apollo.QueryResult<
  ConversionSourceVenueAccountQuery,
  ConversionSourceVenueAccountQueryVariables
>;
export const ConversionSourcesDocument = gql`
  query ConversionSources {
    conversionSources {
      venueAccount
      venueAccountName
      priority
    }
  }
`;

/**
 * __useConversionSourcesQuery__
 *
 * To run a query within a React component, call `useConversionSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversionSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversionSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useConversionSourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<ConversionSourcesQuery, ConversionSourcesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConversionSourcesQuery, ConversionSourcesQueryVariables>(
    ConversionSourcesDocument,
    options,
  );
}
export function useConversionSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConversionSourcesQuery,
    ConversionSourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConversionSourcesQuery, ConversionSourcesQueryVariables>(
    ConversionSourcesDocument,
    options,
  );
}
export type ConversionSourcesQueryHookResult = ReturnType<typeof useConversionSourcesQuery>;
export type ConversionSourcesLazyQueryHookResult = ReturnType<typeof useConversionSourcesLazyQuery>;
export type ConversionSourcesQueryResult = Apollo.QueryResult<
  ConversionSourcesQuery,
  ConversionSourcesQueryVariables
>;
export const CreateApiKeyDocument = gql`
  mutation CreateApiKey($name: String) {
    createApiKey(name: $name) {
      id
      name
      createdAt
      secret
      status
    }
  }
`;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables
>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(
    CreateApiKeyDocument,
    options,
  );
}
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables
>;
export const CreateConversionSourceDocument = gql`
  mutation CreateConversionSource($request: CreateConversionSourceInput!) {
    createConversionSource(request: $request) {
      status
    }
  }
`;
export type CreateConversionSourceMutationFn = Apollo.MutationFunction<
  CreateConversionSourceMutation,
  CreateConversionSourceMutationVariables
>;

/**
 * __useCreateConversionSourceMutation__
 *
 * To run a mutation, you first call `useCreateConversionSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversionSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversionSourceMutation, { data, loading, error }] = useCreateConversionSourceMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateConversionSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConversionSourceMutation,
    CreateConversionSourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConversionSourceMutation,
    CreateConversionSourceMutationVariables
  >(CreateConversionSourceDocument, options);
}
export type CreateConversionSourceMutationHookResult = ReturnType<
  typeof useCreateConversionSourceMutation
>;
export type CreateConversionSourceMutationResult =
  Apollo.MutationResult<CreateConversionSourceMutation>;
export type CreateConversionSourceMutationOptions = Apollo.BaseMutationOptions<
  CreateConversionSourceMutation,
  CreateConversionSourceMutationVariables
>;
export const CreateInstrumentDocument = gql`
  mutation CreateInstrument($request: CreateInstrumentInput!) {
    createInstrument(request: $request) {
      status
    }
  }
`;
export type CreateInstrumentMutationFn = Apollo.MutationFunction<
  CreateInstrumentMutation,
  CreateInstrumentMutationVariables
>;

/**
 * __useCreateInstrumentMutation__
 *
 * To run a mutation, you first call `useCreateInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstrumentMutation, { data, loading, error }] = useCreateInstrumentMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateInstrumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInstrumentMutation,
    CreateInstrumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInstrumentMutation, CreateInstrumentMutationVariables>(
    CreateInstrumentDocument,
    options,
  );
}
export type CreateInstrumentMutationHookResult = ReturnType<typeof useCreateInstrumentMutation>;
export type CreateInstrumentMutationResult = Apollo.MutationResult<CreateInstrumentMutation>;
export type CreateInstrumentMutationOptions = Apollo.BaseMutationOptions<
  CreateInstrumentMutation,
  CreateInstrumentMutationVariables
>;
export const CreateInternalExchangeConfigurationDocument = gql`
  mutation CreateInternalExchangeConfiguration($request: InternalExchangeConfigurationInput!) {
    createInternalExchangeConfiguration(request: $request) {
      status
    }
  }
`;
export type CreateInternalExchangeConfigurationMutationFn = Apollo.MutationFunction<
  CreateInternalExchangeConfigurationMutation,
  CreateInternalExchangeConfigurationMutationVariables
>;

/**
 * __useCreateInternalExchangeConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateInternalExchangeConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInternalExchangeConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInternalExchangeConfigurationMutation, { data, loading, error }] = useCreateInternalExchangeConfigurationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateInternalExchangeConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInternalExchangeConfigurationMutation,
    CreateInternalExchangeConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInternalExchangeConfigurationMutation,
    CreateInternalExchangeConfigurationMutationVariables
  >(CreateInternalExchangeConfigurationDocument, options);
}
export type CreateInternalExchangeConfigurationMutationHookResult = ReturnType<
  typeof useCreateInternalExchangeConfigurationMutation
>;
export type CreateInternalExchangeConfigurationMutationResult =
  Apollo.MutationResult<CreateInternalExchangeConfigurationMutation>;
export type CreateInternalExchangeConfigurationMutationOptions = Apollo.BaseMutationOptions<
  CreateInternalExchangeConfigurationMutation,
  CreateInternalExchangeConfigurationMutationVariables
>;
export const CreatePortfolioDocument = gql`
  mutation CreatePortfolio($request: CreatePortfolioInput!) {
    createPortfolio(request: $request) {
      status
    }
  }
`;
export type CreatePortfolioMutationFn = Apollo.MutationFunction<
  CreatePortfolioMutation,
  CreatePortfolioMutationVariables
>;

/**
 * __useCreatePortfolioMutation__
 *
 * To run a mutation, you first call `useCreatePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortfolioMutation, { data, loading, error }] = useCreatePortfolioMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreatePortfolioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePortfolioMutation,
    CreatePortfolioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePortfolioMutation, CreatePortfolioMutationVariables>(
    CreatePortfolioDocument,
    options,
  );
}
export type CreatePortfolioMutationHookResult = ReturnType<typeof useCreatePortfolioMutation>;
export type CreatePortfolioMutationResult = Apollo.MutationResult<CreatePortfolioMutation>;
export type CreatePortfolioMutationOptions = Apollo.BaseMutationOptions<
  CreatePortfolioMutation,
  CreatePortfolioMutationVariables
>;
export const CreateQuotingConfigurationDocument = gql`
  mutation CreateQuotingConfiguration($request: QuotingConfigurationInput!) {
    createQuotingConfiguration(request: $request) {
      resultsPerInstrument {
        ...InstrumentValidationResult
      }
    }
  }
  ${InstrumentValidationResultFragmentDoc}
`;
export type CreateQuotingConfigurationMutationFn = Apollo.MutationFunction<
  CreateQuotingConfigurationMutation,
  CreateQuotingConfigurationMutationVariables
>;

/**
 * __useCreateQuotingConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateQuotingConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuotingConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuotingConfigurationMutation, { data, loading, error }] = useCreateQuotingConfigurationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateQuotingConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuotingConfigurationMutation,
    CreateQuotingConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateQuotingConfigurationMutation,
    CreateQuotingConfigurationMutationVariables
  >(CreateQuotingConfigurationDocument, options);
}
export type CreateQuotingConfigurationMutationHookResult = ReturnType<
  typeof useCreateQuotingConfigurationMutation
>;
export type CreateQuotingConfigurationMutationResult =
  Apollo.MutationResult<CreateQuotingConfigurationMutation>;
export type CreateQuotingConfigurationMutationOptions = Apollo.BaseMutationOptions<
  CreateQuotingConfigurationMutation,
  CreateQuotingConfigurationMutationVariables
>;
export const CreateRateSubscriptionDocument = gql`
  mutation CreateRateSubscription($request: CreateRateSubscriptionInput!) {
    createRateSubscription(request: $request) {
      status
    }
  }
`;
export type CreateRateSubscriptionMutationFn = Apollo.MutationFunction<
  CreateRateSubscriptionMutation,
  CreateRateSubscriptionMutationVariables
>;

/**
 * __useCreateRateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateRateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRateSubscriptionMutation, { data, loading, error }] = useCreateRateSubscriptionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateRateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRateSubscriptionMutation,
    CreateRateSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRateSubscriptionMutation,
    CreateRateSubscriptionMutationVariables
  >(CreateRateSubscriptionDocument, options);
}
export type CreateRateSubscriptionMutationHookResult = ReturnType<
  typeof useCreateRateSubscriptionMutation
>;
export type CreateRateSubscriptionMutationResult =
  Apollo.MutationResult<CreateRateSubscriptionMutation>;
export type CreateRateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateRateSubscriptionMutation,
  CreateRateSubscriptionMutationVariables
>;
export const CreateVenueAccountDocument = gql`
  mutation CreateVenueAccount($request: CreateVenueAccountInput!) {
    createVenueAccount(request: $request) {
      status
    }
  }
`;
export type CreateVenueAccountMutationFn = Apollo.MutationFunction<
  CreateVenueAccountMutation,
  CreateVenueAccountMutationVariables
>;

/**
 * __useCreateVenueAccountMutation__
 *
 * To run a mutation, you first call `useCreateVenueAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVenueAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVenueAccountMutation, { data, loading, error }] = useCreateVenueAccountMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateVenueAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVenueAccountMutation,
    CreateVenueAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVenueAccountMutation, CreateVenueAccountMutationVariables>(
    CreateVenueAccountDocument,
    options,
  );
}
export type CreateVenueAccountMutationHookResult = ReturnType<typeof useCreateVenueAccountMutation>;
export type CreateVenueAccountMutationResult = Apollo.MutationResult<CreateVenueAccountMutation>;
export type CreateVenueAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateVenueAccountMutation,
  CreateVenueAccountMutationVariables
>;
export const CreateWalletAccountDocument = gql`
  mutation CreateWalletAccount($request: CreateWalletAccountInput!) {
    createWalletAccount(request: $request) {
      status
    }
  }
`;
export type CreateWalletAccountMutationFn = Apollo.MutationFunction<
  CreateWalletAccountMutation,
  CreateWalletAccountMutationVariables
>;

/**
 * __useCreateWalletAccountMutation__
 *
 * To run a mutation, you first call `useCreateWalletAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWalletAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWalletAccountMutation, { data, loading, error }] = useCreateWalletAccountMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateWalletAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWalletAccountMutation,
    CreateWalletAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWalletAccountMutation, CreateWalletAccountMutationVariables>(
    CreateWalletAccountDocument,
    options,
  );
}
export type CreateWalletAccountMutationHookResult = ReturnType<
  typeof useCreateWalletAccountMutation
>;
export type CreateWalletAccountMutationResult = Apollo.MutationResult<CreateWalletAccountMutation>;
export type CreateWalletAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateWalletAccountMutation,
  CreateWalletAccountMutationVariables
>;
export const CurrencySearchDocument = gql`
  query CurrencySearch($search: CurrencySearchInput) {
    currencySearch(search: $search) {
      symbol
      type
      precision
      displayPrecision
    }
  }
`;

/**
 * __useCurrencySearchQuery__
 *
 * To run a query within a React component, call `useCurrencySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrencySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrencySearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCurrencySearchQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrencySearchQuery, CurrencySearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrencySearchQuery, CurrencySearchQueryVariables>(
    CurrencySearchDocument,
    options,
  );
}
export function useCurrencySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrencySearchQuery, CurrencySearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrencySearchQuery, CurrencySearchQueryVariables>(
    CurrencySearchDocument,
    options,
  );
}
export type CurrencySearchQueryHookResult = ReturnType<typeof useCurrencySearchQuery>;
export type CurrencySearchLazyQueryHookResult = ReturnType<typeof useCurrencySearchLazyQuery>;
export type CurrencySearchQueryResult = Apollo.QueryResult<
  CurrencySearchQuery,
  CurrencySearchQueryVariables
>;
export const DeactivateApiKeyDocument = gql`
  mutation DeactivateApiKey($id: String!) {
    deactivateApiKey(id: $id) {
      clientId
    }
  }
`;
export type DeactivateApiKeyMutationFn = Apollo.MutationFunction<
  DeactivateApiKeyMutation,
  DeactivateApiKeyMutationVariables
>;

/**
 * __useDeactivateApiKeyMutation__
 *
 * To run a mutation, you first call `useDeactivateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateApiKeyMutation, { data, loading, error }] = useDeactivateApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateApiKeyMutation,
    DeactivateApiKeyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeactivateApiKeyMutation, DeactivateApiKeyMutationVariables>(
    DeactivateApiKeyDocument,
    options,
  );
}
export type DeactivateApiKeyMutationHookResult = ReturnType<typeof useDeactivateApiKeyMutation>;
export type DeactivateApiKeyMutationResult = Apollo.MutationResult<DeactivateApiKeyMutation>;
export type DeactivateApiKeyMutationOptions = Apollo.BaseMutationOptions<
  DeactivateApiKeyMutation,
  DeactivateApiKeyMutationVariables
>;
export const DeactivateVenueAccountDocument = gql`
  mutation DeactivateVenueAccount($request: DeactivateVenueAccountInput!) {
    deactivateVenueAccount(request: $request) {
      status
    }
  }
`;
export type DeactivateVenueAccountMutationFn = Apollo.MutationFunction<
  DeactivateVenueAccountMutation,
  DeactivateVenueAccountMutationVariables
>;

/**
 * __useDeactivateVenueAccountMutation__
 *
 * To run a mutation, you first call `useDeactivateVenueAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateVenueAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateVenueAccountMutation, { data, loading, error }] = useDeactivateVenueAccountMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDeactivateVenueAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateVenueAccountMutation,
    DeactivateVenueAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateVenueAccountMutation,
    DeactivateVenueAccountMutationVariables
  >(DeactivateVenueAccountDocument, options);
}
export type DeactivateVenueAccountMutationHookResult = ReturnType<
  typeof useDeactivateVenueAccountMutation
>;
export type DeactivateVenueAccountMutationResult =
  Apollo.MutationResult<DeactivateVenueAccountMutation>;
export type DeactivateVenueAccountMutationOptions = Apollo.BaseMutationOptions<
  DeactivateVenueAccountMutation,
  DeactivateVenueAccountMutationVariables
>;
export const DeleteConversionSourceDocument = gql`
  mutation DeleteConversionSource($venueAccount: String!) {
    deleteConversionSource(venueAccount: $venueAccount) {
      status
    }
  }
`;
export type DeleteConversionSourceMutationFn = Apollo.MutationFunction<
  DeleteConversionSourceMutation,
  DeleteConversionSourceMutationVariables
>;

/**
 * __useDeleteConversionSourceMutation__
 *
 * To run a mutation, you first call `useDeleteConversionSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConversionSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConversionSourceMutation, { data, loading, error }] = useDeleteConversionSourceMutation({
 *   variables: {
 *      venueAccount: // value for 'venueAccount'
 *   },
 * });
 */
export function useDeleteConversionSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteConversionSourceMutation,
    DeleteConversionSourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteConversionSourceMutation,
    DeleteConversionSourceMutationVariables
  >(DeleteConversionSourceDocument, options);
}
export type DeleteConversionSourceMutationHookResult = ReturnType<
  typeof useDeleteConversionSourceMutation
>;
export type DeleteConversionSourceMutationResult =
  Apollo.MutationResult<DeleteConversionSourceMutation>;
export type DeleteConversionSourceMutationOptions = Apollo.BaseMutationOptions<
  DeleteConversionSourceMutation,
  DeleteConversionSourceMutationVariables
>;
export const DeletePreTradeCheckDocument = gql`
  mutation DeletePreTradeCheck($request: String!) {
    deletePreTradeCheck(preTradeCheckId: $request) {
      clientId
    }
  }
`;
export type DeletePreTradeCheckMutationFn = Apollo.MutationFunction<
  DeletePreTradeCheckMutation,
  DeletePreTradeCheckMutationVariables
>;

/**
 * __useDeletePreTradeCheckMutation__
 *
 * To run a mutation, you first call `useDeletePreTradeCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePreTradeCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePreTradeCheckMutation, { data, loading, error }] = useDeletePreTradeCheckMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDeletePreTradeCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePreTradeCheckMutation,
    DeletePreTradeCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePreTradeCheckMutation, DeletePreTradeCheckMutationVariables>(
    DeletePreTradeCheckDocument,
    options,
  );
}
export type DeletePreTradeCheckMutationHookResult = ReturnType<
  typeof useDeletePreTradeCheckMutation
>;
export type DeletePreTradeCheckMutationResult = Apollo.MutationResult<DeletePreTradeCheckMutation>;
export type DeletePreTradeCheckMutationOptions = Apollo.BaseMutationOptions<
  DeletePreTradeCheckMutation,
  DeletePreTradeCheckMutationVariables
>;
export const DeleteQuotingConfigurationDocument = gql`
  mutation DeleteQuotingConfiguration($clobUid: String!) {
    deleteQuotingConfiguration(clobUid: $clobUid)
  }
`;
export type DeleteQuotingConfigurationMutationFn = Apollo.MutationFunction<
  DeleteQuotingConfigurationMutation,
  DeleteQuotingConfigurationMutationVariables
>;

/**
 * __useDeleteQuotingConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteQuotingConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuotingConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuotingConfigurationMutation, { data, loading, error }] = useDeleteQuotingConfigurationMutation({
 *   variables: {
 *      clobUid: // value for 'clobUid'
 *   },
 * });
 */
export function useDeleteQuotingConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteQuotingConfigurationMutation,
    DeleteQuotingConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteQuotingConfigurationMutation,
    DeleteQuotingConfigurationMutationVariables
  >(DeleteQuotingConfigurationDocument, options);
}
export type DeleteQuotingConfigurationMutationHookResult = ReturnType<
  typeof useDeleteQuotingConfigurationMutation
>;
export type DeleteQuotingConfigurationMutationResult =
  Apollo.MutationResult<DeleteQuotingConfigurationMutation>;
export type DeleteQuotingConfigurationMutationOptions = Apollo.BaseMutationOptions<
  DeleteQuotingConfigurationMutation,
  DeleteQuotingConfigurationMutationVariables
>;
export const DeleteRateSubscriptionDocument = gql`
  mutation DeleteRateSubscription($request: DeleteRateSubscriptionInput!) {
    deleteRateSubscription(request: $request) {
      status
    }
  }
`;
export type DeleteRateSubscriptionMutationFn = Apollo.MutationFunction<
  DeleteRateSubscriptionMutation,
  DeleteRateSubscriptionMutationVariables
>;

/**
 * __useDeleteRateSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteRateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRateSubscriptionMutation, { data, loading, error }] = useDeleteRateSubscriptionMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDeleteRateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRateSubscriptionMutation,
    DeleteRateSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRateSubscriptionMutation,
    DeleteRateSubscriptionMutationVariables
  >(DeleteRateSubscriptionDocument, options);
}
export type DeleteRateSubscriptionMutationHookResult = ReturnType<
  typeof useDeleteRateSubscriptionMutation
>;
export type DeleteRateSubscriptionMutationResult =
  Apollo.MutationResult<DeleteRateSubscriptionMutation>;
export type DeleteRateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  DeleteRateSubscriptionMutation,
  DeleteRateSubscriptionMutationVariables
>;
export const EffectiveVenueAccountsDocument = gql`
  query EffectiveVenueAccounts($portfolioId: String!, $instrumentId: String!) {
    effectiveVenueAccounts(portfolioId: $portfolioId, instrumentId: $instrumentId) {
      venueAccounts
      isSor
    }
  }
`;

/**
 * __useEffectiveVenueAccountsQuery__
 *
 * To run a query within a React component, call `useEffectiveVenueAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEffectiveVenueAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEffectiveVenueAccountsQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      instrumentId: // value for 'instrumentId'
 *   },
 * });
 */
export function useEffectiveVenueAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EffectiveVenueAccountsQuery,
    EffectiveVenueAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EffectiveVenueAccountsQuery, EffectiveVenueAccountsQueryVariables>(
    EffectiveVenueAccountsDocument,
    options,
  );
}
export function useEffectiveVenueAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EffectiveVenueAccountsQuery,
    EffectiveVenueAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EffectiveVenueAccountsQuery, EffectiveVenueAccountsQueryVariables>(
    EffectiveVenueAccountsDocument,
    options,
  );
}
export type EffectiveVenueAccountsQueryHookResult = ReturnType<
  typeof useEffectiveVenueAccountsQuery
>;
export type EffectiveVenueAccountsLazyQueryHookResult = ReturnType<
  typeof useEffectiveVenueAccountsLazyQuery
>;
export type EffectiveVenueAccountsQueryResult = Apollo.QueryResult<
  EffectiveVenueAccountsQuery,
  EffectiveVenueAccountsQueryVariables
>;
export const EntitlementsDocument = gql`
  query Entitlements {
    entitlements
  }
`;

/**
 * __useEntitlementsQuery__
 *
 * To run a query within a React component, call `useEntitlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntitlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntitlementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEntitlementsQuery(
  baseOptions?: Apollo.QueryHookOptions<EntitlementsQuery, EntitlementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EntitlementsQuery, EntitlementsQueryVariables>(
    EntitlementsDocument,
    options,
  );
}
export function useEntitlementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EntitlementsQuery, EntitlementsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EntitlementsQuery, EntitlementsQueryVariables>(
    EntitlementsDocument,
    options,
  );
}
export type EntitlementsQueryHookResult = ReturnType<typeof useEntitlementsQuery>;
export type EntitlementsLazyQueryHookResult = ReturnType<typeof useEntitlementsLazyQuery>;
export type EntitlementsQueryResult = Apollo.QueryResult<
  EntitlementsQuery,
  EntitlementsQueryVariables
>;
export const EventsLogsDocument = gql`
  subscription EventsLogs {
    eventsLogs {
      status
      clientId
      message
      timestamp
      eventType
      correlationObject
    }
  }
`;

/**
 * __useEventsLogsSubscription__
 *
 * To run a query within a React component, call `useEventsLogsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventsLogsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsLogsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useEventsLogsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    EventsLogsSubscription,
    EventsLogsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<EventsLogsSubscription, EventsLogsSubscriptionVariables>(
    EventsLogsDocument,
    options,
  );
}
export type EventsLogsSubscriptionHookResult = ReturnType<typeof useEventsLogsSubscription>;
export type EventsLogsSubscriptionResult = Apollo.SubscriptionResult<EventsLogsSubscription>;
export const GroupNamesDocument = gql`
  query GroupNames {
    groupNames
  }
`;

/**
 * __useGroupNamesQuery__
 *
 * To run a query within a React component, call `useGroupNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupNamesQuery, GroupNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupNamesQuery, GroupNamesQueryVariables>(GroupNamesDocument, options);
}
export function useGroupNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupNamesQuery, GroupNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupNamesQuery, GroupNamesQueryVariables>(
    GroupNamesDocument,
    options,
  );
}
export type GroupNamesQueryHookResult = ReturnType<typeof useGroupNamesQuery>;
export type GroupNamesLazyQueryHookResult = ReturnType<typeof useGroupNamesLazyQuery>;
export type GroupNamesQueryResult = Apollo.QueryResult<GroupNamesQuery, GroupNamesQueryVariables>;
export const InstrumentSearchDocument = gql`
  query InstrumentSearch($search: InstrumentsSearchInput!) {
    instrumentSearch(search: $search) {
      edges {
        node {
          baseInstrument {
            assetClass
            symbol
            description
            quoteCurrency
            inverseContract
            venueType
            venueName
          }
          forexSpotProperties {
            baseCurrency
          }
          tradingConstraints {
            minQty
            maxQty
            qtyIncr
            minQuoteQty
            maxQuoteQty
            quoteQtyIncr
            minPrice
            maxPrice
            priceIncr
            minNotional
            contractSize
            tradeable
          }
          instrumentIdentifiers {
            adapterTicker
            instrumentId
            tradingViewId
            venueTradingViewId
          }
          archivedAt
          createdAt
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

/**
 * __useInstrumentSearchQuery__
 *
 * To run a query within a React component, call `useInstrumentSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstrumentSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstrumentSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useInstrumentSearchQuery(
  baseOptions: Apollo.QueryHookOptions<InstrumentSearchQuery, InstrumentSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstrumentSearchQuery, InstrumentSearchQueryVariables>(
    InstrumentSearchDocument,
    options,
  );
}
export function useInstrumentSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstrumentSearchQuery, InstrumentSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstrumentSearchQuery, InstrumentSearchQueryVariables>(
    InstrumentSearchDocument,
    options,
  );
}
export type InstrumentSearchQueryHookResult = ReturnType<typeof useInstrumentSearchQuery>;
export type InstrumentSearchLazyQueryHookResult = ReturnType<typeof useInstrumentSearchLazyQuery>;
export type InstrumentSearchQueryResult = Apollo.QueryResult<
  InstrumentSearchQuery,
  InstrumentSearchQueryVariables
>;
export const InstrumentsRefreshDocument = gql`
  mutation InstrumentsRefresh($request: InstrumentsRefreshRequestInput!) {
    instrumentsRefresh(request: $request) {
      clientId
    }
  }
`;
export type InstrumentsRefreshMutationFn = Apollo.MutationFunction<
  InstrumentsRefreshMutation,
  InstrumentsRefreshMutationVariables
>;

/**
 * __useInstrumentsRefreshMutation__
 *
 * To run a mutation, you first call `useInstrumentsRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstrumentsRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [instrumentsRefreshMutation, { data, loading, error }] = useInstrumentsRefreshMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useInstrumentsRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstrumentsRefreshMutation,
    InstrumentsRefreshMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InstrumentsRefreshMutation, InstrumentsRefreshMutationVariables>(
    InstrumentsRefreshDocument,
    options,
  );
}
export type InstrumentsRefreshMutationHookResult = ReturnType<typeof useInstrumentsRefreshMutation>;
export type InstrumentsRefreshMutationResult = Apollo.MutationResult<InstrumentsRefreshMutation>;
export type InstrumentsRefreshMutationOptions = Apollo.BaseMutationOptions<
  InstrumentsRefreshMutation,
  InstrumentsRefreshMutationVariables
>;
export const InternalExchangeConfigurationDocument = gql`
  query InternalExchangeConfiguration($account: String!) {
    internalExchangeConfiguration(account: $account) {
      account
      accountName
      availableOrderTypes
      availableTifs
    }
  }
`;

/**
 * __useInternalExchangeConfigurationQuery__
 *
 * To run a query within a React component, call `useInternalExchangeConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalExchangeConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalExchangeConfigurationQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useInternalExchangeConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    InternalExchangeConfigurationQuery,
    InternalExchangeConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InternalExchangeConfigurationQuery,
    InternalExchangeConfigurationQueryVariables
  >(InternalExchangeConfigurationDocument, options);
}
export function useInternalExchangeConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InternalExchangeConfigurationQuery,
    InternalExchangeConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InternalExchangeConfigurationQuery,
    InternalExchangeConfigurationQueryVariables
  >(InternalExchangeConfigurationDocument, options);
}
export type InternalExchangeConfigurationQueryHookResult = ReturnType<
  typeof useInternalExchangeConfigurationQuery
>;
export type InternalExchangeConfigurationLazyQueryHookResult = ReturnType<
  typeof useInternalExchangeConfigurationLazyQuery
>;
export type InternalExchangeConfigurationQueryResult = Apollo.QueryResult<
  InternalExchangeConfigurationQuery,
  InternalExchangeConfigurationQueryVariables
>;
export const LedgerEntriesDocument = gql`
  query LedgerEntries($search: LedgerEntrySearchInput!) {
    ledgerEntries(search: $search) {
      edges {
        node {
          updatedAt
          quantity
          price
          type
          symbol
          currency
          portfolioId
          portfolioName
          transactionId
          id
          accountId
          accountName
          balanceBefore
          balanceAfter
          orderId
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

/**
 * __useLedgerEntriesQuery__
 *
 * To run a query within a React component, call `useLedgerEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLedgerEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLedgerEntriesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useLedgerEntriesQuery(
  baseOptions: Apollo.QueryHookOptions<LedgerEntriesQuery, LedgerEntriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LedgerEntriesQuery, LedgerEntriesQueryVariables>(
    LedgerEntriesDocument,
    options,
  );
}
export function useLedgerEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LedgerEntriesQuery, LedgerEntriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LedgerEntriesQuery, LedgerEntriesQueryVariables>(
    LedgerEntriesDocument,
    options,
  );
}
export type LedgerEntriesQueryHookResult = ReturnType<typeof useLedgerEntriesQuery>;
export type LedgerEntriesLazyQueryHookResult = ReturnType<typeof useLedgerEntriesLazyQuery>;
export type LedgerEntriesQueryResult = Apollo.QueryResult<
  LedgerEntriesQuery,
  LedgerEntriesQueryVariables
>;
export const MatchesSearchDocument = gql`
  query MatchesSearch($search: MatchesSearchInput) {
    matches(search: $search) {
      totalQuantity
      totalAmount
      avgPrice
      matches {
        id
        timestamp
        makers {
          portfolioId
          portfolioName
          venueAccount
          venueAccountName
          referenceType
          orderId
          price
          volume
        }
        taker {
          portfolioId
          portfolioName
          venueAccount
          venueAccountName
          referenceType
          orderId
          price
          volume
        }
        primarySymbolQuotes {
          instrument {
            baseInstrument {
              symbol
              venueName
              quoteCurrency
            }
            instrumentIdentifiers {
              instrumentId
            }
            forexSpotProperties {
              baseCurrency
            }
          }
          marketAskPrice
          marketBidPrice
          marketAskSize
          marketBidSize
          markupAskPrice
          markupBidPrice
          timestamp
        }
        secondarySymbolQuotes {
          instrument {
            baseInstrument {
              symbol
              venueName
              quoteCurrency
            }
            instrumentIdentifiers {
              instrumentId
            }
            forexSpotProperties {
              baseCurrency
            }
          }
          marketAskPrice
          marketBidPrice
          marketAskSize
          marketBidSize
          markupAskPrice
          markupBidPrice
          timestamp
        }
      }
    }
  }
`;

/**
 * __useMatchesSearchQuery__
 *
 * To run a query within a React component, call `useMatchesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchesSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useMatchesSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<MatchesSearchQuery, MatchesSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MatchesSearchQuery, MatchesSearchQueryVariables>(
    MatchesSearchDocument,
    options,
  );
}
export function useMatchesSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MatchesSearchQuery, MatchesSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MatchesSearchQuery, MatchesSearchQueryVariables>(
    MatchesSearchDocument,
    options,
  );
}
export type MatchesSearchQueryHookResult = ReturnType<typeof useMatchesSearchQuery>;
export type MatchesSearchLazyQueryHookResult = ReturnType<typeof useMatchesSearchLazyQuery>;
export type MatchesSearchQueryResult = Apollo.QueryResult<
  MatchesSearchQuery,
  MatchesSearchQueryVariables
>;
export const OrderBookDocument = gql`
  subscription OrderBook($venueAccount: String, $instrumentId: String!, $portfolioId: String) {
    orderBook(venueAccount: $venueAccount, instrumentId: $instrumentId, portfolioId: $portfolioId) {
      instrumentId
      venue
      venueAccount
      venueAccountName
      dateTime
      bids {
        ...OrderBookContent
      }
      asks {
        ...OrderBookContent
      }
    }
  }
  ${OrderBookContentFragmentDoc}
`;

/**
 * __useOrderBookSubscription__
 *
 * To run a query within a React component, call `useOrderBookSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderBookSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBookSubscription({
 *   variables: {
 *      venueAccount: // value for 'venueAccount'
 *      instrumentId: // value for 'instrumentId'
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useOrderBookSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OrderBookSubscription,
    OrderBookSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OrderBookSubscription, OrderBookSubscriptionVariables>(
    OrderBookDocument,
    options,
  );
}
export type OrderBookSubscriptionHookResult = ReturnType<typeof useOrderBookSubscription>;
export type OrderBookSubscriptionResult = Apollo.SubscriptionResult<OrderBookSubscription>;
export const OrderBookSnapshotDocument = gql`
  query OrderBookSnapshot($search: OrderBookSnapshotSearchInput) {
    orderBookSnapshot(search: $search) {
      bids {
        ...OrderBookContent
      }
      asks {
        ...OrderBookContent
      }
    }
  }
  ${OrderBookContentFragmentDoc}
`;

/**
 * __useOrderBookSnapshotQuery__
 *
 * To run a query within a React component, call `useOrderBookSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBookSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBookSnapshotQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useOrderBookSnapshotQuery(
  baseOptions?: Apollo.QueryHookOptions<OrderBookSnapshotQuery, OrderBookSnapshotQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderBookSnapshotQuery, OrderBookSnapshotQueryVariables>(
    OrderBookSnapshotDocument,
    options,
  );
}
export function useOrderBookSnapshotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBookSnapshotQuery,
    OrderBookSnapshotQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderBookSnapshotQuery, OrderBookSnapshotQueryVariables>(
    OrderBookSnapshotDocument,
    options,
  );
}
export type OrderBookSnapshotQueryHookResult = ReturnType<typeof useOrderBookSnapshotQuery>;
export type OrderBookSnapshotLazyQueryHookResult = ReturnType<typeof useOrderBookSnapshotLazyQuery>;
export type OrderBookSnapshotQueryResult = Apollo.QueryResult<
  OrderBookSnapshotQuery,
  OrderBookSnapshotQueryVariables
>;
export const OrderStatesDocument = gql`
  query OrderStates(
    $portfolioId: String
    $venueAccount: String
    $instrumentId: String
    $orderId: String
    $clOrderId: String
    $from: String
    $to: String
  ) {
    orderStates(
      portfolioId: $portfolioId
      venueAccount: $venueAccount
      instrumentId: $instrumentId
      orderId: $orderId
      clOrderId: $clOrderId
      from: $from
      to: $to
    ) {
      orderId
      clientId
      clOrderId
      expirationDateTime
      origClOrderId
      portfolioId
      portfolioName
      counterPortfolioId
      counterPortfolioName
      orderStatus
      orderQty
      limitPrice
      stopPrice
      tif
      filledQty
      remainingQty
      lastQty
      avgPrice
      lastPrice
      reason
      lastRequestResult
      side
      instrument {
        ...InstrumentContent
      }
      symbol
      assetClass
      venue
      venueTimestamp
      createdAt
      updatedAt
      orderCategory
      parentOrderId
      venueAccountDescs {
        id
        name
      }
    }
  }
  ${InstrumentContentFragmentDoc}
`;

/**
 * __useOrderStatesQuery__
 *
 * To run a query within a React component, call `useOrderStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatesQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      venueAccount: // value for 'venueAccount'
 *      instrumentId: // value for 'instrumentId'
 *      orderId: // value for 'orderId'
 *      clOrderId: // value for 'clOrderId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useOrderStatesQuery(
  baseOptions?: Apollo.QueryHookOptions<OrderStatesQuery, OrderStatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderStatesQuery, OrderStatesQueryVariables>(OrderStatesDocument, options);
}
export function useOrderStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderStatesQuery, OrderStatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderStatesQuery, OrderStatesQueryVariables>(
    OrderStatesDocument,
    options,
  );
}
export type OrderStatesQueryHookResult = ReturnType<typeof useOrderStatesQuery>;
export type OrderStatesLazyQueryHookResult = ReturnType<typeof useOrderStatesLazyQuery>;
export type OrderStatesQueryResult = Apollo.QueryResult<
  OrderStatesQuery,
  OrderStatesQueryVariables
>;
export const OrderStatesWithPredicatesDocument = gql`
  query OrderStatesWithPredicates($search: OrderStateSearchInput!) {
    orderStatesWithPredicates(search: $search) {
      edges {
        node {
          ...OrderStateContent
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${OrderStateContentFragmentDoc}
`;

/**
 * __useOrderStatesWithPredicatesQuery__
 *
 * To run a query within a React component, call `useOrderStatesWithPredicatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatesWithPredicatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatesWithPredicatesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useOrderStatesWithPredicatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderStatesWithPredicatesQuery,
    OrderStatesWithPredicatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderStatesWithPredicatesQuery, OrderStatesWithPredicatesQueryVariables>(
    OrderStatesWithPredicatesDocument,
    options,
  );
}
export function useOrderStatesWithPredicatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderStatesWithPredicatesQuery,
    OrderStatesWithPredicatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderStatesWithPredicatesQuery,
    OrderStatesWithPredicatesQueryVariables
  >(OrderStatesWithPredicatesDocument, options);
}
export type OrderStatesWithPredicatesQueryHookResult = ReturnType<
  typeof useOrderStatesWithPredicatesQuery
>;
export type OrderStatesWithPredicatesLazyQueryHookResult = ReturnType<
  typeof useOrderStatesWithPredicatesLazyQuery
>;
export type OrderStatesWithPredicatesQueryResult = Apollo.QueryResult<
  OrderStatesWithPredicatesQuery,
  OrderStatesWithPredicatesQueryVariables
>;
export const OrderStatesWithSnapshotDocument = gql`
  subscription OrderStatesWithSnapshot(
    $portfolioId: String
    $venueAccount: String
    $isOpen: Boolean
    $historyCap: Int
  ) {
    orderStatesWithSnapshot(
      portfolioId: $portfolioId
      venueAccount: $venueAccount
      isOpen: $isOpen
      historyCap: $historyCap
    ) {
      orderType
      avgPrice
      clOrderId
      clientId
      createdAt
      expirationDateTime
      filledQty
      lastPrice
      lastQty
      lastRequestResult
      limitPrice
      orderId
      orderQty
      currency
      rootOrderId
      orderStatus
      origClOrderId
      portfolioId
      portfolioName
      counterPortfolioId
      counterPortfolioName
      reason
      remainingQty
      side
      stopPrice
      instrument {
        ...InstrumentContent
      }
      symbol
      assetClass
      venue
      tif
      updatedAt
      venueTimestamp
      sequenceNumber
      orderCategory
      parentOrderId
      venueAccountDescs {
        id
        name
      }
    }
  }
  ${InstrumentContentFragmentDoc}
`;

/**
 * __useOrderStatesWithSnapshotSubscription__
 *
 * To run a query within a React component, call `useOrderStatesWithSnapshotSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatesWithSnapshotSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatesWithSnapshotSubscription({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      venueAccount: // value for 'venueAccount'
 *      isOpen: // value for 'isOpen'
 *      historyCap: // value for 'historyCap'
 *   },
 * });
 */
export function useOrderStatesWithSnapshotSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OrderStatesWithSnapshotSubscription,
    OrderStatesWithSnapshotSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OrderStatesWithSnapshotSubscription,
    OrderStatesWithSnapshotSubscriptionVariables
  >(OrderStatesWithSnapshotDocument, options);
}
export type OrderStatesWithSnapshotSubscriptionHookResult = ReturnType<
  typeof useOrderStatesWithSnapshotSubscription
>;
export type OrderStatesWithSnapshotSubscriptionResult =
  Apollo.SubscriptionResult<OrderStatesWithSnapshotSubscription>;
export const PermissionsForResourceDocument = gql`
  query PermissionsForResource($resource: Resource!, $resourceId: String!) {
    usersPermissionsForResource(resource: $resource, resourceId: $resourceId) {
      username
      scopes
    }
    groupsPermissionsForResource(resource: $resource, resourceId: $resourceId) {
      groupName
      scopes
    }
  }
`;

/**
 * __usePermissionsForResourceQuery__
 *
 * To run a query within a React component, call `usePermissionsForResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsForResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsForResourceQuery({
 *   variables: {
 *      resource: // value for 'resource'
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function usePermissionsForResourceQuery(
  baseOptions: Apollo.QueryHookOptions<
    PermissionsForResourceQuery,
    PermissionsForResourceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PermissionsForResourceQuery, PermissionsForResourceQueryVariables>(
    PermissionsForResourceDocument,
    options,
  );
}
export function usePermissionsForResourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PermissionsForResourceQuery,
    PermissionsForResourceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PermissionsForResourceQuery, PermissionsForResourceQueryVariables>(
    PermissionsForResourceDocument,
    options,
  );
}
export type PermissionsForResourceQueryHookResult = ReturnType<
  typeof usePermissionsForResourceQuery
>;
export type PermissionsForResourceLazyQueryHookResult = ReturnType<
  typeof usePermissionsForResourceLazyQuery
>;
export type PermissionsForResourceQueryResult = Apollo.QueryResult<
  PermissionsForResourceQuery,
  PermissionsForResourceQueryVariables
>;
export const PortfolioTagsDocument = gql`
  query PortfolioTags {
    portfolioTags {
      key
      values
    }
  }
`;

/**
 * __usePortfolioTagsQuery__
 *
 * To run a query within a React component, call `usePortfolioTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<PortfolioTagsQuery, PortfolioTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioTagsQuery, PortfolioTagsQueryVariables>(
    PortfolioTagsDocument,
    options,
  );
}
export function usePortfolioTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PortfolioTagsQuery, PortfolioTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PortfolioTagsQuery, PortfolioTagsQueryVariables>(
    PortfolioTagsDocument,
    options,
  );
}
export type PortfolioTagsQueryHookResult = ReturnType<typeof usePortfolioTagsQuery>;
export type PortfolioTagsLazyQueryHookResult = ReturnType<typeof usePortfolioTagsLazyQuery>;
export type PortfolioTagsQueryResult = Apollo.QueryResult<
  PortfolioTagsQuery,
  PortfolioTagsQueryVariables
>;
export const PortfolioConfigValidationDocument = gql`
  query PortfolioConfigValidation($portfolioId: String!) {
    portfolioConfigValidation(portfolioId: $portfolioId) {
      resultsPerInstrument {
        instrumentId
        isValid
        preventsTrading
        preventsMarketData
        errors {
          errorMessage
          errorType
          fieldName
          preventsTrading
          preventsMarketData
        }
      }
    }
  }
`;

/**
 * __usePortfolioConfigValidationQuery__
 *
 * To run a query within a React component, call `usePortfolioConfigValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioConfigValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioConfigValidationQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function usePortfolioConfigValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    PortfolioConfigValidationQuery,
    PortfolioConfigValidationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioConfigValidationQuery, PortfolioConfigValidationQueryVariables>(
    PortfolioConfigValidationDocument,
    options,
  );
}
export function usePortfolioConfigValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PortfolioConfigValidationQuery,
    PortfolioConfigValidationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PortfolioConfigValidationQuery,
    PortfolioConfigValidationQueryVariables
  >(PortfolioConfigValidationDocument, options);
}
export type PortfolioConfigValidationQueryHookResult = ReturnType<
  typeof usePortfolioConfigValidationQuery
>;
export type PortfolioConfigValidationLazyQueryHookResult = ReturnType<
  typeof usePortfolioConfigValidationLazyQuery
>;
export type PortfolioConfigValidationQueryResult = Apollo.QueryResult<
  PortfolioConfigValidationQuery,
  PortfolioConfigValidationQueryVariables
>;
export const PortfolioConfigurationDocument = gql`
  query PortfolioConfiguration($id: ID!) {
    portfolioConfiguration(id: $id) {
      id
      name
      executionConfiguration {
        ...ExecutionConfigurationContent
      }
      pricingConfiguration {
        ...PricingConfigurationContent
      }
      hedgingConfiguration {
        ...HedgingConfigurationContent
      }
      portfolioGroupConfiguration {
        id
        name
        executionConfiguration {
          ...ExecutionConfigurationContent
        }
        pricingConfiguration {
          ...PricingConfigurationContent
        }
        instrumentConfiguration {
          instrumentId
          tradeable
          executionConfiguration {
            ...ExecutionConfigurationContent
          }
          pricingConfiguration {
            ...InstrumentPricingConfigurationContent
          }
        }
      }
      instrumentConfiguration {
        instrumentId
        tradeable
        instrumentGroupConfiguration {
          tradeable
          instrumentId
          executionConfiguration {
            ...ExecutionConfigurationContent
          }
          pricingConfiguration {
            ...InstrumentPricingConfigurationContent
          }
        }
        executionConfiguration {
          ...ExecutionConfigurationContent
        }
        pricingConfiguration {
          ...InstrumentPricingConfigurationContent
        }
      }
    }
  }
  ${ExecutionConfigurationContentFragmentDoc}
  ${PricingConfigurationContentFragmentDoc}
  ${HedgingConfigurationContentFragmentDoc}
  ${InstrumentPricingConfigurationContentFragmentDoc}
`;

/**
 * __usePortfolioConfigurationQuery__
 *
 * To run a query within a React component, call `usePortfolioConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePortfolioConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    PortfolioConfigurationQuery,
    PortfolioConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioConfigurationQuery, PortfolioConfigurationQueryVariables>(
    PortfolioConfigurationDocument,
    options,
  );
}
export function usePortfolioConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PortfolioConfigurationQuery,
    PortfolioConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PortfolioConfigurationQuery, PortfolioConfigurationQueryVariables>(
    PortfolioConfigurationDocument,
    options,
  );
}
export type PortfolioConfigurationQueryHookResult = ReturnType<
  typeof usePortfolioConfigurationQuery
>;
export type PortfolioConfigurationLazyQueryHookResult = ReturnType<
  typeof usePortfolioConfigurationLazyQuery
>;
export type PortfolioConfigurationQueryResult = Apollo.QueryResult<
  PortfolioConfigurationQuery,
  PortfolioConfigurationQueryVariables
>;
export const PortfolioGroupConfigurationDocument = gql`
  query PortfolioGroupConfiguration($id: ID!) {
    portfolioGroupConfiguration(id: $id) {
      id
      name
      executionConfiguration {
        ...ExecutionConfigurationContent
      }
      pricingConfiguration {
        ...PricingConfigurationContent
      }
      instrumentConfiguration {
        instrumentId
        tradeable
        executionConfiguration {
          ...ExecutionConfigurationContent
        }
        pricingConfiguration {
          ...InstrumentPricingConfigurationContent
        }
      }
    }
  }
  ${ExecutionConfigurationContentFragmentDoc}
  ${PricingConfigurationContentFragmentDoc}
  ${InstrumentPricingConfigurationContentFragmentDoc}
`;

/**
 * __usePortfolioGroupConfigurationQuery__
 *
 * To run a query within a React component, call `usePortfolioGroupConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioGroupConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioGroupConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePortfolioGroupConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    PortfolioGroupConfigurationQuery,
    PortfolioGroupConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PortfolioGroupConfigurationQuery,
    PortfolioGroupConfigurationQueryVariables
  >(PortfolioGroupConfigurationDocument, options);
}
export function usePortfolioGroupConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PortfolioGroupConfigurationQuery,
    PortfolioGroupConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PortfolioGroupConfigurationQuery,
    PortfolioGroupConfigurationQueryVariables
  >(PortfolioGroupConfigurationDocument, options);
}
export type PortfolioGroupConfigurationQueryHookResult = ReturnType<
  typeof usePortfolioGroupConfigurationQuery
>;
export type PortfolioGroupConfigurationLazyQueryHookResult = ReturnType<
  typeof usePortfolioGroupConfigurationLazyQuery
>;
export type PortfolioGroupConfigurationQueryResult = Apollo.QueryResult<
  PortfolioGroupConfigurationQuery,
  PortfolioGroupConfigurationQueryVariables
>;
export const PortfolioGroupConfigurationListDocument = gql`
  query PortfolioGroupConfigurationList {
    portfolioGroupConfigurationList {
      id
      name
      portfolioType
    }
  }
`;

/**
 * __usePortfolioGroupConfigurationListQuery__
 *
 * To run a query within a React component, call `usePortfolioGroupConfigurationListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioGroupConfigurationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioGroupConfigurationListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioGroupConfigurationListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PortfolioGroupConfigurationListQuery,
    PortfolioGroupConfigurationListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PortfolioGroupConfigurationListQuery,
    PortfolioGroupConfigurationListQueryVariables
  >(PortfolioGroupConfigurationListDocument, options);
}
export function usePortfolioGroupConfigurationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PortfolioGroupConfigurationListQuery,
    PortfolioGroupConfigurationListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PortfolioGroupConfigurationListQuery,
    PortfolioGroupConfigurationListQueryVariables
  >(PortfolioGroupConfigurationListDocument, options);
}
export type PortfolioGroupConfigurationListQueryHookResult = ReturnType<
  typeof usePortfolioGroupConfigurationListQuery
>;
export type PortfolioGroupConfigurationListLazyQueryHookResult = ReturnType<
  typeof usePortfolioGroupConfigurationListLazyQuery
>;
export type PortfolioGroupConfigurationListQueryResult = Apollo.QueryResult<
  PortfolioGroupConfigurationListQuery,
  PortfolioGroupConfigurationListQueryVariables
>;
export const PortfolioSearchDocument = gql`
  query PortfolioSearch($search: PortfolioSearchInput) {
    portfolioSearch(search: $search) {
      edges {
        node {
          id
          name
          createdAt
          scopes
          portfolioCurrency
          portfolioType
          tags {
            value
            key
          }
          archivedAt
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

/**
 * __usePortfolioSearchQuery__
 *
 * To run a query within a React component, call `usePortfolioSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePortfolioSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<PortfolioSearchQuery, PortfolioSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PortfolioSearchQuery, PortfolioSearchQueryVariables>(
    PortfolioSearchDocument,
    options,
  );
}
export function usePortfolioSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PortfolioSearchQuery, PortfolioSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PortfolioSearchQuery, PortfolioSearchQueryVariables>(
    PortfolioSearchDocument,
    options,
  );
}
export type PortfolioSearchQueryHookResult = ReturnType<typeof usePortfolioSearchQuery>;
export type PortfolioSearchLazyQueryHookResult = ReturnType<typeof usePortfolioSearchLazyQuery>;
export type PortfolioSearchQueryResult = Apollo.QueryResult<
  PortfolioSearchQuery,
  PortfolioSearchQueryVariables
>;
export const PositionChangesDocument = gql`
  subscription PositionChanges($search: PositionSearchInput!) {
    positionChanges(search: $search) {
      account
      accountName
      availableForTradingQuantity
      availableForWithdrawalQuantity
      bookingCurrency
      currency
      grossAveragePrice
      grossCost
      grossCostSc
      grossRealizedPnl
      grossRealizedPnlSc
      grossUnrealizedPnl
      grossUnrealizedPnlSc
      marketValue
      marketValueSc
      netAveragePrice
      netCost
      netCostSc
      netRealizedPnl
      netRealizedPnlSc
      netUnrealizedPnl
      netUnrealizedPnlSc
      notionalQuantity
      pendingQuantity
      portfolio {
        ...PortfolioContent
      }
      quantity
      settledQuantity
      symbol
      unsettledQuantity
      updatedAt
    }
  }
  ${PortfolioContentFragmentDoc}
`;

/**
 * __usePositionChangesSubscription__
 *
 * To run a query within a React component, call `usePositionChangesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePositionChangesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionChangesSubscription({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePositionChangesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PositionChangesSubscription,
    PositionChangesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<PositionChangesSubscription, PositionChangesSubscriptionVariables>(
    PositionChangesDocument,
    options,
  );
}
export type PositionChangesSubscriptionHookResult = ReturnType<
  typeof usePositionChangesSubscription
>;
export type PositionChangesSubscriptionResult =
  Apollo.SubscriptionResult<PositionChangesSubscription>;
export const PositionsDocument = gql`
  query Positions($search: PositionSearchInput!) {
    positions(search: $search) {
      edges {
        node {
          account
          accountName
          availableForTradingQuantity
          availableForWithdrawalQuantity
          bookingCurrency
          currency
          grossAveragePrice
          grossCost
          grossCostSc
          grossRealizedPnl
          grossRealizedPnlSc
          grossUnrealizedPnl
          grossUnrealizedPnlSc
          marketValue
          marketValueSc
          netAveragePrice
          netCost
          netCostSc
          netRealizedPnl
          netRealizedPnlSc
          netUnrealizedPnl
          netUnrealizedPnlSc
          notionalQuantity
          pendingQuantity
          portfolio {
            ...PortfolioContent
          }
          quantity
          settledQuantity
          symbol
          unsettledQuantity
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${PortfolioContentFragmentDoc}
`;

/**
 * __usePositionsQuery__
 *
 * To run a query within a React component, call `usePositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePositionsQuery(
  baseOptions: Apollo.QueryHookOptions<PositionsQuery, PositionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PositionsQuery, PositionsQueryVariables>(PositionsDocument, options);
}
export function usePositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PositionsQuery, PositionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PositionsQuery, PositionsQueryVariables>(PositionsDocument, options);
}
export type PositionsQueryHookResult = ReturnType<typeof usePositionsQuery>;
export type PositionsLazyQueryHookResult = ReturnType<typeof usePositionsLazyQuery>;
export type PositionsQueryResult = Apollo.QueryResult<PositionsQuery, PositionsQueryVariables>;
export const PreTradeCheckAuditLogsDocument = gql`
  query PreTradeCheckAuditLogs($request: PreTradeCheckAuditLogSearchInput!) {
    preTradeCheckAuditLogs(search: $request) {
      edges {
        node {
          id
          createdAt
          portfolioId
          portfolioName
          order {
            orderId
            orderType
            quantity
            side
            instrumentId
          }
          preTradeCheckResults {
            status
            reason
            preTradeCheck {
              id
              type
              portfolios {
                id
                name
                createdAt
                portfolioType
                portfolioCurrency
                scopes
                tags {
                  key
                  value
                }
                archivedAt
              }
              portfolioTags {
                key
                value
              }
              channels
              configuration {
                name
                type
                values
              }
              level
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

/**
 * __usePreTradeCheckAuditLogsQuery__
 *
 * To run a query within a React component, call `usePreTradeCheckAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreTradeCheckAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreTradeCheckAuditLogsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function usePreTradeCheckAuditLogsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PreTradeCheckAuditLogsQuery,
    PreTradeCheckAuditLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PreTradeCheckAuditLogsQuery, PreTradeCheckAuditLogsQueryVariables>(
    PreTradeCheckAuditLogsDocument,
    options,
  );
}
export function usePreTradeCheckAuditLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PreTradeCheckAuditLogsQuery,
    PreTradeCheckAuditLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PreTradeCheckAuditLogsQuery, PreTradeCheckAuditLogsQueryVariables>(
    PreTradeCheckAuditLogsDocument,
    options,
  );
}
export type PreTradeCheckAuditLogsQueryHookResult = ReturnType<
  typeof usePreTradeCheckAuditLogsQuery
>;
export type PreTradeCheckAuditLogsLazyQueryHookResult = ReturnType<
  typeof usePreTradeCheckAuditLogsLazyQuery
>;
export type PreTradeCheckAuditLogsQueryResult = Apollo.QueryResult<
  PreTradeCheckAuditLogsQuery,
  PreTradeCheckAuditLogsQueryVariables
>;
export const PreTradeCheckFormSchemaDocument = gql`
  query PreTradeCheckFormSchema {
    preTradeCheckFormSchema {
      type
      configuration {
        type
        name
        required
        isEnum
        options
      }
    }
  }
`;

/**
 * __usePreTradeCheckFormSchemaQuery__
 *
 * To run a query within a React component, call `usePreTradeCheckFormSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreTradeCheckFormSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreTradeCheckFormSchemaQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreTradeCheckFormSchemaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PreTradeCheckFormSchemaQuery,
    PreTradeCheckFormSchemaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PreTradeCheckFormSchemaQuery, PreTradeCheckFormSchemaQueryVariables>(
    PreTradeCheckFormSchemaDocument,
    options,
  );
}
export function usePreTradeCheckFormSchemaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PreTradeCheckFormSchemaQuery,
    PreTradeCheckFormSchemaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PreTradeCheckFormSchemaQuery, PreTradeCheckFormSchemaQueryVariables>(
    PreTradeCheckFormSchemaDocument,
    options,
  );
}
export type PreTradeCheckFormSchemaQueryHookResult = ReturnType<
  typeof usePreTradeCheckFormSchemaQuery
>;
export type PreTradeCheckFormSchemaLazyQueryHookResult = ReturnType<
  typeof usePreTradeCheckFormSchemaLazyQuery
>;
export type PreTradeCheckFormSchemaQueryResult = Apollo.QueryResult<
  PreTradeCheckFormSchemaQuery,
  PreTradeCheckFormSchemaQueryVariables
>;
export const PreTradeChecksDocument = gql`
  query PreTradeChecks {
    preTradeChecks {
      id
      type
      portfolios {
        id
        name
        createdAt
        portfolioType
        portfolioCurrency
        scopes
        tags {
          key
          value
        }
        archivedAt
      }
      portfolioTags {
        key
        value
      }
      channels
      configuration {
        name
        type
        values
      }
      level
    }
  }
`;

/**
 * __usePreTradeChecksQuery__
 *
 * To run a query within a React component, call `usePreTradeChecksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreTradeChecksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreTradeChecksQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreTradeChecksQuery(
  baseOptions?: Apollo.QueryHookOptions<PreTradeChecksQuery, PreTradeChecksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PreTradeChecksQuery, PreTradeChecksQueryVariables>(
    PreTradeChecksDocument,
    options,
  );
}
export function usePreTradeChecksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PreTradeChecksQuery, PreTradeChecksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PreTradeChecksQuery, PreTradeChecksQueryVariables>(
    PreTradeChecksDocument,
    options,
  );
}
export type PreTradeChecksQueryHookResult = ReturnType<typeof usePreTradeChecksQuery>;
export type PreTradeChecksLazyQueryHookResult = ReturnType<typeof usePreTradeChecksLazyQuery>;
export type PreTradeChecksQueryResult = Apollo.QueryResult<
  PreTradeChecksQuery,
  PreTradeChecksQueryVariables
>;
export const QuotingConfigValidationsDocument = gql`
  query QuotingConfigValidations {
    quotingConfigValidations {
      clobUid
      resultsPerInstrument {
        instrumentId
        isValid
        preventsTrading
        preventsMarketData
        errors {
          errorMessage
          errorType
          fieldName
          preventsTrading
          preventsMarketData
        }
      }
    }
  }
`;

/**
 * __useQuotingConfigValidationsQuery__
 *
 * To run a query within a React component, call `useQuotingConfigValidationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotingConfigValidationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotingConfigValidationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuotingConfigValidationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QuotingConfigValidationsQuery,
    QuotingConfigValidationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuotingConfigValidationsQuery, QuotingConfigValidationsQueryVariables>(
    QuotingConfigValidationsDocument,
    options,
  );
}
export function useQuotingConfigValidationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuotingConfigValidationsQuery,
    QuotingConfigValidationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuotingConfigValidationsQuery, QuotingConfigValidationsQueryVariables>(
    QuotingConfigValidationsDocument,
    options,
  );
}
export type QuotingConfigValidationsQueryHookResult = ReturnType<
  typeof useQuotingConfigValidationsQuery
>;
export type QuotingConfigValidationsLazyQueryHookResult = ReturnType<
  typeof useQuotingConfigValidationsLazyQuery
>;
export type QuotingConfigValidationsQueryResult = Apollo.QueryResult<
  QuotingConfigValidationsQuery,
  QuotingConfigValidationsQueryVariables
>;
export const QuotingConfigurationDocument = gql`
  query QuotingConfiguration {
    quotingConfiguration {
      displayName
      clobUid
      sources {
        accountId
        accountName
        isDroppingQuotesOnDisconnection
        defaultQuoteTTL
        quoteTTLUnit
        calendarEntries {
          fromDayOfTheWeek
          toDayOfTheWeek
          startTime
          endTime
          quoteTTL
          quoteTTLUnit
          additionalMarkup
        }
      }
      nostroPortfolioId
      nostroPortfolioName
      minQuantityFactor
      maxQuantityFactor
      maximumDepth
      askMarkup
      bidMarkup
      throttlingPeriod
      deactivated
      updatedAt
      quoteTTL
      hedgingSafetyMargin
      hedgingAccounts
      hedgingAccountDescs {
        id
        name
      }
      instrumentConfigurations {
        priceLevelIncrement
        hedgingSafetyMargin
        instrumentId
        quoteTTL
        quantityIncrement
        maximumDepth
        deactivated
        askMarkup
        bidMarkup
        minQuantityFactor
        maxQuantityFactor
        sourceConfigurations {
          sourceInstrument {
            baseInstrument {
              assetClass
              symbol
              description
              quoteCurrency
              inverseContract
              venueType
              venueName
            }
            forexSpotProperties {
              baseCurrency
            }
            tradingConstraints {
              minQty
              maxQty
              qtyIncr
              minPrice
              maxPrice
              priceIncr
              minNotional
              contractSize
              tradeable
            }
            instrumentIdentifiers {
              adapterTicker
              instrumentId
              tradingViewId
              venueTradingViewId
            }
            archivedAt
            createdAt
          }
          conversionSourceInstrument {
            baseInstrument {
              assetClass
              symbol
              description
              quoteCurrency
              inverseContract
              venueType
              venueName
            }
            forexSpotProperties {
              baseCurrency
            }
            tradingConstraints {
              minQty
              maxQty
              qtyIncr
              minPrice
              maxPrice
              priceIncr
              minNotional
              contractSize
              tradeable
            }
            instrumentIdentifiers {
              adapterTicker
              instrumentId
              tradingViewId
              venueTradingViewId
            }
            archivedAt
            createdAt
          }
          inverse
        }
      }
    }
  }
`;

/**
 * __useQuotingConfigurationQuery__
 *
 * To run a query within a React component, call `useQuotingConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotingConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotingConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuotingConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QuotingConfigurationQuery,
    QuotingConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuotingConfigurationQuery, QuotingConfigurationQueryVariables>(
    QuotingConfigurationDocument,
    options,
  );
}
export function useQuotingConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuotingConfigurationQuery,
    QuotingConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuotingConfigurationQuery, QuotingConfigurationQueryVariables>(
    QuotingConfigurationDocument,
    options,
  );
}
export type QuotingConfigurationQueryHookResult = ReturnType<typeof useQuotingConfigurationQuery>;
export type QuotingConfigurationLazyQueryHookResult = ReturnType<
  typeof useQuotingConfigurationLazyQuery
>;
export type QuotingConfigurationQueryResult = Apollo.QueryResult<
  QuotingConfigurationQuery,
  QuotingConfigurationQueryVariables
>;
export const RateSubscriptionsDocument = gql`
  query RateSubscriptions {
    rateSubscriptions {
      baseCurrency
      quoteCurrency
      venueAccountId
      venueAccountName
      lastPrice
      health
      lastEventTimestamp
    }
  }
`;

/**
 * __useRateSubscriptionsQuery__
 *
 * To run a query within a React component, call `useRateSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRateSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<RateSubscriptionsQuery, RateSubscriptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RateSubscriptionsQuery, RateSubscriptionsQueryVariables>(
    RateSubscriptionsDocument,
    options,
  );
}
export function useRateSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RateSubscriptionsQuery,
    RateSubscriptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RateSubscriptionsQuery, RateSubscriptionsQueryVariables>(
    RateSubscriptionsDocument,
    options,
  );
}
export type RateSubscriptionsQueryHookResult = ReturnType<typeof useRateSubscriptionsQuery>;
export type RateSubscriptionsLazyQueryHookResult = ReturnType<typeof useRateSubscriptionsLazyQuery>;
export type RateSubscriptionsQueryResult = Apollo.QueryResult<
  RateSubscriptionsQuery,
  RateSubscriptionsQueryVariables
>;
export const RemoveGroupPermissionsDocument = gql`
  mutation RemoveGroupPermissions($request: AddOrRemoveGroupPermissionsInput!) {
    removeGroupPermissions(request: $request) {
      clientId
    }
  }
`;
export type RemoveGroupPermissionsMutationFn = Apollo.MutationFunction<
  RemoveGroupPermissionsMutation,
  RemoveGroupPermissionsMutationVariables
>;

/**
 * __useRemoveGroupPermissionsMutation__
 *
 * To run a mutation, you first call `useRemoveGroupPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupPermissionsMutation, { data, loading, error }] = useRemoveGroupPermissionsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRemoveGroupPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGroupPermissionsMutation,
    RemoveGroupPermissionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGroupPermissionsMutation,
    RemoveGroupPermissionsMutationVariables
  >(RemoveGroupPermissionsDocument, options);
}
export type RemoveGroupPermissionsMutationHookResult = ReturnType<
  typeof useRemoveGroupPermissionsMutation
>;
export type RemoveGroupPermissionsMutationResult =
  Apollo.MutationResult<RemoveGroupPermissionsMutation>;
export type RemoveGroupPermissionsMutationOptions = Apollo.BaseMutationOptions<
  RemoveGroupPermissionsMutation,
  RemoveGroupPermissionsMutationVariables
>;
export const RemoveUserPermissionsDocument = gql`
  mutation RemoveUserPermissions($request: AddOrRemoveUserPermissionsInput!) {
    removeUserPermissions(request: $request) {
      clientId
    }
  }
`;
export type RemoveUserPermissionsMutationFn = Apollo.MutationFunction<
  RemoveUserPermissionsMutation,
  RemoveUserPermissionsMutationVariables
>;

/**
 * __useRemoveUserPermissionsMutation__
 *
 * To run a mutation, you first call `useRemoveUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserPermissionsMutation, { data, loading, error }] = useRemoveUserPermissionsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRemoveUserPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserPermissionsMutation,
    RemoveUserPermissionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveUserPermissionsMutation, RemoveUserPermissionsMutationVariables>(
    RemoveUserPermissionsDocument,
    options,
  );
}
export type RemoveUserPermissionsMutationHookResult = ReturnType<
  typeof useRemoveUserPermissionsMutation
>;
export type RemoveUserPermissionsMutationResult =
  Apollo.MutationResult<RemoveUserPermissionsMutation>;
export type RemoveUserPermissionsMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserPermissionsMutation,
  RemoveUserPermissionsMutationVariables
>;
export const ResetConfigurationDocument = gql`
  mutation ResetConfiguration($resetConfigurationInput: ResetConfigurationInput!) {
    resetConfiguration(resetConfigurationInput: $resetConfigurationInput) {
      resultsPerInstrument {
        ...InstrumentValidationResult
      }
    }
  }
  ${InstrumentValidationResultFragmentDoc}
`;
export type ResetConfigurationMutationFn = Apollo.MutationFunction<
  ResetConfigurationMutation,
  ResetConfigurationMutationVariables
>;

/**
 * __useResetConfigurationMutation__
 *
 * To run a mutation, you first call `useResetConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetConfigurationMutation, { data, loading, error }] = useResetConfigurationMutation({
 *   variables: {
 *      resetConfigurationInput: // value for 'resetConfigurationInput'
 *   },
 * });
 */
export function useResetConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetConfigurationMutation,
    ResetConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetConfigurationMutation, ResetConfigurationMutationVariables>(
    ResetConfigurationDocument,
    options,
  );
}
export type ResetConfigurationMutationHookResult = ReturnType<typeof useResetConfigurationMutation>;
export type ResetConfigurationMutationResult = Apollo.MutationResult<ResetConfigurationMutation>;
export type ResetConfigurationMutationOptions = Apollo.BaseMutationOptions<
  ResetConfigurationMutation,
  ResetConfigurationMutationVariables
>;
export const SaveCurrencyDocument = gql`
  mutation SaveCurrency($request: CurrencyInput!) {
    saveCurrency(request: $request) {
      status
    }
  }
`;
export type SaveCurrencyMutationFn = Apollo.MutationFunction<
  SaveCurrencyMutation,
  SaveCurrencyMutationVariables
>;

/**
 * __useSaveCurrencyMutation__
 *
 * To run a mutation, you first call `useSaveCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCurrencyMutation, { data, loading, error }] = useSaveCurrencyMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveCurrencyMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveCurrencyMutation, SaveCurrencyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveCurrencyMutation, SaveCurrencyMutationVariables>(
    SaveCurrencyDocument,
    options,
  );
}
export type SaveCurrencyMutationHookResult = ReturnType<typeof useSaveCurrencyMutation>;
export type SaveCurrencyMutationResult = Apollo.MutationResult<SaveCurrencyMutation>;
export type SaveCurrencyMutationOptions = Apollo.BaseMutationOptions<
  SaveCurrencyMutation,
  SaveCurrencyMutationVariables
>;
export const SavePreTradeCheckDocument = gql`
  mutation SavePreTradeCheck($request: PreTradeCheckInput!) {
    savePreTradeCheck(request: $request) {
      clientId
    }
  }
`;
export type SavePreTradeCheckMutationFn = Apollo.MutationFunction<
  SavePreTradeCheckMutation,
  SavePreTradeCheckMutationVariables
>;

/**
 * __useSavePreTradeCheckMutation__
 *
 * To run a mutation, you first call `useSavePreTradeCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePreTradeCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePreTradeCheckMutation, { data, loading, error }] = useSavePreTradeCheckMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSavePreTradeCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavePreTradeCheckMutation,
    SavePreTradeCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SavePreTradeCheckMutation, SavePreTradeCheckMutationVariables>(
    SavePreTradeCheckDocument,
    options,
  );
}
export type SavePreTradeCheckMutationHookResult = ReturnType<typeof useSavePreTradeCheckMutation>;
export type SavePreTradeCheckMutationResult = Apollo.MutationResult<SavePreTradeCheckMutation>;
export type SavePreTradeCheckMutationOptions = Apollo.BaseMutationOptions<
  SavePreTradeCheckMutation,
  SavePreTradeCheckMutationVariables
>;
export const SendOrderDocument = gql`
  mutation SendOrder($request: NewOrderSingleRequestInput!) {
    sendOrder(request: $request) {
      clientId
    }
  }
`;
export type SendOrderMutationFn = Apollo.MutationFunction<
  SendOrderMutation,
  SendOrderMutationVariables
>;

/**
 * __useSendOrderMutation__
 *
 * To run a mutation, you first call `useSendOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOrderMutation, { data, loading, error }] = useSendOrderMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSendOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<SendOrderMutation, SendOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendOrderMutation, SendOrderMutationVariables>(
    SendOrderDocument,
    options,
  );
}
export type SendOrderMutationHookResult = ReturnType<typeof useSendOrderMutation>;
export type SendOrderMutationResult = Apollo.MutationResult<SendOrderMutation>;
export type SendOrderMutationOptions = Apollo.BaseMutationOptions<
  SendOrderMutation,
  SendOrderMutationVariables
>;
export const StaticPermissionsDocument = gql`
  query StaticPermissions {
    userStaticPermissions {
      resource
      scope
      resourceId
    }
    groupStaticPermissions {
      resource
      scope
      resourceId
    }
  }
`;

/**
 * __useStaticPermissionsQuery__
 *
 * To run a query within a React component, call `useStaticPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaticPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<StaticPermissionsQuery, StaticPermissionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StaticPermissionsQuery, StaticPermissionsQueryVariables>(
    StaticPermissionsDocument,
    options,
  );
}
export function useStaticPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StaticPermissionsQuery,
    StaticPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StaticPermissionsQuery, StaticPermissionsQueryVariables>(
    StaticPermissionsDocument,
    options,
  );
}
export type StaticPermissionsQueryHookResult = ReturnType<typeof useStaticPermissionsQuery>;
export type StaticPermissionsLazyQueryHookResult = ReturnType<typeof useStaticPermissionsLazyQuery>;
export type StaticPermissionsQueryResult = Apollo.QueryResult<
  StaticPermissionsQuery,
  StaticPermissionsQueryVariables
>;
export const SymbolSearchDocument = gql`
  query SymbolSearch($search: SymbolSearchInput!) {
    symbolSearch(search: $search) {
      edges {
        node {
          assetClass
          symbol
          venues {
            venue
            venueAccountDescs {
              ...VenueAccountDescContent
            }
            instrument {
              baseInstrument {
                assetClass
                symbol
                description
                quoteCurrency
                inverseContract
                venueType
                venueName
              }
              forexSpotProperties {
                baseCurrency
              }
              tradingConstraints {
                minQty
                maxQty
                minQuoteQty
                maxQuoteQty
                qtyIncr
                minPrice
                maxPrice
                priceIncr
                minNotional
                contractSize
                tradeable
              }
              instrumentIdentifiers {
                adapterTicker
                instrumentId
                tradingViewId
                venueTradingViewId
              }
              archivedAt
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${VenueAccountDescContentFragmentDoc}
`;

/**
 * __useSymbolSearchQuery__
 *
 * To run a query within a React component, call `useSymbolSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSymbolSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSymbolSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSymbolSearchQuery(
  baseOptions: Apollo.QueryHookOptions<SymbolSearchQuery, SymbolSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SymbolSearchQuery, SymbolSearchQueryVariables>(
    SymbolSearchDocument,
    options,
  );
}
export function useSymbolSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SymbolSearchQuery, SymbolSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SymbolSearchQuery, SymbolSearchQueryVariables>(
    SymbolSearchDocument,
    options,
  );
}
export type SymbolSearchQueryHookResult = ReturnType<typeof useSymbolSearchQuery>;
export type SymbolSearchLazyQueryHookResult = ReturnType<typeof useSymbolSearchLazyQuery>;
export type SymbolSearchQueryResult = Apollo.QueryResult<
  SymbolSearchQuery,
  SymbolSearchQueryVariables
>;
export const SystemCurrencyDocument = gql`
  query SystemCurrency {
    systemCurrency
  }
`;

/**
 * __useSystemCurrencyQuery__
 *
 * To run a query within a React component, call `useSystemCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemCurrencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemCurrencyQuery(
  baseOptions?: Apollo.QueryHookOptions<SystemCurrencyQuery, SystemCurrencyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SystemCurrencyQuery, SystemCurrencyQueryVariables>(
    SystemCurrencyDocument,
    options,
  );
}
export function useSystemCurrencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SystemCurrencyQuery, SystemCurrencyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SystemCurrencyQuery, SystemCurrencyQueryVariables>(
    SystemCurrencyDocument,
    options,
  );
}
export type SystemCurrencyQueryHookResult = ReturnType<typeof useSystemCurrencyQuery>;
export type SystemCurrencyLazyQueryHookResult = ReturnType<typeof useSystemCurrencyLazyQuery>;
export type SystemCurrencyQueryResult = Apollo.QueryResult<
  SystemCurrencyQuery,
  SystemCurrencyQueryVariables
>;
export const TargetStatesDocument = gql`
  subscription TargetStates {
    targetStates {
      target
      targetName
      capabilities {
        capability
        healthStatus
        timestamp
        message
      }
    }
  }
`;

/**
 * __useTargetStatesSubscription__
 *
 * To run a query within a React component, call `useTargetStatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTargetStatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTargetStatesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTargetStatesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TargetStatesSubscription,
    TargetStatesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TargetStatesSubscription, TargetStatesSubscriptionVariables>(
    TargetStatesDocument,
    options,
  );
}
export type TargetStatesSubscriptionHookResult = ReturnType<typeof useTargetStatesSubscription>;
export type TargetStatesSubscriptionResult = Apollo.SubscriptionResult<TargetStatesSubscription>;
export const TickDocument = gql`
  subscription Tick($venueAccount: String, $instrumentId: String!, $portfolioId: String) {
    tick(venueAccount: $venueAccount, instrumentId: $instrumentId, portfolioId: $portfolioId) {
      instrument {
        ...InstrumentContent
      }
      venue
      venueAccount
      dateTime
      bidPrice
      bidSize
      askPrice
      askSize
      vol
      lastPrice
      lastSize
      side
      marketDataType
    }
  }
  ${InstrumentContentFragmentDoc}
`;

/**
 * __useTickSubscription__
 *
 * To run a query within a React component, call `useTickSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTickSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTickSubscription({
 *   variables: {
 *      venueAccount: // value for 'venueAccount'
 *      instrumentId: // value for 'instrumentId'
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useTickSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<TickSubscription, TickSubscriptionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TickSubscription, TickSubscriptionVariables>(TickDocument, options);
}
export type TickSubscriptionHookResult = ReturnType<typeof useTickSubscription>;
export type TickSubscriptionResult = Apollo.SubscriptionResult<TickSubscription>;
export const TimelineDocument = gql`
  query Timeline($search: TimelineSearchInput) {
    timeline(search: $search) {
      edges {
        node {
          type
          data {
            ... on OrderStateResponse {
              ...OrderStateContent
            }
            ... on MatchResponse {
              id
              makers {
                ...CounterpartyContent
              }
              primarySymbolQuotes {
                ...MarketQuoteContent
              }
              secondarySymbolQuotes {
                ...MarketQuoteContent
              }
              taker {
                ...CounterpartyContent
              }
              timestamp
            }
            ... on HedgeResult {
              timestamp
              clobOrderId
              matchId
              estimatedPrice
              hedgeOrderId
              hedgingVenue
              hedgeOrderQuantity
              hedgeOrderLimitPrice
              hedgeOrderSide
              success
              executionPrice
              hedgeOrderAmount
              hedgeOrderAmountInClobQuoteCurrency
              primarySymbolQuote {
                ...MarketQuoteContent
              }
              secondarySymbolQuote {
                ...MarketQuoteContent
              }
              breakEvenFxRate
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${OrderStateContentFragmentDoc}
  ${CounterpartyContentFragmentDoc}
  ${MarketQuoteContentFragmentDoc}
`;

/**
 * __useTimelineQuery__
 *
 * To run a query within a React component, call `useTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTimelineQuery(
  baseOptions?: Apollo.QueryHookOptions<TimelineQuery, TimelineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimelineQuery, TimelineQueryVariables>(TimelineDocument, options);
}
export function useTimelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimelineQuery, TimelineQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimelineQuery, TimelineQueryVariables>(TimelineDocument, options);
}
export type TimelineQueryHookResult = ReturnType<typeof useTimelineQuery>;
export type TimelineLazyQueryHookResult = ReturnType<typeof useTimelineLazyQuery>;
export type TimelineQueryResult = Apollo.QueryResult<TimelineQuery, TimelineQueryVariables>;
export const TransactionTypesDocument = gql`
  query TransactionTypes {
    transactionTypes
  }
`;

/**
 * __useTransactionTypesQuery__
 *
 * To run a query within a React component, call `useTransactionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransactionTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<TransactionTypesQuery, TransactionTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransactionTypesQuery, TransactionTypesQueryVariables>(
    TransactionTypesDocument,
    options,
  );
}
export function useTransactionTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TransactionTypesQuery, TransactionTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransactionTypesQuery, TransactionTypesQueryVariables>(
    TransactionTypesDocument,
    options,
  );
}
export type TransactionTypesQueryHookResult = ReturnType<typeof useTransactionTypesQuery>;
export type TransactionTypesLazyQueryHookResult = ReturnType<typeof useTransactionTypesLazyQuery>;
export type TransactionTypesQueryResult = Apollo.QueryResult<
  TransactionTypesQuery,
  TransactionTypesQueryVariables
>;
export const TransactionsDocument = gql`
  query Transactions($search: TransactionSearchInput!) {
    transactions(search: $search) {
      edges {
        node {
          ... on ClientCashTrade {
            orderId
            uuid
            updatedAt
            dateTime
            fee
            feeCurrency
            description
            quantity
            price
            currency
            intOrderId
            extOrderId
            baseCurrency
            portfolioId
            portfolioName
            counterPortfolioId
            counterPortfolioName
            rootExecution {
              orderId
              executionId
            }
            settled
            settledDateTime
          }
          ... on StreetAssetTrade {
            orderId
            uuid
            updatedAt
            dateTime
            fee
            feeCurrency
            description
            quantity
            price
            currency
            intOrderId
            extOrderId
            instrument {
              ...InstrumentContent
            }
            portfolioId
            portfolioName
            venueAccount
            venueAccountName
            rootOrderId
            settled
            settledDateTime
          }
          ... on ClientAssetTrade {
            orderId
            uuid
            updatedAt
            dateTime
            fee
            feeCurrency
            description
            quantity
            price
            currency
            intOrderId
            extOrderId
            instrument {
              ...InstrumentContent
            }
            portfolioId
            portfolioName
            counterPortfolioId
            counterPortfolioName
            rootExecution {
              orderId
              executionId
            }
            settled
            settledDateTime
          }
          ... on StreetCashTrade {
            orderId
            uuid
            updatedAt
            dateTime
            fee
            feeCurrency
            description
            quantity
            price
            currency
            intOrderId
            extOrderId
            baseCurrency
            portfolioId
            portfolioName
            venueAccount
            venueAccountName
            rootOrderId
            settled
            settledDateTime
          }
          ... on Withdrawal {
            uuid
            updatedAt
            dateTime
            executionId
            venueExecutionId
            description
            quantity
            currency
            portfolioId
            portfolioName
            account
            accountName
            settled
            settledDateTime
            feeAccountId
            feeAccountName
            feePortfolioId
            feePortfolioName
          }
          ... on Deposit {
            uuid
            updatedAt
            dateTime
            executionId
            venueExecutionId
            description
            quantity
            currency
            portfolioId
            portfolioName
            account
            accountName
            settled
            settledDateTime
            feeAccountId
            feeAccountName
            feePortfolioId
            feePortfolioName
          }
          ... on AccountCashTransfer {
            uuid
            updatedAt
            dateTime
            executionId
            venueExecutionId
            description
            quantity
            currency
            sourceAccountId
            sourceAccountName
            targetAccountId
            targetAccountName
            settled
            settledDateTime
            feeAccountId
            feeAccountName
            feePortfolioId
            feePortfolioName
          }
          ... on PortfolioCashTransfer {
            uuid
            updatedAt
            dateTime
            executionId
            venueExecutionId
            description
            quantity
            currency
            sourcePortfolioId
            sourcePortfolioName
            targetPortfolioId
            targetPortfolioName
            settled
            settledDateTime
            feePortfolioId
            feePortfolioName
          }
          ... on Settlement {
            uuid
            updatedAt
            dateTime
            description
            settledTransactionIds
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    transactionTypes
  }
  ${InstrumentContentFragmentDoc}
`;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<TransactionsQuery, TransactionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransactionsQuery, TransactionsQueryVariables>(
    TransactionsDocument,
    options,
  );
}
export function useTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TransactionsQuery, TransactionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransactionsQuery, TransactionsQueryVariables>(
    TransactionsDocument,
    options,
  );
}
export type TransactionsQueryHookResult = ReturnType<typeof useTransactionsQuery>;
export type TransactionsLazyQueryHookResult = ReturnType<typeof useTransactionsLazyQuery>;
export type TransactionsQueryResult = Apollo.QueryResult<
  TransactionsQuery,
  TransactionsQueryVariables
>;
export const TransactionsWithSnapshotDocument = gql`
  subscription TransactionsWithSnapshot($search: TransactionSearchInput!) {
    transactionsWithSnapshot(search: $search) {
      ... on ClientCashTrade {
        orderId
        uuid
        updatedAt
        dateTime
        fee
        feeCurrency
        description
        quantity
        price
        currency
        intOrderId
        extOrderId
        baseCurrency
        portfolioId
        portfolioName
        counterPortfolioId
        counterPortfolioName
        rootExecution {
          orderId
          executionId
        }
        settled
        settledDateTime
      }
      ... on StreetAssetTrade {
        orderId
        uuid
        updatedAt
        dateTime
        fee
        feeCurrency
        description
        quantity
        price
        currency
        intOrderId
        extOrderId
        instrument {
          ...InstrumentContent
        }
        portfolioId
        portfolioName
        venueAccount
        venueAccountName
        rootOrderId
        settled
        settledDateTime
      }
      ... on ClientAssetTrade {
        orderId
        uuid
        updatedAt
        dateTime
        fee
        feeCurrency
        description
        quantity
        price
        currency
        intOrderId
        extOrderId
        instrument {
          ...InstrumentContent
        }
        portfolioId
        portfolioName
        counterPortfolioId
        counterPortfolioName
        rootExecution {
          orderId
          executionId
        }
        settled
        settledDateTime
      }
      ... on StreetCashTrade {
        orderId
        uuid
        updatedAt
        dateTime
        fee
        feeCurrency
        description
        quantity
        price
        currency
        intOrderId
        extOrderId
        baseCurrency
        portfolioId
        portfolioName
        venueAccount
        venueAccountName
        rootOrderId
        settled
        settledDateTime
      }
      ... on Withdrawal {
        uuid
        updatedAt
        dateTime
        executionId
        venueExecutionId
        description
        quantity
        currency
        portfolioId
        portfolioName
        account
        accountName
        settled
        settledDateTime
        feeAccountId
        feeAccountName
        feePortfolioId
        feePortfolioName
      }
      ... on Deposit {
        uuid
        updatedAt
        dateTime
        executionId
        venueExecutionId
        description
        quantity
        currency
        portfolioId
        portfolioName
        account
        accountName
        settled
        settledDateTime
        feeAccountId
        feeAccountName
        feePortfolioId
        feePortfolioName
      }
      ... on AccountCashTransfer {
        uuid
        updatedAt
        dateTime
        executionId
        venueExecutionId
        description
        quantity
        currency
        sourceAccountId
        sourceAccountName
        targetAccountId
        targetAccountName
        settled
        settledDateTime
        feeAccountId
        feeAccountName
        feePortfolioId
        feePortfolioName
      }
      ... on PortfolioCashTransfer {
        uuid
        updatedAt
        dateTime
        executionId
        venueExecutionId
        description
        quantity
        currency
        sourcePortfolioId
        sourcePortfolioName
        targetPortfolioId
        targetPortfolioName
        settled
        settledDateTime
        feePortfolioId
        feePortfolioName
      }
      ... on Settlement {
        uuid
        updatedAt
        dateTime
        description
        settledTransactionIds
      }
    }
  }
  ${InstrumentContentFragmentDoc}
`;

/**
 * __useTransactionsWithSnapshotSubscription__
 *
 * To run a query within a React component, call `useTransactionsWithSnapshotSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsWithSnapshotSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsWithSnapshotSubscription({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTransactionsWithSnapshotSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    TransactionsWithSnapshotSubscription,
    TransactionsWithSnapshotSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TransactionsWithSnapshotSubscription,
    TransactionsWithSnapshotSubscriptionVariables
  >(TransactionsWithSnapshotDocument, options);
}
export type TransactionsWithSnapshotSubscriptionHookResult = ReturnType<
  typeof useTransactionsWithSnapshotSubscription
>;
export type TransactionsWithSnapshotSubscriptionResult =
  Apollo.SubscriptionResult<TransactionsWithSnapshotSubscription>;
export const UpdateConversionSourceDocument = gql`
  mutation UpdateConversionSource($request: UpdateConversionSourceInput!) {
    updateConversionSource(request: $request) {
      status
    }
  }
`;
export type UpdateConversionSourceMutationFn = Apollo.MutationFunction<
  UpdateConversionSourceMutation,
  UpdateConversionSourceMutationVariables
>;

/**
 * __useUpdateConversionSourceMutation__
 *
 * To run a mutation, you first call `useUpdateConversionSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConversionSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConversionSourceMutation, { data, loading, error }] = useUpdateConversionSourceMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateConversionSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConversionSourceMutation,
    UpdateConversionSourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConversionSourceMutation,
    UpdateConversionSourceMutationVariables
  >(UpdateConversionSourceDocument, options);
}
export type UpdateConversionSourceMutationHookResult = ReturnType<
  typeof useUpdateConversionSourceMutation
>;
export type UpdateConversionSourceMutationResult =
  Apollo.MutationResult<UpdateConversionSourceMutation>;
export type UpdateConversionSourceMutationOptions = Apollo.BaseMutationOptions<
  UpdateConversionSourceMutation,
  UpdateConversionSourceMutationVariables
>;
export const UpdateInstrumentDocument = gql`
  mutation UpdateInstrument($request: UpdateInstrumentInput!) {
    updateInstrument(request: $request) {
      status
    }
  }
`;
export type UpdateInstrumentMutationFn = Apollo.MutationFunction<
  UpdateInstrumentMutation,
  UpdateInstrumentMutationVariables
>;

/**
 * __useUpdateInstrumentMutation__
 *
 * To run a mutation, you first call `useUpdateInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstrumentMutation, { data, loading, error }] = useUpdateInstrumentMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateInstrumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInstrumentMutation,
    UpdateInstrumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInstrumentMutation, UpdateInstrumentMutationVariables>(
    UpdateInstrumentDocument,
    options,
  );
}
export type UpdateInstrumentMutationHookResult = ReturnType<typeof useUpdateInstrumentMutation>;
export type UpdateInstrumentMutationResult = Apollo.MutationResult<UpdateInstrumentMutation>;
export type UpdateInstrumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateInstrumentMutation,
  UpdateInstrumentMutationVariables
>;
export const UpdateInstrumentQuotingConfigurationDocument = gql`
  mutation UpdateInstrumentQuotingConfiguration(
    $clobUid: String!
    $request: InstrumentQuotingConfigurationInput!
  ) {
    updateInstrumentQuotingConfiguration(clobUid: $clobUid, request: $request) {
      resultsPerInstrument {
        ...InstrumentValidationResult
      }
    }
  }
  ${InstrumentValidationResultFragmentDoc}
`;
export type UpdateInstrumentQuotingConfigurationMutationFn = Apollo.MutationFunction<
  UpdateInstrumentQuotingConfigurationMutation,
  UpdateInstrumentQuotingConfigurationMutationVariables
>;

/**
 * __useUpdateInstrumentQuotingConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateInstrumentQuotingConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstrumentQuotingConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstrumentQuotingConfigurationMutation, { data, loading, error }] = useUpdateInstrumentQuotingConfigurationMutation({
 *   variables: {
 *      clobUid: // value for 'clobUid'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateInstrumentQuotingConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInstrumentQuotingConfigurationMutation,
    UpdateInstrumentQuotingConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInstrumentQuotingConfigurationMutation,
    UpdateInstrumentQuotingConfigurationMutationVariables
  >(UpdateInstrumentQuotingConfigurationDocument, options);
}
export type UpdateInstrumentQuotingConfigurationMutationHookResult = ReturnType<
  typeof useUpdateInstrumentQuotingConfigurationMutation
>;
export type UpdateInstrumentQuotingConfigurationMutationResult =
  Apollo.MutationResult<UpdateInstrumentQuotingConfigurationMutation>;
export type UpdateInstrumentQuotingConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdateInstrumentQuotingConfigurationMutation,
  UpdateInstrumentQuotingConfigurationMutationVariables
>;
export const UpdateInternalExchangeConfigurationDocument = gql`
  mutation UpdateInternalExchangeConfiguration($request: InternalExchangeConfigurationInput!) {
    updateInternalExchangeConfiguration(request: $request) {
      status
    }
  }
`;
export type UpdateInternalExchangeConfigurationMutationFn = Apollo.MutationFunction<
  UpdateInternalExchangeConfigurationMutation,
  UpdateInternalExchangeConfigurationMutationVariables
>;

/**
 * __useUpdateInternalExchangeConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateInternalExchangeConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInternalExchangeConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInternalExchangeConfigurationMutation, { data, loading, error }] = useUpdateInternalExchangeConfigurationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateInternalExchangeConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInternalExchangeConfigurationMutation,
    UpdateInternalExchangeConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInternalExchangeConfigurationMutation,
    UpdateInternalExchangeConfigurationMutationVariables
  >(UpdateInternalExchangeConfigurationDocument, options);
}
export type UpdateInternalExchangeConfigurationMutationHookResult = ReturnType<
  typeof useUpdateInternalExchangeConfigurationMutation
>;
export type UpdateInternalExchangeConfigurationMutationResult =
  Apollo.MutationResult<UpdateInternalExchangeConfigurationMutation>;
export type UpdateInternalExchangeConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdateInternalExchangeConfigurationMutation,
  UpdateInternalExchangeConfigurationMutationVariables
>;
export const UpdatePortfolioDocument = gql`
  mutation UpdatePortfolio($request: UpdatePortfolioInput!) {
    updatePortfolio(request: $request) {
      status
    }
  }
`;
export type UpdatePortfolioMutationFn = Apollo.MutationFunction<
  UpdatePortfolioMutation,
  UpdatePortfolioMutationVariables
>;

/**
 * __useUpdatePortfolioMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioMutation, { data, loading, error }] = useUpdatePortfolioMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdatePortfolioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePortfolioMutation,
    UpdatePortfolioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePortfolioMutation, UpdatePortfolioMutationVariables>(
    UpdatePortfolioDocument,
    options,
  );
}
export type UpdatePortfolioMutationHookResult = ReturnType<typeof useUpdatePortfolioMutation>;
export type UpdatePortfolioMutationResult = Apollo.MutationResult<UpdatePortfolioMutation>;
export type UpdatePortfolioMutationOptions = Apollo.BaseMutationOptions<
  UpdatePortfolioMutation,
  UpdatePortfolioMutationVariables
>;
export const UpdatePortfolioConfigurationDocument = gql`
  mutation updatePortfolioConfiguration($portfolioId: ID!, $request: PortfolioConfigurationInput!) {
    updatePortfolioConfiguration(portfolioId: $portfolioId, request: $request) {
      resultsPerInstrument {
        ...InstrumentValidationResult
      }
    }
  }
  ${InstrumentValidationResultFragmentDoc}
`;
export type UpdatePortfolioConfigurationMutationFn = Apollo.MutationFunction<
  UpdatePortfolioConfigurationMutation,
  UpdatePortfolioConfigurationMutationVariables
>;

/**
 * __useUpdatePortfolioConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioConfigurationMutation, { data, loading, error }] = useUpdatePortfolioConfigurationMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdatePortfolioConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePortfolioConfigurationMutation,
    UpdatePortfolioConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePortfolioConfigurationMutation,
    UpdatePortfolioConfigurationMutationVariables
  >(UpdatePortfolioConfigurationDocument, options);
}
export type UpdatePortfolioConfigurationMutationHookResult = ReturnType<
  typeof useUpdatePortfolioConfigurationMutation
>;
export type UpdatePortfolioConfigurationMutationResult =
  Apollo.MutationResult<UpdatePortfolioConfigurationMutation>;
export type UpdatePortfolioConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePortfolioConfigurationMutation,
  UpdatePortfolioConfigurationMutationVariables
>;
export const UpdatePortfolioExecutionConfigurationDocument = gql`
  mutation UpdatePortfolioExecutionConfiguration(
    $portfolioId: ID!
    $request: ExecutionConfigurationInput!
  ) {
    updatePortfolioExecutionConfiguration(portfolioId: $portfolioId, request: $request) {
      resultsPerInstrument {
        ...InstrumentValidationResult
      }
    }
  }
  ${InstrumentValidationResultFragmentDoc}
`;
export type UpdatePortfolioExecutionConfigurationMutationFn = Apollo.MutationFunction<
  UpdatePortfolioExecutionConfigurationMutation,
  UpdatePortfolioExecutionConfigurationMutationVariables
>;

/**
 * __useUpdatePortfolioExecutionConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioExecutionConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioExecutionConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioExecutionConfigurationMutation, { data, loading, error }] = useUpdatePortfolioExecutionConfigurationMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdatePortfolioExecutionConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePortfolioExecutionConfigurationMutation,
    UpdatePortfolioExecutionConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePortfolioExecutionConfigurationMutation,
    UpdatePortfolioExecutionConfigurationMutationVariables
  >(UpdatePortfolioExecutionConfigurationDocument, options);
}
export type UpdatePortfolioExecutionConfigurationMutationHookResult = ReturnType<
  typeof useUpdatePortfolioExecutionConfigurationMutation
>;
export type UpdatePortfolioExecutionConfigurationMutationResult =
  Apollo.MutationResult<UpdatePortfolioExecutionConfigurationMutation>;
export type UpdatePortfolioExecutionConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePortfolioExecutionConfigurationMutation,
  UpdatePortfolioExecutionConfigurationMutationVariables
>;
export const UpdatePortfolioGroupConfigurationDocument = gql`
  mutation UpdatePortfolioGroupConfiguration(
    $portfolioGroupId: ID!
    $request: PortfolioGroupConfigurationInput!
  ) {
    updatePortfolioGroupConfiguration(portfolioGroupId: $portfolioGroupId, request: $request)
  }
`;
export type UpdatePortfolioGroupConfigurationMutationFn = Apollo.MutationFunction<
  UpdatePortfolioGroupConfigurationMutation,
  UpdatePortfolioGroupConfigurationMutationVariables
>;

/**
 * __useUpdatePortfolioGroupConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioGroupConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioGroupConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioGroupConfigurationMutation, { data, loading, error }] = useUpdatePortfolioGroupConfigurationMutation({
 *   variables: {
 *      portfolioGroupId: // value for 'portfolioGroupId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdatePortfolioGroupConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePortfolioGroupConfigurationMutation,
    UpdatePortfolioGroupConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePortfolioGroupConfigurationMutation,
    UpdatePortfolioGroupConfigurationMutationVariables
  >(UpdatePortfolioGroupConfigurationDocument, options);
}
export type UpdatePortfolioGroupConfigurationMutationHookResult = ReturnType<
  typeof useUpdatePortfolioGroupConfigurationMutation
>;
export type UpdatePortfolioGroupConfigurationMutationResult =
  Apollo.MutationResult<UpdatePortfolioGroupConfigurationMutation>;
export type UpdatePortfolioGroupConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePortfolioGroupConfigurationMutation,
  UpdatePortfolioGroupConfigurationMutationVariables
>;
export const UpdatePortfolioGroupExecutionConfigurationDocument = gql`
  mutation UpdatePortfolioGroupExecutionConfiguration(
    $portfolioGroupId: ID!
    $request: ExecutionConfigurationInput!
  ) {
    updatePortfolioGroupExecutionConfiguration(
      portfolioGroupId: $portfolioGroupId
      request: $request
    )
  }
`;
export type UpdatePortfolioGroupExecutionConfigurationMutationFn = Apollo.MutationFunction<
  UpdatePortfolioGroupExecutionConfigurationMutation,
  UpdatePortfolioGroupExecutionConfigurationMutationVariables
>;

/**
 * __useUpdatePortfolioGroupExecutionConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioGroupExecutionConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioGroupExecutionConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioGroupExecutionConfigurationMutation, { data, loading, error }] = useUpdatePortfolioGroupExecutionConfigurationMutation({
 *   variables: {
 *      portfolioGroupId: // value for 'portfolioGroupId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdatePortfolioGroupExecutionConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePortfolioGroupExecutionConfigurationMutation,
    UpdatePortfolioGroupExecutionConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePortfolioGroupExecutionConfigurationMutation,
    UpdatePortfolioGroupExecutionConfigurationMutationVariables
  >(UpdatePortfolioGroupExecutionConfigurationDocument, options);
}
export type UpdatePortfolioGroupExecutionConfigurationMutationHookResult = ReturnType<
  typeof useUpdatePortfolioGroupExecutionConfigurationMutation
>;
export type UpdatePortfolioGroupExecutionConfigurationMutationResult =
  Apollo.MutationResult<UpdatePortfolioGroupExecutionConfigurationMutation>;
export type UpdatePortfolioGroupExecutionConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePortfolioGroupExecutionConfigurationMutation,
  UpdatePortfolioGroupExecutionConfigurationMutationVariables
>;
export const UpdatePortfolioGroupInstrumentConfigurationDocument = gql`
  mutation UpdatePortfolioGroupInstrumentConfiguration(
    $portfolioGroupId: ID!
    $request: InstrumentConfigurationInput!
  ) {
    updatePortfolioGroupInstrumentConfiguration(
      portfolioGroupId: $portfolioGroupId
      request: $request
    )
  }
`;
export type UpdatePortfolioGroupInstrumentConfigurationMutationFn = Apollo.MutationFunction<
  UpdatePortfolioGroupInstrumentConfigurationMutation,
  UpdatePortfolioGroupInstrumentConfigurationMutationVariables
>;

/**
 * __useUpdatePortfolioGroupInstrumentConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioGroupInstrumentConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioGroupInstrumentConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioGroupInstrumentConfigurationMutation, { data, loading, error }] = useUpdatePortfolioGroupInstrumentConfigurationMutation({
 *   variables: {
 *      portfolioGroupId: // value for 'portfolioGroupId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdatePortfolioGroupInstrumentConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePortfolioGroupInstrumentConfigurationMutation,
    UpdatePortfolioGroupInstrumentConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePortfolioGroupInstrumentConfigurationMutation,
    UpdatePortfolioGroupInstrumentConfigurationMutationVariables
  >(UpdatePortfolioGroupInstrumentConfigurationDocument, options);
}
export type UpdatePortfolioGroupInstrumentConfigurationMutationHookResult = ReturnType<
  typeof useUpdatePortfolioGroupInstrumentConfigurationMutation
>;
export type UpdatePortfolioGroupInstrumentConfigurationMutationResult =
  Apollo.MutationResult<UpdatePortfolioGroupInstrumentConfigurationMutation>;
export type UpdatePortfolioGroupInstrumentConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePortfolioGroupInstrumentConfigurationMutation,
  UpdatePortfolioGroupInstrumentConfigurationMutationVariables
>;
export const UpdatePortfolioGroupPricingConfigurationDocument = gql`
  mutation UpdatePortfolioGroupPricingConfiguration(
    $portfolioGroupId: ID!
    $request: PricingConfigurationInput!
  ) {
    updatePortfolioGroupPricingConfiguration(portfolioGroupId: $portfolioGroupId, request: $request)
  }
`;
export type UpdatePortfolioGroupPricingConfigurationMutationFn = Apollo.MutationFunction<
  UpdatePortfolioGroupPricingConfigurationMutation,
  UpdatePortfolioGroupPricingConfigurationMutationVariables
>;

/**
 * __useUpdatePortfolioGroupPricingConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioGroupPricingConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioGroupPricingConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioGroupPricingConfigurationMutation, { data, loading, error }] = useUpdatePortfolioGroupPricingConfigurationMutation({
 *   variables: {
 *      portfolioGroupId: // value for 'portfolioGroupId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdatePortfolioGroupPricingConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePortfolioGroupPricingConfigurationMutation,
    UpdatePortfolioGroupPricingConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePortfolioGroupPricingConfigurationMutation,
    UpdatePortfolioGroupPricingConfigurationMutationVariables
  >(UpdatePortfolioGroupPricingConfigurationDocument, options);
}
export type UpdatePortfolioGroupPricingConfigurationMutationHookResult = ReturnType<
  typeof useUpdatePortfolioGroupPricingConfigurationMutation
>;
export type UpdatePortfolioGroupPricingConfigurationMutationResult =
  Apollo.MutationResult<UpdatePortfolioGroupPricingConfigurationMutation>;
export type UpdatePortfolioGroupPricingConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePortfolioGroupPricingConfigurationMutation,
  UpdatePortfolioGroupPricingConfigurationMutationVariables
>;
export const UpdatePortfolioHedgingConfigurationDocument = gql`
  mutation UpdatePortfolioHedgingConfiguration(
    $portfolioId: ID!
    $request: HedgingConfigurationInput!
  ) {
    updatePortfolioHedgingConfiguration(portfolioId: $portfolioId, request: $request)
  }
`;
export type UpdatePortfolioHedgingConfigurationMutationFn = Apollo.MutationFunction<
  UpdatePortfolioHedgingConfigurationMutation,
  UpdatePortfolioHedgingConfigurationMutationVariables
>;

/**
 * __useUpdatePortfolioHedgingConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioHedgingConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioHedgingConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioHedgingConfigurationMutation, { data, loading, error }] = useUpdatePortfolioHedgingConfigurationMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdatePortfolioHedgingConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePortfolioHedgingConfigurationMutation,
    UpdatePortfolioHedgingConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePortfolioHedgingConfigurationMutation,
    UpdatePortfolioHedgingConfigurationMutationVariables
  >(UpdatePortfolioHedgingConfigurationDocument, options);
}
export type UpdatePortfolioHedgingConfigurationMutationHookResult = ReturnType<
  typeof useUpdatePortfolioHedgingConfigurationMutation
>;
export type UpdatePortfolioHedgingConfigurationMutationResult =
  Apollo.MutationResult<UpdatePortfolioHedgingConfigurationMutation>;
export type UpdatePortfolioHedgingConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePortfolioHedgingConfigurationMutation,
  UpdatePortfolioHedgingConfigurationMutationVariables
>;
export const UpdatePortfolioInstrumentConfigurationDocument = gql`
  mutation UpdatePortfolioInstrumentConfiguration(
    $portfolioId: ID!
    $request: InstrumentConfigurationInput!
  ) {
    updatePortfolioInstrumentConfiguration(portfolioId: $portfolioId, request: $request) {
      resultsPerInstrument {
        ...InstrumentValidationResult
      }
    }
  }
  ${InstrumentValidationResultFragmentDoc}
`;
export type UpdatePortfolioInstrumentConfigurationMutationFn = Apollo.MutationFunction<
  UpdatePortfolioInstrumentConfigurationMutation,
  UpdatePortfolioInstrumentConfigurationMutationVariables
>;

/**
 * __useUpdatePortfolioInstrumentConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioInstrumentConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioInstrumentConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioInstrumentConfigurationMutation, { data, loading, error }] = useUpdatePortfolioInstrumentConfigurationMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdatePortfolioInstrumentConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePortfolioInstrumentConfigurationMutation,
    UpdatePortfolioInstrumentConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePortfolioInstrumentConfigurationMutation,
    UpdatePortfolioInstrumentConfigurationMutationVariables
  >(UpdatePortfolioInstrumentConfigurationDocument, options);
}
export type UpdatePortfolioInstrumentConfigurationMutationHookResult = ReturnType<
  typeof useUpdatePortfolioInstrumentConfigurationMutation
>;
export type UpdatePortfolioInstrumentConfigurationMutationResult =
  Apollo.MutationResult<UpdatePortfolioInstrumentConfigurationMutation>;
export type UpdatePortfolioInstrumentConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePortfolioInstrumentConfigurationMutation,
  UpdatePortfolioInstrumentConfigurationMutationVariables
>;
export const UpdatePortfolioPricingConfigurationDocument = gql`
  mutation UpdatePortfolioPricingConfiguration(
    $portfolioId: ID!
    $request: PricingConfigurationInput!
  ) {
    updatePortfolioPricingConfiguration(portfolioId: $portfolioId, request: $request) {
      resultsPerInstrument {
        ...InstrumentValidationResult
      }
    }
  }
  ${InstrumentValidationResultFragmentDoc}
`;
export type UpdatePortfolioPricingConfigurationMutationFn = Apollo.MutationFunction<
  UpdatePortfolioPricingConfigurationMutation,
  UpdatePortfolioPricingConfigurationMutationVariables
>;

/**
 * __useUpdatePortfolioPricingConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioPricingConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioPricingConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioPricingConfigurationMutation, { data, loading, error }] = useUpdatePortfolioPricingConfigurationMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdatePortfolioPricingConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePortfolioPricingConfigurationMutation,
    UpdatePortfolioPricingConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePortfolioPricingConfigurationMutation,
    UpdatePortfolioPricingConfigurationMutationVariables
  >(UpdatePortfolioPricingConfigurationDocument, options);
}
export type UpdatePortfolioPricingConfigurationMutationHookResult = ReturnType<
  typeof useUpdatePortfolioPricingConfigurationMutation
>;
export type UpdatePortfolioPricingConfigurationMutationResult =
  Apollo.MutationResult<UpdatePortfolioPricingConfigurationMutation>;
export type UpdatePortfolioPricingConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePortfolioPricingConfigurationMutation,
  UpdatePortfolioPricingConfigurationMutationVariables
>;
export const UpdateQuotingConfigurationDocument = gql`
  mutation UpdateQuotingConfiguration($clobUid: String!, $request: QuotingConfigurationInput!) {
    updateQuotingConfiguration(clobUid: $clobUid, request: $request) {
      resultsPerInstrument {
        ...InstrumentValidationResult
      }
    }
  }
  ${InstrumentValidationResultFragmentDoc}
`;
export type UpdateQuotingConfigurationMutationFn = Apollo.MutationFunction<
  UpdateQuotingConfigurationMutation,
  UpdateQuotingConfigurationMutationVariables
>;

/**
 * __useUpdateQuotingConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateQuotingConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuotingConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuotingConfigurationMutation, { data, loading, error }] = useUpdateQuotingConfigurationMutation({
 *   variables: {
 *      clobUid: // value for 'clobUid'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateQuotingConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuotingConfigurationMutation,
    UpdateQuotingConfigurationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateQuotingConfigurationMutation,
    UpdateQuotingConfigurationMutationVariables
  >(UpdateQuotingConfigurationDocument, options);
}
export type UpdateQuotingConfigurationMutationHookResult = ReturnType<
  typeof useUpdateQuotingConfigurationMutation
>;
export type UpdateQuotingConfigurationMutationResult =
  Apollo.MutationResult<UpdateQuotingConfigurationMutation>;
export type UpdateQuotingConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuotingConfigurationMutation,
  UpdateQuotingConfigurationMutationVariables
>;
export const UpdateUserDataDocument = gql`
  mutation UpdateUserData($request: UpdateUserDataInput!) {
    updateUserData(request: $request) {
      data
    }
  }
`;
export type UpdateUserDataMutationFn = Apollo.MutationFunction<
  UpdateUserDataMutation,
  UpdateUserDataMutationVariables
>;

/**
 * __useUpdateUserDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDataMutation, { data, loading, error }] = useUpdateUserDataMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateUserDataMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserDataMutation, UpdateUserDataMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserDataMutation, UpdateUserDataMutationVariables>(
    UpdateUserDataDocument,
    options,
  );
}
export type UpdateUserDataMutationHookResult = ReturnType<typeof useUpdateUserDataMutation>;
export type UpdateUserDataMutationResult = Apollo.MutationResult<UpdateUserDataMutation>;
export type UpdateUserDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserDataMutation,
  UpdateUserDataMutationVariables
>;
export const UpdateVenueAccountDocument = gql`
  mutation UpdateVenueAccount($request: UpdateVenueAccountInput!) {
    updateVenueAccount(request: $request) {
      status
    }
  }
`;
export type UpdateVenueAccountMutationFn = Apollo.MutationFunction<
  UpdateVenueAccountMutation,
  UpdateVenueAccountMutationVariables
>;

/**
 * __useUpdateVenueAccountMutation__
 *
 * To run a mutation, you first call `useUpdateVenueAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueAccountMutation, { data, loading, error }] = useUpdateVenueAccountMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateVenueAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVenueAccountMutation,
    UpdateVenueAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateVenueAccountMutation, UpdateVenueAccountMutationVariables>(
    UpdateVenueAccountDocument,
    options,
  );
}
export type UpdateVenueAccountMutationHookResult = ReturnType<typeof useUpdateVenueAccountMutation>;
export type UpdateVenueAccountMutationResult = Apollo.MutationResult<UpdateVenueAccountMutation>;
export type UpdateVenueAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateVenueAccountMutation,
  UpdateVenueAccountMutationVariables
>;
export const UserDataDocument = gql`
  query UserData {
    userData {
      data
    }
  }
`;

/**
 * __useUserDataQuery__
 *
 * To run a query within a React component, call `useUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDataQuery(
  baseOptions?: Apollo.QueryHookOptions<UserDataQuery, UserDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserDataQuery, UserDataQueryVariables>(UserDataDocument, options);
}
export function useUserDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserDataQuery, UserDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserDataQuery, UserDataQueryVariables>(UserDataDocument, options);
}
export type UserDataQueryHookResult = ReturnType<typeof useUserDataQuery>;
export type UserDataLazyQueryHookResult = ReturnType<typeof useUserDataLazyQuery>;
export type UserDataQueryResult = Apollo.QueryResult<UserDataQuery, UserDataQueryVariables>;
export const UserNamesDocument = gql`
  query UserNames {
    userNames
  }
`;

/**
 * __useUserNamesQuery__
 *
 * To run a query within a React component, call `useUserNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<UserNamesQuery, UserNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserNamesQuery, UserNamesQueryVariables>(UserNamesDocument, options);
}
export function useUserNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserNamesQuery, UserNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserNamesQuery, UserNamesQueryVariables>(UserNamesDocument, options);
}
export type UserNamesQueryHookResult = ReturnType<typeof useUserNamesQuery>;
export type UserNamesLazyQueryHookResult = ReturnType<typeof useUserNamesLazyQuery>;
export type UserNamesQueryResult = Apollo.QueryResult<UserNamesQuery, UserNamesQueryVariables>;
export const VenueAccountDetailsDocument = gql`
  query VenueAccountDetails($request: VenueAccountDetailsInput!) {
    venueAccountDetails(request: $request) {
      venueName
      venueAccountId
      venueAccountName
      venueAccountId
      scopes
      keyValues {
        key
        value
      }
    }
  }
`;

/**
 * __useVenueAccountDetailsQuery__
 *
 * To run a query within a React component, call `useVenueAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenueAccountDetailsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useVenueAccountDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<VenueAccountDetailsQuery, VenueAccountDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VenueAccountDetailsQuery, VenueAccountDetailsQueryVariables>(
    VenueAccountDetailsDocument,
    options,
  );
}
export function useVenueAccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VenueAccountDetailsQuery,
    VenueAccountDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VenueAccountDetailsQuery, VenueAccountDetailsQueryVariables>(
    VenueAccountDetailsDocument,
    options,
  );
}
export type VenueAccountDetailsQueryHookResult = ReturnType<typeof useVenueAccountDetailsQuery>;
export type VenueAccountDetailsLazyQueryHookResult = ReturnType<
  typeof useVenueAccountDetailsLazyQuery
>;
export type VenueAccountDetailsQueryResult = Apollo.QueryResult<
  VenueAccountDetailsQuery,
  VenueAccountDetailsQueryVariables
>;
export const VenueAccountsDocument = gql`
  query VenueAccounts {
    venueAccounts {
      venue
      venueAccounts {
        venueAccountId
        venueAccountName
        venueAccountId
        deactivatedAt
        scopes
      }
    }
  }
`;

/**
 * __useVenueAccountsQuery__
 *
 * To run a query within a React component, call `useVenueAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenueAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVenueAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<VenueAccountsQuery, VenueAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VenueAccountsQuery, VenueAccountsQueryVariables>(
    VenueAccountsDocument,
    options,
  );
}
export function useVenueAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VenueAccountsQuery, VenueAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VenueAccountsQuery, VenueAccountsQueryVariables>(
    VenueAccountsDocument,
    options,
  );
}
export type VenueAccountsQueryHookResult = ReturnType<typeof useVenueAccountsQuery>;
export type VenueAccountsLazyQueryHookResult = ReturnType<typeof useVenueAccountsLazyQuery>;
export type VenueAccountsQueryResult = Apollo.QueryResult<
  VenueAccountsQuery,
  VenueAccountsQueryVariables
>;
export const VenueCapabilitiesDocument = gql`
  query VenueCapabilities {
    venueCapabilities {
      venue
      capabilities {
        tradingCapabilities {
          supportedOrderTypes
          supportedTIFs {
            key
            values
          }
          supportedOrderRequests {
            key
            values
          }
        }
      }
    }
  }
`;

/**
 * __useVenueCapabilitiesQuery__
 *
 * To run a query within a React component, call `useVenueCapabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueCapabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenueCapabilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVenueCapabilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<VenueCapabilitiesQuery, VenueCapabilitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VenueCapabilitiesQuery, VenueCapabilitiesQueryVariables>(
    VenueCapabilitiesDocument,
    options,
  );
}
export function useVenueCapabilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VenueCapabilitiesQuery,
    VenueCapabilitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VenueCapabilitiesQuery, VenueCapabilitiesQueryVariables>(
    VenueCapabilitiesDocument,
    options,
  );
}
export type VenueCapabilitiesQueryHookResult = ReturnType<typeof useVenueCapabilitiesQuery>;
export type VenueCapabilitiesLazyQueryHookResult = ReturnType<typeof useVenueCapabilitiesLazyQuery>;
export type VenueCapabilitiesQueryResult = Apollo.QueryResult<
  VenueCapabilitiesQuery,
  VenueCapabilitiesQueryVariables
>;
export const VenuesDocument = gql`
  query Venues($venueType: VenueType) {
    venues(venueType: $venueType) {
      name
      venueType
    }
  }
`;

/**
 * __useVenuesQuery__
 *
 * To run a query within a React component, call `useVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenuesQuery({
 *   variables: {
 *      venueType: // value for 'venueType'
 *   },
 * });
 */
export function useVenuesQuery(
  baseOptions?: Apollo.QueryHookOptions<VenuesQuery, VenuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VenuesQuery, VenuesQueryVariables>(VenuesDocument, options);
}
export function useVenuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VenuesQuery, VenuesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VenuesQuery, VenuesQueryVariables>(VenuesDocument, options);
}
export type VenuesQueryHookResult = ReturnType<typeof useVenuesQuery>;
export type VenuesLazyQueryHookResult = ReturnType<typeof useVenuesLazyQuery>;
export type VenuesQueryResult = Apollo.QueryResult<VenuesQuery, VenuesQueryVariables>;
export const WalletAccountSearchDocument = gql`
  query WalletAccountSearch($search: WalletAccountSearchInput) {
    walletAccountSearch(search: $search) {
      edges {
        node {
          id
          name
          createdAt
          scopes
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

/**
 * __useWalletAccountSearchQuery__
 *
 * To run a query within a React component, call `useWalletAccountSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletAccountSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletAccountSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useWalletAccountSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WalletAccountSearchQuery,
    WalletAccountSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WalletAccountSearchQuery, WalletAccountSearchQueryVariables>(
    WalletAccountSearchDocument,
    options,
  );
}
export function useWalletAccountSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WalletAccountSearchQuery,
    WalletAccountSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WalletAccountSearchQuery, WalletAccountSearchQueryVariables>(
    WalletAccountSearchDocument,
    options,
  );
}
export type WalletAccountSearchQueryHookResult = ReturnType<typeof useWalletAccountSearchQuery>;
export type WalletAccountSearchLazyQueryHookResult = ReturnType<
  typeof useWalletAccountSearchLazyQuery
>;
export type WalletAccountSearchQueryResult = Apollo.QueryResult<
  WalletAccountSearchQuery,
  WalletAccountSearchQueryVariables
>;

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny =>
  v !== undefined && v !== null;

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v));

export const ApiKeyStatusSchema = z.nativeEnum(ApiKeyStatus);

export const AssetClassSchema = z.nativeEnum(AssetClass);

export const CancelRejectResponseToSchema = z.nativeEnum(CancelRejectResponseTo);

export const CapabilitySchema = z.nativeEnum(Capability);

export const CollectionPredicateTypeSchema = z.nativeEnum(CollectionPredicateType);

export const ConfigurationLevelSchema = z.nativeEnum(ConfigurationLevel);

export const ConfigurationTypeSchema = z.nativeEnum(ConfigurationType);

export const CurrencyCategorySchema = z.nativeEnum(CurrencyCategory);

export const CurrencyTypeSchema = z.nativeEnum(CurrencyType);

export const DatePredicateTypeSchema = z.nativeEnum(DatePredicateType);

export const DayOfTheWeekSchema = z.nativeEnum(DayOfTheWeek);

export const ErrorTypeSchema = z.nativeEnum(ErrorType);

export const ExecTypeSchema = z.nativeEnum(ExecType);

export const ExecutionModeSchema = z.nativeEnum(ExecutionMode);

export const HealthStatusSchema = z.nativeEnum(HealthStatus);

export const InstrumentIdsPredicateTypeSchema = z.nativeEnum(InstrumentIdsPredicateType);

export const InstrumentSortBySchema = z.nativeEnum(InstrumentSortBy);

export const InstrumentTypeSchema = z.nativeEnum(InstrumentType);

export const LedgerEntryTypeSchema = z.nativeEnum(LedgerEntryType);

export const MarketDataTypeSchema = z.nativeEnum(MarketDataType);

export const OrderBookLevelSchema = z.nativeEnum(OrderBookLevel);

export const OrderCategorySchema = z.nativeEnum(OrderCategory);

export const OrderRequestSchema = z.nativeEnum(OrderRequest);

export const OrderStateCollectionFieldSchema = z.nativeEnum(OrderStateCollectionField);

export const OrderStateDateFieldSchema = z.nativeEnum(OrderStateDateField);

export const OrderStateStringFieldSchema = z.nativeEnum(OrderStateStringField);

export const OrderStatusSchema = z.nativeEnum(OrderStatus);

export const OrderTypeSchema = z.nativeEnum(OrderType);

export const ParticipantReferenceTypeSchema = z.nativeEnum(ParticipantReferenceType);

export const PayloadTypeSchema = z.nativeEnum(PayloadType);

export const PortfolioPredicateTypeSchema = z.nativeEnum(PortfolioPredicateType);

export const PortfolioSearchTypeSchema = z.nativeEnum(PortfolioSearchType);

export const PortfolioSortBySchema = z.nativeEnum(PortfolioSortBy);

export const PortfolioTypeSchema = z.nativeEnum(PortfolioType);

export const PreTradeCheckChannelsSchema = z.nativeEnum(PreTradeCheckChannels);

export const PreTradeCheckLevelSchema = z.nativeEnum(PreTradeCheckLevel);

export const PreTradeCheckPropertyTypeSchema = z.nativeEnum(PreTradeCheckPropertyType);

export const PreTradeCheckStatusSchema = z.nativeEnum(PreTradeCheckStatus);

export const QuoteTtlUnitSchema = z.nativeEnum(QuoteTtlUnit);

export const RateSubscriptionHealthSchema = z.nativeEnum(RateSubscriptionHealth);

export const ResourceSchema = z.nativeEnum(Resource);

export const ScopeSchema = z.nativeEnum(Scope);

export const SettlementDirectionSchema = z.nativeEnum(SettlementDirection);

export const SettlementDirectionPrioritySchema = z.nativeEnum(SettlementDirectionPriority);

export const SettlementLegPrioritySchema = z.nativeEnum(SettlementLegPriority);

export const SettlementStatusSchema = z.nativeEnum(SettlementStatus);

export const SideSchema = z.nativeEnum(Side);

export const SimplePredicateTypeSchema = z.nativeEnum(SimplePredicateType);

export const SortingOrderSchema = z.nativeEnum(SortingOrder);

export const StatusSchema = z.nativeEnum(Status);

export const SymbolPredicateTypeSchema = z.nativeEnum(SymbolPredicateType);

export const TifSchema = z.nativeEnum(Tif);

export const TimelineEventTypeSchema = z.nativeEnum(TimelineEventType);

export const TradingModeSchema = z.nativeEnum(TradingMode);

export const TransactionTypeSchema = z.nativeEnum(TransactionType);

export const VenueTypeSchema = z.nativeEnum(VenueType);

export function AccountCapabilitiesSchema(): z.ZodObject<Properties<AccountCapabilities>> {
  return z.object({
    __typename: z.literal('AccountCapabilities').optional(),
    accountPositionsRetrievalSupported: z.boolean().nullish(),
    allocationSupported: z.boolean().nullish(),
    balanceRetrievalBySubAccount: z.boolean().nullish(),
    balanceRetrievalSupported: z.boolean().nullish(),
    balanceStreamingSupported: z.boolean().nullish(),
    depositAddressRetrievalSupported: z.boolean().nullish(),
    extAccountsRetrievalSupported: z.boolean().nullish(),
    orderReconciliationSupported: z.boolean().nullish(),
    subAccountRetrievalSupported: z.boolean().nullish(),
    walletHistorySupported: z.boolean().nullish(),
    withdrawalSupported: z.boolean().nullish(),
  });
}

export function AccountCashTransferSchema(): z.ZodObject<Properties<AccountCashTransfer>> {
  return z.object({
    __typename: z.literal('AccountCashTransfer').optional(),
    currency: z.string(),
    dateTime: z.string(),
    description: z.string(),
    executionId: z.string(),
    feeAccountId: z.string().nullish(),
    feeAccountName: z.string().nullish(),
    feePortfolioId: z.string().nullish(),
    feePortfolioName: z.string().nullish(),
    quantity: z.number(),
    settled: z.string(),
    settledDateTime: z.number().nullish(),
    sourceAccountId: z.string(),
    sourceAccountName: z.string(),
    targetAccountId: z.string(),
    targetAccountName: z.string(),
    updatedAt: z.number(),
    uuid: z.string(),
    venueExecutionId: z.string().nullish(),
  });
}

export function ActivateVenueAccountInputSchema(): z.ZodObject<
  Properties<ActivateVenueAccountInput>
> {
  return z.object({
    correlationObject: z.string().nullish(),
    venueAccountId: z.string().nullish(),
  });
}

export function AddOrRemoveGroupPermissionsInputSchema(): z.ZodObject<
  Properties<AddOrRemoveGroupPermissionsInput>
> {
  return z.object({
    groupName: z.string(),
    permissions: z.array(z.lazy(() => PermissionInputSchema())),
  });
}

export function AddOrRemoveUserPermissionsInputSchema(): z.ZodObject<
  Properties<AddOrRemoveUserPermissionsInput>
> {
  return z.object({
    permissions: z.array(z.lazy(() => PermissionInputSchema())),
    username: z.string(),
  });
}

export function ApiKeySchema(): z.ZodObject<Properties<ApiKey>> {
  return z.object({
    __typename: z.literal('ApiKey').optional(),
    createdAt: z.string(),
    id: z.string(),
    name: z.string().nullish(),
    status: ApiKeyStatusSchema,
  });
}

export function ApiKeyResponseSchema(): z.ZodObject<Properties<ApiKeyResponse>> {
  return z.object({
    __typename: z.literal('ApiKeyResponse').optional(),
    createdAt: z.string(),
    id: z.string(),
    name: z.string().nullish(),
    secret: z.string(),
    status: ApiKeyStatusSchema,
  });
}

export function AuditLogEventSchema(): z.ZodObject<Properties<AuditLogEvent>> {
  return z.object({
    __typename: z.literal('AuditLogEvent').optional(),
    createdAt: z.string().nullish(),
    payload: z.string(),
    payloadType: PayloadTypeSchema,
    source: z.string(),
    uuid: z.string(),
  });
}

export function BaseInstrumentResponseSchema(): z.ZodObject<Properties<BaseInstrumentResponse>> {
  return z.object({
    __typename: z.literal('BaseInstrumentResponse').optional(),
    assetClass: AssetClassSchema,
    description: z.string().nullish(),
    inverseContract: z.boolean().nullish(),
    quoteCurrency: z.string(),
    symbol: z.string().nullish(),
    venueName: z.string(),
    venueType: VenueTypeSchema,
  });
}

export function CancelRejectResponseSchema(): z.ZodObject<Properties<CancelRejectResponse>> {
  return z.object({
    __typename: z.literal('CancelRejectResponse').optional(),
    cancelRejectReason: z.string().nullish(),
    cancelRejectResponseTo: CancelRejectResponseToSchema.nullish(),
    clOrderId: z.string().nullish(),
    clientId: z.string().nullish(),
    orderStatus: OrderStatusSchema.nullish(),
    origClOrderId: z.string().nullish(),
    origOrderId: z.string().nullish(),
    result: z.string().nullish(),
  });
}

export function ClientAssetTradeSchema(): z.ZodObject<Properties<ClientAssetTrade>> {
  return z.object({
    __typename: z.literal('ClientAssetTrade').optional(),
    counterPortfolioId: z.string(),
    counterPortfolioName: z.string(),
    currency: z.string(),
    dateTime: z.string(),
    description: z.string(),
    executionId: z.string(),
    extOrderId: z.string(),
    fee: z.number(),
    feeCurrency: z.string(),
    instrument: InstrumentResponseSchema().nullish(),
    intOrderId: z.string(),
    orderId: z.string(),
    portfolioId: z.string(),
    portfolioName: z.string(),
    price: z.number(),
    quantity: z.number(),
    rootExecution: RootExecutionSchema().nullish(),
    settled: z.string(),
    settledDateTime: z.number().nullish(),
    updatedAt: z.number(),
    uuid: z.string(),
    venueExecutionId: z.string().nullish(),
  });
}

export function ClientCashTradeSchema(): z.ZodObject<Properties<ClientCashTrade>> {
  return z.object({
    __typename: z.literal('ClientCashTrade').optional(),
    baseCurrency: z.string(),
    counterPortfolioId: z.string(),
    counterPortfolioName: z.string(),
    currency: z.string(),
    dateTime: z.string(),
    description: z.string(),
    executionId: z.string(),
    extOrderId: z.string(),
    fee: z.number(),
    feeCurrency: z.string(),
    intOrderId: z.string(),
    orderId: z.string(),
    parentOrderId: z.string().nullish(),
    portfolioId: z.string(),
    portfolioName: z.string(),
    price: z.number(),
    quantity: z.number(),
    rootExecution: RootExecutionSchema().nullish(),
    rootOrderId: z.string().nullish(),
    settled: z.string(),
    settledDateTime: z.number().nullish(),
    underlyingExecutionId: z.string().nullish(),
    updatedAt: z.number(),
    uuid: z.string(),
    venueExecutionId: z.string().nullish(),
  });
}

export function CollectionPredicateInputSchema(): z.ZodObject<
  Properties<CollectionPredicateInput>
> {
  return z.object({
    field: OrderStateCollectionFieldSchema.nullish(),
    method: CollectionPredicateTypeSchema.nullish(),
    value: z.array(z.string().nullable()),
  });
}

export function ConfigFieldSchema(): z.ZodObject<Properties<ConfigField>> {
  return z.object({
    __typename: z.literal('ConfigField').optional(),
    defaultValue: z.string().nullish(),
    description: z.string().nullish(),
    enumValues: z.array(z.string().nullable()).nullish(),
    propertyKey: z.string().nullish(),
    required: z.boolean().nullish(),
    secret: z.boolean().nullish(),
    type: z.string().nullish(),
  });
}

export function ConfigInstrumentValidationResultSchema(): z.ZodObject<
  Properties<ConfigInstrumentValidationResult>
> {
  return z.object({
    __typename: z.literal('ConfigInstrumentValidationResult').optional(),
    errors: z.array(ConfigValidationErrorSchema()),
    instrumentId: z.string(),
    isValid: z.boolean(),
    preventsMarketData: z.boolean(),
    preventsTrading: z.boolean(),
  });
}

export function ConfigValidationErrorSchema(): z.ZodObject<Properties<ConfigValidationError>> {
  return z.object({
    __typename: z.literal('ConfigValidationError').optional(),
    errorMessage: z.string().nullish(),
    errorType: ErrorTypeSchema,
    fieldName: z.string(),
    preventsMarketData: z.boolean(),
    preventsTrading: z.boolean(),
  });
}

export function ConfigValidationResultSchema(): z.ZodObject<Properties<ConfigValidationResult>> {
  return z.object({
    __typename: z.literal('ConfigValidationResult').optional(),
    resultsPerInstrument: z.array(ConfigInstrumentValidationResultSchema()),
  });
}

export function ConversionRateSchema(): z.ZodObject<Properties<ConversionRate>> {
  return z.object({
    baseCurrency: z.string(),
    quoteCurrency: z.string(),
    rate: z.string(),
  });
}

export function ConversionSourceResponseSchema(): z.ZodObject<
  Properties<ConversionSourceResponse>
> {
  return z.object({
    __typename: z.literal('ConversionSourceResponse').optional(),
    priority: z.number(),
    venueAccount: z.string(),
    venueAccountName: z.string(),
  });
}

export function ConversionSourceVenueAccountSchema(): z.ZodObject<
  Properties<ConversionSourceVenueAccount>
> {
  return z.object({
    __typename: z.literal('ConversionSourceVenueAccount').optional(),
    venueAccount: z.string(),
    venueAccountName: z.string(),
  });
}

export function ConversionSourceVenueAccountResponseSchema(): z.ZodObject<
  Properties<ConversionSourceVenueAccountResponse>
> {
  return z.object({
    __typename: z.literal('ConversionSourceVenueAccountResponse').optional(),
    venueAccounts: z.array(ConversionSourceVenueAccountSchema()),
    venueName: z.string(),
  });
}

export function CounterpartySchema(): z.ZodObject<Properties<Counterparty>> {
  return z.object({
    __typename: z.literal('Counterparty').optional(),
    orderId: z.string().nullish(),
    portfolioId: z.string().nullish(),
    portfolioName: z.string().nullish(),
    price: z.number(),
    referenceType: ParticipantReferenceTypeSchema.nullish(),
    venueAccount: z.string().nullish(),
    venueAccountName: z.string().nullish(),
    volume: z.number(),
  });
}

export function CreateBaseInstrumentInputSchema(): z.ZodObject<
  Properties<CreateBaseInstrumentInput>
> {
  return z.object({
    assetClass: AssetClassSchema,
    description: z.string().nullish(),
    inverseContract: z.boolean().nullish(),
    quoteCurrency: z.string(),
    symbol: z.string().nullish(),
    venueName: z.string(),
    venueType: VenueTypeSchema.nullish(),
  });
}

export function CreateConversionSourceInputSchema(): z.ZodObject<
  Properties<CreateConversionSourceInput>
> {
  return z.object({
    priority: z.number().nullish(),
    venueAccount: z.string(),
  });
}

export function CreateForexSpotPropertiesInputSchema(): z.ZodObject<
  Properties<CreateForexSpotPropertiesInput>
> {
  return z.object({
    baseCurrency: z.string(),
  });
}

export function CreateInstrumentIdentifiersInputSchema(): z.ZodObject<
  Properties<CreateInstrumentIdentifiersInput>
> {
  return z.object({
    adapterTicker: z.string().nullish(),
    tradingViewId: z.string().nullish(),
    venueTradingViewId: z.string().nullish(),
  });
}

export function CreateInstrumentInputSchema(): z.ZodObject<Properties<CreateInstrumentInput>> {
  return z.object({
    baseInstrument: z.lazy(() => CreateBaseInstrumentInputSchema()),
    correlationObject: z.string().nullish(),
    forexSpotProperties: z.lazy(() => CreateForexSpotPropertiesInputSchema()),
    instrumentIdentifiers: z.lazy(() => CreateInstrumentIdentifiersInputSchema()),
    tradingConstraints: z.lazy(() => CreateTradingConstraintsInputSchema()),
  });
}

export function CreateInstrumentResultSchema(): z.ZodObject<Properties<CreateInstrumentResult>> {
  return z.object({
    __typename: z.literal('CreateInstrumentResult').optional(),
    status: z.string().nullish(),
  });
}

export function CreatePortfolioInputSchema(): z.ZodObject<Properties<CreatePortfolioInput>> {
  return z.object({
    correlationObject: z.string().nullish(),
    id: z.string().nullish(),
    name: z.string(),
    portfolioCurrency: z.string(),
    portfolioType: PortfolioTypeSchema.nullish(),
    tags: z.array(z.lazy(() => TagInputSchema().nullable())).nullish(),
  });
}

export function CreateRateSubscriptionInputSchema(): z.ZodObject<
  Properties<CreateRateSubscriptionInput>
> {
  return z.object({
    baseCurrency: z.string(),
    quoteCurrency: z.string(),
  });
}

export function CreateSettlementRunInputSchema(): z.ZodObject<
  Properties<CreateSettlementRunInput>
> {
  return z.object({
    accountIds: z.array(z.string()),
    date: z.string(),
  });
}

export function CreateTradingConstraintsInputSchema(): z.ZodObject<
  Properties<CreateTradingConstraintsInput>
> {
  return z.object({
    contractSize: z.string().nullish(),
    maxPrice: z.string().nullish(),
    maxQty: z.string(),
    maxQuoteQty: z.string().nullish(),
    minNotional: z.string().nullish(),
    minPrice: z.string().nullish(),
    minQty: z.string(),
    minQuoteQty: z.string().nullish(),
    priceIncr: z.string(),
    qtyIncr: z.string(),
    quoteQtyIncr: z.string().nullish(),
    tradeable: z.boolean(),
  });
}

export function CreateVenueAccountInputSchema(): z.ZodObject<Properties<CreateVenueAccountInput>> {
  return z.object({
    apiKey: z.string().nullish(),
    apiSecret: z.string().nullish(),
    correlationObject: z.string().nullish(),
    keyValues: z.array(z.lazy(() => KeyValueInputSchema().nullable())).nullish(),
    venueAccountId: z.string().nullish(),
    venueAccountName: z.string(),
    venueName: z.string(),
  });
}

export function CreateWalletAccountInputSchema(): z.ZodObject<
  Properties<CreateWalletAccountInput>
> {
  return z.object({
    correlationObject: z.string().nullish(),
    id: z.string().nullish(),
    name: z.string(),
  });
}

export function CurrencyInputSchema(): z.ZodObject<Properties<CurrencyInput>> {
  return z.object({
    displayPrecision: z.number(),
    precision: z.number(),
    symbol: z.string(),
    type: CurrencyCategorySchema,
  });
}

export function CurrencyResponseSchema(): z.ZodObject<Properties<CurrencyResponse>> {
  return z.object({
    __typename: z.literal('CurrencyResponse').optional(),
    displayPrecision: z.number(),
    precision: z.number(),
    symbol: z.string(),
    type: CurrencyCategorySchema,
  });
}

export function CurrencySearchInputSchema(): z.ZodObject<Properties<CurrencySearchInput>> {
  return z.object({
    symbols: z.array(z.string()).nullish(),
    type: CurrencyCategorySchema.nullish(),
  });
}

export function DatePredicateSchema(): z.ZodObject<Properties<DatePredicate>> {
  return z.object({
    field: OrderStateDateFieldSchema.nullish(),
    method: DatePredicateTypeSchema.nullish(),
    value: z.string(),
  });
}

export function DeactivateVenueAccountInputSchema(): z.ZodObject<
  Properties<DeactivateVenueAccountInput>
> {
  return z.object({
    correlationObject: z.string().nullish(),
    venueAccountId: z.string().nullish(),
  });
}

export function DefaultTifSchema(): z.ZodObject<Properties<DefaultTif>> {
  return z.object({
    __typename: z.literal('DefaultTif').optional(),
    key: OrderTypeSchema.nullish(),
    value: TifSchema.nullish(),
  });
}

export function DeleteRateSubscriptionInputSchema(): z.ZodObject<
  Properties<DeleteRateSubscriptionInput>
> {
  return z.object({
    baseCurrency: z.string(),
    quoteCurrency: z.string(),
  });
}

export function DepositSchema(): z.ZodObject<Properties<Deposit>> {
  return z.object({
    __typename: z.literal('Deposit').optional(),
    account: z.string(),
    accountName: z.string(),
    currency: z.string(),
    dateTime: z.string(),
    description: z.string(),
    executionId: z.string(),
    feeAccountId: z.string().nullish(),
    feeAccountName: z.string().nullish(),
    feePortfolioId: z.string().nullish(),
    feePortfolioName: z.string().nullish(),
    portfolioId: z.string(),
    portfolioName: z.string(),
    quantity: z.number(),
    settled: z.string(),
    settledDateTime: z.number().nullish(),
    updatedAt: z.number(),
    uuid: z.string(),
    venueExecutionId: z.string().nullish(),
  });
}

export function DetailedCapabilitiesSchema(): z.ZodObject<Properties<DetailedCapabilities>> {
  return z.object({
    __typename: z.literal('DetailedCapabilities').optional(),
    accountCapabilities: AccountCapabilitiesSchema().nullish(),
    connectorCapabilities: z.array(CapabilitySchema.nullable()).nullish(),
    dropCopyCapabilities: z.boolean().nullish(),
    genericEventCapabilities: z.boolean().nullish(),
    historicalDataCapabilities: HistoricalDataCapabilitiesSchema().nullish(),
    marketDataCapabilities: MarketDataCapabilitiesSchema().nullish(),
    rateLimited: z.boolean().nullish(),
    referenceDataCapabilities: ReferenceDataCapabilitiesSchema().nullish(),
    tradingCapabilities: TradingCapabilitiesSchema().nullish(),
    transferCapabilities: z.boolean().nullish(),
  });
}

export function EffectiveVenueAccountsResponseSchema(): z.ZodObject<
  Properties<EffectiveVenueAccountsResponse>
> {
  return z.object({
    __typename: z.literal('EffectiveVenueAccountsResponse').optional(),
    isSor: z.boolean().nullish(),
    venueAccounts: z.array(z.string()),
  });
}

export function EventLogResponseSchema(): z.ZodObject<Properties<EventLogResponse>> {
  return z.object({
    __typename: z.literal('EventLogResponse').optional(),
    clientId: z.string(),
    correlationObject: z.string().nullish(),
    eventType: z.string(),
    message: z.string(),
    status: StatusSchema,
    timestamp: z.string(),
  });
}

export function ExecutionConfigurationSchema(): z.ZodObject<Properties<ExecutionConfiguration>> {
  return z.object({
    __typename: z.literal('ExecutionConfiguration').optional(),
    agencyTargetInstrument: InstrumentResponseSchema().nullish(),
    agencyTradingAccount: z.string().nullish(),
    agencyTradingAccountName: z.string().nullish(),
    chargeExchangeFee: z.boolean().nullish(),
    counterPortfolioId: z.string().nullish(),
    counterPortfolioName: z.string().nullish(),
    discloseTradingVenue: z.boolean().nullish(),
    executionMode: ExecutionModeSchema.nullish(),
    fixedFee: z.number().nullish(),
    fixedFeeCurrency: z.string().nullish(),
    minFee: z.number().nullish(),
    minFeeCurrency: z.string().nullish(),
    percentageFee: z.number().nullish(),
    percentageFeeCurrency: z.string().nullish(),
    percentageFeeCurrencyType: CurrencyTypeSchema.nullish(),
    sorTarget: SorTargetSchema().nullish(),
    sorTradingAccountDescs: z.array(VenueAccountDescSchema()).nullish(),
    tradingMode: TradingModeSchema.nullish(),
  });
}

export function ExecutionConfigurationInputSchema(): z.ZodObject<
  Properties<ExecutionConfigurationInput>
> {
  return z.object({
    agencyTargetInstrumentId: z.string().nullish(),
    agencyTradingAccount: z.string().nullish(),
    chargeExchangeFee: z.boolean().nullish(),
    counterPortfolioId: z.string().nullish(),
    discloseTradingVenue: z.boolean().nullish(),
    executionMode: ExecutionModeSchema.nullish(),
    fixedFee: z.number().nullish(),
    fixedFeeCurrency: z.string().nullish(),
    minFee: z.number().nullish(),
    minFeeCurrency: z.string().nullish(),
    percentageFee: z.number().nullish(),
    percentageFeeCurrency: z.string().nullish(),
    percentageFeeCurrencyType: CurrencyTypeSchema.nullish(),
    sorTarget: z.lazy(() => SorTargetInputSchema().nullish()),
    sorTradingAccounts: z.array(z.string()).nullish(),
    tradingMode: TradingModeSchema.nullish(),
  });
}

export function ExecutionReportResponseSchema(): z.ZodObject<Properties<ExecutionReportResponse>> {
  return z.object({
    __typename: z.literal('ExecutionReportResponse').optional(),
    avgPrice: z.string().nullish(),
    clOrderId: z.string().nullish(),
    clientId: z.string().nullish(),
    createdAt: z.string().nullish(),
    cumQty: z.number().nullish(),
    currency: z.string().nullish(),
    execType: ExecTypeSchema.nullish(),
    executionId: z.string().nullish(),
    fee: z.number().nullish(),
    feeCurrency: z.string().nullish(),
    instrumentId: z.string().nullish(),
    instrumentType: InstrumentTypeSchema.nullish(),
    lastPrice: z.string().nullish(),
    lastQty: z.number().nullish(),
    leavesQty: z.number().nullish(),
    orderId: z.string().nullish(),
    orderQty: z.number().nullish(),
    orderStatus: OrderStatusSchema.nullish(),
    orderStatusRequestId: z.string().nullish(),
    origClOrderId: z.string().nullish(),
    parentOrderId: z.string().nullish(),
    portfolioId: z.string().nullish(),
    reason: z.string().nullish(),
    result: z.string().nullish(),
    sequenceNumber: z.number().nullish(),
    side: SideSchema.nullish(),
    symbol: z.string().nullish(),
    targetVenueAccount: z.string().nullish(),
    targetVenueTicker: z.string().nullish(),
    targetVenueTimestamp: z.string().nullish(),
    text: z.string().nullish(),
    timestamp: z.string().nullish(),
    underlyingVenueAccount: z.string().nullish(),
    updatedAt: z.string().nullish(),
    venueAccount: z.string().nullish(),
    venueExecutionId: z.string().nullish(),
  });
}

export function FlatPermissionSchema(): z.ZodObject<Properties<FlatPermission>> {
  return z.object({
    __typename: z.literal('FlatPermission').optional(),
    groups: z.array(GroupPermissionSchema()).nullish(),
    resource: ResourceSchema,
    resourceId: z.string().nullish(),
    userScopes: z.array(ScopeSchema).nullish(),
    username: z.string(),
  });
}

export function ForexSpotPropertiesResponseSchema(): z.ZodObject<
  Properties<ForexSpotPropertiesResponse>
> {
  return z.object({
    __typename: z.literal('ForexSpotPropertiesResponse').optional(),
    baseCurrency: z.string(),
  });
}

export function GroupPermissionSchema(): z.ZodObject<Properties<GroupPermission>> {
  return z.object({
    __typename: z.literal('GroupPermission').optional(),
    groupName: z.string(),
    groupScopes: z.array(ScopeSchema).nullish(),
  });
}

export function GroupResponseSchema(): z.ZodObject<Properties<GroupResponse>> {
  return z.object({
    __typename: z.literal('GroupResponse').optional(),
    name: z.string(),
    permissions: z.array(PermissionSchema()),
  });
}

export function GroupsPermissionsForResourceResponseSchema(): z.ZodObject<
  Properties<GroupsPermissionsForResourceResponse>
> {
  return z.object({
    __typename: z.literal('GroupsPermissionsForResourceResponse').optional(),
    groupName: z.string(),
    scopes: z.array(ScopeSchema),
  });
}

export function HedgeResultSchema(): z.ZodObject<Properties<HedgeResult>> {
  return z.object({
    __typename: z.literal('HedgeResult').optional(),
    breakEvenFxRate: z.number().nullish(),
    clobOrderId: z.string(),
    estimatedPrice: z.number().nullish(),
    executionPrice: z.number().nullish(),
    hedgeOrderAmount: z.number().nullish(),
    hedgeOrderAmountInClobQuoteCurrency: z.number().nullish(),
    hedgeOrderId: z.string(),
    hedgeOrderLimitPrice: z.number().nullish(),
    hedgeOrderQuantity: z.number(),
    hedgeOrderSide: SideSchema,
    hedgingVenue: z.string(),
    matchId: z.string(),
    primarySymbolQuote: MarketQuoteSchema().nullish(),
    secondarySymbolQuote: MarketQuoteSchema().nullish(),
    success: z.boolean(),
    timestamp: z.string(),
  });
}

export function HedgingConfigurationSchema(): z.ZodObject<Properties<HedgingConfiguration>> {
  return z.object({
    __typename: z.literal('HedgingConfiguration').optional(),
    autoHedging: z.boolean().nullish(),
    targetAccountId: z.string().nullish(),
    targetAccountName: z.string().nullish(),
    thresholdConfiguration: z.array(ThresholdConfigurationSchema()).nullish(),
  });
}

export function HedgingConfigurationInputSchema(): z.ZodObject<
  Properties<HedgingConfigurationInput>
> {
  return z.object({
    autoHedging: z.boolean().nullish(),
    targetAccountId: z.string().nullish(),
    thresholdConfiguration: z.array(z.lazy(() => ThresholdConfigurationInputSchema())).nullish(),
  });
}

export function HistoricalDataCapabilitiesSchema(): z.ZodObject<
  Properties<HistoricalDataCapabilities>
> {
  return z.object({
    __typename: z.literal('HistoricalDataCapabilities').optional(),
    supportingCollectingAsks: z.boolean().nullish(),
    supportingCollectingBars: z.boolean().nullish(),
    supportingCollectingBidAsks: z.boolean().nullish(),
    supportingCollectingBids: z.boolean().nullish(),
    supportingCollectingTrades: z.boolean().nullish(),
  });
}

export function HistoryOrderStatusesInputSchema(): z.ZodObject<
  Properties<HistoryOrderStatusesInput>
> {
  return z.object({
    includeRelated: z.boolean().nullish(),
    orderId: z.string(),
  });
}

export function InstrumentConfigurationSchema(): z.ZodObject<Properties<InstrumentConfiguration>> {
  return z.object({
    __typename: z.literal('InstrumentConfiguration').optional(),
    executionConfiguration: ExecutionConfigurationSchema().nullish(),
    instrumentGroupConfiguration: InstrumentGroupConfigurationSchema().nullish(),
    instrumentId: z.string(),
    pricingConfiguration: InstrumentPricingConfigurationSchema().nullish(),
    tradeable: z.boolean().nullish(),
  });
}

export function InstrumentConfigurationInputSchema(): z.ZodObject<
  Properties<InstrumentConfigurationInput>
> {
  return z.object({
    executionConfiguration: z.lazy(() => ExecutionConfigurationInputSchema().nullish()),
    instrumentId: z.string(),
    pricingConfiguration: z.lazy(() => InstrumentPricingConfigurationInputSchema().nullish()),
    tradeable: z.boolean().nullish(),
  });
}

export function InstrumentEdgeSchema(): z.ZodObject<Properties<InstrumentEdge>> {
  return z.object({
    __typename: z.literal('InstrumentEdge').optional(),
    cursor: z.string(),
    node: InstrumentResponseSchema(),
  });
}

export function InstrumentGroupConfigurationSchema(): z.ZodObject<
  Properties<InstrumentGroupConfiguration>
> {
  return z.object({
    __typename: z.literal('InstrumentGroupConfiguration').optional(),
    executionConfiguration: ExecutionConfigurationSchema().nullish(),
    instrumentId: z.string(),
    pricingConfiguration: InstrumentPricingConfigurationSchema().nullish(),
    tradeable: z.boolean().nullish(),
  });
}

export function InstrumentGroupConfigurationInputSchema(): z.ZodObject<
  Properties<InstrumentGroupConfigurationInput>
> {
  return z.object({
    executionConfiguration: z.lazy(() => ExecutionConfigurationInputSchema().nullish()),
    instrumentId: z.string(),
    pricingConfiguration: z.lazy(() => InstrumentPricingConfigurationInputSchema().nullish()),
    tradeable: z.boolean().nullish(),
  });
}

export function InstrumentIdentifiersResponseSchema(): z.ZodObject<
  Properties<InstrumentIdentifiersResponse>
> {
  return z.object({
    __typename: z.literal('InstrumentIdentifiersResponse').optional(),
    adapterTicker: z.string().nullish(),
    instrumentId: z.string(),
    tradingViewId: z.string().nullish(),
    venueTradingViewId: z.string().nullish(),
  });
}

export function InstrumentIdsPredicateInputSchema(): z.ZodObject<
  Properties<InstrumentIdsPredicateInput>
> {
  return z.object({
    instrumentIds: z.array(z.string()),
    method: InstrumentIdsPredicateTypeSchema,
  });
}

export function InstrumentKeySchema(): z.ZodObject<Properties<InstrumentKey>> {
  return z.object({
    __typename: z.literal('InstrumentKey').optional(),
    instrument: InstrumentResponseSchema(),
    venueAccount: z.string(),
    venueAccountName: z.string(),
  });
}

export function InstrumentKeyInputSchema(): z.ZodObject<Properties<InstrumentKeyInput>> {
  return z.object({
    instrumentId: z.string(),
    venueAccount: z.string(),
  });
}

export function InstrumentPricingConfigurationSchema(): z.ZodObject<
  Properties<InstrumentPricingConfiguration>
> {
  return z.object({
    __typename: z.literal('InstrumentPricingConfiguration').optional(),
    markup: z.number().nullish(),
    pricingSource: z.array(InstrumentKeySchema()).nullish(),
  });
}

export function InstrumentPricingConfigurationInputSchema(): z.ZodObject<
  Properties<InstrumentPricingConfigurationInput>
> {
  return z.object({
    markup: z.number().nullish(),
    pricingSource: z.array(z.lazy(() => InstrumentKeyInputSchema())).nullish(),
  });
}

export function InstrumentQuotingConfigurationSchema(): z.ZodObject<
  Properties<InstrumentQuotingConfiguration>
> {
  return z.object({
    __typename: z.literal('InstrumentQuotingConfiguration').optional(),
    askMarkup: z.number().nullish(),
    bidMarkup: z.number().nullish(),
    deactivated: z.boolean(),
    hedgingSafetyMargin: z.number().nullish(),
    instrumentId: z.string(),
    maxQuantityFactor: z.number().nullish(),
    maximumDepth: z.number().nullish(),
    minQuantityFactor: z.number().nullish(),
    priceLevelIncrement: z.number().nullish(),
    quantityIncrement: z.number().nullish(),
    quoteTTL: z.number().nullish(),
    sourceConfigurations: z.array(SourceConfigurationSchema()),
  });
}

export function InstrumentQuotingConfigurationInputSchema(): z.ZodObject<
  Properties<InstrumentQuotingConfigurationInput>
> {
  return z.object({
    askMarkup: z.number().nullish(),
    bidMarkup: z.number().nullish(),
    deactivated: z.boolean(),
    hedgingSafetyMargin: z.number().nullish(),
    instrumentId: z.string(),
    maxQuantityFactor: z.number().nullish(),
    maximumDepth: z.number().nullish(),
    minQuantityFactor: z.number().nullish(),
    priceLevelIncrement: z.number().nullish(),
    quantityIncrement: z.number().nullish(),
    quoteTTL: z.number().nullish(),
    sourceConfigurations: z.array(z.lazy(() => SourceConfigurationInputSchema())),
  });
}

export function InstrumentResponseSchema(): z.ZodObject<Properties<InstrumentResponse>> {
  return z.object({
    __typename: z.literal('InstrumentResponse').optional(),
    archivedAt: z.string().nullish(),
    baseInstrument: BaseInstrumentResponseSchema(),
    createdAt: z.string().nullish(),
    forexSpotProperties: ForexSpotPropertiesResponseSchema().nullish(),
    instrumentIdentifiers: InstrumentIdentifiersResponseSchema(),
    tradingConstraints: TradingConstraintsResponseSchema(),
    updatedAt: z.string().nullish(),
  });
}

export function InstrumentsConnectionSchema(): z.ZodObject<Properties<InstrumentsConnection>> {
  return z.object({
    __typename: z.literal('InstrumentsConnection').optional(),
    edges: z.array(InstrumentEdgeSchema()),
    pageInfo: PageInfoSchema(),
  });
}

export function InstrumentsRefreshRequestInputSchema(): z.ZodObject<
  Properties<InstrumentsRefreshRequestInput>
> {
  return z.object({
    correlationObject: z.string().nullish(),
    venueAccount: z.string().nullish(),
  });
}

export function InstrumentsSearchInputSchema(): z.ZodObject<Properties<InstrumentsSearchInput>> {
  return z.object({
    after: z.string().nullish(),
    archived: z.boolean().nullish(),
    first: z.number().nullish(),
    instrumentIdsPredicate: z.lazy(() => InstrumentIdsPredicateInputSchema().nullish()),
    sortBy: InstrumentSortBySchema.nullish(),
    sortingOrder: SortingOrderSchema.nullish(),
    tradeable: z.boolean().nullish(),
    venueNames: z.array(z.string()).nullish(),
    venueType: VenueTypeSchema.nullish(),
  });
}

export function InternalExchangeConfigurationInputSchema(): z.ZodObject<
  Properties<InternalExchangeConfigurationInput>
> {
  return z.object({
    account: z.string(),
    availableOrderTypes: z.array(OrderTypeSchema),
    availableTifs: z.array(TifSchema),
  });
}

export function InternalExchangeConfigurationResponseSchema(): z.ZodObject<
  Properties<InternalExchangeConfigurationResponse>
> {
  return z.object({
    __typename: z.literal('InternalExchangeConfigurationResponse').optional(),
    account: z.string(),
    accountName: z.string(),
    availableOrderTypes: z.array(OrderTypeSchema),
    availableTifs: z.array(TifSchema),
  });
}

export function KeyValueSchema(): z.ZodObject<Properties<KeyValue>> {
  return z.object({
    __typename: z.literal('KeyValue').optional(),
    key: z.string(),
    value: z.string(),
  });
}

export function KeyValueInputSchema(): z.ZodObject<Properties<KeyValueInput>> {
  return z.object({
    key: z.string(),
    value: z.string(),
  });
}

export function KeyValuesSchema(): z.ZodObject<Properties<KeyValues>> {
  return z.object({
    __typename: z.literal('KeyValues').optional(),
    key: z.string().nullish(),
    values: z.array(z.string().nullable()).nullish(),
  });
}

export function LedgerEntryConnectionSchema(): z.ZodObject<Properties<LedgerEntryConnection>> {
  return z.object({
    __typename: z.literal('LedgerEntryConnection').optional(),
    edges: z.array(LedgerEntryEdgeSchema()),
    pageInfo: PageInfoSchema(),
  });
}

export function LedgerEntryEdgeSchema(): z.ZodObject<Properties<LedgerEntryEdge>> {
  return z.object({
    __typename: z.literal('LedgerEntryEdge').optional(),
    cursor: z.string(),
    node: LedgerEntryResponseSchema(),
  });
}

export function LedgerEntryResponseSchema(): z.ZodObject<Properties<LedgerEntryResponse>> {
  return z.object({
    __typename: z.literal('LedgerEntryResponse').optional(),
    accountId: z.string().nullish(),
    accountName: z.string().nullish(),
    balanceAfter: z.number().nullish(),
    balanceBefore: z.number().nullish(),
    currency: z.string().nullish(),
    fee: z.number(),
    id: z.string(),
    orderId: z.string().nullish(),
    portfolioId: z.string().nullish(),
    portfolioName: z.string().nullish(),
    price: z.number(),
    quantity: z.number(),
    symbol: z.string().nullish(),
    transactionId: z.string().nullish(),
    type: LedgerEntryTypeSchema,
    updatedAt: z.number(),
  });
}

export function LedgerEntrySearchInputSchema(): z.ZodObject<Properties<LedgerEntrySearchInput>> {
  return z.object({
    accountName: z.array(z.string()).nullish(),
    after: z.string().nullish(),
    currency: z.array(z.string()).nullish(),
    first: z.number().nullish(),
    from: z.string().nullish(),
    ledgerEntryType: z.array(LedgerEntryTypeSchema).nullish(),
    orderId: z.string().nullish(),
    portfolioId: z.array(z.string()).nullish(),
    sortingOrder: SortingOrderSchema.nullish(),
    symbol: z.array(z.string()).nullish(),
    to: z.string().nullish(),
    transactionId: z.string().nullish(),
  });
}

export function MarketDataCapabilitiesSchema(): z.ZodObject<Properties<MarketDataCapabilities>> {
  return z.object({
    __typename: z.literal('MarketDataCapabilities').optional(),
    fetchingMarketDataSupported: z.boolean().nullish(),
    marketScanSupported: z.boolean().nullish(),
    otcBroker: z.boolean().nullish(),
    streamingLiveMarketDataSupported: z.boolean().nullish(),
    supportedOrderBookLevels: z.array(OrderBookLevelSchema.nullable()).nullish(),
    supportedSecurityTypes: z.array(InstrumentTypeSchema.nullable()).nullish(),
  });
}

export function MarketDataResponseSchema(): z.ZodObject<Properties<MarketDataResponse>> {
  return z.object({
    __typename: z.literal('MarketDataResponse').optional(),
    askPrice: z.number().nullish(),
    askSize: z.number().nullish(),
    bidPrice: z.number().nullish(),
    bidSize: z.number().nullish(),
    dateTime: z.number().nullish(),
    instrument: InstrumentResponseSchema().nullish(),
    lastPrice: z.number().nullish(),
    lastSize: z.number().nullish(),
    marketDataType: MarketDataTypeSchema.nullish(),
    side: SideSchema.nullish(),
    venue: z.string().nullish(),
    venueAccount: z.string().nullish(),
    venueAccountName: z.string().nullish(),
    vol: z.number().nullish(),
  });
}

export function MarketQuoteSchema(): z.ZodObject<Properties<MarketQuote>> {
  return z.object({
    __typename: z.literal('MarketQuote').optional(),
    instrument: InstrumentResponseSchema(),
    marketAskPrice: z.number(),
    marketAskSize: z.number(),
    marketBidPrice: z.number(),
    marketBidSize: z.number(),
    markupAskPrice: z.number().nullish(),
    markupBidPrice: z.number().nullish(),
    timestamp: z.string().nullish(),
  });
}

export function MatchResponseSchema(): z.ZodObject<Properties<MatchResponse>> {
  return z.object({
    __typename: z.literal('MatchResponse').optional(),
    id: z.string(),
    makers: z.array(CounterpartySchema()),
    primarySymbolQuotes: z.array(MarketQuoteSchema()),
    secondarySymbolQuotes: z.array(MarketQuoteSchema()).nullish(),
    taker: CounterpartySchema(),
    timestamp: z.string(),
  });
}

export function MatchesResultSchema(): z.ZodObject<Properties<MatchesResult>> {
  return z.object({
    __typename: z.literal('MatchesResult').optional(),
    avgPrice: z.number().nullish(),
    matches: z.array(MatchResponseSchema()),
    totalAmount: z.number().nullish(),
    totalQuantity: z.number().nullish(),
  });
}

export function MatchesSearchInputSchema(): z.ZodObject<Properties<MatchesSearchInput>> {
  return z.object({
    orderId: z.string().nullish(),
  });
}

export function MutationReturnValueSchema(): z.ZodObject<Properties<MutationReturnValue>> {
  return z.object({
    __typename: z.literal('MutationReturnValue').optional(),
    clientId: z.string().nullish(),
  });
}

export function MutationSubmittedResponseSchema(): z.ZodObject<
  Properties<MutationSubmittedResponse>
> {
  return z.object({
    __typename: z.literal('MutationSubmittedResponse').optional(),
    status: z.string(),
  });
}

export function NewOrderSingleRequestInputSchema(): z.ZodObject<
  Properties<NewOrderSingleRequestInput>
> {
  return z.object({
    assetClass: AssetClassSchema.nullish(),
    clOrderId: z.string(),
    currency: z.string().nullish(),
    expirationDateTime: z.string().nullish(),
    instrumentId: z.string().nullish(),
    limitPrice: z.number().nullish(),
    orderType: OrderTypeSchema,
    portfolioId: z.string(),
    quantity: z.number(),
    side: SideSchema,
    stopPrice: z.number().nullish(),
    symbol: z.string().nullish(),
    tif: TifSchema,
    venueAccounts: z.array(z.string()).nullish(),
  });
}

export function OrderBookSchema(): z.ZodObject<Properties<OrderBook>> {
  return z.object({
    __typename: z.literal('OrderBook').optional(),
    amount: z.string().nullish(),
    count: z.string().nullish(),
    price: z.string().nullish(),
  });
}

export function OrderBookResponseSchema(): z.ZodObject<Properties<OrderBookResponse>> {
  return z.object({
    __typename: z.literal('OrderBookResponse').optional(),
    asks: z.array(OrderBookSchema().nullable()).nullish(),
    bids: z.array(OrderBookSchema().nullable()).nullish(),
    dateTime: z.string().nullish(),
    instrumentId: z.string().nullish(),
    venue: z.string().nullish(),
    venueAccount: z.string().nullish(),
    venueAccountName: z.string().nullish(),
  });
}

export function OrderBookSnapshotSchema(): z.ZodObject<Properties<OrderBookSnapshot>> {
  return z.object({
    __typename: z.literal('OrderBookSnapshot').optional(),
    asks: z.array(OrderBookSchema().nullable()).nullish(),
    bids: z.array(OrderBookSchema().nullable()).nullish(),
  });
}

export function OrderBookSnapshotSearchInputSchema(): z.ZodObject<
  Properties<OrderBookSnapshotSearchInput>
> {
  return z.object({
    matchId: z.string(),
    orderId: z.string(),
  });
}

export function OrderCancelReplaceRequestInputSchema(): z.ZodObject<
  Properties<OrderCancelReplaceRequestInput>
> {
  return z.object({
    origClOrderId: z.string().nullish(),
    replacingOrder: z.lazy(() => NewOrderSingleRequestInputSchema().nullish()),
  });
}

export function OrderCancelRequestInputSchema(): z.ZodObject<Properties<OrderCancelRequestInput>> {
  return z.object({
    clOrderId: z.string().nullish(),
    forceCancel: z.boolean().nullish(),
    origClOrderId: z.string().nullish(),
  });
}

export function OrderRequestsPerOrderTypeSchema(): z.ZodObject<
  Properties<OrderRequestsPerOrderType>
> {
  return z.object({
    __typename: z.literal('OrderRequestsPerOrderType').optional(),
    key: OrderTypeSchema.nullish(),
    values: z.array(OrderRequestSchema),
  });
}

export function OrderSnapshotSchema(): z.ZodObject<Properties<OrderSnapshot>> {
  return z.object({
    __typename: z.literal('OrderSnapshot').optional(),
    baseCurrency: z.string(),
    instrumentId: z.string(),
    instrumentType: InstrumentTypeSchema,
    orderId: z.string(),
    orderType: OrderTypeSchema,
    parentOrderId: z.string().nullish(),
    portfolioId: z.string(),
    portfolioName: z.string(),
    postOnly: z.boolean().nullish(),
    price: z.number().nullish(),
    quantity: z.number().nullish(),
    quoteCurrency: z.string(),
    side: SideSchema,
    stopPrice: z.number().nullish(),
    tif: TifSchema.nullish(),
    venueAccount: z.string(),
    venueAccountDescs: z.array(VenueAccountDescSchema()).nullish(),
    venueAccountName: z.string(),
  });
}

export function OrderStateConnectionSchema(): z.ZodObject<Properties<OrderStateConnection>> {
  return z.object({
    __typename: z.literal('OrderStateConnection').optional(),
    edges: z.array(OrderStateEdgeSchema()),
    pageInfo: PageInfoSchema(),
  });
}

export function OrderStateEdgeSchema(): z.ZodObject<Properties<OrderStateEdge>> {
  return z.object({
    __typename: z.literal('OrderStateEdge').optional(),
    cursor: z.string(),
    node: OrderStateResponseSchema(),
  });
}

export function OrderStateResponseSchema(): z.ZodObject<Properties<OrderStateResponse>> {
  return z.object({
    __typename: z.literal('OrderStateResponse').optional(),
    assetClass: AssetClassSchema.nullish(),
    avgPrice: z.string().nullish(),
    clOrderId: z.string().nullish(),
    clientId: z.string().nullish(),
    counterPortfolioId: z.string().nullish(),
    counterPortfolioName: z.string().nullish(),
    createdAt: z.string().nullish(),
    currency: z.string().nullish(),
    expirationDateTime: z.string().nullish(),
    filledQty: z.number(),
    instrument: InstrumentResponseSchema().nullish(),
    lastPrice: z.string().nullish(),
    lastQty: z.number().nullish(),
    lastRequestResult: z.string(),
    limitPrice: z.number().nullish(),
    orderCategory: OrderCategorySchema,
    orderId: z.string(),
    orderQty: z.number(),
    orderStateId: z.string().nullish(),
    orderStatus: OrderStatusSchema,
    orderType: OrderTypeSchema,
    origClOrderId: z.string().nullish(),
    parentOrderId: z.string().nullish(),
    portfolioId: z.string(),
    portfolioName: z.string(),
    reason: z.string().nullish(),
    remainingQty: z.number(),
    rootOrderId: z.string().nullish(),
    sequenceNumber: z.number().nullish(),
    side: SideSchema,
    stopPrice: z.number().nullish(),
    symbol: z.string().nullish(),
    tif: TifSchema.nullish(),
    updatedAt: z.string().nullish(),
    venue: z.string().nullish(),
    venueAccountDescs: z.array(VenueAccountDescSchema()).nullish(),
    venueTimestamp: z.string().nullish(),
  });
}

export function OrderStateSearchInputSchema(): z.ZodObject<Properties<OrderStateSearchInput>> {
  return z.object({
    after: z.string().nullish(),
    collectionPredicates: z
      .array(z.lazy(() => CollectionPredicateInputSchema().nullable()))
      .nullish(),
    datePredicateInputs: z.array(DatePredicateSchema().nullable()).nullish(),
    first: z.number().nullish(),
    simplePredicates: z.array(z.lazy(() => SimplePredicateInputSchema().nullable())).nullish(),
    sortingOrder: SortingOrderSchema.nullish(),
  });
}

export function PageInfoSchema(): z.ZodObject<Properties<PageInfo>> {
  return z.object({
    __typename: z.literal('PageInfo').optional(),
    endCursor: z.string().nullish(),
    hasNextPage: z.boolean(),
  });
}

export function PermissionSchema(): z.ZodObject<Properties<Permission>> {
  return z.object({
    __typename: z.literal('Permission').optional(),
    resource: ResourceSchema,
    resourceId: z.string().nullish(),
    scope: ScopeSchema,
  });
}

export function PermissionConnectionSchema(): z.ZodObject<Properties<PermissionConnection>> {
  return z.object({
    __typename: z.literal('PermissionConnection').optional(),
    edges: z.array(PermissionEdgeSchema()),
    pageInfo: PageInfoSchema(),
  });
}

export function PermissionEdgeSchema(): z.ZodObject<Properties<PermissionEdge>> {
  return z.object({
    __typename: z.literal('PermissionEdge').optional(),
    cursor: z.string(),
    node: FlatPermissionSchema(),
  });
}

export function PermissionInputSchema(): z.ZodObject<Properties<PermissionInput>> {
  return z.object({
    resource: ResourceSchema,
    resourceId: z.string().nullish(),
    scope: ScopeSchema,
  });
}

export function PermissionSearchInputSchema(): z.ZodObject<Properties<PermissionSearchInput>> {
  return z.object({
    after: z.string().nullish(),
    first: z.number().nullish(),
  });
}

export function PortfolioCashTransferSchema(): z.ZodObject<Properties<PortfolioCashTransfer>> {
  return z.object({
    __typename: z.literal('PortfolioCashTransfer').optional(),
    currency: z.string(),
    dateTime: z.string(),
    description: z.string(),
    executionId: z.string(),
    feePortfolioId: z.string().nullish(),
    feePortfolioName: z.string().nullish(),
    quantity: z.number(),
    settled: z.string(),
    settledDateTime: z.number().nullish(),
    sourcePortfolioId: z.string(),
    sourcePortfolioName: z.string(),
    targetPortfolioId: z.string(),
    targetPortfolioName: z.string(),
    updatedAt: z.number(),
    uuid: z.string(),
    venueExecutionId: z.string().nullish(),
  });
}

export function PortfolioConfigurationSchema(): z.ZodObject<Properties<PortfolioConfiguration>> {
  return z.object({
    __typename: z.literal('PortfolioConfiguration').optional(),
    executionConfiguration: ExecutionConfigurationSchema().nullish(),
    hedgingConfiguration: HedgingConfigurationSchema().nullish(),
    id: z.string(),
    instrumentConfiguration: z.array(InstrumentConfigurationSchema()).nullish(),
    name: z.string().nullish(),
    portfolioGroupConfiguration: PortfolioGroupConfigurationSchema().nullish(),
    pricingConfiguration: PricingConfigurationSchema().nullish(),
  });
}

export function PortfolioConfigurationFlatSchema(): z.ZodObject<
  Properties<PortfolioConfigurationFlat>
> {
  return z.object({
    __typename: z.literal('PortfolioConfigurationFlat').optional(),
    id: z.string(),
    name: z.string().nullish(),
  });
}

export function PortfolioConfigurationInputSchema(): z.ZodObject<
  Properties<PortfolioConfigurationInput>
> {
  return z.object({
    executionConfiguration: z.lazy(() => ExecutionConfigurationInputSchema().nullish()),
    hedgingConfiguration: z.lazy(() => HedgingConfigurationInputSchema().nullish()),
    id: z.string(),
    instrumentConfiguration: z.array(z.lazy(() => InstrumentConfigurationInputSchema())).nullish(),
    name: z.string().nullish(),
    portfolioGroupConfigurationId: z.string().nullish(),
    pricingConfiguration: z.lazy(() => PricingConfigurationInputSchema().nullish()),
  });
}

export function PortfolioConnectionSchema(): z.ZodObject<Properties<PortfolioConnection>> {
  return z.object({
    __typename: z.literal('PortfolioConnection').optional(),
    edges: z.array(PortfolioEdgeSchema()),
    pageInfo: PageInfoSchema(),
  });
}

export function PortfolioEdgeSchema(): z.ZodObject<Properties<PortfolioEdge>> {
  return z.object({
    __typename: z.literal('PortfolioEdge').optional(),
    cursor: z.string(),
    node: PortfolioResponseSchema(),
  });
}

export function PortfolioGroupConfigurationSchema(): z.ZodObject<
  Properties<PortfolioGroupConfiguration>
> {
  return z.object({
    __typename: z.literal('PortfolioGroupConfiguration').optional(),
    executionConfiguration: ExecutionConfigurationSchema().nullish(),
    hedgingConfiguration: HedgingConfigurationSchema().nullish(),
    id: z.string(),
    instrumentConfiguration: z.array(InstrumentGroupConfigurationSchema()).nullish(),
    name: z.string().nullish(),
    portfolioType: PortfolioTypeSchema.nullish(),
    pricingConfiguration: PricingConfigurationSchema().nullish(),
  });
}

export function PortfolioGroupConfigurationFlatSchema(): z.ZodObject<
  Properties<PortfolioGroupConfigurationFlat>
> {
  return z.object({
    __typename: z.literal('PortfolioGroupConfigurationFlat').optional(),
    id: z.string(),
    name: z.string().nullish(),
    portfolioType: PortfolioTypeSchema.nullish(),
  });
}

export function PortfolioGroupConfigurationInputSchema(): z.ZodObject<
  Properties<PortfolioGroupConfigurationInput>
> {
  return z.object({
    executionConfiguration: z.lazy(() => ExecutionConfigurationInputSchema().nullish()),
    hedgingConfiguration: z.lazy(() => HedgingConfigurationInputSchema().nullish()),
    id: z.string(),
    instrumentConfiguration: z
      .array(z.lazy(() => InstrumentGroupConfigurationInputSchema()))
      .nullish(),
    name: z.string().nullish(),
    portfolioType: PortfolioTypeSchema.nullish(),
    pricingConfiguration: z.lazy(() => PricingConfigurationInputSchema().nullish()),
  });
}

export function PortfolioPredicateInputSchema(): z.ZodObject<Properties<PortfolioPredicateInput>> {
  return z.object({
    method: PortfolioPredicateTypeSchema,
    searchType: PortfolioSearchTypeSchema,
    value: z.string(),
  });
}

export function PortfolioResponseSchema(): z.ZodObject<Properties<PortfolioResponse>> {
  return z.object({
    __typename: z.literal('PortfolioResponse').optional(),
    archivedAt: z.string().nullish(),
    createdAt: z.string(),
    id: z.string(),
    name: z.string(),
    portfolioCurrency: z.string(),
    portfolioType: PortfolioTypeSchema.nullish(),
    scopes: z.array(ScopeSchema),
    tags: z.array(TagSchema()),
  });
}

export function PortfolioSearchInputSchema(): z.ZodObject<Properties<PortfolioSearchInput>> {
  return z.object({
    after: z.string().nullish(),
    first: z.number().nullish(),
    portfolioIds: z.array(z.string()).nullish(),
    portfolioPredicate: z.lazy(() => PortfolioPredicateInputSchema().nullish()),
    portfolioType: PortfolioTypeSchema.nullish(),
    scopes: z.array(ScopeSchema).nullish(),
    sortBy: PortfolioSortBySchema.nullish(),
    sortingOrder: SortingOrderSchema.nullish(),
    tagValues: z.array(z.string()).nullish(),
  });
}

export function PortfolioTagSchema(): z.ZodObject<Properties<PortfolioTag>> {
  return z.object({
    __typename: z.literal('PortfolioTag').optional(),
    key: z.string(),
    values: z.array(z.string()),
  });
}

export function PositionConnectionSchema(): z.ZodObject<Properties<PositionConnection>> {
  return z.object({
    __typename: z.literal('PositionConnection').optional(),
    edges: z.array(PositionEdgeSchema()),
    pageInfo: PageInfoSchema(),
  });
}

export function PositionEdgeSchema(): z.ZodObject<Properties<PositionEdge>> {
  return z.object({
    __typename: z.literal('PositionEdge').optional(),
    cursor: z.string(),
    node: PositionResponseSchema(),
  });
}

export function PositionResponseSchema(): z.ZodObject<Properties<PositionResponse>> {
  return z.object({
    __typename: z.literal('PositionResponse').optional(),
    account: z.string().nullish(),
    accountName: z.string().nullish(),
    availableForTradingQuantity: z.number().nullish(),
    availableForWithdrawalQuantity: z.number().nullish(),
    bookingCurrency: z.string(),
    currency: z.string().nullish(),
    grossAveragePrice: z.number().nullish(),
    grossCost: z.number(),
    grossCostSc: z.number().nullish(),
    grossRealizedPnl: z.number(),
    grossRealizedPnlSc: z.number().nullish(),
    grossUnrealizedPnl: z.number().nullish(),
    grossUnrealizedPnlSc: z.number().nullish(),
    marketValue: z.number().nullish(),
    marketValueSc: z.number().nullish(),
    netAveragePrice: z.number().nullish(),
    netCost: z.number(),
    netCostSc: z.number().nullish(),
    netRealizedPnl: z.number(),
    netRealizedPnlSc: z.number().nullish(),
    netUnrealizedPnl: z.number().nullish(),
    netUnrealizedPnlSc: z.number().nullish(),
    notionalQuantity: z.number().nullish(),
    pendingQuantity: z.number().nullish(),
    portfolio: PortfolioResponseSchema().nullish(),
    quantity: z.number(),
    settledQuantity: z.number().nullish(),
    symbol: z.string(),
    unsettledQuantity: z.number().nullish(),
    updatedAt: z.number(),
  });
}

export function PositionSearchInputSchema(): z.ZodObject<Properties<PositionSearchInput>> {
  return z.object({
    after: z.string().nullish(),
    currency: z.array(z.string()).nullish(),
    first: z.number().nullish(),
    portfolio: z.array(z.string()).nullish(),
    sortingOrder: SortingOrderSchema.nullish(),
    symbol: z.array(z.string()).nullish(),
    venueAccount: z.array(z.string()).nullish(),
  });
}

export function PreTradeCheckSchema(): z.ZodObject<Properties<PreTradeCheck>> {
  return z.object({
    __typename: z.literal('PreTradeCheck').optional(),
    channels: z.array(PreTradeCheckChannelsSchema),
    configuration: z.array(PreTradeCheckPropertySchema()),
    id: z.string(),
    level: PreTradeCheckLevelSchema,
    portfolioTags: z.array(TagSchema()),
    portfolios: z.array(PortfolioResponseSchema()),
    type: z.string(),
  });
}

export function PreTradeCheckAuditLogSchema(): z.ZodObject<Properties<PreTradeCheckAuditLog>> {
  return z.object({
    __typename: z.literal('PreTradeCheckAuditLog').optional(),
    createdAt: z.string().nullish(),
    id: z.string(),
    order: OrderSnapshotSchema().nullish(),
    portfolioId: z.string().nullish(),
    portfolioName: z.string().nullish(),
    preTradeCheckResults: z.array(PreTradeCheckResultSchema().nullable()).nullish(),
  });
}

export function PreTradeCheckAuditLogConnectionSchema(): z.ZodObject<
  Properties<PreTradeCheckAuditLogConnection>
> {
  return z.object({
    __typename: z.literal('PreTradeCheckAuditLogConnection').optional(),
    edges: z.array(PreTradeCheckAuditLogEdgeSchema()),
    pageInfo: PageInfoSchema(),
  });
}

export function PreTradeCheckAuditLogEdgeSchema(): z.ZodObject<
  Properties<PreTradeCheckAuditLogEdge>
> {
  return z.object({
    __typename: z.literal('PreTradeCheckAuditLogEdge').optional(),
    cursor: z.string(),
    node: PreTradeCheckAuditLogSchema(),
  });
}

export function PreTradeCheckAuditLogSearchInputSchema(): z.ZodObject<
  Properties<PreTradeCheckAuditLogSearchInput>
> {
  return z.object({
    after: z.string().nullish(),
    first: z.number().nullish(),
    from: z.string().nullish(),
    portfolioIds: z.array(z.string().nullable()).nullish(),
    to: z.string().nullish(),
  });
}

export function PreTradeCheckInputSchema(): z.ZodObject<Properties<PreTradeCheckInput>> {
  return z.object({
    channels: z.array(PreTradeCheckChannelsSchema),
    configuration: z.array(z.lazy(() => PreTradeCheckPropertyInputSchema())),
    id: z.string(),
    level: PreTradeCheckLevelSchema,
    portfolioTags: z.array(z.lazy(() => TagInputSchema())),
    portfolios: z.array(z.string()),
    type: z.string(),
  });
}

export function PreTradeCheckPropertySchema(): z.ZodObject<Properties<PreTradeCheckProperty>> {
  return z.object({
    __typename: z.literal('PreTradeCheckProperty').optional(),
    name: z.string(),
    type: PreTradeCheckPropertyTypeSchema,
    values: z.array(z.string()),
  });
}

export function PreTradeCheckPropertyInputSchema(): z.ZodObject<
  Properties<PreTradeCheckPropertyInput>
> {
  return z.object({
    name: z.string(),
    type: PreTradeCheckPropertyTypeSchema,
    values: z.array(z.string()),
  });
}

export function PreTradeCheckPropertySchemaSchema(): z.ZodObject<
  Properties<PreTradeCheckPropertySchema>
> {
  return z.object({
    __typename: z.literal('PreTradeCheckPropertySchema').optional(),
    isEnum: z.boolean(),
    name: z.string(),
    options: z.array(z.string()).nullish(),
    required: z.boolean(),
    type: PreTradeCheckPropertyTypeSchema,
  });
}

export function PreTradeCheckResultSchema(): z.ZodObject<Properties<PreTradeCheckResult>> {
  return z.object({
    __typename: z.literal('PreTradeCheckResult').optional(),
    preTradeCheck: PreTradeCheckSchema(),
    reason: z.string(),
    status: PreTradeCheckStatusSchema,
  });
}

export function PreTradeCheckSchemaSchema(): z.ZodObject<Properties<PreTradeCheckSchema>> {
  return z.object({
    __typename: z.literal('PreTradeCheckSchema').optional(),
    configuration: z.array(PreTradeCheckPropertySchemaSchema()),
    type: z.string(),
  });
}

export function PricingConfigurationSchema(): z.ZodObject<Properties<PricingConfiguration>> {
  return z.object({
    __typename: z.literal('PricingConfiguration').optional(),
    markup: z.number().nullish(),
    venueAccounts: z.array(VenueAccountDescSchema()).nullish(),
  });
}

export function PricingConfigurationInputSchema(): z.ZodObject<
  Properties<PricingConfigurationInput>
> {
  return z.object({
    markup: z.number().nullish(),
    venueAccount: z.array(z.string()).nullish(),
  });
}

export function QuotingCalendarSchema(): z.ZodObject<Properties<QuotingCalendar>> {
  return z.object({
    __typename: z.literal('QuotingCalendar').optional(),
    additionalMarkup: z.number(),
    endTime: z.string(),
    fromDayOfTheWeek: DayOfTheWeekSchema,
    quoteTTL: z.number(),
    quoteTTLUnit: QuoteTtlUnitSchema,
    startTime: z.string(),
    toDayOfTheWeek: DayOfTheWeekSchema,
  });
}

export function QuotingCalendarInputSchema(): z.ZodObject<Properties<QuotingCalendarInput>> {
  return z.object({
    additionalMarkup: z.number(),
    endTime: z.string(),
    fromDayOfTheWeek: DayOfTheWeekSchema,
    quoteTTL: z.number(),
    quoteTTLUnit: QuoteTtlUnitSchema,
    startTime: z.string(),
    toDayOfTheWeek: DayOfTheWeekSchema,
  });
}

export function QuotingConfigValidationResultSchema(): z.ZodObject<
  Properties<QuotingConfigValidationResult>
> {
  return z.object({
    __typename: z.literal('QuotingConfigValidationResult').optional(),
    clobUid: z.string(),
    resultsPerInstrument: z.array(ConfigInstrumentValidationResultSchema()),
  });
}

export function QuotingConfigurationSchema(): z.ZodObject<Properties<QuotingConfiguration>> {
  return z.object({
    __typename: z.literal('QuotingConfiguration').optional(),
    askMarkup: z.number().nullish(),
    bidMarkup: z.number().nullish(),
    clobUid: z.string(),
    deactivated: z.boolean(),
    displayName: z.string(),
    hedgingAccountDescs: z.array(VenueAccountDescSchema()).nullish(),
    hedgingAccounts: z.array(z.string()),
    hedgingSafetyMargin: z.number(),
    instrumentConfigurations: z.array(InstrumentQuotingConfigurationSchema()),
    maxQuantityFactor: z.number(),
    maximumDepth: z.number().nullish(),
    minQuantityFactor: z.number(),
    nostroPortfolioId: z.string(),
    nostroPortfolioName: z.string(),
    quoteTTL: z.number().nullish(),
    sourceAccountDescs: z.array(VenueAccountDescSchema()).nullish(),
    sourceAccounts: z.array(z.string()).nullish(),
    sources: z.array(QuotingSourceAccountConfigSchema()).nullish(),
    throttlingPeriod: z.number().nullish(),
    updatedAt: z.string(),
  });
}

export function QuotingConfigurationInputSchema(): z.ZodObject<
  Properties<QuotingConfigurationInput>
> {
  return z.object({
    askMarkup: z.number().nullish(),
    bidMarkup: z.number().nullish(),
    deactivated: z.boolean(),
    displayName: z.string(),
    hedgingAccounts: z.array(z.string()),
    hedgingSafetyMargin: z.number(),
    maxQuantityFactor: z.number(),
    maximumDepth: z.number().nullish(),
    minQuantityFactor: z.number(),
    nostroPortfolioId: z.string(),
    quoteTTL: z.number().nullish(),
    sourceAccounts: z.array(z.string()).nullish(),
    sources: z.array(z.lazy(() => QuotingSourceAccountConfigInputSchema())).nullish(),
    throttlingPeriod: z.number().nullish(),
  });
}

export function QuotingSourceAccountConfigSchema(): z.ZodObject<
  Properties<QuotingSourceAccountConfig>
> {
  return z.object({
    __typename: z.literal('QuotingSourceAccountConfig').optional(),
    accountId: z.string(),
    accountName: z.string(),
    calendarEntries: z.array(QuotingCalendarSchema()).nullish(),
    defaultQuoteTTL: z.number().nullish(),
    isDroppingQuotesOnDisconnection: z.boolean().nullish(),
    quoteTTLUnit: QuoteTtlUnitSchema,
  });
}

export function QuotingSourceAccountConfigInputSchema(): z.ZodObject<
  Properties<QuotingSourceAccountConfigInput>
> {
  return z.object({
    accountId: z.string(),
    calendarEntries: z.array(z.lazy(() => QuotingCalendarInputSchema())).nullish(),
    defaultQuoteTTL: z.number().nullish(),
    isDroppingQuotesOnDisconnection: z.boolean().nullish(),
    quoteTTLUnit: QuoteTtlUnitSchema,
  });
}

export function RateSubscriptionResponseSchema(): z.ZodObject<
  Properties<RateSubscriptionResponse>
> {
  return z.object({
    __typename: z.literal('RateSubscriptionResponse').optional(),
    baseCurrency: z.string(),
    health: RateSubscriptionHealthSchema,
    lastEventTimestamp: z.string().nullish(),
    lastPrice: z.string().nullish(),
    quoteCurrency: z.string(),
    venueAccountId: z.string().nullish(),
    venueAccountName: z.string().nullish(),
  });
}

export function ReferenceDataCapabilitiesSchema(): z.ZodObject<
  Properties<ReferenceDataCapabilities>
> {
  return z.object({
    __typename: z.literal('ReferenceDataCapabilities').optional(),
    canRetrieveAll: z.boolean().nullish(),
    canRetrieveSampleData: z.boolean().nullish(),
    canRetrieveSampleDataRequests: z.boolean().nullish(),
    retrievableByRequest: z.array(KeyValuesSchema().nullable()).nullish(),
  });
}

export function ResetConfigurationInputSchema(): z.ZodObject<Properties<ResetConfigurationInput>> {
  return z.object({
    configurationLevel: ConfigurationLevelSchema,
    configurationType: ConfigurationTypeSchema,
    instrumentId: z.string().nullish(),
    resourceId: z.string(),
  });
}

export function RootExecutionSchema(): z.ZodObject<Properties<RootExecution>> {
  return z.object({
    __typename: z.literal('RootExecution').optional(),
    executionId: z.string(),
    orderId: z.string(),
  });
}

export function SelectAllTransactionsInputSchema(): z.ZodObject<
  Properties<SelectAllTransactionsInput>
> {
  return z.object({
    accountId: z.array(z.string()).nullish(),
    currency: z.array(z.string()).nullish(),
    executionId: z.string().nullish(),
    from: z.string().nullish(),
    orderId: z.string().nullish(),
    parentOrderId: z.string().nullish(),
    portfolioId: z.array(z.string()).nullish(),
    rootExecutionId: z.string().nullish(),
    rootOrderId: z.string().nullish(),
    selected: z.boolean().nullish(),
    settlementId: z.string(),
    symbol: z.array(z.string()).nullish(),
    to: z.string().nullish(),
    transactionType: z.array(z.string()).nullish(),
    underlyingExecutionId: z.string().nullish(),
    venueExecutionId: z.string().nullish(),
  });
}

export function SelectTransactionsInputSchema(): z.ZodObject<Properties<SelectTransactionsInput>> {
  return z.object({
    changes: z.array(TransactionSelectSchema()),
    settlementId: z.string(),
  });
}

export function SettlementSchema(): z.ZodObject<Properties<Settlement>> {
  return z.object({
    __typename: z.literal('Settlement').optional(),
    dateTime: z.string(),
    description: z.string(),
    settledTransactionIds: z.array(z.string().nullable()),
    updatedAt: z.number(),
    uuid: z.string(),
  });
}

export function SettlementAccountConfigurationSchema(): z.ZodObject<
  Properties<SettlementAccountConfiguration>
> {
  return z.object({
    __typename: z.literal('SettlementAccountConfiguration').optional(),
    assetToWalletMap: z.array(WalletByAssetSchema()).nullish(),
    automationEnabled: z.boolean().nullish(),
    daysExcluded: z.array(z.string()).nullish(),
    directionPriority: SettlementDirectionPrioritySchema.nullish(),
    legPriority: SettlementLegPrioritySchema.nullish(),
    schedule: z.array(SettlementSchedulePointSchema()).nullish(),
    treasuryManagementAutomationEnabled: z.boolean().nullish(),
    treasuryThresholds: z.array(TreasuryThresholdSchema()).nullish(),
  });
}

export function SettlementAccountConfigurationInputSchema(): z.ZodObject<
  Properties<SettlementAccountConfigurationInput>
> {
  return z.object({
    assetToWalletMap: z.array(z.lazy(() => WalletByAssetInputSchema())).nullish(),
    automationEnabled: z.boolean().nullish(),
    daysExcluded: z.array(z.string()).nullish(),
    directionPriority: SettlementDirectionPrioritySchema.nullish(),
    legPriority: SettlementLegPrioritySchema.nullish(),
    schedule: z.array(z.lazy(() => SettlementSchedulePointInputSchema())).nullish(),
    treasuryManagementAutomationEnabled: z.boolean().nullish(),
    treasuryThresholds: z.array(z.lazy(() => TreasuryThresholdInputSchema())).nullish(),
  });
}

export function SettlementConfigurationInputSchema(): z.ZodObject<
  Properties<SettlementConfigurationInput>
> {
  return z.object({
    accountId: z.string(),
    config: z.lazy(() => SettlementAccountConfigurationInputSchema().nullish()),
  });
}

export function SettlementConfigurationResponseSchema(): z.ZodObject<
  Properties<SettlementConfigurationResponse>
> {
  return z.object({
    __typename: z.literal('SettlementConfigurationResponse').optional(),
    accountId: z.string(),
    config: SettlementAccountConfigurationSchema().nullish(),
  });
}

export function SettlementLegSchema(): z.ZodObject<Properties<SettlementLeg>> {
  return z.object({
    __typename: z.literal('SettlementLeg').optional(),
    asset: z.string(),
    auto: z.boolean(),
    direction: SettlementDirectionSchema,
    id: z.string(),
    quantity: z.number(),
    status: SettlementStatusSchema,
    transferId: z.string().nullish(),
    venue: z.string(),
    venueAccountId: z.string(),
    venueAccountName: z.string(),
  });
}

export function SettlementRunResponseSchema(): z.ZodObject<Properties<SettlementRunResponse>> {
  return z.object({
    __typename: z.literal('SettlementRunResponse').optional(),
    dateTime: z.string(),
    id: z.string(),
    legs: z.array(SettlementLegSchema()),
    status: SettlementStatusSchema,
  });
}

export function SettlementSchedulePointSchema(): z.ZodObject<Properties<SettlementSchedulePoint>> {
  return z.object({
    __typename: z.literal('SettlementSchedulePoint').optional(),
    day: DayOfTheWeekSchema,
    time: z.string(),
  });
}

export function SettlementSchedulePointInputSchema(): z.ZodObject<
  Properties<SettlementSchedulePointInput>
> {
  return z.object({
    day: DayOfTheWeekSchema,
    time: z.string(),
  });
}

export function SettlementTransactionSearchInputSchema(): z.ZodObject<
  Properties<SettlementTransactionSearchInput>
> {
  return z.object({
    accountId: z.array(z.string()).nullish(),
    after: z.string().nullish(),
    currency: z.array(z.string()).nullish(),
    executionId: z.string().nullish(),
    first: z.number().nullish(),
    from: z.string().nullish(),
    orderId: z.string().nullish(),
    parentOrderId: z.string().nullish(),
    portfolioId: z.array(z.string()).nullish(),
    rootExecutionId: z.string().nullish(),
    rootOrderId: z.string().nullish(),
    selected: z.boolean().nullish(),
    settlementId: z.string(),
    sortingOrder: SortingOrderSchema.nullish(),
    symbol: z.array(z.string()).nullish(),
    to: z.string().nullish(),
    transactionType: z.array(z.string()).nullish(),
    underlyingExecutionId: z.string().nullish(),
    venueExecutionId: z.string().nullish(),
  });
}

export function SimplePredicateInputSchema(): z.ZodObject<Properties<SimplePredicateInput>> {
  return z.object({
    field: OrderStateStringFieldSchema.nullish(),
    method: SimplePredicateTypeSchema.nullish(),
    value: z.string(),
  });
}

export function SorTargetSchema(): z.ZodObject<Properties<SorTarget>> {
  return z.object({
    __typename: z.literal('SorTarget').optional(),
    assetClass: AssetClassSchema,
    symbol: z.string(),
  });
}

export function SorTargetInputSchema(): z.ZodObject<Properties<SorTargetInput>> {
  return z.object({
    assetClass: AssetClassSchema,
    symbol: z.string(),
  });
}

export function SourceConfigurationSchema(): z.ZodObject<Properties<SourceConfiguration>> {
  return z.object({
    __typename: z.literal('SourceConfiguration').optional(),
    conversionSourceInstrument: InstrumentResponseSchema().nullish(),
    inverse: z.boolean(),
    sourceInstrument: InstrumentResponseSchema(),
  });
}

export function SourceConfigurationInputSchema(): z.ZodObject<
  Properties<SourceConfigurationInput>
> {
  return z.object({
    conversionSourceInstrumentId: z.string().nullish(),
    inverse: z.boolean(),
    sourceInstrumentId: z.string(),
  });
}

export function StreetAssetTradeSchema(): z.ZodObject<Properties<StreetAssetTrade>> {
  return z.object({
    __typename: z.literal('StreetAssetTrade').optional(),
    currency: z.string(),
    dateTime: z.string(),
    description: z.string(),
    executionId: z.string(),
    extOrderId: z.string(),
    fee: z.number(),
    feeCurrency: z.string(),
    instrument: InstrumentResponseSchema().nullish(),
    intOrderId: z.string(),
    orderId: z.string(),
    portfolioId: z.string(),
    portfolioName: z.string(),
    price: z.number(),
    quantity: z.number(),
    rootOrderId: z.string().nullish(),
    settled: z.string(),
    settledDateTime: z.number().nullish(),
    updatedAt: z.number(),
    uuid: z.string(),
    venueAccount: z.string(),
    venueAccountName: z.string(),
    venueExecutionId: z.string().nullish(),
  });
}

export function StreetCashTradeSchema(): z.ZodObject<Properties<StreetCashTrade>> {
  return z.object({
    __typename: z.literal('StreetCashTrade').optional(),
    baseCurrency: z.string(),
    currency: z.string(),
    dateTime: z.string(),
    description: z.string(),
    executionId: z.string(),
    extOrderId: z.string(),
    fee: z.number(),
    feeCurrency: z.string(),
    intOrderId: z.string(),
    orderId: z.string(),
    parentOrderId: z.string().nullish(),
    portfolioId: z.string(),
    portfolioName: z.string(),
    price: z.number(),
    quantity: z.number(),
    rootExecution: RootExecutionSchema().nullish(),
    rootOrderId: z.string().nullish(),
    settled: z.string(),
    settledDateTime: z.number().nullish(),
    underlyingExecutionId: z.string().nullish(),
    updatedAt: z.number(),
    uuid: z.string(),
    venueAccount: z.string(),
    venueAccountName: z.string(),
    venueExecutionId: z.string().nullish(),
  });
}

export function SymbolPredicateInputSchema(): z.ZodObject<Properties<SymbolPredicateInput>> {
  return z.object({
    method: SymbolPredicateTypeSchema,
    symbols: z.array(z.string()),
  });
}

export function SymbolSearchConnectionSchema(): z.ZodObject<Properties<SymbolSearchConnection>> {
  return z.object({
    __typename: z.literal('SymbolSearchConnection').optional(),
    edges: z.array(SymbolSearchEdgeSchema()),
    pageInfo: PageInfoSchema(),
  });
}

export function SymbolSearchEdgeSchema(): z.ZodObject<Properties<SymbolSearchEdge>> {
  return z.object({
    __typename: z.literal('SymbolSearchEdge').optional(),
    cursor: z.string(),
    node: SymbolSearchResponseSchema(),
  });
}

export function SymbolSearchInputSchema(): z.ZodObject<Properties<SymbolSearchInput>> {
  return z.object({
    after: z.string().nullish(),
    first: z.number().nullish(),
    sortingOrder: SortingOrderSchema.nullish(),
    symbolPredicate: z.lazy(() => SymbolPredicateInputSchema().nullish()),
    venueNames: z.array(z.string()).nullish(),
  });
}

export function SymbolSearchResponseSchema(): z.ZodObject<Properties<SymbolSearchResponse>> {
  return z.object({
    __typename: z.literal('SymbolSearchResponse').optional(),
    assetClass: AssetClassSchema.nullish(),
    symbol: z.string().nullish(),
    venues: z.array(VenueAccountNamesPerVenueSchema().nullable()).nullish(),
  });
}

export function TagSchema(): z.ZodObject<Properties<Tag>> {
  return z.object({
    __typename: z.literal('Tag').optional(),
    key: z.string(),
    value: z.string(),
  });
}

export function TagInputSchema(): z.ZodObject<Properties<TagInput>> {
  return z.object({
    key: z.string(),
    value: z.string(),
  });
}

export function TargetCapabilityStatusSchema(): z.ZodObject<Properties<TargetCapabilityStatus>> {
  return z.object({
    __typename: z.literal('TargetCapabilityStatus').optional(),
    capability: CapabilitySchema,
    healthStatus: HealthStatusSchema,
    message: z.string().nullish(),
    timestamp: z.string().nullish(),
  });
}

export function TargetStatesResponseSchema(): z.ZodObject<Properties<TargetStatesResponse>> {
  return z.object({
    __typename: z.literal('TargetStatesResponse').optional(),
    capabilities: z.array(TargetCapabilityStatusSchema().nullable()),
    target: z.string(),
    targetName: z.string().nullish(),
  });
}

export function ThresholdConfigurationSchema(): z.ZodObject<Properties<ThresholdConfiguration>> {
  return z.object({
    __typename: z.literal('ThresholdConfiguration').optional(),
    asset: z.string().nullish(),
    hedgeInstrument: InstrumentResponseSchema().nullish(),
    highThreshold: z.string().nullish(),
    lowThreshold: z.string().nullish(),
    targetExposure: z.string().nullish(),
  });
}

export function ThresholdConfigurationInputSchema(): z.ZodObject<
  Properties<ThresholdConfigurationInput>
> {
  return z.object({
    asset: z.string().nullish(),
    hedgeInstrumentId: z.string().nullish(),
    highThreshold: z.string().nullish(),
    lowThreshold: z.string().nullish(),
    targetExposure: z.string().nullish(),
  });
}

export function TifsPerOrderTypeSchema(): z.ZodObject<Properties<TifsPerOrderType>> {
  return z.object({
    __typename: z.literal('TifsPerOrderType').optional(),
    key: OrderTypeSchema.nullish(),
    values: z.array(TifSchema),
  });
}

export function TimelineConnectionSchema(): z.ZodObject<Properties<TimelineConnection>> {
  return z.object({
    __typename: z.literal('TimelineConnection').optional(),
    edges: z.array(TimelineEdgeSchema()),
    pageInfo: PageInfoSchema(),
  });
}

export function TimelineEdgeSchema(): z.ZodObject<Properties<TimelineEdge>> {
  return z.object({
    __typename: z.literal('TimelineEdge').optional(),
    cursor: z.string(),
    node: TimelineResultSchema(),
  });
}

export function TimelineResponseSchema() {
  return z.union([HedgeResultSchema(), MatchResponseSchema(), OrderStateResponseSchema()]);
}

export function TimelineResultSchema(): z.ZodObject<Properties<TimelineResult>> {
  return z.object({
    __typename: z.literal('TimelineResult').optional(),
    data: TimelineResponseSchema().nullish(),
    type: TimelineEventTypeSchema.nullish(),
  });
}

export function TimelineSearchInputSchema(): z.ZodObject<Properties<TimelineSearchInput>> {
  return z.object({
    after: z.string().nullish(),
    eventType: z.array(TimelineEventTypeSchema.nullable()).nullish(),
    first: z.number().nullish(),
    includeRelated: z.boolean().nullish(),
    orderId: z.string(),
    sortingOrder: SortingOrderSchema.nullish(),
  });
}

export function TradingCapabilitiesSchema(): z.ZodObject<Properties<TradingCapabilities>> {
  return z.object({
    __typename: z.literal('TradingCapabilities').optional(),
    accountingLongAndShortPositionsSeparately: z.boolean().nullish(),
    allowingMultipleRFQsForTheSameSecurity: z.boolean().nullish(),
    allowingMultipleRFQsOnAdapterLevel: z.boolean().nullish(),
    allowingOrdersWhenRFQIsOngoingForTheSameSecurity: z.boolean().nullish(),
    defaultTIFs: z.array(DefaultTifSchema().nullable()).nullish(),
    exchangeTradingSupported: z.boolean().nullish(),
    marginTradingSupported: z.boolean().nullish(),
    postOnlyOrderSupportedTypesAndTIFs: z.array(TifsPerOrderTypeSchema().nullable()).nullish(),
    rfqcancelSupported: z.boolean().nullish(),
    rfqstreamingSupported: z.boolean().nullish(),
    rfqsupported: z.boolean().nullish(),
    supportedOrderRequests: z.array(OrderRequestsPerOrderTypeSchema().nullable()).nullish(),
    supportedOrderTypes: z.array(OrderTypeSchema.nullable()).nullish(),
    supportedSecurityTypes: z.array(InstrumentTypeSchema.nullable()).nullish(),
    supportedTIFs: z.array(TifsPerOrderTypeSchema().nullable()).nullish(),
    supportingCustomizedOrders: z.boolean().nullish(),
    supportingFixLiteralProperties: z.boolean().nullish(),
  });
}

export function TradingConstraintsResponseSchema(): z.ZodObject<
  Properties<TradingConstraintsResponse>
> {
  return z.object({
    __typename: z.literal('TradingConstraintsResponse').optional(),
    contractSize: z.string().nullish(),
    maxPrice: z.string().nullish(),
    maxQty: z.string().nullish(),
    maxQuoteQty: z.string().nullish(),
    minNotional: z.string().nullish(),
    minPrice: z.string().nullish(),
    minQty: z.string().nullish(),
    minQuoteQty: z.string().nullish(),
    priceIncr: z.string().nullish(),
    qtyIncr: z.string().nullish(),
    quoteQtyIncr: z.string().nullish(),
    tradeable: z.boolean().nullish(),
  });
}

export function TransactionConnectionSchema(): z.ZodObject<Properties<TransactionConnection>> {
  return z.object({
    __typename: z.literal('TransactionConnection').optional(),
    edges: z.array(TransactionEdgeSchema()),
    pageInfo: PageInfoSchema(),
  });
}

export function TransactionEdgeSchema(): z.ZodObject<Properties<TransactionEdge>> {
  return z.object({
    __typename: z.literal('TransactionEdge').optional(),
    cursor: z.string(),
    node: TransactionResponseSchema(),
  });
}

export function TransactionInputSchema(): z.ZodObject<Properties<TransactionInput>> {
  return z.object({
    conversionRates: z.array(ConversionRateSchema()).nullish(),
    counterPortfolioId: z.string().nullish(),
    currency: z.string().nullish(),
    dateTime: z.string(),
    executionId: z.string().nullish(),
    externalOrderId: z.string().nullish(),
    fee: z.string().nullish(),
    feeAccountId: z.string().nullish(),
    feePortfolioId: z.string().nullish(),
    instrumentId: z.string().nullish(),
    isSettled: z.boolean(),
    portfolioId: z.string().nullish(),
    price: z.string().nullish(),
    quantity: z.string(),
    settlementDate: z.string().nullish(),
    sourceAccountId: z.string().nullish(),
    sourcePortfolioId: z.string().nullish(),
    type: TransactionTypeSchema,
    venueAccountId: z.string().nullish(),
    venueExecutionId: z.string().nullish(),
  });
}

export function TransactionResponseSchema() {
  return z.union([
    AccountCashTransferSchema(),
    ClientAssetTradeSchema(),
    ClientCashTradeSchema(),
    DepositSchema(),
    PortfolioCashTransferSchema(),
    SettlementSchema(),
    StreetAssetTradeSchema(),
    StreetCashTradeSchema(),
    WithdrawalSchema(),
  ]);
}

export function TransactionSearchInputSchema(): z.ZodObject<Properties<TransactionSearchInput>> {
  return z.object({
    accountId: z.array(z.string()).nullish(),
    after: z.string().nullish(),
    currency: z.array(z.string()).nullish(),
    executionId: z.string().nullish(),
    first: z.number().nullish(),
    from: z.string().nullish(),
    orderId: z.string().nullish(),
    parentOrderId: z.string().nullish(),
    portfolioId: z.array(z.string()).nullish(),
    rootExecutionId: z.string().nullish(),
    rootOrderId: z.string().nullish(),
    sortingOrder: SortingOrderSchema.nullish(),
    symbol: z.array(z.string()).nullish(),
    to: z.string().nullish(),
    transactionType: z.array(z.string()).nullish(),
    underlyingExecutionId: z.string().nullish(),
    venueExecutionId: z.string().nullish(),
  });
}

export function TransactionSelectSchema(): z.ZodObject<Properties<TransactionSelect>> {
  return z.object({
    selected: z.boolean(),
    transactionId: z.string(),
  });
}

export function TreasuryThresholdSchema(): z.ZodObject<Properties<TreasuryThreshold>> {
  return z.object({
    __typename: z.literal('TreasuryThreshold').optional(),
    currency: z.string(),
    maximum: z.string().nullish(),
    minimum: z.string().nullish(),
    target: z.string().nullish(),
  });
}

export function TreasuryThresholdInputSchema(): z.ZodObject<Properties<TreasuryThresholdInput>> {
  return z.object({
    currency: z.string(),
    maximum: z.string().nullish(),
    minimum: z.string().nullish(),
    target: z.string().nullish(),
  });
}

export function UpdateApiKeyNameInputSchema(): z.ZodObject<Properties<UpdateApiKeyNameInput>> {
  return z.object({
    apiKeyId: z.string().nullish(),
    apiKeyName: z.string().nullish(),
  });
}

export function UpdateBaseInstrumentInputSchema(): z.ZodObject<
  Properties<UpdateBaseInstrumentInput>
> {
  return z.object({
    description: z.string().nullish(),
    inverseContract: z.boolean().nullish(),
    symbol: z.string().nullish(),
  });
}

export function UpdateConversionSourceInputSchema(): z.ZodObject<
  Properties<UpdateConversionSourceInput>
> {
  return z.object({
    priority: z.number(),
    venueAccount: z.string(),
  });
}

export function UpdateInstrumentIdentifiersInputSchema(): z.ZodObject<
  Properties<UpdateInstrumentIdentifiersInput>
> {
  return z.object({
    adapterTicker: z.string().nullish(),
    tradingViewId: z.string().nullish(),
    venueTradingViewId: z.string().nullish(),
  });
}

export function UpdateInstrumentInputSchema(): z.ZodObject<Properties<UpdateInstrumentInput>> {
  return z.object({
    archived: z.boolean().nullish(),
    baseInstrument: z.lazy(() => UpdateBaseInstrumentInputSchema().nullish()),
    correlationObject: z.string().nullish(),
    instrumentId: z.string(),
    instrumentIdentifiers: z.lazy(() => UpdateInstrumentIdentifiersInputSchema().nullish()),
    tradingConstraints: z.lazy(() => UpdateTradingConstraintsInputSchema().nullish()),
  });
}

export function UpdateInstrumentResultSchema(): z.ZodObject<Properties<UpdateInstrumentResult>> {
  return z.object({
    __typename: z.literal('UpdateInstrumentResult').optional(),
    status: z.string().nullish(),
  });
}

export function UpdatePortfolioInputSchema(): z.ZodObject<Properties<UpdatePortfolioInput>> {
  return z.object({
    archived: z.boolean().nullish(),
    correlationObject: z.string().nullish(),
    id: z.string(),
    name: z.string().nullish(),
    portfolioType: PortfolioTypeSchema.nullish(),
    tags: z.array(z.lazy(() => TagInputSchema().nullable())).nullish(),
  });
}

export function UpdateTradingConstraintsInputSchema(): z.ZodObject<
  Properties<UpdateTradingConstraintsInput>
> {
  return z.object({
    contractSize: z.string().nullish(),
    maxPrice: z.string().nullish(),
    maxQty: z.string(),
    maxQuoteQty: z.string().nullish(),
    minNotional: z.string().nullish(),
    minPrice: z.string().nullish(),
    minQty: z.string(),
    minQuoteQty: z.string().nullish(),
    priceIncr: z.string(),
    qtyIncr: z.string(),
    quoteQtyIncr: z.string().nullish(),
    tradeable: z.boolean(),
  });
}

export function UpdateUserDataInputSchema(): z.ZodObject<Properties<UpdateUserDataInput>> {
  return z.object({
    data: z.string(),
  });
}

export function UpdateVenueAccountInputSchema(): z.ZodObject<Properties<UpdateVenueAccountInput>> {
  return z.object({
    keyValues: z.array(z.lazy(() => KeyValueInputSchema().nullable())).nullish(),
    venueAccountId: z.string().nullish(),
    venueAccountName: z.string(),
  });
}

export function UserDataResponseSchema(): z.ZodObject<Properties<UserDataResponse>> {
  return z.object({
    __typename: z.literal('UserDataResponse').optional(),
    data: z.string().nullish(),
  });
}

export function UserResponseSchema(): z.ZodObject<Properties<UserResponse>> {
  return z.object({
    __typename: z.literal('UserResponse').optional(),
    groups: z.array(GroupResponseSchema()),
    permissions: z.array(PermissionSchema()),
    username: z.string(),
  });
}

export function UsersPermissionsForResourceResponseSchema(): z.ZodObject<
  Properties<UsersPermissionsForResourceResponse>
> {
  return z.object({
    __typename: z.literal('UsersPermissionsForResourceResponse').optional(),
    scopes: z.array(ScopeSchema),
    username: z.string(),
  });
}

export function ValidationErrorSchema(): z.ZodObject<Properties<ValidationError>> {
  return z.object({
    __typename: z.literal('ValidationError').optional(),
    field: z.string(),
    message: z.string(),
  });
}

export function ValidationResponseSchema(): z.ZodObject<Properties<ValidationResponse>> {
  return z.object({
    __typename: z.literal('ValidationResponse').optional(),
    errors: z.array(ValidationErrorSchema()).nullish(),
    isValid: z.boolean(),
  });
}

export function VenueSchema(): z.ZodObject<Properties<Venue>> {
  return z.object({
    __typename: z.literal('Venue').optional(),
    name: z.string(),
    venueType: VenueTypeSchema,
  });
}

export function VenueAccountSchema(): z.ZodObject<Properties<VenueAccount>> {
  return z.object({
    __typename: z.literal('VenueAccount').optional(),
    createdAt: z.string().nullish(),
    deactivatedAt: z.string().nullish(),
    scopes: z.array(ScopeSchema),
    venueAccountId: z.string(),
    venueAccountName: z.string(),
  });
}

export function VenueAccountDescSchema(): z.ZodObject<Properties<VenueAccountDesc>> {
  return z.object({
    __typename: z.literal('VenueAccountDesc').optional(),
    id: z.string(),
    name: z.string(),
  });
}

export function VenueAccountDetailsInputSchema(): z.ZodObject<
  Properties<VenueAccountDetailsInput>
> {
  return z.object({
    venueAccount: z.string(),
  });
}

export function VenueAccountDetailsResponseSchema(): z.ZodObject<
  Properties<VenueAccountDetailsResponse>
> {
  return z.object({
    __typename: z.literal('VenueAccountDetailsResponse').optional(),
    keyValues: z.array(KeyValueSchema().nullable()).nullish(),
    scopes: z.array(ScopeSchema),
    venueAccountId: z.string(),
    venueAccountName: z.string(),
    venueName: z.string(),
  });
}

export function VenueAccountNamesPerVenueSchema(): z.ZodObject<
  Properties<VenueAccountNamesPerVenue>
> {
  return z.object({
    __typename: z.literal('VenueAccountNamesPerVenue').optional(),
    instrument: InstrumentResponseSchema().nullish(),
    venue: z.string().nullish(),
    venueAccountDescs: z.array(VenueAccountDescSchema().nullable()).nullish(),
  });
}

export function VenueAccountsPerVenueSchema(): z.ZodObject<Properties<VenueAccountsPerVenue>> {
  return z.object({
    __typename: z.literal('VenueAccountsPerVenue').optional(),
    venue: z.string().nullish(),
    venueAccounts: z.array(VenueAccountSchema().nullable()).nullish(),
  });
}

export function VenueCapabilitiesResponseSchema(): z.ZodObject<
  Properties<VenueCapabilitiesResponse>
> {
  return z.object({
    __typename: z.literal('VenueCapabilitiesResponse').optional(),
    capabilities: DetailedCapabilitiesSchema().nullish(),
    venue: z.string(),
  });
}

export function WalletAccountConnectionSchema(): z.ZodObject<Properties<WalletAccountConnection>> {
  return z.object({
    __typename: z.literal('WalletAccountConnection').optional(),
    edges: z.array(WalletAccountEdgeSchema()),
    pageInfo: PageInfoSchema(),
  });
}

export function WalletAccountEdgeSchema(): z.ZodObject<Properties<WalletAccountEdge>> {
  return z.object({
    __typename: z.literal('WalletAccountEdge').optional(),
    cursor: z.string(),
    node: WalletAccountResponseSchema(),
  });
}

export function WalletAccountResponseSchema(): z.ZodObject<Properties<WalletAccountResponse>> {
  return z.object({
    __typename: z.literal('WalletAccountResponse').optional(),
    createdAt: z.string().nullish(),
    id: z.string(),
    name: z.string(),
    scopes: z.array(ScopeSchema),
  });
}

export function WalletAccountSearchInputSchema(): z.ZodObject<
  Properties<WalletAccountSearchInput>
> {
  return z.object({
    after: z.string().nullish(),
    first: z.number().nullish(),
    name: z.string().nullish(),
    scopes: z.array(ScopeSchema).nullish(),
  });
}

export function WalletByAssetSchema(): z.ZodObject<Properties<WalletByAsset>> {
  return z.object({
    __typename: z.literal('WalletByAsset').optional(),
    asset: z.string(),
    wallet: z.string(),
  });
}

export function WalletByAssetInputSchema(): z.ZodObject<Properties<WalletByAssetInput>> {
  return z.object({
    asset: z.string(),
    wallet: z.string(),
  });
}

export function WithdrawalSchema(): z.ZodObject<Properties<Withdrawal>> {
  return z.object({
    __typename: z.literal('Withdrawal').optional(),
    account: z.string(),
    accountName: z.string(),
    currency: z.string(),
    dateTime: z.string(),
    description: z.string(),
    executionId: z.string(),
    feeAccountId: z.string().nullish(),
    feeAccountName: z.string().nullish(),
    feePortfolioId: z.string().nullish(),
    feePortfolioName: z.string().nullish(),
    portfolioId: z.string(),
    portfolioName: z.string(),
    quantity: z.number(),
    settled: z.string(),
    settledDateTime: z.number().nullish(),
    updatedAt: z.number(),
    uuid: z.string(),
    venueExecutionId: z.string().nullish(),
  });
}
